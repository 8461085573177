@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Template Name:Multikart
    Template URI: themes.pixelstrap.com/multikart
    Description: This is E-commerce website
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

----------------------------------------------------------------------------------- */
/* 1. General CSS
   1.1. Button CSS
   1.2. Title CSS
   1.3. Brand logo CSS */
/* 2. Header CSS */
/* 3. Home slider CSS  */
/* 4. Instagram CSS  */
/* 5. Blog CSS */
/* 6. Service CSS  */
/* 7. About CSS  */
/* 8. Banner Timer CSS  */
/* 9. Category CSS */
/* 10. Product Box CSS */
/* 11. Theme tab CSS */
/* 12. Banner CSS */
/* 13. Collection Banner CSS */
/* 14. Product-box slider CSS */
/* 15. Modal popup CSS */
/* 16. Look-book CSS */
/* 17. Demo css
   17.1. look-book
   17.2. beauty demo
   17.3. electronics
   17.4. pets demo
   17.5. metro demo
   17.6. gym demo
   17.7. extra layout css */
/* 18. Flying products CSS */
/* 19. Footer CSS */
/* 20. Loader CSS*/
/* 21. Tap to top CSS */
/* 22. theme setting css */
/* 23. Inner pages CSS */
/* 24. RTL CSS */
/* 25. Dark CSS */
/* 26. Menu CSS */
/* 27. Responsive CSS */
/* 28. Masonry */
/*=====================
    1.General CSS start
==========================*/
body {
  font-family: "Nanum Gothic", sans-serif;
  position: relative;
  background: #ffffff;
  font-size: 14px;
  overflow-x: hidden;
  letter-spacing: 0.8px; }

h1 {
  font-size: 60px;
  color: #222222;
  font-weight: 700;
  text-transform: uppercase; }
  h1 span {
    font-size: 107px;
    font-weight: 700;
    color: #b5dccd; }

h2 {
  font-size: 36px;
  color: #222222;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.02em; }

h3 {
  font-size: 24px;
  font-weight: 400;
  color: #777777;
  letter-spacing: 0.03em; }

h4 {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1.4; }

h5 {
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
  letter-spacing: 0.05em; }

h6 {
  font-size: 1.1rem;
  font-weight: 400;
  color: #777777;
  line-height: 24px; }

ul {
  padding-left: 0;
  margin-bottom: 0; }

li {
  display: inline-block; }

p {
  font-size: 14px;
  color: #777777;
  line-height: 1; }

a {
  transition: 0.5s ease; }
  a:hover {
    text-decoration: none;
    transition: 0.5s ease; }
  a:focus {
    outline: none; }

button:focus {
  outline: none; }

.white {
  background-color: #fff;
  border: 1px solid #aaa; }

.blue {
  background-color: #4695b1; }

.pink {
  background-color: pink; }

.yellow {
  background-color: #fdf791; }

.red {
  background-color: #b65d60; }

.gray {
  background-color: #bababa; }

.green {
  background-color: #7ee768; }

.orange {
  background-color: rgba(230, 184, 71, 0.92); }

.black {
  background-color: #1b1b1b; }

section,
.section-t-space {
  padding-top: 70px; }

.section-b-space {
  padding-bottom: 70px; }

.p-t-0 {
  padding-top: 0; }

hr.style1 {
  width: 100%;
  height: 3px;
  margin-top: 13px;
  background-color: #b5dccd;
  text-align: center; }

.no-arrow .slick-next,
.no-arrow .slick-prev {
  display: none !important; }

.icon-angle-left:before {
  content: '\E64A'; }

.form-control {
  border-radius: 0; }

.small-section {
  padding-top: 35px;
  padding-bottom: 35px; }

.banner-padding {
  padding-top: 30px; }

.border-section {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd; }

.border-b {
  border-bottom: 1px solid #38352f; }

.border-bottom-grey {
  border-bottom: 1px solid #efefef; }

.border-top-grey {
  border-top: 1px solid #efefef; }

.darken-layout {
  background-color: #393230; }

.dark-layout {
  background-color: #2d2a25; }

.light-layout {
  background-color: #f9f9f9; }

.white-layout {
  background-color: #ffffff; }

.bg-light0 {
  background-color: #d0edff; }

.bg-light1 {
  background-color: #f1e7e6; }

.bg-light2 {
  background-color: #bfbfbf; }

.bg-blog {
  background-color: #eeeeee; }

.Toastify__toast {
  min-height: 36px; }

.input-range__track--active {
  background: #b5dccd !important; }

.input-range__slider {
  background: #b5dccd !important;
  border: 1px solid #b5dccd !important; }

.block-price-content .custom-control {
  padding-right: 1.5rem;
  padding-bottom: 18px; }

del {
  font-size: 14px;
  color: #aaaaaa;
  font-weight: 400; }

.sidenav .sidebar-back {
  display: flex;
  align-items: center; }

.srv-validation-message {
  color: red; }

.sticky.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  transition: all 300ms ease;
  background: #b5dccd;
  box-shadow: 0px 0px 8px #ddd;
  animation: smoothScroll 1s forwards; }
  .sticky.fixed .brand-logo {
    padding-top: 15px;
    padding-bottom: 15px; }
  .sticky.fixed .onhover-div .show-div {
    top: 66px; }
  .sticky.fixed .top-header {
    display: none; }
  .sticky.fixed .cart-qty-cls {
    top: 13%;
    right: -11px; }

@-webkit-keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }

@keyframes smoothScroll {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }

.shopping-cart {
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .shopping-cart::-webkit-scrollbar {
    width: 3px; }
  .shopping-cart::-webkit-scrollbar-track {
    background: #ddd; }
  .shopping-cart::-webkit-scrollbar-thumb {
    background: #b5dccd;
    border-radius: 10px; }
  .shopping-cart::-webkit-scrollbar-thumb:hover {
    background: #555; }

[data-notify='progressbar'] {
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px; }

.progress-bar {
  background-color: #19a340; }

.progress-bar-info {
  background-color: #00829a; }

.ratio_40 .bg-size:before {
  padding-top: 40%;
  content: '';
  display: block; }

.ratio_45 .bg-size:before {
  padding-top: 45%;
  content: '';
  display: block; }

.ratio2_1 .bg-size:before {
  padding-top: 50%;
  content: '';
  display: block; }

.ratio2_3 .bg-size:before {
  padding-top: 60%;
  content: '';
  display: block; }

.ratio3_2 .bg-size:before {
  padding-top: 66.66%;
  content: '';
  display: block; }

.ratio_landscape .bg-size:before {
  padding-top: 75%;
  content: '';
  display: block; }

.ratio_square .bg-size:before {
  padding-top: 100%;
  content: '';
  display: block; }

.ratio_asos .bg-size:before {
  padding-top: 127.7777778%;
  content: '';
  display: block; }

.ratio_portrait .bg-size:before {
  padding-top: 150%;
  content: '';
  display: block; }

.ratio1_2 .bg-size:before {
  padding-top: 200%;
  content: '';
  display: block; }

.b-top {
  background-position: top !important; }

.b-bottom {
  background-position: bottom !important; }

.b-center {
  background-position: center !important; }

.b_size_content {
  background-size: contain !important;
  background-repeat: no-repeat; }

/*=====================
    1.1.Button CSS start
==========================*/
button {
  cursor: pointer; }

.btn {
  line-height: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0;
  transition: 0.3s ease-in-out; }
  .btn:hover {
    transition: 0.3s ease-in-out; }
  .btn:focus {
    box-shadow: none; }

.btn-solid {
  padding: 13px 29px;
  color: black;
  letter-spacing: 0.05em;
  border: 2px solid #b5dccd;
  background-image: -webkit-linear-gradient(30deg, #b5dccd 50%, transparent 50%);
  background-image: linear-gradient(30deg, #b5dccd 50%, transparent 50%);
  background-size: 540px;
  background-repeat: no-repeat;
  background-position: 0;
  -webkit-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out; }
  .btn-solid:hover {
    background-position: 100%;
    color: #000000 !important;
    background-color: #ffffff; }
  .btn-solid.black-btn {
    background-image: -webkit-linear-gradient(30deg, #222222 50%, transparent 50%);
    background-image: linear-gradient(30deg, #222222 50%, transparent 50%);
    border: 2px solid #222222; }
  .btn-solid:focus {
    color: #ffffff; }

.btn-outline {
  display: inline-block;
  padding: 13px 29px;
  letter-spacing: 0.05em;
  border: 2px solid #b5dccd;
  position: relative;
  color: #000000; }
  .btn-outline:before {
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: '';
    background-color: #b5dccd;
    z-index: -2; }
  .btn-outline:hover {
    color: #ffffff; }
    .btn-outline:hover:before {
      -webkit-transition: 0.5s all ease;
      transition: 0.5s all ease;
      left: 0;
      right: 0;
      opacity: 1; }
  .btn-outline:focus:before {
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1; }

button.btn.btn-solid:active, button.btn.btn-outline:active {
  background-image: -webkit-linear-gradient(30deg, #b5dccd 50%, transparent 50%);
  background-image: linear-gradient(30deg, #b5dccd 50%, transparent 50%);
  color: #ffffff;
  background: #b5dccd; }

.btn-classic:hover {
  background-color: #b5dccd; }

.btn-theme {
  background-color: #b5dccd;
  color: #ffffff; }

/*=====================
    1.2.Title CSS start
    ==========================*/
.title1 {
  text-align: center; }
  .title1 h4 {
    color: #b5dccd;
    padding-bottom: 10px;
    margin-bottom: 0;
    margin-top: -3px; }
  .title1 .title-inner1 {
    padding-bottom: 15px;
    margin-bottom: 30px;
    position: relative; }
    .title1 .title-inner1:after {
      position: absolute;
      height: 5px;
      width: 25rem;
      background-color: #b5dccd;
      content: '';
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0; }
  .title1.title5 .title-inner1 {
    padding-bottom: 0;
    margin-bottom: 0; }
    .title1.title5 .title-inner1:after {
      display: none; }
  .title1.title5 hr[role='tournament6'] {
    border: 0px solid;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(181, 220, 205, 0.75), rgba(0, 0, 0, 0));
    display: block;
    width: 25%;
    margin: 15px auto 30px auto; }
  .title1.title5 hr[role='tournament6']::before {
    position: absolute;
    background-color: #efefef;
    border: 1px solid;
    border-color: #b5dccd;
    padding: 5px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 50%;
    margin: -6px 0px 0px 0;
    content: ''; }

.title2 {
  text-align: center; }
  .title2 h4 {
    color: #b5dccd;
    padding-bottom: 10px;
    margin-bottom: 0;
    text-transform: uppercase;
    margin-top: -3px; }
  .title2 .title-inner2 {
    margin-bottom: 50px;
    position: relative;
    display: inline-block;
    padding: 0 25px; }
    .title2 .title-inner2:after {
      position: absolute;
      height: 5px;
      width: 100%;
      background-color: #d8ece4;
      content: '';
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 3px;
      z-index: -1; }

.title3 {
  text-align: center; }
  .title3 h4 {
    color: #b5dccd;
    padding-bottom: 10px;
    margin-bottom: 5px;
    margin-top: -3px; }
  .title3 .title-inner3 {
    margin-bottom: 20px;
    position: relative;
    margin-top: -5px; }
  .title3 .line {
    position: relative;
    height: 5px;
    width: 45px;
    background-color: #b5dccd;
    content: '';
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    margin-bottom: 50px; }
    .title3 .line:after, .title3 .line:before {
      position: absolute;
      height: 5px;
      width: 10px;
      content: '';
      background-color: #b5dccd; }
    .title3 .line:after {
      right: 50px; }
    .title3 .line:before {
      left: 50px; }

.title4 {
  text-align: center; }
  .title4 .title-inner4 {
    padding-bottom: 25px;
    margin-bottom: 0;
    position: relative;
    margin-top: -5px; }
  .title4 .line {
    position: relative;
    height: 1px;
    width: 180px;
    background-color: #b5dccd;
    margin: 0 auto;
    margin-bottom: 55px;
    bottom: 0;
    top: 0; }
    .title4 .line:after, .title4 .line:before {
      position: absolute;
      height: 10px;
      width: 10px;
      content: '';
      background-color: #b5dccd;
      top: -4px; }
    .title4 .line:after {
      left: 78px; }
    .title4 .line:before {
      left: 64px; }
    .title4 .line span:after, .title4 .line span:before {
      position: absolute;
      height: 10px;
      width: 10px;
      content: '';
      background-color: #b5dccd;
      top: -4px; }
    .title4 .line span:after {
      right: 78px; }
    .title4 .line span:before {
      right: 64px; }

.title-borderless {
  margin-bottom: 30px;
  text-align: center;
  margin-top: -5px; }

/*=====================
    1.3.Brand logo CSS start
    ==========================*/
.logo-block a:focus {
  outline: none; }

.logo-block img {
  filter: grayscale(100%);
  opacity: 0.5;
  transition: 0.5s ease;
  margin: 0 auto; }
  .logo-block img:focus {
    outline: none; }
  .logo-block img:hover {
    filter: grayscale(0);
    opacity: 1;
    transition: 0.5s ease; }

/*=====================
    2.Header CSS start
==========================*/
header {
  background-color: #ffffff; }

.onhover-dropdown {
  position: relative;
  font-size: 14px; }
  .onhover-dropdown:before {
    content: '\f107';
    font-family: "Font Awesome 5 Free";
    position: absolute;
    right: 3px; }
  .onhover-dropdown .onhover-show-div {
    top: 50px;
    position: absolute;
    z-index: 10;
    background-color: #ffffff;
    transition: all linear 0.3s;
    min-width: 160px;
    text-align: left;
    -webkit-box-shadow: 0 0 1px 0 #ebebeb;
    -moz-box-shadow: 0 0 1px 0 #ebebeb;
    box-shadow: 0 0 1px 0 #ebebeb;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    right: 0;
    padding: 15px 20px;
    border-radius: 0 !important; }
    .onhover-dropdown .onhover-show-div.product-page-full {
      z-index: 99999; }
  .onhover-dropdown:hover .onhover-show-div {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible; }

.onhover-div {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  font-family: "Nanum Gothic", sans-serif; }
  .onhover-div img,
  .onhover-div i {
    cursor: pointer; }
  .onhover-div .show-div {
    top: 103px;
    position: absolute;
    z-index: 9;
    background-color: #ffffff;
    transition: all linear 0.3s;
    min-width: 236px;
    text-align: left;
    -webkit-box-shadow: 0 0 1px 0 #ebebeb;
    -moz-box-shadow: 0 0 1px 0 #ebebeb;
    box-shadow: 0 0 1px 0 #ebebeb;
    transform: translateY(30px);
    opacity: 0;
    visibility: hidden;
    right: 0;
    padding: 0;
    border-radius: 0 !important; }
  .onhover-div:hover .show-div {
    opacity: 1;
    transform: translateY(0px);
    visibility: visible; }

.search-overlay {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: white; }
  .search-overlay > div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .search-overlay > div .overlay-content {
      width: 100%; }
      .search-overlay > div .overlay-content .form-control:focus {
        box-shadow: none; }
      .search-overlay > div .overlay-content input {
        background-color: transparent;
        border: 0;
        border-bottom: 2px solid #777777;
        border-radius: 0;
        padding: 20px 0; }
        .search-overlay > div .overlay-content input::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #777777;
          font-size: 18px; }
        .search-overlay > div .overlay-content input::-moz-placeholder {
          /* Firefox 19+ */
          color: #777777;
          font-size: 18px; }
        .search-overlay > div .overlay-content input:-ms-input-placeholder {
          /* IE 10+ */
          color: #777777;
          font-size: 18px; }
        .search-overlay > div .overlay-content input:-moz-placeholder {
          /* Firefox 18- */
          color: #777777;
          font-size: 18px; }
      .search-overlay > div .overlay-content button {
        position: absolute;
        top: 10px;
        background-color: transparent;
        border: 0;
        font-size: 24px;
        color: #777777;
        right: 10px; }
    .search-overlay > div .closebtn {
      position: fixed;
      top: 10px;
      right: 25px;
      color: #777777;
      font-size: 40px;
      cursor: pointer; }

.top-header {
  background-color: #f8f8f8; }
  .top-header .header-contact {
    padding: 15px 0; }
    .top-header .header-contact li {
      color: #999999;
      font-size: 14px;
      padding-right: 25px; }
      .top-header .header-contact li i {
        color: #b5dccd;
        padding-right: 10px; }
  .top-header .header-dropdown li {
    cursor: pointer;
    color: #999999;
    padding: 15px 25px;
    padding-right: 20px;
    font-size: 14px; }
    .top-header .header-dropdown li:first-child {
      padding-right: 0; }
    r .top-header .header-dropdown li:nth-child(2) {
      padding-right: 25px; }
    .top-header .header-dropdown li a {
      text-transform: capitalize;
      color: #999999; }
    .top-header .header-dropdown li i {
      padding-right: 5px;
      transition: all 0.5s ease; }
    .top-header .header-dropdown li ul li {
      padding-top: 0;
      padding-bottom: 0; }
    .top-header .header-dropdown li a i {
      color: #999999;
      transition: all 0.5s ease; }
    .top-header .header-dropdown li a:hover i {
      color: #b5dccd;
      transition: all 0.5s ease; }
    .top-header .header-dropdown li:hover i {
      color: #b5dccd;
      transition: all 0.5s ease; }
  .top-header .header-dropdown .onhover-dropdown .onhover-show-div li {
    padding-left: 0;
    display: flex;
    font-size: 15px;
    padding-bottom: 5px; }
    .top-header .header-dropdown .onhover-dropdown .onhover-show-div li:last-child {
      padding-bottom: 0; }
    .top-header .header-dropdown .onhover-dropdown .onhover-show-div li a {
      color: #333333; }
  .top-header.top-header-dark {
    background-color: #222222; }
    .top-header.top-header-dark .header-contact li {
      color: #777777; }
      .top-header.top-header-dark .header-contact li i {
        color: #777777; }
    .top-header.top-header-dark .header-dropdown li {
      color: #777777; }
      .top-header.top-header-dark .header-dropdown li a {
        color: #777777; }
        .top-header.top-header-dark .header-dropdown li a i {
          color: #777777; }
        .top-header.top-header-dark .header-dropdown li a:hover i {
          color: #b5dccd; }
  .top-header.top-header-dark2 {
    background-color: #2d2a25; }
    .top-header.top-header-dark2 .header-contact li {
      color: #7f786d; }
      .top-header.top-header-dark2 .header-contact li i {
        color: #7f786d; }
    .top-header.top-header-dark2 .header-dropdown li {
      color: #7f786d; }
      .top-header.top-header-dark2 .header-dropdown li a {
        color: #7f786d; }
        .top-header.top-header-dark2 .header-dropdown li a i {
          color: #7f786d; }
        .top-header.top-header-dark2 .header-dropdown li a:hover i {
          color: #b5dccd; }
  .top-header.top-header-dark3 {
    background-color: #393230; }
    .top-header.top-header-dark3 .header-contact li {
      color: #948e8c; }
      .top-header.top-header-dark3 .header-contact li i {
        color: #948e8c; }
    .top-header.top-header-dark3 .header-dropdown li {
      color: #948e8c; }
      .top-header.top-header-dark3 .header-dropdown li a {
        color: #948e8c; }
        .top-header.top-header-dark3 .header-dropdown li a i {
          color: #948e8c; }
        .top-header.top-header-dark3 .header-dropdown li a:hover i {
          color: #b5dccd; }

.layout3-menu .main-menu .menu-left .navbar i {
  font-size: 22px; }

.main-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9; }
  .main-menu.product-full-page {
    z-index: 9999; }
  .main-menu .brand-logo {
    display: inline-block;
    padding-top: 35px;
    padding-bottom: 35px; }
  .main-menu .menu-left {
    display: flex;
    align-items: center; }
    .main-menu .menu-left .navbar {
      display: inline-block;
      padding: 40px 45px 40px 0; }
      .main-menu .menu-left .navbar i {
        font-size: 28px;
        color: #222222;
        cursor: pointer; }
      .main-menu .menu-left .navbar.white-navbar i {
        color: #ffffff; }
    .main-menu .menu-left.around-border .navbar > a {
      border: 2px solid white;
      padding: 11px 10px 6px; }
    .main-menu .menu-left.category-nav-right .navbar {
      padding-right: 0;
      padding-left: 45px; }
      .main-menu .menu-left.category-nav-right .navbar > a {
        padding: 0; }
      .main-menu .menu-left.category-nav-right .navbar i {
        color: #938d8c; }
  .main-menu .menu-right {
    display: flex;
    float: right; }
    .main-menu .menu-right .icon-nav {
      display: inline-block; }
      .main-menu .menu-right .icon-nav li {
        padding-left: 20px; }
      .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart {
        padding: 15px 15px;
        min-width: 270px;
        left: unset;
        right: 0;
        max-height: 250px;
        overflow-y: auto; }
        .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li {
          position: relative;
          padding-bottom: 10px;
          width: 100%; }
          .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li:last-child {
            padding-bottom: 0; }
          .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media img {
            height: 90px; }
          .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body {
            align-self: center; }
            .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 {
              color: #333333; }
              .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 span {
                color: #777777;
                font-size: 16px; }
          .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle {
            position: absolute;
            top: 0;
            right: 0; }
            .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i {
              color: #a1a1a1;
              transition: all 0.3s ease; }
              .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i:hover {
                color: #000000;
                transition: all 0.3s ease; }
          .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total {
            border-top: 1px solid #f1f5f4;
            border-bottom: 1px solid #f1f5f4;
            padding-top: 10px;
            padding-bottom: 10px; }
            .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 {
              text-transform: capitalize;
              margin-bottom: 0;
              color: #999999; }
              .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 span {
                float: right; }
          .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a {
            font-size: 16px;
            color: #333333;
            text-transform: capitalize;
            font-weight: 700; }
            .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a:hover {
              color: #b5dccd; }
          .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons .checkout {
            float: right; }
      .main-menu .menu-right .icon-nav .onhover-div .show-div.setting {
        padding: 15px 20px 20px;
        min-width: 175px;
        right: 0;
        left: unset; }
        .main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6 {
          font-size: 16px;
          text-transform: capitalize;
          color: #333333;
          font-weight: 700;
          margin-bottom: 0;
          margin-top: 5px; }
          .main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6:first-child {
            margin-top: 0; }
        .main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li {
          display: flex;
          padding-bottom: 0;
          padding-left: 17px;
          padding-top: 5px; }
          .main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li a {
            color: #333333;
            font-weight: 400;
            font-size: 16px;
            text-transform: capitalize; }
      .main-menu .menu-right .icon-nav .onhover-div .show-div li {
        padding: 0; }
        .main-menu .menu-right .icon-nav .onhover-div .show-div li .form-control {
          border-radius: 0; }
          .main-menu .menu-right .icon-nav .onhover-div .show-div li .form-control:focus {
            border: 1px solid #ced4da;
            box-shadow: none; }
        .main-menu .menu-right .icon-nav .onhover-div .show-div li .search-btn {
          padding: 8px 12px;
          background-color: #b5dccd;
          color: #ffffff; }
      .main-menu .menu-right .icon-nav .onhover-div .show-div ul li a {
        cursor: pointer; }
      .main-menu .menu-right .icon-nav.white-icon .onhover-div img {
        filter: brightness(5); }
      .main-menu .menu-right .icon-nav.white-icon .onhover-div .show-div img {
        filter: none; }

.nav-cat li {
  position: relative;
  display: flex;
  padding-left: 25px;
  padding-right: 25px; }
  .nav-cat li .round-cat {
    height: 50px;
    width: 50px;
    border: 1px solid #dddddd;
    padding: 12px;
    border-radius: 100%;
    margin-right: 10px; }
  .nav-cat li a {
    color: #222222;
    font-size: 16px;
    text-transform: uppercase; }

.Toastify__toast--info {
  background: #007bff; }

.Toastify__toast--success {
  background: #22af47; }

.Toastify__toast--warning {
  background: #ff850d; }

.Toastify__toast--error {
  background: #ff2046; }

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px); }
  100% {
    transform: translateY(0px); } }

/*=====================
    3.Home slider CSS start
==========================*/
.home-slider .slick-prev,
.home-slider .slick-next,
.center-slider .slick-prev,
.center-slider .slick-next {
  opacity: 0;
  transform: scale(1);
  transition: all 0.5s ease; }
  .home-slider .slick-prev:before,
  .home-slider .slick-next:before,
  .center-slider .slick-prev:before,
  .center-slider .slick-next:before {
    font: "Font Awesome 5 Free";
    opacity: 1;
    color: black;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.home-slider .slick-next,
.center-slider .slick-next {
  right: 1px; }
  .home-slider .slick-next:before,
  .center-slider .slick-next:before {
    font-weight: 900; }

.home-slider .slick-prev,
.center-slider .slick-prev {
  left: 1px;
  z-index: 1; }
  .home-slider .slick-prev:before,
  .center-slider .slick-prev:before {
    font-weight: 900; }

.home-slider .home,
.center-slider .home {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 75vh; }
  .home-slider .home.home1,
  .center-slider .home.home1 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/1.jpg"); }
  .home-slider .home.home2,
  .center-slider .home.home2 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/2.jpg"); }
  .home-slider .home.home3,
  .center-slider .home.home3 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/3.jpg"); }
  .home-slider .home.home4,
  .center-slider .home.home4 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/4.jpg"); }
  .home-slider .home.home5,
  .center-slider .home.home5 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/5.jpg"); }

@media (max-width: 576px) {
  .home-slider .home.home1,
  .center-slider .home.home1 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/mobile/1.jpg"); }
  .home-slider .home.home2,
  .center-slider .home.home2 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/mobile/2.jpg"); } }

.home-slider .slider-contain,
.center-slider .slider-contain {
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: center; }
  .home-slider .slider-contain h1,
  .center-slider .slider-contain h1 {
    margin-bottom: 0;
    margin-top: 10px;
    color: #222222; }
  .home-slider .slider-contain h4,
  .center-slider .slider-contain h4 {
    font-weight: 700;
    letter-spacing: 0.1em;
    color: #777777;
    margin-bottom: 0; }
  .home-slider .slider-contain .btn-solid,
  .home-slider .slider-contain .btn-outline,
  .center-slider .slider-contain .btn-solid,
  .center-slider .slider-contain .btn-outline {
    margin-top: 20px; }

.home-slider .p-left .slider-contain,
.center-slider .p-left .slider-contain {
  justify-content: flex-start; }

.home-slider .p-right .slider-contain,
.center-slider .p-right .slider-contain {
  justify-content: flex-end; }

.home-slider .p-center .slider-contain,
.center-slider .p-center .slider-contain {
  justify-content: center; }

.home-slider:hover .slick-prev,
.home-slider:hover .slick-next,
.center-slider:hover .slick-prev,
.center-slider:hover .slick-next {
  opacity: 1;
  transform: scale(2.5);
  transition: all 0.5s ease; }
  .home-slider:hover .slick-prev:before,
  .home-slider:hover .slick-next:before,
  .center-slider:hover .slick-prev:before,
  .center-slider:hover .slick-next:before {
    opacity: 1; }

.home-slider:hover .slick-next,
.center-slider:hover .slick-next {
  right: 100px; }

.home-slider:hover .slick-prev,
.center-slider:hover .slick-prev {
  left: 100px; }

.home-slider-container .slider-contain {
  padding: 0 100px; }

.center-slider {
  border-left: 1px solid #f1f5f4;
  border-right: 1px solid #f1f5f4; }

.home-slider.text-white .slider-contain h1,
.home-slider.text-white .slider-contain h4 {
  color: #ffffff; }

.gym-slider .home-slider .home {
  height: 85vh; }
  .gym-slider .home-slider .home .slider-contain {
    height: 85vh; }
    .gym-slider .home-slider .home .slider-contain h4,
    .gym-slider .home-slider .home .slider-contain h1 {
      color: #ffffff; }

.metro-slider .home-slider .home {
  background-position-y: 70%; }
  .metro-slider .home-slider .home .slider-contain {
    margin-top: 75px; }
    .metro-slider .home-slider .home .slider-contain h4,
    .metro-slider .home-slider .home .slider-contain h1 {
      color: #000000; }

.small-slider .home-slider .home {
  height: 55vh; }
  .small-slider .home-slider .home .slider-contain {
    height: 55vh; }
    .small-slider .home-slider .home .slider-contain h4,
    .small-slider .home-slider .home .slider-contain h1 {
      color: #000000; }

.height-100 .home-slider .home {
  height: 100vh; }

.height-100 .home-slider .slider-contain {
  height: calc(99vh + 80px); }

.height-85 .home-slider .home {
  height: 85vh; }

.height-85 .home-slider .slider-contain {
  height: 85vh; }

.height-85.content_top .slider-contain {
  padding-top: 150px;
  align-items: flex-start; }
  .height-85.content_top .slider-contain h1 {
    font-size: 40px;
    letter-spacing: 0.05em; }
  .height-85.content_top .slider-contain .btn-solid {
    padding: 9px 18px;
    margin-top: 10px; }
  .height-85.content_top .slider-contain h4 {
    letter-spacing: 0.08em; }

.layout-7 .home {
  height: 60vh; }
  .layout-7 .home .slider-contain {
    height: 60vh; }

.banner-slider .height-banner {
  height: 100%; }

.banner-slider .home {
  height: 81vh; }
  .banner-slider .home .slider-contain {
    height: 81vh; }

.banner-slider .home-banner > div img {
  width: 100%; }

.banner-slider .home-banner > div:last-child img {
  margin-top: 30px; }

.absolute-banner {
  margin-top: -105px; }
  .absolute-banner .absolute-bg {
    background-color: white;
    position: relative;
    padding: 25px;
    box-shadow: 0 0 8px 0 #ddd; }

/*=====================
    3.Home slider CSS start - Jumbotron
==========================*/
.home-slider .slick-prev,
.home-slider .slick-next,
.center-slider .slick-prev,
.center-slider .slick-next {
  opacity: 0;
  transform: scale(1);
  transition: all 0.5s ease; }
  .home-slider .slick-prev:before,
  .home-slider .slick-next:before,
  .center-slider .slick-prev:before,
  .center-slider .slick-next:before {
    font: "Font Awesome 5 Free";
    opacity: 1;
    color: black;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

.home-slider .slick-next,
.center-slider .slick-next {
  right: 1px; }
  .home-slider .slick-next:before,
  .center-slider .slick-next:before {
    font-weight: 900; }

.home-slider .slick-prev,
.center-slider .slick-prev {
  left: 1px;
  z-index: 1; }
  .home-slider .slick-prev:before,
  .center-slider .slick-prev:before {
    font-weight: 900; }

.home-slider .home-jumbo,
.center-slider .home-jumbo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 75vh; }
  .home-slider .home-jumbo.home-jumbo1,
  .center-slider .home-jumbo.home-jumbo1 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/1.jpg"); }
  .home-slider .home-jumbo.home-jumbo2,
  .center-slider .home-jumbo.home-jumbo2 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/2.jpg"); }
  .home-slider .home-jumbo.home-jumbo3,
  .center-slider .home-jumbo.home-jumbo3 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/3.jpg"); }
  .home-slider .home-jumbo.home-jumbo4,
  .center-slider .home-jumbo.home-jumbo4 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/4.jpg"); }
  .home-slider .home-jumbo.home-jumbo5,
  .center-slider .home-jumbo.home-jumbo5 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/5.jpg"); }

@media (max-width: 576px) {
  .home-slider .home-jumbo.home-jumbo1,
  .center-slider .home-jumbo.home-jumbo1 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/mobile/1.jpg"); }
  .home-slider .home-jumbo.home-jumbo2,
  .center-slider .home-jumbo.home-jumbo2 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/mobile/2.jpg"); }
  .home-slider .home-jumbo.home-jumbo3,
  .center-slider .home-jumbo.home-jumbo3 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/mobile/3.jpg"); }
  .home-slider .home-jumbo.home-jumbo4,
  .center-slider .home-jumbo.home-jumbo4 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/mobile/4.jpg"); }
  .home-slider .home-jumbo.home-jumbo5,
  .center-slider .home-jumbo.home-jumbo5 {
    background-image: url("../assets/photos/home-banner/landing-jumbo/mobile/5.jpg"); } }

.home-slider .slider-contain,
.center-slider .slider-contain {
  width: 100%;
  height: 75vh;
  display: flex;
  align-items: center; }
  .home-slider .slider-contain h1,
  .center-slider .slider-contain h1 {
    margin-bottom: 0;
    margin-top: 10px;
    color: #222222; }
  .home-slider .slider-contain h4,
  .center-slider .slider-contain h4 {
    font-weight: 700;
    letter-spacing: 0.1em;
    color: #777777;
    margin-bottom: 0; }
  .home-slider .slider-contain .btn-solid,
  .home-slider .slider-contain .btn-outline,
  .center-slider .slider-contain .btn-solid,
  .center-slider .slider-contain .btn-outline {
    margin-top: 20px; }

.home-slider .p-left .slider-contain,
.center-slider .p-left .slider-contain {
  justify-content: flex-start; }

.home-slider .p-right .slider-contain,
.center-slider .p-right .slider-contain {
  justify-content: flex-end; }

.home-slider .p-center .slider-contain,
.center-slider .p-center .slider-contain {
  justify-content: center; }

.home-slider:hover .slick-prev,
.home-slider:hover .slick-next,
.center-slider:hover .slick-prev,
.center-slider:hover .slick-next {
  opacity: 1;
  transform: scale(2.5);
  transition: all 0.5s ease; }
  .home-slider:hover .slick-prev:before,
  .home-slider:hover .slick-next:before,
  .center-slider:hover .slick-prev:before,
  .center-slider:hover .slick-next:before {
    opacity: 1; }

.home-slider:hover .slick-next,
.center-slider:hover .slick-next {
  right: 100px; }

.home-slider:hover .slick-prev,
.center-slider:hover .slick-prev {
  left: 100px; }

/*=====================
    4.Instagram CSS start
==========================*/
.instagram-box {
  position: relative;
  background-color: #b5dccd;
  overflow: hidden; }
  .instagram-box img {
    width: 100%; }
  .instagram-box .overlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(181, 220, 205, 0.5);
    top: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(3) rotate(90deg); }
    .instagram-box .overlay i {
      font-size: 60px;
      color: #ffffff; }
  .instagram-box:hover .overlay {
    opacity: 1;
    transition: 0.5s ease;
    transform: scale(1) rotate(0deg); }

/*=====================
    5.Blog CSS start
==========================*/
.blog .slick-slide {
  line-height: 1; }

.blog-bg {
  background-color: #f1f5f4; }

.classic-effect {
  position: relative;
  overflow: hidden; }
  .classic-effect:before, .classic-effect:after {
    background: #b5dccd;
    width: 25%;
    position: absolute;
    content: '';
    opacity: 0;
    -webkit-transition: all 0.3s steps(4);
    transition: all 0.3s steps(4);
    z-index: 1;
    bottom: 100%;
    top: 0; }
  .classic-effect:before {
    left: 0;
    transition-delay: 0s; }
  .classic-effect:after {
    left: 25%;
    transition-delay: 0.025s; }
  .classic-effect span:before, .classic-effect span:after {
    background: #b5dccd;
    width: 25%;
    position: absolute;
    content: '';
    opacity: 0;
    -webkit-transition: all 0.3s steps(4);
    transition: all 0.3s steps(4);
    z-index: 1;
    bottom: 100%;
    top: 0; }
  .classic-effect span:before {
    left: 50%;
    transition-delay: 0.05s; }
  .classic-effect span:after {
    left: 75%;
    transition-delay: 0.15s; }
  .classic-effect:hover:before, .classic-effect:hover:after {
    bottom: 0;
    opacity: 0.5; }
  .classic-effect:hover span:before, .classic-effect:hover span:after {
    bottom: 0;
    opacity: 0.5; }

.blog-details {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px; }
  .blog-details h4 {
    color: #b5dccd;
    margin-top: 25px;
    line-height: 1;
    margin-bottom: 0; }
  .blog-details p {
    color: #222222;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.03em;
    margin-top: 10px;
    margin-bottom: -5px;
    transition: all 0.5s ease; }
    .blog-details p:hover {
      color: #b5dccd;
      transition: all 0.5s ease; }
  .blog-details h6 {
    line-height: 1;
    letter-spacing: 0.1em;
    margin-bottom: 0; }

.blog-2 .blog-details h6 {
  padding: 1rem 0 1rem 1.4rem; }

/*=====================
    6.Service CSS start
==========================*/
.service-block {
  display: flex;
  align-items: center;
  justify-content: center; }
  .service-block .media {
    padding-top: 23px;
    padding-bottom: 23px;
    align-items: center; }
  .service-block svg {
    margin-right: 12px;
    width: 60px;
    height: 60px; }
    .service-block svg path {
      fill: #b5dccd; }
  .service-block h4 {
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.03em; }
  .service-block p {
    text-transform: capitalize;
    margin-bottom: 0; }
  .service-block + .service-block {
    border-left: 1px solid #dddddd; }
  .service-block:hover svg {
    animation: pulse 1000ms ease-in-out;
    transition: all 0.3s ease; }
  .service-block:hover h4 {
    color: #b5dccd;
    transition: all 0.3s ease; }

.service-block1 {
  text-align: center; }
  .service-block1 svg {
    margin-bottom: 20px;
    width: 60px;
    height: 60px; }
    .service-block1 svg path {
      fill: #b5dccd; }
  .service-block1 h4 {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px; }
  .service-block1 p {
    letter-spacing: 0.03em;
    margin-bottom: -3px;
    line-height: 1.5; }
  .service-block1:hover svg {
    animation: pulse 1000ms ease-in-out;
    transition: all 0.3s ease; }
  .service-block1:hover h4 {
    color: #b5dccd;
    transition: all 0.3s ease; }

/*=====================
    7.About CSS start
==========================*/
.about-text p {
  line-height: 28px;
  letter-spacing: 0.06em;
  text-align: center;
  margin-bottom: 50px; }

/*=====================
    8.Banner Timer CSS start
==========================*/
.banner-timer {
  background-image: url("/assets/images/offer-banner.jpg");
  background-size: cover;
  margin-left: 20px;
  margin-right: 20px; }
  .banner-timer .banner-text {
    padding-top: 45px;
    padding-bottom: 45px; }
    .banner-timer .banner-text h2 {
      margin-bottom: 0;
      text-align: center; }
      .banner-timer .banner-text h2 span {
        color: #b5dccd; }
  .banner-timer .timer-box {
    justify-content: center;
    display: flex; }

.timer {
  padding-top: 15px;
  padding-bottom: 7px;
  padding-left: 40px;
  margin-top: 30px;
  background-color: #2d2a25;
  display: inline-block; }
  .timer p {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 0; }
  .timer span {
    width: 70px;
    display: inline-block; }
    .timer span .timer-cal {
      font-size: 12px;
      color: #777777; }
    .timer span .padding-l {
      padding-left: 22px;
      display: inline; }

/*=====================
    9.Category CSS start
==========================*/
.category-block .category-image {
  margin: 0 auto;
  text-align: center;
  border: 1px solid #dddddd;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease; }

.category-block .category-details {
  margin-top: 15px;
  text-align: center; }
  .category-block .category-details h5 {
    font-weight: 700;
    margin: 0 auto;
    transition: 0.5s ease;
    text-transform: uppercase; }

.category-block:hover .category-image {
  background-color: #b5dccd;
  transition: all 0.5s ease; }
  .category-block:hover .category-image img {
    filter: brightness(0) invert(1);
    transition: all 0.5s ease; }

.category-block:hover .category-details h5 {
  color: #b5dccd;
  transition: all 0.5s ease; }

.category-border {
  background-color: #f1f5f4;
  padding: 20px 10px; }
  .category-border .border-padding {
    padding: 0 10px; }
  .category-border div .category-banner {
    padding: 0;
    position: relative;
    overflow: hidden; }
    .category-border div .category-banner img {
      transform: scale(1);
      transition: 0.5s ease; }
    .category-border div .category-banner:hover img {
      transform: scale(1.1) translateX(14px);
      transition: 0.5s ease; }
    .category-border div .category-banner:hover h2 {
      color: #b5dccd;
      transition: 0.5s ease; }
    .category-border div .category-banner .category-box {
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center; }
      .category-border div .category-banner .category-box h2 {
        background-color: #ffffff;
        display: inline-block;
        padding: 20px 35px;
        margin-bottom: 0;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
        transition: 0.5s ease; }

.category-bg {
  background-color: white;
  padding: 40px 50px; }
  .category-bg .image-block img {
    width: 100%; }
  .category-bg .image-block.even:after {
    bottom: 94%; }
  .category-bg .image-block:after {
    content: '';
    position: absolute;
    background-color: white;
    height: 0;
    width: 40px;
    z-index: 1;
    right: 0;
    left: 0;
    margin: 0 auto;
    opacity: 0;
    transition: 0.5s ease;
    margin-top: -40px; }
  .category-bg .image-block:hover:after {
    opacity: 1;
    transition: 0.5s ease;
    height: 80px; }
  .category-bg .contain-block {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #ffffff; }
    .category-bg .contain-block.even:after {
      top: 94%; }
    .category-bg .contain-block:after {
      content: '';
      position: absolute;
      background-color: white;
      height: 0;
      width: 40px;
      z-index: 1;
      right: 0;
      left: 0;
      margin: 0 auto;
      opacity: 0;
      transition: 0.5s ease;
      bottom: 94%; }
    .category-bg .contain-block:hover h2 {
      color: #b5dccd;
      transition: all 0.5s ease; }
    .category-bg .contain-block:hover h6 span {
      color: #b5dccd;
      transition: all 0.5s ease; }
    .category-bg .contain-block:hover .category-btn {
      background-image: linear-gradient(30deg, #222222 50%, transparent 50%);
      border: 2px solid #222222;
      transition: all 0.5s ease; }
    .category-bg .contain-block:hover:after {
      opacity: 1;
      transition: all 0.5s ease;
      height: 80px; }
    .category-bg .contain-block h2 {
      margin-bottom: 0;
      margin-top: 15px;
      transition: 0.5s ease; }
    .category-bg .contain-block h6 {
      color: #b5dccd;
      text-transform: uppercase;
      letter-spacing: 0.3em;
      line-height: 1;
      margin-bottom: 0; }
      .category-bg .contain-block h6 span {
        color: #7f786d;
        letter-spacing: 0.03em;
        font-weight: 700;
        transition: all 0.5s ease; }
    .category-bg .contain-block .category-btn {
      letter-spacing: 0.07em;
      margin-bottom: 25px;
      margin-top: 25px;
      transition: all 0.5s ease; }

.category-m .slick-slide > div {
  margin: 0 20px; }

.category-m .category-wrapper {
  border: 1px solid #dddada;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative; }
  .category-m .category-wrapper > div:after, .category-m .category-wrapper > div:before {
    content: '';
    width: 1px;
    height: 0;
    position: absolute;
    transition: all 0.2s linear;
    background: #b5dccd;
    transition-delay: 0s; }
  .category-m .category-wrapper > div:before {
    left: 0;
    top: 0; }
  .category-m .category-wrapper > div:after {
    right: 0;
    bottom: 0; }
  .category-m .category-wrapper:after, .category-m .category-wrapper:before {
    content: '';
    width: 0;
    height: 1px;
    position: absolute;
    transition: all 0.2s linear;
    background: #b5dccd; }
  .category-m .category-wrapper:before {
    right: 0;
    top: 0; }
  .category-m .category-wrapper:after {
    left: 0;
    bottom: 0; }
  .category-m .category-wrapper:hover {
    transition: all 0.5s ease; }
    .category-m .category-wrapper:hover:after, .category-m .category-wrapper:hover:before {
      transition-delay: 0s;
      width: 100%; }
    .category-m .category-wrapper:hover > div:after, .category-m .category-wrapper:hover > div:before {
      transition-delay: 0.2s;
      height: 100%; }
  .category-m .category-wrapper img {
    display: inline; }
  .category-m .category-wrapper h4 {
    text-transform: uppercase;
    color: #2d2a25;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 25px;
    padding-top: 25px; }
  .category-m .category-wrapper .btn {
    margin-top: 20px; }
  .category-m .category-wrapper .category-link li {
    display: block;
    text-transform: capitalize;
    margin-top: 5px; }
    .category-m .category-wrapper .category-link li:first-child {
      margin-top: 0; }
    .category-m .category-wrapper .category-link li a {
      color: #948e8c; }
      .category-m .category-wrapper .category-link li a:hover {
        color: #b5dccd; }

.background {
  background-color: #f1f5f4;
  padding: 20px 10px;
  margin: 0; }
  .background .contain-bg {
    width: 100%;
    background-color: #ffffff;
    padding-top: 45px;
    padding-bottom: 45px;
    text-align: center;
    transition: all 0.5s ease; }
    .background .contain-bg h4 {
      color: #222222;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 0;
      transition: all 0.5s ease; }
    .background .contain-bg:hover {
      background-color: white; }
      .background .contain-bg:hover h4 {
        color: #b5dccd;
        transition: all 0.5s ease; }

/*=====================
    10.Product Box CSS start
==========================*/
.absolute-product .theme-tab .tab-title .current a {
  font-weight: 700; }

.absolute-product .product-box {
  width: 100%;
  display: inline-block;
  padding-bottom: 10px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px; }
  .absolute-product .product-box .img-wrapper {
    border-radius: 5px 5px 0 0; }
  .absolute-product .product-box .product-detail {
    text-align: center;
    margin-top: 0;
    padding: 0 15px; }
    .absolute-product .product-box .product-detail .color-variant {
      padding-top: 5px; }
      .absolute-product .product-box .product-detail .color-variant li {
        height: 16px;
        width: 16px; }
    .absolute-product .product-box .product-detail .cart-bottom {
      border-top: 1px solid #ddd;
      padding-top: 10px;
      margin-top: 10px; }
      .absolute-product .product-box .product-detail .cart-bottom button {
        border: none;
        background-color: transparent; }
      .absolute-product .product-box .product-detail .cart-bottom i {
        color: #828282;
        font-size: 18px;
        padding-right: 7px;
        padding-left: 7px;
        transition: all 0.5s ease; }
        .absolute-product .product-box .product-detail .cart-bottom i:hover {
          color: #b5dccd;
          transition: all 0.5s ease; }
    .absolute-product .product-box .product-detail .rating {
      margin-top: 10px; }

.absolute-product .slick-slider .product-box {
  margin-bottom: 3px; }

.absolute-product.digital-product .product-box {
  padding-bottom: 15px; }
  .absolute-product.digital-product .product-box .img-wrapper .digital-wrapper {
    display: flex;
    width: 100%; }
    .absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .live-demo,
    .absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .details_product {
      width: 50%;
      background-color: rgba(181, 220, 205, 0.6);
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 0;
      position: absolute;
      bottom: 0; }
      .absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .live-demo h4,
      .absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .details_product h4 {
        margin-bottom: 0;
        line-height: 1.2; }
    .absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .live-demo {
      left: -50%;
      transition: all 0.5s ease; }
    .absolute-product.digital-product .product-box .img-wrapper .digital-wrapper .details_product {
      right: -50%;
      transition: all 0.5s ease; }
  .absolute-product.digital-product .product-box .product-detail h6 {
    padding-top: 15px;
    color: #000000;
    font-weight: 700; }
  .absolute-product.digital-product .product-box .product-detail h4 {
    color: #000000;
    font-weight: 100; }
  .absolute-product.digital-product .product-box:hover .img-wrapper .digital-wrapper .live-demo {
    left: 0;
    transition: all 0.5s ease; }
  .absolute-product.digital-product .product-box:hover .img-wrapper .digital-wrapper .details_product {
    right: 0;
    transition: all 0.5s ease; }

.product-m .slick-slide > div {
  margin: 0 10px; }

.color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  transition: all 0.1s ease;
  vertical-align: middle; }

.no-slider .product-box {
  width: 100%;
  flex: 0 0 25%;
  max-width: calc(25% - 30px);
  margin: 0 15px 30px; }
  .no-slider .product-box:nth-last-child(-n + 4) {
    margin: 0 15px 0; }

.product-para p {
  margin-bottom: 0;
  padding-bottom: 25px;
  line-height: 24px;
  letter-spacing: 0.05em;
  margin-top: -7px; }

.portfolio-section .article-title {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  color: white;
  transition: all 0.5s ease;
  text-align: center;
  float: left;
  font-size: 18px;
  line-height: 24px; }
  .portfolio-section .article-title a {
    color: white; }

.portfolio-section .isotopeSelector {
  float: left;
  width: 100%;
  position: relative; }
  .portfolio-section .isotopeSelector .overlay {
    position: relative;
    border: 1px solid #dddddd; }
  .portfolio-section .isotopeSelector img {
    width: 100%;
    height: auto; }

.portfolio-section .isotopeSelector {
  margin-bottom: 30px; }
  .portfolio-section .isotopeSelector img {
    transition: all 0.4s ease; }
  .portfolio-section .isotopeSelector .overlay-background {
    transform: scale(0);
    transition: all 0.4s ease; }
    .portfolio-section .isotopeSelector .overlay-background i {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: white;
      padding: 10px;
      border-radius: 100%;
      transform: translate(-50%, -50%);
      color: #b5dccd;
      font-size: 22px;
      display: none;
      transition: all 0.4s ease;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center; }
  .portfolio-section .isotopeSelector:hover img {
    transform: scale(1.2) rotate(2deg);
    transition: all 0.4s ease; }
  .portfolio-section .isotopeSelector:hover .overlay-background {
    transform: scale(1);
    border: 3px solid white;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(181, 220, 205, 0.2);
    transition: all 0.4s ease; }
    .portfolio-section .isotopeSelector:hover .overlay-background i {
      display: flex;
      transition: all 0.4s ease; }

.portfolio-section .border-portfolio {
  margin: 3px;
  overflow: hidden; }

.portfolio-section #form1 {
  padding-bottom: 20px; }

.portfolio-section .filter-button {
  padding: 0 24px;
  font-size: 20px;
  line-height: 35px;
  border: none;
  background-color: white; }
  .portfolio-section .filter-button.active {
    background-color: #b5dccd;
    color: #ffffff; }

.portfolio-section.fullwidth-portfolio .isotopeSelector {
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 14px; }

.portfolio-section.metro-section .product-box .product-detail {
  opacity: 0;
  position: absolute;
  background-color: #ffffff;
  padding: 10px;
  transition: all 0.5s ease;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -10px;
  text-align: center; }
  .portfolio-section.metro-section .product-box .product-detail h6 {
    color: #525252;
    padding-top: 0;
    margin-top: -2px; }
  .portfolio-section.metro-section .product-box .product-detail h4 {
    font-weight: 400;
    color: #000000; }

.portfolio-section.metro-section .product-box .cart-wrap {
  top: 7px;
  right: 7px;
  opacity: 1; }
  .portfolio-section.metro-section .product-box .cart-wrap button {
    border: 1px solid #ffffff;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1; }
    .portfolio-section.metro-section .product-box .cart-wrap button i {
      padding: 7px;
      color: #333333; }
  .portfolio-section.metro-section .product-box .cart-wrap a i {
    border-radius: 100%;
    border: 1px solid #ffffff;
    margin: 5px 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 7px;
    color: #333333;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.portfolio-section.metro-section .product-box:hover .product-detail {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 15px; }

.portfolio-section.metro-section .product-box:hover .cart-wrap button {
  animation: none; }

.portfolio-section.metro-section .product-box:hover .cart-wrap a i {
  opacity: 1; }

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(2) i {
  animation: fadeInDown 500ms ease-in-out; }

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(3) i {
  animation: fadeInDown 700ms ease-in-out; }

.portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
  animation: fadeInDown 1000ms ease-in-out; }

.product-box,
.product-wrap {
  position: relative;
  transition: all 0.5s ease; }
  .product-box .img-block,
  .product-wrap .img-block {
    background-color: #f9f9f9;
    position: relative;
    overflow: hidden; }
    .product-box .img-block .front,
    .product-wrap .img-block .front {
      opacity: 1;
      top: 0;
      left: 0;
      transition: all 0.5s ease; }
    .product-box .img-block .back,
    .product-wrap .img-block .back {
      opacity: 0;
      position: absolute;
      backface-visibility: hidden;
      top: 0;
      left: 0;
      transition: all 0.5s ease;
      transform: translateX(-100px); }
    .product-box .img-block .lable-wrapper,
    .product-wrap .img-block .lable-wrapper {
      margin: 0 auto;
      top: 40px;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      transition: all 0.5s ease;
      z-index: 2; }
      .product-box .img-block .lable-wrapper .lable1,
      .product-box .img-block .lable-wrapper .lable2,
      .product-wrap .img-block .lable-wrapper .lable1,
      .product-wrap .img-block .lable-wrapper .lable2 {
        font-size: 14px;
        padding: 10px 14px 10px 20px;
        display: inline-block;
        text-transform: uppercase;
        text-align: center; }
      .product-box .img-block .lable-wrapper .lable1,
      .product-wrap .img-block .lable-wrapper .lable1 {
        background-color: #b5dccd;
        color: #ffffff;
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px; }
      .product-box .img-block .lable-wrapper .lable2,
      .product-wrap .img-block .lable-wrapper .lable2 {
        background-color: #ffffff;
        color: #000000;
        border-bottom-right-radius: 25px;
        border-top-right-radius: 25px; }
  .product-box .img-wrapper,
  .product-wrap .img-wrapper {
    position: relative;
    overflow: hidden; }
    .product-box .img-wrapper .front,
    .product-wrap .img-wrapper .front {
      opacity: 1;
      top: 0;
      left: 0;
      transition: all 0.5s ease; }
    .product-box .img-wrapper .back,
    .product-wrap .img-wrapper .back {
      opacity: 0;
      position: absolute;
      backface-visibility: hidden;
      top: 0;
      left: 0;
      transition: all 0.5s ease;
      transform: translateX(-100px); }
    .product-box .img-wrapper .cart-box,
    .product-wrap .img-wrapper .cart-box {
      position: absolute;
      margin: 0 auto;
      display: inline-block;
      right: 0;
      left: 0;
      border-radius: 50px;
      width: max-content;
      padding: 12px 15px;
      box-shadow: 0 0 12px 0 #dddddd;
      bottom: 30px;
      background-color: #ffffff;
      opacity: 0;
      transition: all 0.2s ease; }
      .product-box .img-wrapper .cart-box button,
      .product-wrap .img-wrapper .cart-box button {
        background: none;
        box-shadow: none;
        border: none;
        padding: 0; }
      .product-box .img-wrapper .cart-box i,
      .product-wrap .img-wrapper .cart-box i {
        color: #bbbbbb;
        font-size: 18px;
        padding-left: 8px;
        padding-right: 8px;
        transition: all 0.2s ease;
        display: inline-block; }
        .product-box .img-wrapper .cart-box i:hover,
        .product-wrap .img-wrapper .cart-box i:hover {
          color: #b5dccd;
          transition: all 0.2s ease; }
    .product-box .img-wrapper .lable-block .lable3,
    .product-wrap .img-wrapper .lable-block .lable3 {
      border-radius: 100%;
      background-color: #b5dccd;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      position: absolute;
      padding: 12px 6px;
      text-transform: uppercase;
      color: #ffffff;
      top: 7px;
      left: 7px; }
    .product-box .img-wrapper .lable-block .lable4,
    .product-wrap .img-wrapper .lable-block .lable4 {
      position: absolute;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      top: 7px;
      right: 7px;
      letter-spacing: 0.1em; }
    .product-box .img-wrapper .product-thumb-list,
    .product-wrap .img-wrapper .product-thumb-list {
      position: absolute;
      bottom: 0;
      left: 0; }
      .product-box .img-wrapper .product-thumb-list li,
      .product-wrap .img-wrapper .product-thumb-list li {
        display: block;
        opacity: 0.3; }
        .product-box .img-wrapper .product-thumb-list li.active,
        .product-wrap .img-wrapper .product-thumb-list li.active {
          opacity: 1; }
        .product-box .img-wrapper .product-thumb-list li img,
        .product-wrap .img-wrapper .product-thumb-list li img {
          height: 50px; }
  .product-box .cart-info,
  .product-box .cart-wrap,
  .product-wrap .cart-info,
  .product-wrap .cart-wrap {
    position: absolute;
    bottom: 40px;
    text-align: center;
    margin: 0 auto;
    display: inline-block;
    right: 0;
    left: 0;
    justify-content: center;
    opacity: 0;
    transition: all 0.5s ease; }
    .product-box .cart-info a [class^='icon-'],
    .product-box .cart-info a [class*=' icon-'],
    .product-box .cart-wrap a [class^='icon-'],
    .product-box .cart-wrap a [class*=' icon-'],
    .product-wrap .cart-info a [class^='icon-'],
    .product-wrap .cart-info a [class*=' icon-'],
    .product-wrap .cart-wrap a [class^='icon-'],
    .product-wrap .cart-wrap a [class*=' icon-'] {
      display: inline-block; }
    .product-box .cart-info.cart-wrap,
    .product-box .cart-wrap.cart-wrap,
    .product-wrap .cart-info.cart-wrap,
    .product-wrap .cart-wrap.cart-wrap {
      bottom: 0;
      text-align: right;
      left: unset; }
      .product-box .cart-info.cart-wrap i,
      .product-box .cart-wrap.cart-wrap i,
      .product-wrap .cart-info.cart-wrap i,
      .product-wrap .cart-wrap.cart-wrap i {
        display: block;
        padding-bottom: 10px;
        padding-top: 10px; }
      .product-box .cart-info.cart-wrap.cart-effect-left,
      .product-box .cart-wrap.cart-wrap.cart-effect-left,
      .product-wrap .cart-info.cart-wrap.cart-effect-left,
      .product-wrap .cart-wrap.cart-wrap.cart-effect-left {
        left: 0;
        right: unset; }
    .product-box .cart-info button,
    .product-box .cart-wrap button,
    .product-wrap .cart-info button,
    .product-wrap .cart-wrap button {
      background: none;
      box-shadow: none;
      border: none;
      padding: 0; }
    .product-box .cart-info i,
    .product-box .cart-wrap i,
    .product-wrap .cart-info i,
    .product-wrap .cart-wrap i {
      color: #bbbbbb;
      font-size: 18px;
      padding-right: 10px;
      padding-left: 10px; }
      .product-box .cart-info i:hover,
      .product-box .cart-wrap i:hover,
      .product-wrap .cart-info i:hover,
      .product-wrap .cart-wrap i:hover {
        color: #b5dccd; }
  .product-box .cart-detail,
  .product-wrap .cart-detail {
    position: absolute;
    top: 15px;
    right: 20px;
    opacity: 0; }
    .product-box .cart-detail i,
    .product-wrap .cart-detail i {
      color: #6f6f6f;
      font-size: 18px;
      display: flex;
      padding-top: 8px;
      padding-bottom: 8px; }
    .product-box .cart-detail button,
    .product-wrap .cart-detail button {
      background: none;
      box-shadow: none;
      border: none;
      padding: 0; }
  .product-box .product-detail,
  .product-box .product-info,
  .product-wrap .product-detail,
  .product-wrap .product-info {
    padding-left: 5px; }
    .product-box .product-detail .rating,
    .product-box .product-info .rating,
    .product-wrap .product-detail .rating,
    .product-wrap .product-info .rating {
      margin-top: 15px; }
      .product-box .product-detail .rating i,
      .product-box .product-info .rating i,
      .product-wrap .product-detail .rating i,
      .product-wrap .product-info .rating i {
        padding-right: 5px; }
        .product-box .product-detail .rating i:nth-child(-n + 4),
        .product-box .product-info .rating i:nth-child(-n + 4),
        .product-wrap .product-detail .rating i:nth-child(-n + 4),
        .product-wrap .product-info .rating i:nth-child(-n + 4) {
          color: #ffa200; }
        .product-box .product-detail .rating i:last-child,
        .product-box .product-info .rating i:last-child,
        .product-wrap .product-detail .rating i:last-child,
        .product-wrap .product-info .rating i:last-child {
          color: #dddddd; }
    .product-box .product-detail h6,
    .product-box .product-info h6,
    .product-wrap .product-detail h6,
    .product-wrap .product-info h6 {
      line-height: 1;
      margin-bottom: 0;
      padding-top: 2px;
      padding-bottom: 5px;
      transition: all 0.5s ease;
      font-size: 16px; }
    .product-box .product-detail h4,
    .product-box .product-info h4,
    .product-wrap .product-detail h4,
    .product-wrap .product-info h4 {
      font-size: 18px;
      color: #222222;
      font-weight: 700;
      margin-bottom: 0;
      transition: all 0.5s ease; }
    .product-box .product-detail .color-variant,
    .product-box .product-info .color-variant,
    .product-wrap .product-detail .color-variant,
    .product-wrap .product-info .color-variant {
      padding-top: 15px; }
      .product-box .product-detail .color-variant li,
      .product-box .product-info .color-variant li,
      .product-wrap .product-detail .color-variant li,
      .product-wrap .product-info .color-variant li {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        margin-right: 5px;
        transition: all 0.1s ease;
        cursor: pointer; }
  .product-box .product-info,
  .product-wrap .product-info {
    padding: 0;
    text-align: center;
    position: relative; }
    .product-box .product-info .add-btn,
    .product-wrap .product-info .add-btn {
      position: absolute;
      bottom: 110px;
      margin: 0 auto;
      left: 0;
      right: 0;
      opacity: 0;
      transition: all 0.2s ease;
      z-index: 1; }
      .product-box .product-info .add-btn i,
      .product-wrap .product-info .add-btn i {
        color: #b5dccd; }
      .product-box .product-info .add-btn .btn-outline,
      .product-wrap .product-info .add-btn .btn-outline {
        transition: all 0.2s ease;
        color: #b5dccd; }
        .product-box .product-info .add-btn .btn-outline:hover,
        .product-wrap .product-info .add-btn .btn-outline:hover {
          color: #ffffff; }
          .product-box .product-info .add-btn .btn-outline:hover i,
          .product-wrap .product-info .add-btn .btn-outline:hover i {
            color: #ffffff; }
  .product-box.effect-center .front img,
  .product-wrap.effect-center .front img {
    transition: all 0.5s ease; }
  .product-box.effect-center .img-wrapper .cart-box,
  .product-wrap.effect-center .img-wrapper .cart-box {
    bottom: 20%;
    transition: all 0.5s ease; }
  .product-box:hover.effect-center .front img,
  .product-wrap:hover.effect-center .front img {
    opacity: 0.3;
    transition: all 0.5s ease; }
  .product-box:hover.effect-center .img-wrapper .cart-box,
  .product-wrap:hover.effect-center .img-wrapper .cart-box {
    bottom: 35%;
    transition: all 0.5s ease; }
  .product-box:hover .img-block .first,
  .product-box:hover .img-wrapper .first,
  .product-wrap:hover .img-block .first,
  .product-wrap:hover .img-wrapper .first {
    opacity: 0;
    transition: all 0.5s ease; }
  .product-box:hover .img-block .back,
  .product-box:hover .img-wrapper .back,
  .product-wrap:hover .img-block .back,
  .product-wrap:hover .img-wrapper .back {
    opacity: 1;
    transition: all 0.5s ease;
    transform: translateX(0); }
  .product-box:hover .cart-info,
  .product-wrap:hover .cart-info {
    opacity: 1;
    transition: all 0.5s ease; }
    .product-box:hover .cart-info button,
    .product-wrap:hover .cart-info button {
      animation: fadeInUp 300ms ease-in-out; }
    .product-box:hover .cart-info a:nth-child(2) i,
    .product-wrap:hover .cart-info a:nth-child(2) i {
      animation: fadeInUp 500ms ease-in-out; }
    .product-box:hover .cart-info a:nth-child(3) i,
    .product-wrap:hover .cart-info a:nth-child(3) i {
      animation: fadeInUp 700ms ease-in-out; }
    .product-box:hover .cart-info a:nth-child(4) i,
    .product-wrap:hover .cart-info a:nth-child(4) i {
      animation: fadeInUp 1000ms ease-in-out; }
  .product-box:hover .cart-wrap button,
  .product-wrap:hover .cart-wrap button {
    animation: fadeInRight 300ms ease-in-out; }
  .product-box:hover .cart-wrap a:nth-child(2) i,
  .product-wrap:hover .cart-wrap a:nth-child(2) i {
    animation: fadeInRight 500ms ease-in-out; }
  .product-box:hover .cart-wrap a:nth-child(3) i,
  .product-wrap:hover .cart-wrap a:nth-child(3) i {
    animation: fadeInRight 700ms ease-in-out; }
  .product-box:hover .cart-wrap a:nth-child(4) i,
  .product-wrap:hover .cart-wrap a:nth-child(4) i {
    animation: fadeInRight 1000ms ease-in-out; }
  .product-box:hover .cart-wrap.cart-effect-left button,
  .product-wrap:hover .cart-wrap.cart-effect-left button {
    animation: fadeInLeft 300ms ease-in-out; }
  .product-box:hover .cart-wrap.cart-effect-left a:nth-child(2) i,
  .product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(2) i {
    animation: fadeInLeft 500ms ease-in-out; }
  .product-box:hover .cart-wrap.cart-effect-left a:nth-child(3) i,
  .product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(3) i {
    animation: fadeInLeft 700ms ease-in-out; }
  .product-box:hover .cart-wrap.cart-effect-left a:nth-child(4) i,
  .product-wrap:hover .cart-wrap.cart-effect-left a:nth-child(4) i {
    animation: fadeInLeft 1000ms ease-in-out; }
  .product-box:hover .cart-detail,
  .product-wrap:hover .cart-detail {
    opacity: 1;
    transition: all 0.5s ease; }
    .product-box:hover .cart-detail button,
    .product-wrap:hover .cart-detail button {
      animation: fadeInRight 300ms ease-in-out; }
    .product-box:hover .cart-detail a:nth-child(2) i,
    .product-wrap:hover .cart-detail a:nth-child(2) i {
      animation: fadeInRight 500ms ease-in-out; }
    .product-box:hover .cart-detail a:nth-child(3) i,
    .product-wrap:hover .cart-detail a:nth-child(3) i {
      animation: fadeInRight 700ms ease-in-out; }
    .product-box:hover .cart-detail a:nth-child(4) i,
    .product-wrap:hover .cart-detail a:nth-child(4) i {
      animation: fadeInRight 1000ms ease-in-out; }
  .product-box:hover .product-info .add-btn,
  .product-wrap:hover .product-info .add-btn {
    opacity: 1;
    transition: all 0.2s ease;
    animation: fadeInUp 500ms ease-in-out; }
  .product-box:hover .img-wrapper .cart-box,
  .product-wrap:hover .img-wrapper .cart-box {
    opacity: 1;
    transition: all 0.2s ease;
    animation: fadeInUp 400ms ease-in-out; }

.addtocart_count {
  position: relative; }
  .addtocart_count .product-box .product-detail {
    text-align: center; }
  .addtocart_count .product-box .cart-info {
    bottom: 40px;
    right: 10px; }
    .addtocart_count .product-box .cart-info a i {
      background-color: #e2e2e2;
      border-radius: 100%;
      margin: 10px 0;
      padding: 8px;
      font-size: 16px;
      color: #313131; }
  .addtocart_count .product-box .add-button {
    background-color: #efefef;
    color: black;
    text-align: center;
    font-size: 18px;
    text-transform: capitalize;
    width: 100%;
    padding: 5px 0;
    transition: all 0.5s ease;
    border: none;
    cursor: pointer; }
  .addtocart_count .product-box:hover .cart-info a:nth-child(1) i {
    animation: fadeInRight 300ms ease-in-out; }
  .addtocart_count .product-box:hover .add-button {
    bottom: 0;
    transition: all 0.5s ease; }
  .addtocart_count .addtocart_btn {
    position: relative; }
    .addtocart_count .addtocart_btn .cart_qty {
      width: 100%; }
      .addtocart_count .addtocart_btn .cart_qty.qty-box {
        position: absolute;
        bottom: 0;
        display: none; }
        .addtocart_count .addtocart_btn .cart_qty.qty-box .input-group .form-control {
          width: 100%; }
          .addtocart_count .addtocart_btn .cart_qty.qty-box .input-group .form-control:focus {
            border-color: #efefef;
            box-shadow: none; }
        .addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button {
          background: #efefef !important;
          position: absolute;
          height: 100%;
          z-index: 9; }
          .addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button.quantity-left-minus {
            left: 0; }
          .addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button.quantity-right-plus {
            right: 0; }
        .addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button i {
          color: #000000; }
      .addtocart_count .addtocart_btn .cart_qty.open {
        display: block; }

.game-product .theme-tab .tab-title .current a,
.game-product .theme-tab .tab-title2 .current a {
  border-bottom: 2px solid #b5dccd;
  padding-bottom: 5px; }

.game-product .product-box .product-detail {
  text-align: center; }

.game-product .product-box .cart-info {
  bottom: 40px;
  right: 10px; }
  .game-product .product-box .cart-info a i {
    background-color: white;
    border-radius: 100%;
    margin: 10px 0;
    padding: 8px;
    font-size: 16px; }

.game-product .product-box .add-button {
  background-color: #b5dccd;
  color: white;
  text-align: center;
  font-size: 18px;
  text-transform: capitalize;
  position: absolute;
  width: 100%;
  bottom: -40px;
  padding: 5px 0;
  transition: all 0.5s ease;
  cursor: pointer; }

.game-product .product-box:hover .cart-info a:nth-child(1) i {
  animation: fadeInRight 300ms ease-in-out; }

.game-product .product-box:hover .add-button {
  bottom: 0;
  transition: all 0.5s ease; }

.game-product .img-wrapper img {
  width: 100%; }

/*=====================
    11.Theme tab CSS start
==========================*/
.react-tabs__tab--selected {
  background: unset !important;
  border-color: transparent !important;
  color: #b5dccd !important;
  border-radius: 0 !important; }
  .react-tabs__tab--selected .nav-link {
    color: #b5dccd !important; }

.tab-content.nav-material .react-tabs__tab--selected {
  border-bottom: 2px solid #b5dccd !important; }

.react-tabs__tab:focus {
  border-color: transparent !important;
  box-shadow: none !important; }

.theme-tab {
  position: relative; }
  .theme-tab .tab-content {
    display: block; }
    .theme-tab .tab-content[style='display: block;'] .product-box,
    .theme-tab .tab-content[style='display: block;'] .tab-box {
      animation: zoomIn 300ms ease-in-out; }
    .theme-tab .tab-content .product-tab .tab-box {
      background-color: #ffffff;
      width: 100%;
      flex: 0 0 25%;
      max-width: calc(25% - 10px);
      margin: 0 5px 10px; }
      .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n + 4) {
        margin: 0 5px 0; }
      .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
        height: 250px;
        padding: 15px;
        transition: all 0.5s ease; }
      .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating {
        margin-top: 0; }
        .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i {
          padding-right: 0; }
          .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i:nth-child(-n + 4) {
            color: #ffa200; }
          .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i:last-child {
            color: #dddddd; }
      .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
        margin-right: 35px;
        line-height: 17px;
        margin-top: 5px;
        margin-bottom: 0; }
      .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
        font-size: 20px;
        margin-top: 5px;
        font-weight: 700;
        color: #222222;
        margin-bottom: 0; }
      .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        margin-right: 2px;
        margin-top: 20px;
        transition: all 0.1s ease;
        margin-bottom: 0;
        cursor: pointer; }
      .theme-tab .tab-content .product-tab .tab-box:hover .product-box2 img {
        transform: scale(1.03);
        transition: all 0.5s ease; }
  .theme-tab .tab-title,
  .theme-tab .tab-title2 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 25px;
    margin-top: -9px; }
    .theme-tab .tab-title .current a,
    .theme-tab .tab-title2 .current a {
      color: #b5dccd; }
    .theme-tab .tab-title li,
    .theme-tab .tab-title2 li {
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 0;
      line-height: 1; }
    .theme-tab .tab-title a,
    .theme-tab .tab-title2 a {
      color: #2d2a25;
      text-transform: uppercase; }
  .theme-tab .tab-title2 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -8px; }
    .theme-tab .tab-title2:after {
      content: '';
      position: absolute;
      border: 1px solid #f1f5f4;
      width: 100%;
      left: 0;
      top: 17px;
      z-index: -1; }
    .theme-tab .tab-title2 .current {
      background-color: #ffffff; }
      .theme-tab .tab-title2 .current a {
        background-color: #ffffff; }

.tab-bg {
  background-color: white;
  padding: 70px 50px 60px 50px; }
  .tab-bg.tab-grey-bg {
    background-color: #f9f9f9; }
  .tab-bg .theme-tab .tab-title {
    margin-bottom: 30px; }

/*=====================
    12.Banner CSS start
==========================*/
.full-banner {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 190px;
  padding-bottom: 190px;
  background-position: center; }
  .full-banner.parallax-banner1 {
    background-image: url("/assets/images/parallax/1.jpg"); }
  .full-banner.parallax-banner2 {
    background-image: url("/assets/images/parallax/2.jpg"); }
  .full-banner.parallax-banner3 {
    background-image: url("/assets/images/parallax/3.jpg"); }
  .full-banner.parallax-banner4 {
    background-image: url("/assets/images/parallax/4.jpg"); }
  .full-banner.parallax-banner5 {
    background-image: url("/assets/images/parallax/5.jpg"); }
  .full-banner.parallax-banner6 {
    background-image: url("/assets/images/parallax/6.jpg"); }
  .full-banner.parallax-banner7 {
    background-image: url("/assets/images/parallax/7.jpg"); }
  .full-banner.parallax-banner8 {
    background-image: url("/assets/images/parallax/8.jpg"); }
  .full-banner.parallax-banner9 {
    background-image: url("/assets/images/parallax/9.jpg"); }
  .full-banner.parallax-banner10 {
    background-image: url("/assets/images/parallax/10.jpg"); }
  .full-banner.parallax-banner11 {
    background-image: url("/assets/images/parallax/11.jpg"); }
  .full-banner.parallax-banner12 {
    background-image: url("/assets/images/parallax/12.jpg"); }
  .full-banner.parallax-banner13 {
    background-image: url("/assets/images/parallax/13.jpg"); }
  .full-banner.parallax-banner14 {
    background-image: url("/assets/images/parallax/14.jpg"); }
  .full-banner.parallax-banner15 {
    background-image: url("/assets/images/parallax/15.jpg"); }
  .full-banner.parallax-banner16 {
    background-image: url("/assets/images/parallax/16.jpg"); }
  .full-banner.parallax-banner17 {
    background-image: url("/assets/images/parallax/17.jpg"); }
  .full-banner.parallax-banner18 {
    background-image: url("/assets/images/parallax/18.jpg"); }
  .full-banner.parallax-banner19 {
    background-image: url("/assets/images/parallax/19.jpg"); }
  .full-banner.parallax-banner20 {
    background-image: url("/assets/images/parallax/20.jpg"); }
  .full-banner.parallax-banner21 {
    background-image: url("/assets/images/parallax/21.jpg"); }
  .full-banner.parallax-banner22 {
    background-image: url("/assets/images/parallax/22.jpg"); }
  .full-banner.parallax-banner23 {
    background-image: url("/assets/images/parallax/23.jpg"); }
  .full-banner.parallax-banner24 {
    background-image: url("/assets/images/parallax/24.jpg"); }
  .full-banner.parallax-banner25 {
    background-image: url("/assets/images/parallax/25.jpg"); }
  .full-banner.parallax-banner26 {
    background-image: url("/assets/images/parallax/26.jpg"); }
  .full-banner.parallax-banner27 {
    background-image: url("/assets/images/parallax/27.jpg"); }
  .full-banner.parallax-banner28 {
    background-image: url("/assets/images/parallax/28.jpg"); }
  .full-banner.parallax-layout {
    padding-top: 350px;
    padding-bottom: 350px; }
    .full-banner.parallax-layout .banner-contain h4 {
      color: #7f786d; }
  .full-banner.banner-layout-3 .banner-contain h4 {
    color: #ffffff; }
  .full-banner.banner-layout-3 .banner-contain h3 {
    color: #ffffff;
    margin-top: 10px; }
  .full-banner.banner-layout-3 .banner-contain .color {
    color: #b5dccd;
    line-height: 1; }
  .full-banner.parallax {
    background-attachment: fixed; }
  .full-banner.p-left .banner-contain {
    float: left; }
  .full-banner.p-right .banner-contain {
    float: right; }
  .full-banner.p-center .banner-contain {
    left: 0;
    right: 0;
    margin: 0 auto; }
  .full-banner .banner-contain .btn-solid,
  .full-banner .banner-contain .btn-outline {
    margin-top: 20px; }
  .full-banner .banner-contain h2 {
    font-size: 100px;
    font-weight: 700;
    color: #b5dccd;
    text-transform: uppercase;
    margin-top: -15px; }
  .full-banner .banner-contain h3 {
    font-size: 60px;
    color: #333333;
    text-transform: uppercase;
    font-weight: 700; }
  .full-banner .banner-contain h4 {
    font-size: 24px;
    color: #777777;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    padding-top: 10px;
    margin-bottom: -5px;
    line-height: 1.3; }

.parallax-cls section:nth-child(odd) .full-banner {
  background-position: right; }

.parallax-cls section:nth-child(even) .full-banner {
  background-position: left; }

.parallax-cls section .banner-contain {
  margin-top: -12px; }

/*=====================
    13.Collection Banner CSS start
==========================*/
.collection-banner {
  position: relative;
  overflow: hidden; }
  .collection-banner img {
    transition: all 0.5s ease;
    width: 100%; }
  .collection-banner.p-left .contain-banner {
    justify-content: flex-start; }
  .collection-banner.p-right .contain-banner {
    justify-content: flex-end; }
  .collection-banner.p-center .contain-banner {
    justify-content: center; }
  .collection-banner .contain-banner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 70px;
    padding-right: 70px; }
    .collection-banner .contain-banner.banner-3 {
      padding-left: 50px;
      padding-right: 50px; }
      .collection-banner .contain-banner.banner-3 h2 {
        font-size: 36px;
        letter-spacing: 0.05em;
        color: white;
        margin-top: 5px;
        margin-bottom: -6px; }
      .collection-banner .contain-banner.banner-3 h4 {
        color: #ffffff; }
    .collection-banner .contain-banner.banner-4 {
      padding-left: 50px;
      padding-right: 50px; }
      .collection-banner .contain-banner.banner-4 h2 {
        font-size: 28px;
        letter-spacing: 0.03em;
        color: white;
        margin-bottom: -6px; }
    .collection-banner .contain-banner h4 {
      color: #b5dccd;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      margin-bottom: 0; }
    .collection-banner .contain-banner h2 {
      font-size: 60px;
      font-weight: 700;
      color: #222222;
      letter-spacing: 0.1em;
      margin-bottom: -6px; }
  .collection-banner:hover img {
    transform: scale(1.05);
    transition: all 0.5s ease; }

.banner-furniture {
  padding-left: 15px;
  padding-right: 15px; }
  .banner-furniture .collection-banner .contain-banner.banner-3 h2 {
    color: #222222;
    margin-bottom: 0; }
  .banner-furniture .collection-banner .contain-banner.banner-3 h4 {
    color: #b5dccd; }

.banner-goggles .collection-banner .contain-banner.banner-3 h2 {
  color: #222222; }

.banner-goggles .collection-banner .contain-banner.banner-3 h4 {
  color: #b5dccd; }

.banner-top-cls {
  margin-top: 30px; }

.banner-6 .collection-banner .contain-banner.banner-3 h2 {
  color: #222222; }

.absolute_banner {
  margin-bottom: 22px; }
  .absolute_banner .collection-banner {
    overflow: unset; }
    .absolute_banner .collection-banner .absolute-contain {
      position: absolute;
      background-color: #ffffff;
      bottom: -22px;
      left: 50%;
      transform: translateX(-50%);
      padding: 20px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
      min-width: 85%;
      text-align: center;
      transition: all 0.5s ease; }
      .absolute_banner .collection-banner .absolute-contain h3 {
        color: #b5dccd;
        text-transform: capitalize;
        margin-top: -5px;
        font-weight: 700; }
      .absolute_banner .collection-banner .absolute-contain h4 {
        color: #000000;
        margin-bottom: 0; }
    .absolute_banner .collection-banner:hover img {
      transform: none; }

/*=====================
    14.Product-box slider CSS start
==========================*/
.layout7-product .product-box {
  padding: 2px; }
  .layout7-product .product-box img {
    width: 100%; }
  .layout7-product .product-box .cart-info {
    right: 7px;
    bottom: 7px; }
  .layout7-product .product-box .details-product {
    position: absolute;
    transition: all 0.5s ease;
    opacity: 0;
    bottom: 15px;
    left: 15px; }
    .layout7-product .product-box .details-product h6,
    .layout7-product .product-box .details-product h4 {
      margin-bottom: 0; }
    .layout7-product .product-box .details-product h6 {
      color: #000000; }
  .layout7-product .product-box:hover .details-product {
    opacity: 1;
    animation: fadeIn 500ms ease-in-out;
    transition: all 0.5s ease; }

.theme-card {
  position: relative; }
  .theme-card.center-align {
    display: flex;
    height: 100%;
    align-items: center; }
    .theme-card.center-align .offer-slider .product-box2 .media .media-body {
      margin-top: 0; }
  .theme-card.card-border {
    border: 1px solid #dddddd; }
    .theme-card.card-border h5 {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 50px; }
    .theme-card.card-border .slick-prev,
    .theme-card.card-border .slick-next {
      top: -45px; }
    .theme-card.card-border .slick-next {
      right: 30px; }
    .theme-card.card-border .slick-prev {
      right: 50px; }
    .theme-card.card-border .offer-slider {
      padding-top: 0;
      padding-bottom: 20px; }
      .theme-card.card-border .offer-slider img {
        padding: 15px 15px 15px 30px; }
      .theme-card.card-border .offer-slider .media .media-body a h6 {
        margin-right: 25px; }
  .theme-card h5 {
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-top: -5px; }
    .theme-card h5.title-border {
      border-bottom: 1px solid #dddddd; }
  .theme-card .slick-prev,
  .theme-card .slick-next {
    top: -35px; }
    .theme-card .slick-prev:before,
    .theme-card .slick-next:before {
      font: "Font Awesome 5 Free";
      color: #222222;
      opacity: 0.6; }
  .theme-card .slick-next {
    right: 0; }
    .theme-card .slick-next:before {
      content: '\f054';
      font-weight: 900; }
  .theme-card .slick-prev {
    left: unset;
    right: 25px; }
    .theme-card .slick-prev:before {
      content: '\f104';
      font-weight: 900; }
  .theme-card .offer-slider img {
    height: 160px;
    padding: 15px 15px 15px 0; }
  .theme-card .offer-slider > div .media:last-child .media-body {
    margin-top: 15px; }
  .theme-card .offer-slider > div .media:last-child img {
    margin-bottom: -15px; }
  .theme-card .offer-slider .media .media-body .rating {
    margin-top: 0; }
    .theme-card .offer-slider .media .media-body .rating i {
      padding-right: 5px; }
      .theme-card .offer-slider .media .media-body .rating i:nth-child(-n + 4) {
        color: #ffa200; }
      .theme-card .offer-slider .media .media-body .rating i:last-child {
        color: #dddddd; }
  .theme-card .offer-slider .media .media-body a h6 {
    margin-right: 61px;
    line-height: 17px;
    margin-top: 5px;
    margin-bottom: 0; }
  .theme-card .offer-slider .media .media-body h4 {
    margin-top: 10px;
    font-weight: 700;
    color: #222222;
    margin-bottom: 0; }

.offer-slider .slick-slide {
  line-height: 1; }

.center-slider .product-box {
  box-shadow: none; }
  .center-slider .product-box .img-wrapper img {
    padding: 15px; }

.center-slider .slick-next:before,
.center-slider .slick-prev:before {
  box-shadow: 0 0 8px 0 #dddddd; }

.center-slider .title2 .title-inner2 {
  margin-bottom: 0; }

.center-slider:hover .product-box {
  box-shadow: none; }

.center-slider:hover .slick-next {
  right: 20px;
  transform: scale(1.8); }

.center-slider:hover .slick-prev {
  left: 20px;
  transform: scale(1.8); }

.full-box .theme-card .offer-slider .product-box2 .media img {
  height: 300px;
  transform: scale(1);
  transition: all 0.5s ease; }

.full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
  margin-right: 20px; }

.full-box .theme-card .offer-slider .product-box2 .media .media-body h4 {
  font-size: 20px;
  margin-top: 5px; }

.full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin-right: 5px;
  margin-top: 15px;
  transition: all 0.1s ease;
  margin-bottom: 0;
  cursor: pointer; }

.full-box .theme-card .offer-slider .product-box2 + .product-box2 {
  border-top: 1px solid #f1f5f4; }

.full-box .theme-card .offer-slider .product-box2:hover .media img {
  transform: scale(1.03);
  transition: all 0.5s ease; }

/*=====================
    15.Modal popup CSS start
==========================*/
.ribbon-1 {
  width: 24px;
  height: 45px;
  background: #b5dccd;
  top: -6px;
  left: 25px;
  position: absolute;
  box-shadow: 4px 5px 15px 2px rgba(90, 90, 90, 0.35); }
  .ribbon-1:before {
    height: 0;
    width: 0;
    border-bottom: 6px solid #70bb9e;
    border-right: 6px solid transparent;
    right: -6px;
    content: '';
    position: absolute; }
  .ribbon-1:after {
    height: 0;
    width: 0;
    border-left: 12px solid #b5dccd;
    border-right: 12px solid #b5dccd;
    border-bottom: 12px solid transparent;
    bottom: -11px;
    left: 0;
    content: '';
    position: absolute; }
  .ribbon-1 span {
    display: block;
    color: #fff;
    height: 12px;
    text-transform: lowercase; }

.modal-open {
  padding-right: 0 !important; }

#sizemodal {
  padding-right: 0 !important; }

.modal-dialog {
  margin: 0; }
  .modal-dialog .modal-content {
    border: none; }
    .modal-dialog .modal-content .modal-body {
      padding: 0; }

.theme-modal {
  padding-right: 0 !important; }
  .theme-modal .modal-dialog .modal-content .modal-body {
    padding: 15px;
    background-image: linear-gradient(135deg, #b5dccd 5.77%, #ffffff 5.77%, #ffffff 25%, #111111 25%, #111111 30.77%, #ffffff 30.77%, #ffffff 50%, #b5dccd 50%, #b5dccd 55.77%, #ffffff 55.77%, #ffffff 75%, #111111 75%, #111111 80.77%, #ffffff 80.77%, #ffffff 100%);
    background-size: 36.77px 36.77px; }
    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
      background-color: #ffffff;
      padding: 45px; }
      .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close {
        padding-right: 10px;
        padding-top: 5px;
        position: absolute;
        right: 17px;
        top: 0; }
        .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close span {
          color: #000000; }
      .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h2 {
        border-bottom: 1px dashed #777777;
        text-align: center;
        margin-bottom: 30px;
        padding-bottom: 30px;
        font-weight: 700; }
      .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
        margin-bottom: 30px;
        text-align: center;
        color: #272727;
        font-size: 18px;
        font-weight: 400;
        text-transform: capitalize; }
      .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form {
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center; }
        .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .form-control {
          padding-top: 15px;
          padding-bottom: 15px;
          text-align: center;
          font-size: 12px;
          height: 50px;
          border-radius: 0;
          width: 170px;
          letter-spacing: 0.05em; }
      .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
        margin-bottom: 40px; }
      .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
        margin-bottom: 30px;
        text-align: center; }
      .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group {
        margin: 0 auto;
        text-align: center; }
        .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control {
          padding-top: 15px;
          padding-bottom: 15px;
          text-align: center;
          font-size: 12px;
          max-width: 640px;
          left: 0;
          right: 0;
          margin: 0 auto;
          letter-spacing: 0.05em; }
        .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .btn-solid,
        .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .btn-outline {
          margin-top: 10px;
          text-align: center; }
  .theme-modal .modal-dialog .quick-view-modal button.close {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 5px; }
  .theme-modal .modal-dialog .quick-view-modal .view-detail {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase; }

.modal-dialog .modal-content .modal-body .modal-bg {
  padding: 25px; }
  .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media a img {
    max-width: 150px; }
  .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body {
    padding: 0 15px; }
    .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 {
      font-size: 16px; }
      .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 i {
        margin-right: 8px;
        color: #fff;
        background: #19a340;
        font-size: 14px;
        width: 18px;
        height: 18px;
        line-height: 18px;
        border-radius: 50%; }
    .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body .buttons a {
      display: inline-block;
      font-size: 16px;
      padding: 6px 10px;
      margin: 0 6px; }
  .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box {
    padding-left: 10px;
    padding-right: 10px; }
    .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail {
      text-align: center; }
      .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 {
        font-weight: 700;
        text-transform: capitalize; }
        .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 a {
          color: #b5dccd; }

.modal-dialog .modal-content .modal-body .upsell_payment {
  padding-top: 15px; }

.modal-dialog .modal-content .modal-body .product-upsell {
  margin: 20px 0 10px; }
  .modal-dialog .modal-content .modal-body .product-upsell h4 {
    font-weight: 600; }

/*=====================
    16.look-book demo CSS start
==========================*/
.lookbook-section {
  padding: 25px; }
  .lookbook-section .lookbook-img > div:last-child img {
    margin-top: 25px; }

/*=====================
    17.1.lookbook CSS start
==========================*/
.lookbook .lookbook-block {
  position: relative; }
  .lookbook .lookbook-block .lookbook-dot {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    width: 29px;
    height: 29px;
    line-height: 29px;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 50%;
    left: 12%;
    background-color: #ffffff; }
    .lookbook .lookbook-block .lookbook-dot.dot2 {
      top: 70%;
      left: 27%; }
    .lookbook .lookbook-block .lookbook-dot.dot3 {
      top: 28%;
      left: 30%; }
    .lookbook .lookbook-block .lookbook-dot.dot4 {
      top: 67%;
      left: 8%; }
    .lookbook .lookbook-block .lookbook-dot.dot5 {
      top: 40%;
      left: 35%; }
    .lookbook .lookbook-block .lookbook-dot.dot6 {
      top: 80%;
      left: 58%; }
    .lookbook .lookbook-block .lookbook-dot.dot7 {
      top: 67%;
      left: 45%; }
    .lookbook .lookbook-block .lookbook-dot.dot8 {
      top: 43%;
      left: 39%; }
    .lookbook .lookbook-block .lookbook-dot.dot9 {
      top: 32%;
      left: 50%; }
    .lookbook .lookbook-block .lookbook-dot.dot10 {
      top: 60%;
      left: 50%; }
    .lookbook .lookbook-block .lookbook-dot.dot11 {
      top: 50%;
      left: 45%; }
    .lookbook .lookbook-block .lookbook-dot.dot12 {
      top: 75%;
      left: 45%; }
    .lookbook .lookbook-block .lookbook-dot.dot13 {
      top: 40%;
      left: 45%; }
    .lookbook .lookbook-block .lookbook-dot.dot14 {
      top: 76%;
      left: 45%; }
    .lookbook .lookbook-block .lookbook-dot.dot15 {
      top: 50%;
      left: 15%; }
    .lookbook .lookbook-block .lookbook-dot:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      content: '';
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.5);
      zoom: 1;
      background-color: transparent\9;
      z-index: -1;
      border-radius: 50%;
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease; }
    .lookbook .lookbook-block .lookbook-dot span {
      text-align: center;
      justify-content: center;
      display: flex; }
    .lookbook .lookbook-block .lookbook-dot .dot-showbox {
      visibility: hidden;
      top: -98px;
      left: 150%;
      position: absolute;
      width: 130px;
      background-color: #ffffff;
      -webkit-box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
      box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1); }
      .lookbook .lookbook-block .lookbook-dot .dot-showbox img {
        margin-top: 0; }
      .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
        padding: 5px;
        text-align: center; }
        .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
          margin-bottom: 0;
          text-transform: capitalize;
          line-height: 20px; }
          .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5.title {
            font-weight: 700; }
        .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h6 {
          text-transform: uppercase;
          color: #b5dccd;
          margin-bottom: 0; }
      .lookbook .lookbook-block .lookbook-dot .dot-showbox:before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-right: 8px solid #ffffff;
        border-bottom: 8px solid transparent;
        top: 41%;
        left: -8px; }
    .lookbook .lookbook-block .lookbook-dot:hover {
      background-color: #b5dccd;
      -webkit-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
      box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
      z-index: 10; }
      .lookbook .lookbook-block .lookbook-dot:hover .dot-showbox {
        animation: fadeInUp 400ms ease-in-out;
        visibility: visible;
        transition: all 0.5s ease; }
      .lookbook .lookbook-block .lookbook-dot:hover span {
        color: #ffffff; }
      .lookbook .lookbook-block .lookbook-dot:hover:before {
        -webkit-transform: scale(1.3); }

/*=====================
    17.2. Beauty Demo css
==========================*/
.beauty-about .about-text p {
  text-align: left; }

.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }
  .about-section .small-section {
    padding-top: 20px; }
  .about-section .service .service-block1 img {
    margin-bottom: 10px; }
  .about-section .service .service-block1 h5 {
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 0; }

.video-modal .modal-body {
  padding: 0; }

.video-modal iframe {
  height: 500px;
  width: 100%;
  vertical-align: middle;
  border: none; }

.video-section .video-img {
  position: relative;
  overflow: hidden; }
  .video-section .video-img img {
    transition: all 0.5s ease;
    transform: scale(1); }
  .video-section .video-img .play-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .video-section .video-img .play-btn span {
      height: 60px;
      width: 60px;
      border-radius: 100%;
      background-color: #b5dccd;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex; }
    .video-section .video-img .play-btn i {
      font-size: 35px;
      padding-left: 5px;
      color: #ffffff; }
  .video-section .video-img:hover img {
    transform: scale(1.03);
    transition: all 0.5s ease; }

/*=====================
    17.3. Electronics Demo CSS
==========================*/
.layout-8 {
  padding: 0 80px; }
  .layout-8 .layout-8-bg {
    background-image: url("/assets/images/electronics/bg.jpg");
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
    padding: 0 15px;
    animation: 10s linear 0s normal none infinite animate; }
    .layout-8 .layout-8-bg .layout7-product .product-box .details-product {
      opacity: 1; }
    .layout-8 .layout-8-bg .layout7-product .product-box:hover .details-product {
      animation: none; }

.white-bg {
  background-color: #ffffff; }

@keyframes animate {
  from {
    background-position: 0 0; }
  to {
    background-position: 0 500px; } }

/*=====================
      17.4.pets demo CSS start
==========================*/
.pets-box .product-box .img-wrapper .cart-info {
  background-color: rgba(181, 220, 205, 0.5);
  padding: 10px;
  border: none;
  transform: perspective(500px) rotateX(90deg);
  bottom: 0; }
  .pets-box .product-box .img-wrapper .cart-info i {
    padding: 5px;
    margin: 0 3px;
    background-color: white; }
  .pets-box .product-box .img-wrapper .cart-info a {
    border-left: none; }

.pets-box .product-box .product-detail {
  text-align: center;
  background-color: #fafafa; }
  .pets-box .product-box .product-detail .rating {
    margin-top: 0;
    padding-top: 15px; }

.pets-box .product-box:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.08); }
  .pets-box .product-box:hover .cart-info {
    transform: perspective(500px) rotateX(0deg); }

.pets-box .br.br-default {
  margin: 0 0 15px 0;
  justify-content: center;
  display: flex; }

.pets-box .product-m .slick-slide .img-wrapper {
  margin: 0; }

.small-slider .home-slider .home {
  height: 55vh; }
  .small-slider .home-slider .home .slider-contain {
    height: 55vh; }
    .small-slider .home-slider .home .slider-contain h4,
    .small-slider .home-slider .home .slider-contain h1 {
      color: #000000; }

.banner-6 .collection-banner .contain-banner.banner-3 h2 {
  color: #222222; }

.banner-top-cls {
  margin-top: 30px; }

.j-box .product-box {
  width: 100%;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
  margin: 0 10px; }
  .j-box .product-box .cart-info {
    display: flex;
    left: 0;
    background-color: rgba(250, 250, 250, 0.84);
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    bottom: -30px;
    transition: all 0.5s ease; }
    .j-box .product-box .cart-info a {
      border-left: 1px solid #dddddd; }
      .j-box .product-box .cart-info a i {
        padding: 12px; }
  .j-box .product-box .product-detail {
    padding-left: 15px;
    padding-bottom: 15px;
    background-color: #fafafa; }
    .j-box .product-box .product-detail .rating {
      margin-top: 0;
      padding-top: 15px; }
  .j-box .product-box:hover .img-wrapper .cart-info {
    opacity: 1;
    transition: all 0.5s ease;
    bottom: 0; }
    .j-box .product-box:hover .img-wrapper .cart-info button {
      animation: none; }
    .j-box .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
      animation: none; }
    .j-box .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
      animation: none; }
    .j-box .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
      animation: none; }

.pet-parallax {
  position: relative; }
  .pet-parallax .pet-decor {
    position: absolute;
    left: 110px;
    bottom: -164px; }
  .pet-parallax .banner-contain h4,
  .pet-parallax .banner-contain h3,
  .pet-parallax .banner-contain p {
    color: #212121; }
  .pet-parallax .banner-contain p {
    max-width: 75%;
    margin: 0 auto;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: 0.04em; }
  .pet-parallax .banner-contain h4 {
    letter-spacing: 0.05em;
    padding-top: 0 !important;
    margin-bottom: 0;
    line-height: 1.3;
    margin-top: -7px; }
  .pet-parallax .banner-contain h3 {
    margin: 15px 0;
    font-size: 48px; }
  .pet-parallax .full-banner {
    padding-top: 130px;
    padding-bottom: 130px; }

footer.pet-layout-footer .white-layout {
  background-color: #f5e1d0; }

footer.pet-layout-footer .footer-brand-logo {
  margin-bottom: 20px; }

footer.pet-layout-footer .sub-footer.black-subfooter {
  background-color: #b5dccd; }

footer.pet-layout-footer .sub-footer p {
  padding: 15px 0;
  color: #ffffff; }

footer.pet-layout-footer .social-white {
  display: block;
  margin-top: 40px;
  margin-bottom: 30px; }

.form_search {
  height: 45px;
  box-shadow: 0 0 0 1px #e2e2e2;
  overflow: hidden;
  position: relative;
  width: 525px;
  background-color: #f5f5f5;
  border-radius: 25px; }
  .form_search input {
    width: 100%;
    height: 45px;
    font-size: 16px;
    color: darkgrey;
    border: none;
    outline: none;
    background: none;
    padding: 0 10px; }
  .form_search button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 35px;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px; }
    .form_search button i {
      font-size: 18px;
      color: #b5dccd; }

.blog.blog_box .blog-details {
  text-align: left;
  padding: 0; }
  .blog.blog_box .blog-details p {
    font-size: 14px;
    text-transform: none;
    color: #333333;
    font-weight: 400;
    margin-bottom: 8px;
    margin-top: 3px; }
  .blog.blog_box .blog-details a p {
    color: #40494f;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 10px; }
  .blog.blog_box .blog-details .read-cls {
    text-transform: capitalize;
    font-weight: 700;
    color: #000000; }

/*=====================
   17.5. Metro layout  CSS start
==========================*/
.header-metro {
  position: absolute;
  width: 100%; }
  .header-metro .top-header {
    background-color: transparent;
    z-index: 9;
    position: relative; }
  .header-metro .metro {
    background-color: white;
    z-index: 9;
    position: relative; }

.metro-section .product-box .product-detail {
  opacity: 0;
  position: absolute;
  background-color: #ffffff;
  padding: 10px;
  transition: all 0.5s ease;
  width: 65%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -10px;
  text-align: center; }
  .metro-section .product-box .product-detail h6 {
    color: #525252;
    padding-top: 0;
    margin-top: -2px; }
  .metro-section .product-box .product-detail h4 {
    font-weight: 400;
    color: #000000; }
  .metro-section .product-box .product-detail .rating {
    margin-top: 0; }

.metro-section .product-box .cart-wrap {
  top: 7px;
  right: 7px;
  opacity: 1; }
  .metro-section .product-box .cart-wrap button {
    border: 1px solid #ffffff;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1; }
    .metro-section .product-box .cart-wrap button i {
      padding: 7px;
      color: #333333; }
  .metro-section .product-box .cart-wrap a i {
    border-radius: 100%;
    border: 1px solid #ffffff;
    margin: 5px 0;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 7px;
    color: #333333;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center; }

.metro-section .product-box:hover .product-detail {
  opacity: 1;
  transition: all 0.5s ease;
  bottom: 15px; }

.metro-section .product-box:hover .cart-wrap button {
  animation: none; }

.metro-section .product-box:hover .cart-wrap a i {
  opacity: 1; }

.metro-section .product-box:hover .cart-wrap a:nth-child(2) i {
  animation: fadeInDown 500ms ease-in-out; }

.metro-section .product-box:hover .cart-wrap a:nth-child(3) i {
  animation: fadeInDown 700ms ease-in-out; }

.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
  animation: fadeInDown 1000ms ease-in-out; }

/*=====================
   17.6. GYM layout  CSS start
==========================*/
.gym-slider .home-slider .home {
  height: 85vh; }
  .gym-slider .home-slider .home .slider-contain {
    height: 85vh; }
    .gym-slider .home-slider .home .slider-contain h4,
    .gym-slider .home-slider .home .slider-contain h1 {
      color: #ffffff; }

.metro-slider .home-slider .home {
  background-position-y: 70%; }
  .metro-slider .home-slider .home .slider-contain {
    margin-top: 75px; }
    .metro-slider .home-slider .home .slider-contain h4,
    .metro-slider .home-slider .home .slider-contain h1 {
      color: #000000; }

.gym-parallax .title1 h2 {
  color: white; }

.gym-parallax .product-para p {
  color: white; }

.gym-parallax .instagram-box .overlay {
  background: linear-gradient(-180deg, rgba(1, 239, 252, 0.8) 0%, rgba(72, 95, 242, 0.8) 99%); }

.gym-product {
  text-align: center; }
  .gym-product .product-box {
    background-color: white;
    padding: 15px;
    box-shadow: 0 0 8px 0 #ddd;
    transition: all 0.5s ease; }
    .gym-product .product-box .img-wrapper {
      position: unset; }
      .gym-product .product-box .img-wrapper .cart-info {
        z-index: 1;
        display: flex;
        left: 0;
        height: 100%;
        align-items: center;
        bottom: 0;
        top: 0; }
        .gym-product .product-box .img-wrapper .cart-info i {
          background: linear-gradient(180deg, #01effc 0%, #485ff2 99%);
          color: rgba(255, 255, 255, 0.6);
          border-radius: 100%;
          margin: 0 5px;
          transition: all 0.5s ease; }
        .gym-product .product-box .img-wrapper .cart-info button:hover i,
        .gym-product .product-box .img-wrapper .cart-info a:hover i {
          color: white; }
    .gym-product .product-box .br.br-default {
      display: flex;
      justify-content: center; }
    .gym-product .product-box:hover {
      transform: scale(1.02);
      transition: all 0.5s ease; }
      .gym-product .product-box:hover .img-wrapper .cart-info {
        opacity: 1;
        transition: all 0.5s ease; }
        .gym-product .product-box:hover .img-wrapper .cart-info button {
          animation: fadeInUp 300ms ease-in-out; }
        .gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
          animation: fadeInUp 500ms ease-in-out; }
        .gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
          animation: fadeInUp 700ms ease-in-out; }
        .gym-product .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
          animation: fadeInUp 1000ms ease-in-out; }
  .gym-product .partition-cls > div:nth-child(-n + 4) {
    margin-bottom: 30px; }

.gym-blog .blog-details {
  text-align: left;
  padding-left: 0; }
  .gym-blog .blog-details p {
    margin-bottom: 3px; }

.gym-banner .collection-banner .contain-banner h4,
.gym-banner .collection-banner .contain-banner h2 {
  color: #ffffff; }

.bg-img-gym {
  background-image: url("/assets/images/gym/bg.jpg"); }
  .bg-img-gym .dark-layout {
    background-color: transparent; }
  .bg-img-gym .footer-theme2 p {
    color: #ffffff; }
  .bg-img-gym .footer-theme2 .contact-details li {
    color: #ffffff; }
    .bg-img-gym .footer-theme2 .contact-details li a {
      color: #ffffff; }
  .bg-img-gym .footer-theme2 .footer-link a,
  .bg-img-gym .footer-theme2 .footer-link-b a {
    color: #ebebeb; }
  .bg-img-gym .sub-footer.darker-subfooter {
    background-color: black; }
    .bg-img-gym .sub-footer.darker-subfooter p {
      color: #ffffff; }

.tap-top.gradient-top {
  background: linear-gradient(180deg, #01effc 0%, #485ff2 99%); }

.banner-furniture {
  padding-left: 15px;
  padding-right: 15px; }
  .banner-furniture .collection-banner .contain-banner.banner-3 h2 {
    color: #222222;
    margin-bottom: 0; }
  .banner-furniture .collection-banner .contain-banner.banner-3 h4 {
    color: #b5dccd; }

.gym-slider .home-slider .home {
  height: 85vh; }
  .gym-slider .home-slider .home .slider-contain {
    height: 85vh; }
    .gym-slider .home-slider .home .slider-contain h4,
    .gym-slider .home-slider .home .slider-contain h1 {
      color: #ffffff; }

.main-nav-center {
  display: flex;
  align-items: center;
  justify-content: center; }

.header-gym {
  position: absolute;
  width: 100%; }
  .header-gym .main-menu {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0 15px;
    z-index: 1; }
    .header-gym .main-menu .menu-left .navbar {
      padding: 20px 45px 20px 0; }
      .header-gym .main-menu .menu-left .navbar i {
        color: white; }
    .header-gym .main-menu .brand-logo {
      padding-top: 20px;
      padding-bottom: 20px; }
    .header-gym .main-menu .onhover-div .show-div {
      top: 70px; }
  .header-gym .sidebar-back i {
    color: black; }
  .header-gym .sm-horizontal > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
    color: #ffffff; }
    .header-gym .sm-horizontal > li > a:hover, .header-gym .sm-horizontal > li > a:active, .header-gym .sm-horizontal > li > a:focus, .header-gym .sm-horizontal > li > a.highlighted {
      padding-top: 20px;
      padding-bottom: 20px;
      color: #ffffff; }
  .header-gym .onhover-div {
    padding-top: 20px;
    padding-bottom: 20px; }
    .header-gym .onhover-div > div img {
      filter: brightness(54); }
  .header-gym .top-header {
    z-index: 1;
    position: relative;
    background: transparent; }
    .header-gym .top-header .header-contact li {
      color: #fff; }
      .header-gym .top-header .header-contact li i {
        color: #fff; }
    .header-gym .top-header .header-dropdown li {
      color: #ffffff; }
      .header-gym .top-header .header-dropdown li a {
        color: #ffffff; }
        .header-gym .top-header .header-dropdown li a i {
          color: #ffffff;
          padding-right: 8px; }
    .header-gym .top-header.blue-layout {
      border-bottom: 1px solid #223b7d; }

.btn-gradient {
  background: #b5dccd;
  background: linear-gradient(180deg, #01effc 0%, #485ff2 99%);
  transition: background 300ms ease-in-out;
  background-size: 300% 100%;
  border: none; }
  .btn-gradient:hover {
    background: linear-gradient(-180deg, #485ff2 0%, #01effc 99%);
    transition: background 300ms ease-in-out;
    color: white; }

/*=====================
  17.7  extra layout CSS start
==========================*/
.left-header .top-header {
  padding-left: 300px;
  background-color: #ffffff; }
  .left-header .top-header .header-dropdown .mobile-wishlist img,
  .left-header .top-header .header-dropdown .mobile-account img {
    display: block; }
  .left-header .top-header .header-dropdown .mobile-wishlist i,
  .left-header .top-header .header-dropdown .mobile-account i {
    display: none; }

.left-header .onhover-div {
  padding-top: 15px;
  padding-bottom: 15px; }
  .left-header .onhover-div > div img {
    width: 20px;
    height: auto; }
  .left-header .onhover-div .show-div {
    top: 56px; }

.left-header .sidenav {
  left: 0;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25); }
  .left-header .sidenav .sidebar-back {
    display: none; }
  .left-header .sidenav nav {
    background-color: #ffffff; }
  .left-header .sidenav .pixelstrap > li > a {
    padding: 10px 0 20px 0; }
    .left-header .sidenav .pixelstrap > li > a:hover, .left-header .sidenav .pixelstrap > li > a:focus {
      padding: 10px 0 20px 0;
      background-color: #ffffff; }
  .left-header .sidenav .pixelstrap > li .lable-nav {
    top: -12px;
    background-color: #eeeeee; }
    .left-header .sidenav .pixelstrap > li .lable-nav:before {
      border-top: 5px solid #ff4c3b; }
    .left-header .sidenav .pixelstrap > li .lable-nav.grey-lable {
      background-color: #4d6171; }
      .left-header .sidenav .pixelstrap > li .lable-nav.grey-lable:before {
        border-top: 5px solid #4d6171; }
  .left-header .sidenav .left-sidebar_search {
    width: 100%;
    background-color: #ffffff; }
    .left-header .sidenav .left-sidebar_search input {
      height: 55px;
      width: 95%;
      border: none;
      padding-left: 15px;
      background-color: transparent; }
    .left-header .sidenav .left-sidebar_search .btn-search {
      position: absolute;
      right: 10px;
      top: 20px;
      border: none;
      background-color: transparent; }
  .left-header .sidenav .left-sidebar_center {
    padding: 40px 0 30px 30px;
    background-color: #ffffff; }
  .left-header .sidenav .bottom-section {
    padding: 70px 25px; }
    .left-header .sidenav .bottom-section .icon_settings li {
      display: inline-block; }
    .left-header .sidenav .bottom-section .icon_settings .shopping-cart {
      position: relative; }
      .left-header .sidenav .bottom-section .icon_settings .shopping-cart .shopping-count {
        width: 18px;
        height: 18px;
        background-color: #ff4c3b;
        color: white;
        position: absolute;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -7px;
        right: 7px; }
    .left-header .sidenav .bottom-section ul li {
      display: inline-block;
      padding: 8px 15px 8px 0; }
      .left-header .sidenav .bottom-section ul li a {
        font-size: 16px;
        text-transform: capitalize;
        color: #222222; }
        .left-header .sidenav .bottom-section ul li a i {
          font-size: 18px;
          padding-right: 5px;
          color: #222222; }
    .left-header .sidenav .bottom-section .leftside_setting .language,
    .left-header .sidenav .bottom-section .leftside_setting .currency {
      display: flex; }
      .left-header .sidenav .bottom-section .leftside_setting .language h5,
      .left-header .sidenav .bottom-section .leftside_setting .currency h5 {
        margin-bottom: 0;
        text-transform: capitalize;
        color: #999;
        margin-top: 0; }
      .left-header .sidenav .bottom-section .leftside_setting .language select,
      .left-header .sidenav .bottom-section .leftside_setting .currency select {
        margin-left: 10px;
        border: none;
        outline: none;
        text-transform: capitalize;
        color: #222222; }
    .left-header .sidenav .bottom-section .leftside_setting h5 {
      margin-top: 10px; }
  .left-header .sidenav .leftside_social {
    position: relative;
    width: 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px dashed #dddddd; }
    .left-header .sidenav .leftside_social .footer-social {
      margin-top: 0; }
      .left-header .sidenav .leftside_social .footer-social i {
        font-size: 18px; }
      .left-header .sidenav .leftside_social .footer-social li {
        padding: 10px; }
    .left-header .sidenav .leftside_social .call_us h5 {
      text-align: center; }
      .left-header .sidenav .leftside_social .call_us h5 span {
        display: block; }

.left-header .main-menu {
  justify-content: flex-end; }
  .left-header .main-menu .brand-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px dashed #dddddd; }
  .left-header .main-menu .menu-right .header-dropdown > li img {
    width: 20px;
    height: auto; }
  .left-header .main-menu .menu-right .icon-nav .onhover-div > img {
    width: 20px;
    height: auto; }
  .left-header .main-menu .menu-left .navbar {
    display: none; }
  .left-header .main-menu .menu-left .mobile-logo {
    display: none; }

.left-header .search-overlay {
  padding-left: 300px; }

.left-sidebar_space {
  padding-left: 300px; }

.header-6 .top-header {
  background-color: #b5dccd; }
  .header-6 .top-header .header-contact li {
    color: #ffffff; }
    .header-6 .top-header .header-contact li i {
      color: #ffffff; }
  .header-6 .top-header .header-dropdown li {
    color: #ffffff; }
    .header-6 .top-header .header-dropdown li a {
      color: #ffffff; }
      .header-6 .top-header .header-dropdown li a i {
        color: white; }

.header-6 .mobile-search {
  display: none; }

.form_search {
  height: 45px;
  box-shadow: 0 0 0 1px #e2e2e2;
  overflow: hidden;
  position: relative;
  width: 525px;
  background-color: #f5f5f5;
  border-radius: 25px; }
  .form_search input {
    width: 100%;
    height: 45px;
    font-size: 16px;
    color: darkgrey;
    border: none;
    outline: none;
    background: none;
    padding: 0 10px; }
  .form_search button {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 35px;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px; }
    .form_search button i {
      font-size: 18px;
      color: #b5dccd; }

.header-tools {
  position: absolute;
  width: 100%;
  top: 35px;
  z-index: 1;
  background-color: transparent; }
  .header-tools .container {
    padding-left: 30px;
    padding-right: 30px; }
  .header-tools .top-header {
    background-color: transparent; }
    .header-tools .top-header .container {
      background-color: #b5dccd; }
    .header-tools .top-header .header-contact {
      padding: 10px 0; }
      .header-tools .top-header .header-contact li {
        color: #ffffff; }
    .header-tools .top-header .header-dropdown li {
      color: #ffffff;
      padding: 10px 25px;
      padding-right: 0; }
      .header-tools .top-header .header-dropdown li a {
        color: #ffffff; }
        .header-tools .top-header .header-dropdown li a i {
          color: #ffffff; }
      .header-tools .top-header .header-dropdown li:hover i {
        color: #ffffff; }
  .header-tools .logo-menu-part {
    background-color: #fff;
    border-bottom: 2px solid #b5dccd; }
  .header-tools .main-menu .menu-left .navbar {
    padding: 25px 45px 25px 0; }
  .header-tools .main-menu .brand-logo {
    padding-top: 25px;
    padding-bottom: 25px; }
  .header-tools .sm-horizontal > li > a {
    padding-top: 25px;
    padding-bottom: 25px; }
    .header-tools .sm-horizontal > li > a:hover, .header-tools .sm-horizontal > li > a:active, .header-tools .sm-horizontal > li > a:focus, .header-tools .sm-horizontal > li > a.highlighted {
      padding-top: 25px;
      padding-bottom: 25px; }
  .header-tools .sm-horizontal li .lable-nav {
    top: 5px; }
  .header-tools .onhover-div {
    padding-top: 25px;
    padding-bottom: 25px; }
  .header-tools .cart_qty_cls {
    top: 16%;
    right: -14px; }

.tools-bg {
  background-color: #f5f5f5; }
  .tools-bg section {
    background-color: #f5f5f5; }

.tools-parallax.small-slider .home-slider .home .slider-contain h4,
.tools-parallax.small-slider .home-slider .home .slider-contain h1 {
  color: #ffffff; }

.tools-parallax-product.full-banner {
  padding-top: 110px;
  padding-bottom: 110px; }

.tools-parallax-product .tools-description h3 {
  color: #ffffff;
  text-transform: uppercase;
  margin-top: -5px; }

.tools-parallax-product .tools-description .tools-form .search-box {
  width: 75%;
  margin: 20px 0; }
  .tools-parallax-product .tools-description .tools-form .search-box select {
    text-transform: capitalize; }

.tools-parallax-product .tools-description .tools-form .btn-find {
  width: 75%;
  padding: 7px 29px;
  background-size: 1000px; }

.tools-parallax-product .tools-grey .slick-prev,
.tools-parallax-product .tools-grey .slick-next {
  transform: scale(1.5); }

.tools_slider .home-slider .home .container {
  position: relative; }

.tools_slider .home-slider .home .tools-parts {
  position: absolute;
  top: 0;
  right: 10%;
  display: flex;
  align-items: center;
  height: 75vh;
  margin-top: 50px; }

.tools_slider .home-slider .home .tools-parts1 {
  position: absolute;
  top: 0;
  right: 20%;
  display: flex;
  align-items: center;
  height: 75vh;
  margin-top: 50px; }

.tools_slider .home-slider .slider-contain {
  margin-top: 70px;
  margin-left: 150px; }
  .tools_slider .home-slider .slider-contain h4,
  .tools_slider .home-slider .slider-contain h1 {
    color: #ffffff; }

.tools-grey .product-m .slick-slide .img-wrapper {
  margin: 0; }

.tools-grey .product-box {
  width: 100%;
  display: inline-block;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
  margin: 0 10px; }
  .tools-grey .product-box .img-wrapper .lable-block {
    transition: all 0.5s ease; }
  .tools-grey .product-box .cart-info {
    display: flex;
    left: 0;
    bottom: -30px;
    transition: all 0.5s ease; }
    .tools-grey .product-box .cart-info .mobile-quick-view {
      display: none; }
    .tools-grey .product-box .cart-info a {
      background-color: #eef0f1;
      border-radius: 5px; }
      .tools-grey .product-box .cart-info a i {
        color: #555555; }
      .tools-grey .product-box .cart-info a:hover {
        background-color: #b5dccd;
        transition: all 0.5s ease; }
        .tools-grey .product-box .cart-info a:hover i {
          color: #ffffff;
          transition: all 0.5s ease; }
    .tools-grey .product-box .cart-info button {
      width: 55%;
      background-color: #eef0f1;
      color: #555555;
      border-radius: 5px;
      margin: 0 5px;
      transition: all 0.5s ease; }
      .tools-grey .product-box .cart-info button i {
        display: none; }
      .tools-grey .product-box .cart-info button:hover {
        background-color: #b5dccd;
        color: #ffffff;
        transition: all 0.5s ease; }
  .tools-grey .product-box .quick-view-part {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -20px;
    background-color: #b5dccd;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.5s ease; }
    .tools-grey .product-box .quick-view-part i {
      font-size: 18px;
      color: #ffffff; }
  .tools-grey .product-box .product-info {
    padding-left: 15px;
    padding-bottom: 15px;
    background-color: #ffffff;
    border-top: 1px solid rgba(221, 221, 221, 0.2); }
    .tools-grey .product-box .product-info .rating {
      margin-top: 0;
      padding-top: 15px; }
  .tools-grey .product-box .br.br-default {
    display: flex;
    justify-content: center; }
  .tools-grey .product-box:hover .img-wrapper .front img {
    opacity: 0.05;
    transition: all 0.5s ease; }
  .tools-grey .product-box:hover .img-wrapper .cart-info {
    opacity: 1;
    transition: all 0.5s ease;
    bottom: 10px; }
    .tools-grey .product-box:hover .img-wrapper .cart-info button {
      animation: none; }
    .tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(2) i {
      animation: none; }
    .tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(3) i {
      animation: none; }
    .tools-grey .product-box:hover .img-wrapper .cart-info a:nth-child(4) i {
      animation: none; }
  .tools-grey .product-box:hover .img-wrapper .lable-block {
    display: none;
    transition: all 0.5s ease; }
  .tools-grey .product-box:hover .quick-view-part {
    opacity: 1;
    transition: all 0.5s ease; }
  .tools-grey .product-box:hover .ribbon {
    display: none;
    transition: all 0.5s ease; }

.tools-grey .ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
  transition: all 0.5s ease; }
  .tools-grey .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #b5dccd;
    box-shadow: 0 3px 10px -5px black;
    position: absolute;
    top: 19px;
    left: -21px; }
    .tools-grey .ribbon span:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #b5dccd;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #b5dccd; }
    .tools-grey .ribbon span:after {
      content: '';
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #b5dccd;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #b5dccd; }

.category-tools .category-m .category-wrapper {
  background-color: white;
  border: none;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05); }

.banner-tools {
  margin-top: 30px;
  border: 1px solid #dddddd;
  overflow: hidden; }
  .banner-tools img {
    transition: all 0.5s ease; }
  .banner-tools:hover img {
    transform: scale(1.05);
    transition: all 0.5s ease; }

.tools-brand .row {
  background-color: white;
  padding: 25px 0;
  box-shadow: 0 0 8px 0 #ddd;
  margin: 0; }

.sticky-footer {
  background-image: url("/assets/images/tools/footer.jpg"); }
  .sticky-footer .darken-layout {
    background-color: transparent !important; }
    .sticky-footer .darken-layout p {
      color: #c0c0c0; }
    .sticky-footer .darken-layout .sub-title li a {
      color: #c0c0c0; }
    .sticky-footer .darken-layout .sub-title .contact-list li,
    .sticky-footer .darken-layout .sub-title .contact-list i {
      color: #c0c0c0; }
    .sticky-footer .darken-layout .footer-social i {
      color: #c0c0c0; }
  .sticky-footer .dark-subfooter {
    background-color: #141111 !important; }
    .sticky-footer .dark-subfooter p {
      color: #c0c0c0 !important; }

.green-gradient .top-header {
  background: #b5dccd;
  background-image: linear-gradient(to right, #01effc, #485ff2); }
  .green-gradient .top-header .header-contact {
    padding: 10px 0; }
    .green-gradient .top-header .header-contact li {
      color: #ffffff; }
      .green-gradient .top-header .header-contact li i {
        color: #ffffff; }
  .green-gradient .top-header .header-dropdown li {
    color: #ffffff;
    padding: 10px 25px; }
    .green-gradient .top-header .header-dropdown li a {
      color: #ffffff; }
      .green-gradient .top-header .header-dropdown li a i {
        color: #ffffff; }

.green-gradient .pixelstrap li .lable-nav {
  background: #b5dccd;
  background-image: linear-gradient(to right, #01effc, #485ff2); }

.service_slide {
  position: relative; }
  .service_slide .home-slider .home {
    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.4); }
  .service_slide .home-slider .slider-contain {
    height: 60vh; }
  .service_slide .service-home {
    position: absolute;
    width: 100%;
    bottom: 0; }
    .service_slide .service-home .service-block1 {
      background-color: rgba(255, 255, 255, 0.9);
      padding: 25px 0; }
      .service_slide .service-home .service-block1 h4 {
        margin-bottom: 0; }
      .service_slide .service-home .service-block1 p {
        text-transform: capitalize; }
      .service_slide .service-home .service-block1 svg {
        margin-bottom: 5px;
        width: 50px;
        height: 50px; }
      .service_slide .service-home .service-block1:nth-child(even) {
        background-color: rgba(255, 255, 255, 0.85); }

.tab-left .theme-tab {
  display: flex; }
  .tab-left .theme-tab .left-side {
    width: 30%; }
    .tab-left .theme-tab .left-side .left-tab-title {
      margin-bottom: 38px; }
      .tab-left .theme-tab .left-side .left-tab-title h4 {
        color: #dafe69;
        margin-top: -3px; }
      .tab-left .theme-tab .left-side .left-tab-title h3 {
        color: #ffffff; }
    .tab-left .theme-tab .left-side .tab-title {
      margin-right: 100px;
      text-align: left; }
      .tab-left .theme-tab .left-side .tab-title a {
        text-transform: capitalize; }
    .tab-left .theme-tab .left-side li {
      width: 100%;
      padding: 7px 0;
      border-bottom: 1px solid #606060; }
      .tab-left .theme-tab .left-side li a {
        color: #ffffff; }
      .tab-left .theme-tab .left-side li:first-child {
        border-top: 1px solid #606060; }
      .tab-left .theme-tab .left-side li.current a {
        color: #dafe69; }
  .tab-left .theme-tab .tab-content-cls {
    width: 70%; }

.product_section {
  background-image: url("/assets/images/marijuana/leaf-bg.jpg"); }

.border-box.tools-grey .ribbon span {
  background-image: linear-gradient(to right, #01effc, #485ff2); }

.border-box.tools-grey .product-box {
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #dddddd; }
  .border-box.tools-grey .product-box .cart-info a:hover {
    background-image: linear-gradient(to right, #01effc, #485ff2); }
  .border-box.tools-grey .product-box .cart-info button:hover {
    background-image: linear-gradient(to right, #01effc, #485ff2); }
  .border-box.tools-grey .product-box .quick-view-part {
    background-image: linear-gradient(to right, #01effc, #485ff2); }

.detail-cannabis .detail_section {
  text-align: center; }
  .detail-cannabis .detail_section > div {
    box-shadow: -1px 2px 5px 1px #ececec;
    padding: 45px 25px;
    background-color: white; }
  .detail-cannabis .detail_section p {
    margin-bottom: 0;
    line-height: 24px;
    letter-spacing: 0.05em; }
  .detail-cannabis .detail_section h4 {
    font-weight: 700; }
  .detail-cannabis .detail_section svg {
    width: 50px;
    margin-bottom: 15px;
    fill: #b5dccd; }

footer.footer-5 {
  background-image: linear-gradient(to right, #01effc, #485ff2); }
  footer.footer-5 .dark-layout {
    background-color: transparent; }
  footer.footer-5 .sub-footer {
    background-color: transparent; }
    footer.footer-5 .sub-footer > .container {
      border-top: 1px solid #ffffff; }
    footer.footer-5 .sub-footer p {
      color: #ffffff; }
  footer.footer-5 .footer-theme2 p {
    color: #ffffff; }
  footer.footer-5 .footer-theme2 .contact-details li {
    color: #ffffff; }
    footer.footer-5 .footer-theme2 .contact-details li a {
      color: #ffffff; }
  footer.footer-5 .footer-theme2 .subscribe-block {
    border-left: 1px solid #ffffff;
    border-right: 1px solid #ffffff; }

.tap-top.green-top {
  background-image: linear-gradient(to right, #01effc, #485ff2);
  border: 1px solid rgba(255, 255, 255, 0.5); }

.quickviewm .modal-dialog .modal-content .modal-body {
  background-image: none; }

#toast-container > div {
  opacity: 1; }

.toast {
  font-size: initial !important;
  border: initial !important;
  backdrop-filter: blur(0) !important; }

.toast-success {
  background-color: #51a351 !important; }

.toast-error {
  background-color: #bd362f !important; }

.toast-info {
  background-color: #2f96b4 !important; }

.toast-warning {
  background-color: #f89406 !important; }

/*=====================
    18.Flying Cart CSS start
==========================*/
.addcart_btm_popup {
  position: fixed;
  right: 0;
  width: 42px;
  height: 41px;
  color: #000000;
  cursor: pointer;
  transition: all ease-in 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  text-decoration: none;
  background: #ffffff;
  top: 33%;
  border: 1px solid white;
  -webkit-box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);
  box-shadow: 0 0 5px rgba(4, 4, 4, 0.28); }
  .addcart_btm_popup .fixed_cart {
    position: relative;
    z-index: 99;
    display: block;
    float: right;
    font-size: 18px;
    text-decoration: none;
    padding-left: 8px;
    white-space: nowrap; }
    .addcart_btm_popup .fixed_cart .cart_qty_cls {
      font-weight: 400; }

/*=====================
    19.footer CSS start
==========================*/
footer {
  position: relative; }
  footer .footer-lable {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #b5dccd;
    top: -10px;
    display: flex;
    align-items: center;
    justify-content: center; }
    footer .footer-lable:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 10px solid #50a886;
      border-right: 10px solid transparent;
      top: 0;
      right: -10px; }
    footer .footer-lable i {
      color: white;
      font-size: 25px; }
  footer .footer-theme .footer-mobile-title {
    display: none; }
  footer .darken-layout p {
    color: #8c9391; }
  footer .darken-layout .sub-title .footer-title.active .according-menu {
    font: "Font Awesome 5 Free"; }
    footer .darken-layout .sub-title .footer-title.active .according-menu.before {
      content: '\f068'; }
  footer .darken-layout .sub-title h4 {
    color: #ffffff; }
  footer .darken-layout .sub-title li a {
    color: #8c9391; }
  footer .darken-layout .sub-title .contact-list i {
    color: #8c9391; }
  footer .darken-layout .sub-title .contact-list li {
    color: #8c9391; }
  footer .darken-layout .footer-social i {
    color: #8c9391; }
  footer p {
    line-height: 32px;
    letter-spacing: 0.03em;
    margin-bottom: 0; }

.footer-white .light-layout {
  background-color: white; }

.footer-white .sub-footer .row {
  border-top: 1px solid #dddddd; }

.footer-light .subscribe {
  display: flex;
  height: 100%;
  align-items: center;
  border-right: 1px solid #dddddd;
  text-align: left; }
  .footer-light .subscribe p {
    line-height: 1; }
  .footer-light .subscribe h4 {
    margin-bottom: 10px; }

.footer-light .subscribe-form {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end; }

.subscribe {
  text-align: center; }
  .subscribe h4 {
    color: #222222;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    margin-bottom: 15px; }
    .subscribe h4 i {
      color: #b5dccd;
      padding-right: 5px;
      font-size: 23px; }

.subscribe-form {
  display: inline-flex; }
  .subscribe-form .form-group {
    margin-bottom: 0; }
  .subscribe-form .form-control {
    padding: 13px;
    width: 345px;
    border-radius: 0;
    border: 1px solid #eeeeee; }
    .subscribe-form .form-control.name-cls {
      width: 265px; }
  .subscribe-form.classic-form .form-control {
    border-radius: 0;
    border: none;
    background-color: #f5f2f2;
    margin-left: 15px; }
  .subscribe-form.classic-form .btn-solid {
    margin-bottom: 0 !important; }

.footer-social,
.social-white {
  margin-top: 40px;
  display: flex;
  align-items: center; }
  .footer-social li,
  .social-white li {
    display: inline-block;
    padding-right: 45px; }
  .footer-social i,
  .social-white i {
    font-size: 20px;
    color: #000000;
    transition: 0.5s ease; }
    .footer-social i:hover,
    .social-white i:hover {
      color: #b5dccd !important;
      transition: 0.5s ease; }

.social-white li {
  padding-left: 22px;
  padding-right: 22px; }

.footer-theme .footer-logo {
  margin-bottom: 18px; }

.footer-theme .sub-title h4 {
  color: #222222;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-weight: 700; }

.footer-theme .sub-title li {
  position: relative;
  display: inherit;
  padding-top: 13px;
  text-transform: capitalize;
  font-size: 14px;
  color: #777777; }
  .footer-theme .sub-title li a {
    position: relative;
    color: #777777; }
    .footer-theme .sub-title li a:before {
      position: absolute;
      top: 20px;
      content: '';
      height: 2px;
      width: 0;
      background-color: #b5dccd;
      transition: 0.5s ease; }
  .footer-theme .sub-title li:hover a:before {
    width: 100%;
    height: 2px;
    transition: 0.5s ease; }

.footer-theme .sub-title .contact-list i {
  position: absolute;
  left: 0;
  top: 21px; }

.footer-theme .sub-title .contact-list li {
  position: relative;
  padding-left: 25px;
  line-height: 28px; }
  .footer-theme .sub-title .contact-list li a:hover {
    color: #b5dccd;
    transition: all 0.5s ease; }
  .footer-theme .sub-title .contact-list li:hover {
    color: #999999;
    transition: all 0.3s ease; }
    .footer-theme .sub-title .contact-list li:hover a:before {
      display: none; }
  .footer-theme .sub-title .contact-list li:last-child {
    margin-bottom: -5px; }

.sub-footer {
  background-color: #ffffff; }
  .sub-footer.dark-subfooter {
    background-color: #2e2726; }
    .sub-footer.dark-subfooter p {
      color: #8c9391; }
  .sub-footer.darker-subfooter {
    background-color: #25221e; }
    .sub-footer.darker-subfooter p {
      color: #7f786d; }
  .sub-footer.black-subfooter {
    background-color: #222222; }
    .sub-footer.black-subfooter p {
      color: #777777; }
  .sub-footer p {
    color: #777777;
    margin-bottom: 0;
    padding: 20px 0; }
  .sub-footer .payment-card-bottom {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .sub-footer .payment-card-bottom li {
      padding-left: 7px;
      padding-right: 7px;
      display: inline-block; }

.footer-theme2 {
  text-align: center; }
  .footer-theme2 .footer-mobile-title {
    display: none !important; }
  .footer-theme2 .contact-details li a {
    color: #7f786d; }
    .footer-theme2 .contact-details li a:hover {
      color: #b5dccd;
      transition: all 0.5s ease; }
  .footer-theme2.footer-border {
    border: 20px solid #f1f5f4; }
  .footer-theme2 .footer-logo {
    margin-bottom: 18px; }
  .footer-theme2 p {
    line-height: 32px;
    letter-spacing: 0.03em;
    margin-bottom: 0;
    color: #7f786d; }
  .footer-theme2 h4 {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0;
    padding-bottom: 25px; }
  .footer-theme2 .contact-details li {
    display: block;
    color: #7f786d;
    padding: 0 50px;
    line-height: 2.2; }
    .footer-theme2 .contact-details li:last-child {
      margin-bottom: -8px; }
  .footer-theme2 .subscribe-block {
    border-left: 1px solid #38352f;
    border-right: 1px solid #38352f;
    padding: 10px 110px; }
    .footer-theme2 .subscribe-block h2 {
      color: #ffffff;
      margin-bottom: 30px; }
    .footer-theme2 .subscribe-block .btn-solid {
      margin-top: 10px; }
    .footer-theme2 .subscribe-block .form-group {
      margin-bottom: 0; }
    .footer-theme2 .subscribe-block .form-control {
      text-align: center;
      letter-spacing: 5px;
      font-size: 12px;
      padding: 15px 0;
      border-radius: 0; }
  .footer-theme2 .footer-link.link-white h4,
  .footer-theme2 .footer-link-b.link-white h4 {
    color: #222222; }
  .footer-theme2 .footer-link.link-white a,
  .footer-theme2 .footer-link-b.link-white a {
    color: #777777;
    transition: 0.5s ease; }
    .footer-theme2 .footer-link.link-white a:hover,
    .footer-theme2 .footer-link-b.link-white a:hover {
      color: #2d2a25;
      transition: 0.5s ease; }
  .footer-theme2 .footer-link h4,
  .footer-theme2 .footer-link-b h4 {
    padding-bottom: 20px; }
  .footer-theme2 .footer-link li,
  .footer-theme2 .footer-link-b li {
    padding-right: 15px;
    padding-left: 15px;
    text-transform: capitalize; }
  .footer-theme2 .footer-link a,
  .footer-theme2 .footer-link-b a {
    color: #7f786d;
    transition: 0.5s ease; }
    .footer-theme2 .footer-link a:hover,
    .footer-theme2 .footer-link-b a:hover {
      color: #f5f2f2;
      transition: 0.5s ease; }
  .footer-theme2 .footer-link-b h4 {
    margin-top: 50px; }
  .footer-theme2.section-light h2,
  .footer-theme2.section-light h4 {
    color: #222222; }
  .footer-theme2.section-light .footer-block {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%; }
    .footer-theme2.section-light .footer-block h4 {
      padding-bottom: 25px; }
    .footer-theme2.section-light .footer-block .contact-details li a {
      color: #7f786d; }
      .footer-theme2.section-light .footer-block .contact-details li a:hover {
        color: #b5dccd;
        transition: all 0.5s ease; }
    .footer-theme2.section-light .footer-block .subscribe-white {
      background-color: #d8ece4;
      width: 100%;
      padding: 85px 65px;
      border-left: 20px solid #f1f5f4;
      border-right: 20px solid #f1f5f4; }
      .footer-theme2.section-light .footer-block .subscribe-white .form-group {
        margin-bottom: 0; }
      .footer-theme2.section-light .footer-block .subscribe-white .form-control {
        border: none;
        border-radius: 0; }
      .footer-theme2.section-light .footer-block .subscribe-white h2 {
        margin-bottom: 30px;
        margin-top: -5px; }
      .footer-theme2.section-light .footer-block .subscribe-white .btn-solid {
        margin-top: 10px; }
      .footer-theme2.section-light .footer-block .subscribe-white .form-control {
        text-align: center;
        letter-spacing: 5px;
        font-size: 12px;
        padding: 15px 0; }

.cart-qty-cls {
  position: absolute;
  background: #b5dccd;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  top: 20%;
  right: -8px;
  padding: 3px; }

/*=====================
    20.Loader CSS start
==========================*/
.loader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  display: block;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
  top: 0; }
  .loader-wrapper .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    background-color: #b5dccd;
    border-radius: 50%; }
    .loader-wrapper .loader:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      border: 0 solid white;
      transform: translate(-50%, -50%);
      animation: loading 1000ms ease-out forwards infinite;
      transition: all 0.3s ease; }

@keyframes loading {
  0% {
    border: 0 solid white;
    transition: all 0.3s ease; }
  20% {
    border: 8px solid white;
    width: 0;
    height: 0;
    transition: all 0.3s ease; }
  100% {
    border: 8px solid white;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease; } }

/*=====================
    21.Tab to top CSS start
==========================*/
.tap-top {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 8;
  display: none;
  color: #fff;
  text-align: center;
  background: #b5dccd;
  border: none;
  font-size: 22px;
  padding: 7px;
  cursor: pointer; }
  .tap-top.top-cls {
    bottom: 130px; }

/*=====================
    22.theme setting box CSS start
==========================*/
.color1 {
  background: #ff4c3b; }

.color2 {
  background: #3fdda7; }

.color3 {
  background: #f0b54d; }

.color4 {
  background: #e4604a; }

.color5 {
  background: #d4b196; }

.color6 {
  background: #866e6c; }

.color7 {
  background: #cc2121; }

.color8 {
  background: #dc457e; }

.color9 {
  background: #6d7e87; }

.color10 {
  background: #fa869b; }

.color11 {
  background: #81ba00; }

.color12 {
  background: #fe816d; }

.color13 {
  background: #01effc; }

.color14 {
  background: #5d7227; }

.color15 {
  background: #ff9944; }

.color16 {
  background: #5fcbc4; }

.color17 {
  background: #e38888; }

.color18 {
  background: #000000; }

.color-picker {
  position: fixed;
  right: -190px;
  top: calc(38vh + 220px);
  width: 190px;
  z-index: 1;
  transition: all 0.3s ease; }
  .color-picker .settings-header {
    background: #f3f3f3;
    padding: 5px 15px;
    box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62); }
    .color-picker .settings-header h3 {
      color: black;
      margin: 0;
      font-size: 20px;
      padding: 4px 9px; }
  .color-picker .section {
    background: white;
    padding: 10px;
    border: 1px solid #f3f3f3;
    overflow: auto; }
    .color-picker .section i {
      font-size: 16px;
      margin-right: 10px; }
  .color-picker a.handle {
    position: absolute;
    right: 190px;
    width: 45px;
    height: 41px;
    transition: all ease-in 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    z-index: -1;
    text-decoration: none;
    background-color: #f9f9f9;
    box-shadow: -5.985px -0.419px 30px 0 rgba(0, 0, 0, 0.08), inset -2px 0 10px 0 rgba(0, 0, 0, 0.04);
    top: -80px;
    color: #000000; }
    .color-picker a.handle i {
      margin-left: 5px; }
  .color-picker .colors a {
    width: 32px;
    height: 32px;
    margin: 5px;
    float: left;
    transition: all ease-in 0.3s; }
  .color-picker .skin a {
    display: block;
    width: 70px;
    height: 30px;
    margin-left: 0;
    margin-top: 0;
    margin-right: 5px;
    float: left;
    text-align: center;
    line-height: 30px;
    border: 2px transparent solid; }
    .color-picker .skin a.actt {
      border: 2px white solid; }

.dark-light {
  position: fixed;
  right: 0;
  width: 40px;
  height: 38px;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f7f7f7;
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  top: calc(50% + 34px);
  cursor: pointer; }

.setting-sidebar {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f7f7f7;
  width: 40px;
  height: 40px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  transition: all 0.5s ease;
  z-index: 1; }
  .setting-sidebar i {
    animation: ring-rotate 3s infinite linear;
    font-size: 20px;
    color: #3c3c3c;
    margin-left: 10px; }
  .setting-sidebar.open-icon {
    right: 320px;
    transition: all 0.5s ease; }

.setting_box_body {
  position: relative;
  background-color: white;
  z-index: 9;
  height: 100vh; }

.setting-box {
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 320px;
  right: -360px;
  background-color: white;
  top: 0;
  z-index: 999;
  box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
  transition: all 0.5s ease; }
  .setting-box ::-webkit-scrollbar {
    width: 3px;
    height: 7px; }
  .setting-box ::-webkit-scrollbar-track {
    background: #dddddd; }
  .setting-box ::-webkit-scrollbar-thumb {
    background: #888888; }
  .setting-box ::-webkit-scrollbar-thumb:hover {
    background: #555555; }
  .setting-box.open-setting {
    right: 0;
    transition: all 0.5s ease; }
    .setting-box.open-setting .overlay {
      visibility: visible;
      transition: all 0.5s ease; }
  .setting-box .sidebar-back {
    padding: 20px;
    font-size: 18px;
    color: #222222;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: inset 0px 0px 6px rgba(115, 115, 115, 0.09);
    background-color: #f3f3f3; }
  .setting-box .setting-title {
    padding: 20px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 0; }
    .setting-box .setting-title h4 {
      position: relative;
      margin-bottom: 0;
      cursor: pointer; }
    .setting-box .setting-title.active .according-menu {
      font: "Font Awesome 5 Free"; }
      .setting-box .setting-title.active .according-menu:before {
        content: '\f068';
        position: absolute;
        right: 2px;
        top: 2px; }
    .setting-box .setting-title .according-menu {
      font: "Font Awesome 5 Free"; }
      .setting-box .setting-title .according-menu:before {
        content: '\f067';
        position: absolute;
        right: 2px;
        top: 2px; }
  .setting-box .setting-contant {
    padding: 20px;
    border-bottom: 1px solid #efefef; }
    .setting-box .setting-contant .setting_buttons .setting_btn {
      background-color: #b5dccd;
      color: white;
      width: 100%; }
    .setting-box .setting-contant .setting_buttons li:first-child {
      width: 49%; }
    .setting-box .setting-contant .setting_buttons li.active {
      box-shadow: 0px 0px 5px 0px #929292; }
    .setting-box .setting-contant .setting_buttons li:last-child {
      margin-left: 10px;
      width: 46%; }
    .setting-box .setting-contant .color-box li {
      margin: 3px;
      width: 32px;
      height: 32px;
      display: inline-grid; }
    .setting-box .setting-contant .color-box.active {
      position: relative; }
      .setting-box .setting-contant .color-box.active li {
        box-shadow: 0px 0px 5px 0px #929292; }
      .setting-box .setting-contant .color-box.active:after {
        content: '';
        background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
        top: 12px;
        height: 15px;
        width: 15px;
        background-size: 80%;
        background-repeat: no-repeat;
        position: absolute;
        left: 13px;
        filter: invert(100); }
  .setting-box .buy_btn {
    padding: 20px; }
    .setting-box .buy_btn a {
      background-color: #f3f3f3;
      color: #3c3c3c;
      padding: 10px;
      transition: all 0.5s ease; }
      .setting-box .buy_btn a i {
        margin-right: 5px;
        font-size: 18px; }
      .setting-box .buy_btn a img {
        filter: grayscale(100); }
  .setting-box .overlay {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease; }

.demo-section .title-text h3 {
  color: #b5dccd;
  margin-bottom: 24px;
  display: inline-block;
  padding-bottom: 5px;
  font-size: 40px;
  position: relative; }
  .demo-section .title-text h3 i {
    font-size: 22px;
    position: absolute;
    top: 0;
    right: -20px; }

.demo-section .demo-effects {
  margin-bottom: 30px; }
  .demo-section .demo-effects h4 {
    text-transform: capitalize; }
  .demo-section .demo-effects .set-position {
    display: block; }
  .demo-section .demo-effects > div {
    text-decoration: none;
    color: black; }
    .demo-section .demo-effects > div .layout-container {
      height: 110px;
      width: 100%;
      display: inline-flex;
      background-size: cover;
      transition: all 2s ease-out 0s;
      box-shadow: -1px 2px 5px 1px #ececec; }
      .demo-section .demo-effects > div .layout-container.demo1 {
        background-image: url("/assets/images/landing-page/demo/1.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo2 {
        background-image: url("/assets/images/landing-page/demo/2.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo3 {
        background-image: url("/assets/images/landing-page/demo/3.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo4 {
        background-image: url("/assets/images/landing-page/demo/4.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo5 {
        background-image: url("/assets/images/landing-page/demo/5.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo6 {
        background-image: url("/assets/images/landing-page/demo/6.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo7 {
        background-image: url("/assets/images/landing-page/demo/7.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo8 {
        background-image: url("/assets/images/landing-page/demo/8.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo9 {
        background-image: url("/assets/images/landing-page/demo/9.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo10 {
        background-image: url("/assets/images/landing-page/demo/10.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo11 {
        background-image: url("/assets/images/landing-page/demo/11.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo12 {
        background-image: url("/assets/images/landing-page/demo/12.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo13 {
        background-image: url("/assets/images/landing-page/demo/13.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo14 {
        background-image: url("/assets/images/landing-page/demo/14.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo15 {
        background-image: url("/assets/images/landing-page/demo/15.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo16 {
        background-image: url("/assets/images/landing-page/demo/16.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo17 {
        background-image: url("/assets/images/landing-page/demo/17.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo18 {
        background-image: url("/assets/images/landing-page/demo/18.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo19 {
        background-image: url("/assets/images/landing-page/demo/19.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo20 {
        background-image: url("/assets/images/landing-page/demo/20.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo21 {
        background-image: url("/assets/images/landing-page/demo/21.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo22 {
        background-image: url("/assets/images/landing-page/demo/22.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo23 {
        background-image: url("/assets/images/landing-page/demo/23.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo24 {
        background-image: url("/assets/images/landing-page/demo/24.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo25 {
        background-image: url("/assets/images/landing-page/demo/25.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo26 {
        background-image: url("/assets/images/landing-page/demo/26.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo27 {
        background-image: url("/assets/images/landing-page/demo/27.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo28 {
        background-image: url("/assets/images/landing-page/demo/28.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo29 {
        background-image: url("/assets/images/landing-page/demo/29.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo30 {
        background-image: url("/assets/images/landing-page/demo/30.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo31 {
        background-image: url("/assets/images/landing-page/demo/31.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo32 {
        background-image: url("/assets/images/landing-page/demo/32.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo33 {
        background-image: url("/assets/images/landing-page/demo/33.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo34 {
        background-image: url("/assets/images/landing-page/demo/34.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo35 {
        background-image: url("/assets/images/landing-page/demo/35.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo36 {
        background-image: url("/assets/images/landing-page/demo/36.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo37 {
        background-image: url("/assets/images/landing-page/demo/37.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo38 {
        background-image: url("/assets/images/landing-page/demo/38.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo39 {
        background-image: url("/assets/images/landing-page/demo/39.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo40 {
        background-image: url("/assets/images/landing-page/demo/40.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo41 {
        background-image: url("/assets/images/landing-page/demo/41.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo42 {
        background-image: url("/assets/images/landing-page/demo/42.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo43 {
        background-image: url("/assets/images/landing-page/demo/43.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo44 {
        background-image: url("/assets/images/landing-page/demo/44.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo45 {
        background-image: url("/assets/images/landing-page/demo/45.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo46 {
        background-image: url("/assets/images/landing-page/demo/46.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo47 {
        background-image: url("/assets/images/landing-page/demo/47.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo48 {
        background-image: url("/assets/images/landing-page/demo/48.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo49 {
        background-image: url("/assets/images/landing-page/demo/49.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo50 {
        background-image: url("/assets/images/landing-page/demo/50.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo51 {
        background-image: url("/assets/images/landing-page/demo/51.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo52 {
        background-image: url("/assets/images/landing-page/demo/52.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo53 {
        background-image: url("/assets/images/landing-page/demo/53.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo54 {
        background-image: url("/assets/images/landing-page/demo/54.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo55 {
        background-image: url("/assets/images/landing-page/demo/55.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo56 {
        background-image: url("/assets/images/landing-page/demo/56.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo57 {
        background-image: url("/assets/images/landing-page/demo/57.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo58 {
        background-image: url("/assets/images/landing-page/demo/58.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo59 {
        background-image: url("/assets/images/landing-page/demo/59.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo60 {
        background-image: url("/assets/images/landing-page/demo/60.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo61 {
        background-image: url("/assets/images/landing-page/demo/61.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo62 {
        background-image: url("/assets/images/landing-page/demo/62.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo63 {
        background-image: url("/assets/images/landing-page/demo/63.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo64 {
        background-image: url("/assets/images/landing-page/demo/64.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo65 {
        background-image: url("/assets/images/landing-page/demo/65.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo66 {
        background-image: url("/assets/images/landing-page/demo/66.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo67 {
        background-image: url("/assets/images/landing-page/demo/67.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo68 {
        background-image: url("/assets/images/landing-page/demo/68.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo69 {
        background-image: url("/assets/images/landing-page/demo/69.jpg"); }
      .demo-section .demo-effects > div .layout-container.demo70 {
        background-image: url("/assets/images/landing-page/demo/70.jpg"); }
    .demo-section .demo-effects > div .demo-text h4 {
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 0;
      color: #000000; }
      .demo-section .demo-effects > div .demo-text h4 span {
        font-weight: 500; }
    .demo-section .demo-effects > div .demo-text .demo-btn {
      margin-top: 10px; }
      .demo-section .demo-effects > div .demo-text .demo-btn .btn {
        border: 1px solid #ddd;
        background-color: white;
        width: 90px;
        color: #b5dccd;
        padding: 5px 10px;
        border-radius: 30px; }
        .demo-section .demo-effects > div .demo-text .demo-btn .btn:hover {
          background-color: #b5dccd;
          color: white; }
  .demo-section .demo-effects:last-child {
    margin-bottom: 0; }
  .demo-section .demo-effects.effect-2 .layout-container {
    background-size: cover;
    background-repeat: no-repeat; }
  .demo-section .demo-effects.effect-2:hover .layout-container {
    background-position: top !important; }
  .demo-section .demo-effects.effect-3 .layout-container {
    background-size: cover;
    background-repeat: no-repeat; }
  .demo-section .demo-effects.effect-3:hover .layout-container {
    background-position: top !important; }
  .demo-section .demo-effects:hover .layout-container {
    background-position: center 100% !important; }

@keyframes ring-rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.sec-position {
  position: relative;
  top: -80px; }

.color-picker {
  position: fixed;
  right: -190px;
  top: 35%;
  width: 190px;
  z-index: 1;
  transition: all 0.3s ease; }
  .color-picker .settings-header {
    background: #f3f3f3;
    padding: 5px 15px;
    box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62); }
    .color-picker .settings-header h3 {
      color: black;
      margin: 0;
      font-size: 20px;
      padding: 4px 9px; }
  .color-picker .section {
    background: white;
    padding: 10px;
    border: 1px solid #f3f3f3;
    overflow: auto; }
    .color-picker .section i {
      font-size: 16px;
      margin-right: 10px; }
  .color-picker a.handle {
    position: absolute;
    right: 190px;
    width: 42px;
    height: 41px;
    color: #000000;
    transition: all ease-in 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    z-index: -1;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    text-decoration: none;
    background: #ffffff;
    top: -80px;
    border: 1px solid white;
    box-shadow: 0 0 5px rgba(4, 4, 4, 0.28); }
    .color-picker a.handle i {
      margin-left: 5px; }
    .color-picker a.handle:hover {
      color: #b5dccd; }
  .color-picker .colors a {
    width: 32px;
    height: 32px;
    margin: 5px;
    float: left;
    transition: all ease-in 0.3s; }
  .color-picker .skin a {
    display: block;
    width: 70px;
    height: 30px;
    margin-left: 0;
    margin-top: 0;
    margin-right: 5px;
    float: left;
    text-align: center;
    line-height: 30px;
    border: 2px transparent solid; }
    .color-picker .skin a.actt {
      border: 2px white solid; }

.handle i {
  animation: ring-rotate 3s infinite linear; }

.handle:hover {
  background-color: white; }

/*=====================
  23.Inner pages CSS start
==========================*/
.error-section {
  padding: 130px 0 140px;
  text-align: center; }
  .error-section h1 {
    font-size: 200px;
    font-weight: 900;
    margin: 0;
    line-height: 0.8;
    color: #aaaaaa;
    margin-top: -11px; }
  .error-section h2 {
    color: #aaaaaa;
    font-weight: 500;
    letter-spacing: 3px;
    margin: 40px 0; }

.breadcrumb-section {
  background-color: #f8f8f8;
  padding: 30px 0; }
  .breadcrumb-section .page-title {
    display: flex;
    height: 100%;
    align-items: center; }
    .breadcrumb-section .page-title h2 {
      font-size: 16px;
      margin-bottom: 0; }
  .breadcrumb-section .breadcrumb {
    background-color: transparent;
    justify-content: flex-end;
    text-transform: uppercase;
    font-size: 14px;
    color: #555555;
    font-weight: 600;
    margin: 0; }
    .breadcrumb-section .breadcrumb a {
      color: #555555;
      font-weight: 600; }

.about-head {
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  height: 100%; }
  .about-head h2 {
    color: #b5dccd;
    margin-bottom: 0; }

.about-page p {
  margin-bottom: -7px; }

.about-page .banner-section {
  border-radius: 5px;
  overflow: hidden; }

.about-page h4 {
  margin-bottom: 10px;
  margin-top: 30px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.3; }

.about-page p {
  line-height: 2;
  letter-spacing: 0.03em; }

.testimonial {
  background-color: #f7f7f7; }
  .testimonial .testimonial-slider .media img {
    border-radius: 100%;
    border: 1px solid #dddddd;
    padding: 5px;
    height: 130px;
    width: 130px; }
  .testimonial .testimonial-slider .media h5 {
    font-weight: 700;
    color: #b5dccd;
    margin-bottom: 0;
    margin-top: 7px; }
  .testimonial .testimonial-slider .media h6 {
    margin-bottom: -4px; }
  .testimonial .testimonial-slider .media .media-body {
    padding: 30px 75px 30px 30px; }
    .testimonial .testimonial-slider .media .media-body p {
      line-height: 2;
      letter-spacing: 0.03em; }
  .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
    border-left: 1px solid #dddddd;
    padding-left: 50px; }

.team {
  position: relative; }
  .team img {
    margin: 0 auto; }
  .team .slick-prev,
  .team .slick-next {
    position: absolute;
    top: -45px; }
    .team .slick-prev:before,
    .team .slick-next:before {
      font: "Font Awesome 5 Free";
      color: #222222;
      opacity: 0.6; }
  .team .slick-prev {
    left: unset;
    right: 20px; }
    .team .slick-prev:before {
      content: '\f104';
      font-weight: 900; }
  .team .slick-next {
    right: 0; }
    .team .slick-next:before {
      font-weight: 900; }
  .team h2 {
    color: #222222;
    padding-bottom: 15px;
    border-bottom: 1px solid #dddada;
    margin-bottom: 10px; }
  .team h4,
  .team h6 {
    text-align: center; }
  .team h4 {
    color: #b5dccd;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 5px; }
  .team h6 {
    margin-bottom: 0; }

.infinite-scroll-component {
  overflow: hidden !important; }

.collection-filter-block {
  margin-bottom: 30px;
  padding: 0 30px;
  border: 1px solid #dddddd;
  background-color: #ffffff; }
  .collection-filter-block .product-service {
    padding: 30px 0; }
    .collection-filter-block .product-service .media {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #dddddd; }
      .collection-filter-block .product-service .media svg {
        height: 40px;
        width: 40px; }
        .collection-filter-block .product-service .media svg path {
          fill: #b5dccd; }
      .collection-filter-block .product-service .media .media-body {
        padding-left: 10px; }
        .collection-filter-block .product-service .media .media-body h4 {
          margin-bottom: 5px; }
        .collection-filter-block .product-service .media .media-body p {
          margin-bottom: 0;
          text-transform: capitalize; }
      .collection-filter-block .product-service .media:last-child {
        padding-bottom: 0; }

.collection-collapse-block.open {
  margin-bottom: 12px; }

.collection-collapse-block:last-child {
  padding-bottom: 24px; }

.collection-collapse-block .collapse-block-title {
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  color: #444444;
  font-weight: 600;
  margin: 30px 0 0 0; }
  .collection-collapse-block .collapse-block-title:after {
    position: absolute;
    display: block;
    top: 0;
    right: -3px;
    content: '\f0d7';
    font-family: "Font Awesome 5 Free";
    color: #444444; }

.collection-collapse-block .collection-collapse-block-content {
  overflow: hidden; }
  .collection-collapse-block .collection-collapse-block-content .color-selector {
    margin-top: 30px; }
  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li {
    display: flex;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 18px; }
    .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li a {
      color: #777777;
      padding-bottom: 10px; }
    .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:last-child a {
      padding-bottom: 0; }
    .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:first-child {
      margin-top: 30px; }
  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox {
    margin-bottom: 12px; }
    .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox:first-child {
      margin-top: 30px; }
    .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox:last-child {
      margin-bottom: 0; }
    .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
      text-transform: uppercase;
      cursor: pointer;
      font-size: 12px;
      color: #777777;
      font-weight: 600;
      letter-spacing: 0.5px; }
      .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:before {
        top: 1px;
        height: 15px;
        width: 15px;
        background: #ffffff;
        border: 1px solid #777777; }
      .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:after {
        top: 1px;
        height: 15px;
        width: 15px;
        background-size: 65%; }
    .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox .custom-control-input:checked ~ .custom-control-label:after {
      background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>"); }

.color-selector ul li {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #444444; }

.color-selector ul .color-1 {
  background-color: white; }

.color-selector ul .color-2 {
  background-color: grey; }

.color-selector ul .color-3 {
  background-color: red; }

.color-selector ul .color-4 {
  background-color: purple; }

.color-selector ul .color-5 {
  background-color: blue; }

.color-selector ul .color-6 {
  background-color: yellow; }

.color-selector ul .color-7 {
  background-color: green; }

.color-selector ul li.active {
  border: 1px solid black;
  position: relative; }
  .color-selector ul li.active:after {
    content: '';
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
    top: 10px;
    right: 4px;
    height: 15px;
    width: 15px;
    background-size: 70%;
    background-repeat: no-repeat;
    position: absolute; }

.col-lg-2 .product-box .img-wrapper .product-thumb-list li img {
  height: 35px; }

.collection-sidebar-banner {
  margin-top: 30px; }
  .collection-sidebar-banner img {
    width: 100%; }

.top-banner-wrapper .top-banner-content h4 {
  font-weight: 600;
  color: #444444;
  margin-bottom: 15px; }

.top-banner-wrapper .top-banner-content h5 {
  font-size: 16px;
  font-weight: 600;
  color: #444444;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-bottom: 10px; }

.top-banner-wrapper .top-banner-content p {
  line-height: 1.5;
  color: #777777;
  margin-bottom: 0; }

.product-wrapper-grid.list-view .product-wrap .product-info {
  text-align: left;
  align-self: center;
  padding-left: 15px; }

.product-wrapper-grid.list-view .product-box {
  display: flex;
  padding-bottom: 0; }
  .product-wrapper-grid.list-view .product-box .img-wrapper,
  .product-wrapper-grid.list-view .product-box .img-block {
    width: 25%;
    min-width: 150px; }
  .product-wrapper-grid.list-view .product-box .product-detail {
    padding-left: 15px;
    align-self: center;
    text-align: left !important; }
    .product-wrapper-grid.list-view .product-box .product-detail .rating {
      margin-top: 0; }
    .product-wrapper-grid.list-view .product-box .product-detail p {
      display: block !important;
      margin-bottom: 5px;
      line-height: 18px; }
    .product-wrapper-grid.list-view .product-box .product-detail .color-variant {
      padding-top: 10px; }
    .product-wrapper-grid.list-view .product-box .product-detail h6 {
      font-weight: 700; }

.collection-product-wrapper .product-top-filter {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd; }
  .collection-product-wrapper .product-top-filter .product-filter-content,
  .collection-product-wrapper .product-top-filter .popup-filter {
    display: flex;
    flex-wrap: wrap; }
    .collection-product-wrapper .product-top-filter .product-filter-content .search-count,
    .collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup,
    .collection-product-wrapper .product-top-filter .popup-filter .search-count,
    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
      border-right: 1px solid #dddddd;
      padding: 20px;
      text-transform: capitalize;
      width: 35%; }
      .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5,
      .collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup h5,
      .collection-product-wrapper .product-top-filter .popup-filter .search-count h5,
      .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup h5 {
        margin-bottom: 0; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-view,
    .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
      padding: 20px;
      width: 15%; }
      .collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li,
      .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li {
        height: 100%;
        vertical-align: middle;
        cursor: pointer; }
        .collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li:first-child,
        .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li:first-child {
          margin-right: 14px; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
    .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
      padding: 20px;
      width: 25%;
      border-right: 1px solid #dddddd; }
      .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul,
      .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul {
        display: flex;
        justify-content: flex-end; }
        .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li,
        .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li {
          margin-left: 10px; }
          .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li img,
          .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li img {
            height: 18px;
            cursor: pointer; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
      position: relative;
      width: 25%; }
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
        border: 0;
        padding: 20px;
        border-right: 1px solid #dddddd;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
        background: url("/assets/images/dropdown.png") no-repeat 95%; }
        .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select:focus,
        .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select:focus,
        .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select:focus,
        .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select:focus {
          outline: unset; }
        .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select option,
        .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select option,
        .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select option,
        .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select option {
          padding: 20px 0; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
      width: 25%; }
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
        right: 10px; }
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
        border-right: none; }
  .collection-product-wrapper .product-top-filter .popup-filter {
    justify-content: unset; }
    .collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar {
      width: 3px; }
    .collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-track {
      background: #dddddd; }
    .collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb {
      background: #888888; }
    .collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb:hover {
      background: #555555; }
    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
      position: relative;
      cursor: pointer;
      width: 20%;
      background: url("/assets/images/dropdown.png") no-repeat 95%; }
      .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup a {
        font-size: 16px;
        color: #222222; }
      .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
        background-color: #f8f8f8;
        width: 270px;
        height: 600px;
        overflow-y: scroll; }
        .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-filter-block {
          border: none; }
        .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .theme-card {
          padding: 0 30px; }
        .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner {
          padding: 0 30px; }
          .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner img {
            width: 100%; }
    .collection-product-wrapper .product-top-filter .popup-filter .open-popup {
      display: none;
      position: absolute;
      z-index: 9;
      top: 75px;
      left: 0;
      border: 1px solid #f1f5f4;
      box-shadow: 0 0 5px #ddd;
      min-width: 330px; }
      .collection-product-wrapper .product-top-filter .popup-filter .open-popup.open {
        display: block; }
      .collection-product-wrapper .product-top-filter .popup-filter .open-popup .collection-filter-block {
        margin-bottom: 0; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
      width: 20%; }
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
        padding-right: 45px;
        font-size: 16px; }
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
      .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
        right: 35px; }
    .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
      padding: 20px; }
    .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
      width: 20%; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
      right: 25px !important; }

.collection-product-wrapper .product-wrapper-grid .product-box {
  margin-top: 50px; }
  .collection-product-wrapper .product-wrapper-grid .product-box .product-detail p {
    display: none; }

.collection-product-wrapper .product-wrapper-grid .product-five {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h6 {
  font-size: 13px; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h4 {
  font-size: 18px; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail .rating i {
  padding-right: 0; }

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .img-wrapper .lable-block .lable3 {
  padding: 8px 4px;
  font-size: 12px; }

.filter-main-btn {
  display: none;
  margin-bottom: 20px;
  cursor: pointer; }
  .filter-main-btn span {
    width: 40%;
    border-radius: 0;
    font-size: 20px; }

.collection-mobile-back {
  padding: 15px 0;
  border-bottom: 1px solid #dddddd;
  display: none; }
  .collection-mobile-back span {
    text-transform: uppercase;
    font-size: 14px;
    color: #000;
    cursor: pointer; }
    .collection-mobile-back span i {
      font-size: 20px;
      font-weight: bold;
      margin-right: 5px; }

.product-pagination {
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
  margin: 50px 20px 0 20px; }
  .product-pagination .pagination {
    border-radius: 0; }
    .product-pagination .pagination .page-item {
      border-radius: 0; }
      .product-pagination .pagination .page-item a {
        padding: 18px;
        border-bottom: 0;
        border-top: 0;
        color: #777777;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      .product-pagination .pagination .page-item:last-child .page-link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .product-pagination .pagination .page-item.active a {
      color: #b5dccd;
      background-color: #ffffff;
      border-color: #dddddd; }
  .product-pagination .product-search-count-bottom {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    height: 100%;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    padding-right: 15px; }
    .product-pagination .product-search-count-bottom h5 {
      color: #777777;
      font-size: 14px;
      margin-bottom: 0;
      padding: 5px 0; }

.collection .collection-block {
  text-align: center; }
  .collection .collection-block img {
    margin-bottom: 20px; }
  .collection .collection-block .collection-content {
    text-align: center; }
    .collection .collection-block .collection-content h3 {
      margin-bottom: 10px;
      color: #222222;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 0.05em; }
    .collection .collection-block .collection-content h4 {
      color: #222222;
      margin-bottom: 10px;
      font-size: 16px; }
    .collection .collection-block .collection-content p {
      line-height: 1.5; }
    .collection .collection-block .collection-content .btn-solid,
    .collection .collection-block .collection-content .btn-outline {
      padding: 10px 25px; }

.faq-section .accordion.theme-accordion .card {
  margin-top: 10px;
  border-radius: 0;
  overflow: hidden;
  border-bottom: 1px solid #dddddd; }
  .faq-section .accordion.theme-accordion .card :not(:first-of-type):not(:last-of-type),
  .faq-section .accordion.theme-accordion .card .theme-accordion .card:first-of-type {
    border-bottom: 1px solid #dddddd; }
  .faq-section .accordion.theme-accordion .card:first-child {
    margin-top: 0; }
  .faq-section .accordion.theme-accordion .card .card-header {
    padding: 0;
    border-bottom: 0;
    transition: all 0.3s ease;
    background-color: #f9f9f9; }
    .faq-section .accordion.theme-accordion .card .card-header button {
      color: #333333;
      text-align: left;
      font-size: 17px;
      padding: 18px;
      font-weight: 600; }
      .faq-section .accordion.theme-accordion .card .card-header button:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 6px solid #000000;
        right: 20px;
        top: 25px;
        transition: all 0.3s ease; }
      .faq-section .accordion.theme-accordion .card .card-header button[aria-expanded='true']:before {
        border-top: 0;
        border-bottom: 6px solid #000000;
        transition: all 0.3s ease; }
      .faq-section .accordion.theme-accordion .card .card-header button:hover {
        text-decoration: none;
        color: #b5dccd;
        transition: all 0.3s ease; }
  .faq-section .accordion.theme-accordion .card .card-body {
    padding: 15px 18px; }
    .faq-section .accordion.theme-accordion .card .card-body p {
      margin-bottom: 0;
      font-size: 14px;
      color: #555555;
      line-height: 1.8;
      letter-spacing: 0.06em; }

.pwd-page {
  text-align: center; }
  .pwd-page h2 {
    margin-bottom: 25px;
    color: #222222;
    margin-top: -5px; }
  .pwd-page .theme-form input {
    border-color: #dddddd;
    font-size: 15px;
    padding: 15px 25px;
    margin-bottom: 15px;
    height: inherit;
    text-align: center; }
  .pwd-page .theme-form .btn-solid {
    margin: 0 auto; }

.login-page h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #222222;
  font-weight: 700;
  margin-top: -5px; }

.login-page .theme-card {
  padding: 30px;
  border: 1px solid #dddddd;
  line-height: 1; }
  .login-page .theme-card .theme-form label {
    text-transform: capitalize;
    color: #333333;
    font-size: 1.1rem;
    font-weight: 600; }
  .login-page .theme-card .theme-form input {
    border-color: #dddddd;
    font-size: 12px;
    padding: 17px 25px;
    margin-bottom: 30px;
    height: inherit; }

.login-page .authentication-right {
  height: calc(100% - 48px); }
  .login-page .authentication-right h6 {
    text-transform: uppercase;
    color: #333333;
    font-weight: 600;
    margin-bottom: 20px; }
  .login-page .authentication-right p {
    font-size: 14px;
    color: #555555;
    line-height: 2;
    margin-bottom: 1rem; }

.register-page h3 {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #222222;
  font-weight: 700;
  margin-top: -5px; }

.register-page .theme-card {
  padding: 30px;
  border: 1px solid #dddddd;
  line-height: 1; }
  .register-page .theme-card .theme-form label {
    text-transform: capitalize;
    color: #333333;
    font-size: 14px;
    font-weight: 600; }
  .register-page .theme-card .theme-form input {
    border-color: #dddddd;
    font-size: 12px;
    padding: 17px 25px;
    margin-bottom: 30px;
    height: inherit; }

.search-block {
  padding: 0; }
  .search-block .form-header .input-group input {
    border-radius: 0 5px 5px 0;
    padding: 15px 20px; }
  .search-block .form-header .input-group i {
    padding-right: 10px; }
  .search-block .form-header .input-group .btn {
    z-index: 0; }

.contact-page .map iframe {
  width: 100%;
  height: 100%;
  border: none; }

.contact-page .contact-right {
  padding-top: 30px;
  padding-bottom: 30px; }
  .contact-page .contact-right ul li {
    position: relative;
    padding-left: 150px;
    margin-top: 45px;
    display: block; }
    .contact-page .contact-right ul li:first-child {
      margin-top: 0; }
    .contact-page .contact-right ul li .contact-icon {
      position: absolute;
      left: 0;
      text-align: center;
      width: 130px;
      border-right: 1px solid #dddddd; }
      .contact-page .contact-right ul li .contact-icon i {
        font-size: 20px;
        color: #ff4c3b; }
      .contact-page .contact-right ul li .contact-icon h6 {
        text-transform: uppercase;
        padding-top: 5px;
        font-weight: 600;
        color: #ff4c3b; }
    .contact-page .contact-right ul li p {
      font-weight: 600;
      margin-bottom: 0;
      line-height: 30px; }

.contact-page .theme-form {
  margin-top: -6px; }
  .contact-page .theme-form label {
    text-transform: capitalize;
    color: #333333;
    font-size: 1.1rem;
    font-weight: 600; }
  .contact-page .theme-form input {
    border-color: #dddddd;
    font-size: 12px;
    padding: 17px 25px;
    margin-bottom: 30px;
    height: inherit; }
  .contact-page .theme-form textarea {
    border-color: #dddddd;
    font-size: 12px;
    padding: 17px 25px;
    margin-bottom: 30px;
    height: inherit; }

.contact-page .form-control {
  border-radius: 0; }

.qty-box .input-group {
  justify-content: center; }
  .qty-box .input-group span button {
    background: #ffffff !important;
    border: 1px solid #ced4da; }
  .qty-box .input-group .form-control {
    text-align: center;
    width: 80px;
    flex: unset; }
  .qty-box .input-group button {
    background-color: transparent;
    border: 0;
    color: #777777;
    cursor: pointer;
    padding-left: 12px;
    font-size: 12px;
    font-weight: 900;
    line-height: 1; }
    .qty-box .input-group button i {
      font-weight: 900;
      color: #222222; }
  .qty-box .input-group .icon {
    padding-right: 0; }

.cart-section .table tbody + tbody,
.wishlist-section .table tbody + tbody {
  border-top: none; }

.cart-section .cart-buttons,
.wishlist-section .cart-buttons {
  padding-top: 35px; }
  .cart-section .cart-buttons > div:last-child,
  .wishlist-section .cart-buttons > div:last-child {
    text-align: right;
    padding-right: 59px; }

.cart-section .wishlist-buttons,
.wishlist-section .wishlist-buttons {
  padding-top: 35px;
  text-align: right; }
  .cart-section .wishlist-buttons:last-child a,
  .wishlist-section .wishlist-buttons:last-child a {
    margin-left: 15px; }

.cart-section .cart-table,
.wishlist-section .cart-table {
  overflow: hidden;
  margin-bottom: 0; }
  .cart-section .cart-table thead th,
  .wishlist-section .cart-table thead th {
    border-bottom-width: 1px;
    font-weight: 900;
    color: #222222;
    text-transform: uppercase;
    font-size: 14px;
    border-top: 0;
    text-align: center;
    padding: 0 0.75rem 0.75rem 0.75rem; }

.cart-section tbody h2,
.wishlist-section tbody h2 {
  margin-bottom: 0; }

.cart-section tbody tr td,
.wishlist-section tbody tr td {
  min-width: 210px;
  vertical-align: middle;
  color: #777777;
  border-top: 0;
  border-bottom: 1px solid #eeeeee;
  text-align: center; }
  .cart-section tbody tr td a,
  .cart-section tbody tr td p,
  .wishlist-section tbody tr td a,
  .wishlist-section tbody tr td p {
    color: #777777;
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    margin-bottom: 0; }
    .cart-section tbody tr td a img,
    .cart-section tbody tr td p img,
    .wishlist-section tbody tr td a img,
    .wishlist-section tbody tr td p img {
      height: 90px; }
  .cart-section tbody tr td .td-color,
  .wishlist-section tbody tr td .td-color {
    color: #b5dccd; }
  .cart-section tbody tr td h2,
  .wishlist-section tbody tr td h2 {
    font-size: 24px;
    color: #222222;
    font-weight: 400; }
  .cart-section tbody tr td .mobile-cart-content,
  .wishlist-section tbody tr td .mobile-cart-content {
    display: none;
    justify-content: center;
    margin-top: 10px; }
    .cart-section tbody tr td .mobile-cart-content .qty-box,
    .wishlist-section tbody tr td .mobile-cart-content .qty-box {
      border-right: 1px solid #dddddd;
      border-radius: 5px; }
      .cart-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control,
      .wishlist-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control {
        width: 48px;
        padding: 6px; }
    .cart-section tbody tr td .mobile-cart-content .col-xs-3,
    .wishlist-section tbody tr td .mobile-cart-content .col-xs-3 {
      align-self: center;
      margin-left: 10px;
      margin-right: 10px; }
    .cart-section tbody tr td .mobile-cart-content h2,
    .wishlist-section tbody tr td .mobile-cart-content h2 {
      font-size: 20px; }

.cart-section tfoot tr th,
.wishlist-section tfoot tr th {
  padding-top: 35px;
  text-align: right; }

.cart-section tfoot tr td,
.wishlist-section tfoot tr td {
  padding-top: 35px;
  padding-bottom: 0;
  text-align: right;
  border: none;
  padding-right: 63px; }
  .cart-section tfoot tr td h2,
  .wishlist-section tfoot tr td h2 {
    font-size: 24px;
    margin-bottom: 0; }
  .cart-section tfoot tr td:first-child,
  .wishlist-section tfoot tr td:first-child {
    width: 85%;
    font-size: 15px;
    padding-right: 10px;
    text-transform: capitalize;
    font-weight: 700; }

.blog-detail-page .blog-detail p:last-child {
  margin-bottom: 0; }

.blog-detail-page .blog-detail img {
  margin-bottom: 40px; }

.blog-detail-page .blog-detail h3 {
  color: #222222;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase; }

.blog-detail-page .blog-detail p {
  color: #777777;
  line-height: 1.8; }

.blog-detail-page .blog-detail .post-social {
  color: #777777;
  text-align: left;
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 15px; }
  .blog-detail-page .blog-detail .post-social li + li {
    padding-left: 15px;
    margin-left: 15px;
    border-left: 1px solid #dddddd; }

.blog-detail-page .blog-advance > div:last-child p:last-child {
  margin-bottom: 0; }

.blog-detail-page .blog-advance img {
  margin-bottom: 25px;
  margin-top: 25px; }

.blog-detail-page .blog-advance ul {
  list-style-type: decimal;
  list-style-position: inside;
  line-height: 2.5;
  font-size: 15px; }
  .blog-detail-page .blog-advance ul li {
    display: list-item;
    font-weight: 700; }

.blog-detail-page .blog-advance p {
  line-height: 1.8; }

.blog-detail-page .comment-section {
  border-bottom: 1px solid #dddddd; }
  .blog-detail-page .comment-section li {
    padding-top: 60px;
    padding-bottom: 60px;
    border-top: 1px solid #dddddd; }
    .blog-detail-page .comment-section li img {
      height: 65px;
      width: 65px;
      margin-right: 20px;
      border-radius: 100%; }
    .blog-detail-page .comment-section li h6 {
      color: #444444;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 5px; }
      .blog-detail-page .comment-section li h6 span {
        font-size: 13px;
        font-weight: 500;
        text-transform: capitalize;
        color: #777777;
        margin-left: 20px; }
    .blog-detail-page .comment-section li p {
      line-height: 1.8;
      margin-bottom: -4px; }

.blog-detail-page .blog-contact h2 {
  margin-bottom: 30px;
  margin-top: -5px; }

.blog-detail-page .blog-contact .theme-form label {
  text-transform: capitalize;
  color: #333333;
  font-size: 16px;
  font-weight: 700; }

.blog-detail-page .blog-contact .theme-form input,
.blog-detail-page .blog-contact .theme-form textarea {
  border-color: #dddddd;
  font-size: 14px;
  padding: 17px 25px;
  margin-bottom: 30px; }

.blog-page .blog-sidebar h6 {
  margin-bottom: 0;
  line-height: 20px;
  color: #333333;
  font-weight: 700; }

.blog-page .blog-sidebar p {
  line-height: 20px;
  margin-bottom: 0;
  color: #555555; }

.blog-page .blog-sidebar h4 {
  color: #333333;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 20px;
  letter-spacing: 1px; }

.blog-page .blog-sidebar .theme-card {
  border: 1px solid #dddddd;
  padding: 30px;
  background-color: #ffffff; }
  .blog-page .blog-sidebar .theme-card .recent-blog li .media img {
    width: 100px;
    margin-right: 10px; }
  .blog-page .blog-sidebar .theme-card .recent-blog li + li {
    margin-top: 30px; }
  .blog-page .blog-sidebar .theme-card .popular-blog li .blog-date {
    background-color: #b5dccd;
    height: 70px;
    width: 70px;
    margin: 0 15px 0 0;
    padding: 15px; }
    .blog-page .blog-sidebar .theme-card .popular-blog li .blog-date span {
      display: block;
      text-align: center;
      color: #ffffff;
      text-transform: uppercase; }
  .blog-page .blog-sidebar .theme-card .popular-blog li + li {
    margin-top: 30px; }
  .blog-page .blog-sidebar .theme-card .popular-blog li p {
    margin-top: 10px; }
  .blog-page .blog-sidebar .theme-card + .theme-card {
    margin-top: 30px; }

.blog-page .blog-media {
  margin-bottom: 30px; }
  .blog-page .blog-media:last-child {
    margin-bottom: 0; }
  .blog-page .blog-media .blog-right {
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex; }
    .blog-page .blog-media .blog-right h6 {
      color: #b5dccd;
      text-transform: uppercase;
      margin-bottom: 10px;
      margin-top: -3px; }
    .blog-page .blog-media .blog-right h4 {
      color: #333333;
      font-weight: 700;
      line-height: 1.5;
      margin-bottom: 15px; }
    .blog-page .blog-media .blog-right p {
      line-height: 1.8;
      margin-bottom: 0;
      letter-spacing: 0.04em; }
    .blog-page .blog-media .blog-right ul {
      color: #777777;
      margin-bottom: 15px; }
      .blog-page .blog-media .blog-right ul li {
        font-size: 14px;
        line-height: 1.5; }
        .blog-page .blog-media .blog-right ul li i {
          padding-right: 3px; }
        .blog-page .blog-media .blog-right ul li + li {
          padding-left: 15px;
          margin-left: 15px;
          border-left: 1px solid #dddddd; }

.loading-cls {
  width: 70px;
  height: 60px;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-image: url("/assets/images/loader.gif"); }

.seen-cls {
  text-align: center;
  margin-top: 15px; }

.seen-it-cls {
  margin-top: 50px;
  color: red;
  font-size: 16px; }
  .seen-it-cls b {
    border: 1px solid #ddd;
    padding: 5px; }

.instock-cls {
  color: #b5dccd;
  font-weight: 700; }

.product-slick,
.rtl-product-slick,
.product-right-slick,
.rtl-product-right-slick {
  z-index: 0; }
  .product-slick .slick-prev,
  .product-slick .slick-next,
  .rtl-product-slick .slick-prev,
  .rtl-product-slick .slick-next,
  .product-right-slick .slick-prev,
  .product-right-slick .slick-next,
  .rtl-product-right-slick .slick-prev,
  .rtl-product-right-slick .slick-next {
    opacity: 0;
    transform: scale(1);
    transition: all 0.5s ease;
    margin-top: -10px; }
    .product-slick .slick-prev:before,
    .product-slick .slick-next:before,
    .rtl-product-slick .slick-prev:before,
    .rtl-product-slick .slick-next:before,
    .product-right-slick .slick-prev:before,
    .product-right-slick .slick-next:before,
    .rtl-product-right-slick .slick-prev:before,
    .rtl-product-right-slick .slick-next:before {
      font: "Font Awesome 5 Free";
      opacity: 1;
      color: black;
      background-color: white;
      border-radius: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center; }
  .product-slick .slick-next,
  .rtl-product-slick .slick-next,
  .product-right-slick .slick-next,
  .rtl-product-right-slick .slick-next {
    right: 1px; }
    .product-slick .slick-next:before,
    .rtl-product-slick .slick-next:before,
    .product-right-slick .slick-next:before,
    .rtl-product-right-slick .slick-next:before {
      content: '\f054';
      font-weight: 900; }
  .product-slick .slick-prev,
  .rtl-product-slick .slick-prev,
  .product-right-slick .slick-prev,
  .rtl-product-right-slick .slick-prev {
    left: 1px;
    z-index: 1; }
    .product-slick .slick-prev:before,
    .rtl-product-slick .slick-prev:before,
    .product-right-slick .slick-prev:before,
    .rtl-product-right-slick .slick-prev:before {
      content: '\f104';
      font-weight: 900; }
  .product-slick .slick-list,
  .rtl-product-slick .slick-list,
  .product-right-slick .slick-list,
  .rtl-product-right-slick .slick-list {
    overflow: hidden; }
  .product-slick .slick-slide > div,
  .rtl-product-slick .slick-slide > div,
  .product-right-slick .slick-slide > div,
  .rtl-product-right-slick .slick-slide > div {
    display: flex;
    margin-bottom: 5px; }
  .product-slick:hover .slick-prev,
  .product-slick:hover .slick-next,
  .rtl-product-slick:hover .slick-prev,
  .rtl-product-slick:hover .slick-next,
  .product-right-slick:hover .slick-prev,
  .product-right-slick:hover .slick-next,
  .rtl-product-right-slick:hover .slick-prev,
  .rtl-product-right-slick:hover .slick-next {
    opacity: 1;
    transform: scale(1.05);
    transition: all 0.5s ease; }
    .product-slick:hover .slick-prev:before,
    .product-slick:hover .slick-next:before,
    .rtl-product-slick:hover .slick-prev:before,
    .rtl-product-slick:hover .slick-next:before,
    .product-right-slick:hover .slick-prev:before,
    .product-right-slick:hover .slick-next:before,
    .rtl-product-right-slick:hover .slick-prev:before,
    .rtl-product-right-slick:hover .slick-next:before {
      opacity: 1; }
  .product-slick:hover .slick-next,
  .rtl-product-slick:hover .slick-next,
  .product-right-slick:hover .slick-next,
  .rtl-product-right-slick:hover .slick-next {
    right: 20px; }
  .product-slick:hover .slick-prev,
  .rtl-product-slick:hover .slick-prev,
  .product-right-slick:hover .slick-prev,
  .rtl-product-right-slick:hover .slick-prev {
    left: 20px; }

.slider-nav .slick-track {
  height: auto !important; }

.slider-nav .slick-slide,
.rtl-slider-nav .slick-slide,
.slider-right-nav .slick-slide,
.rtl-slider-right-nav .slick-slide {
  padding: 0 15px 15px;
  padding-bottom: 0; }
  .slider-nav .slick-slide > div,
  .rtl-slider-nav .slick-slide > div,
  .slider-right-nav .slick-slide > div,
  .rtl-slider-right-nav .slick-slide > div {
    display: flex;
    margin: 15px 15px 0 15px;
    border: 1px solid #dddddd;
    transition: all 0.5s ease; }
  .slider-nav .slick-slide.slick-current > div,
  .rtl-slider-nav .slick-slide.slick-current > div,
  .slider-right-nav .slick-slide.slick-current > div,
  .rtl-slider-right-nav .slick-slide.slick-current > div {
    border-color: #aaaaaa;
    transition: all 0.5s ease; }
  .slider-nav .slick-slide.slick-current img,
  .rtl-slider-nav .slick-slide.slick-current img,
  .slider-right-nav .slick-slide.slick-current img,
  .rtl-slider-right-nav .slick-slide.slick-current img {
    border: 1px solid #dddddd; }
  .slider-nav .slick-slide.slick-active,
  .rtl-slider-nav .slick-slide.slick-active,
  .slider-right-nav .slick-slide.slick-active,
  .rtl-slider-right-nav .slick-slide.slick-active {
    transition: all 0.5s ease; }

.slider-right-nav .slick-slide:first-child > div,
.rtl-slider-right-nav .slick-slide:first-child > div {
  margin-top: 0; }

.product-thumbnail .slider-nav .slick-list .slick-track {
  margin-top: 30px; }

.pro_sticky_info {
  border: 1px solid #dddddd;
  padding: 20px; }

.is_stuck {
  margin-top: 80px; }

.image-scroll div > div {
  padding-bottom: 20px; }
  .image-scroll div > div:last-child {
    padding: 0; }

.product-right p {
  margin-bottom: 0;
  line-height: 1.5em; }

.product-right .product-title {
  color: #222222;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0; }

.product-right .border-product {
  padding-top: 15px;
  padding-bottom: 20px;
  border-top: 1px dashed #dddddd; }
  .product-right .border-product:last-child {
    padding-bottom: 0; }

.product-right h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 1.2em; }

.product-right h3 {
  font-size: 26px;
  color: #222222;
  margin-bottom: 15px; }

.product-right h4 {
  font-size: 16px;
  margin-bottom: 7px; }
  .product-right h4 del {
    color: #777777; }
  .product-right h4 span {
    padding-left: 5px;
    color: #b5dccd; }

.product-right .color-variant {
  margin-bottom: 10px; }
  .product-right .color-variant li {
    height: 30px;
    width: 30px !important;
    cursor: pointer;
    float: none;
    display: inline-block; }

.product-right .product-buttons {
  margin-bottom: 20px; }
  .product-right .product-buttons .btn-solid,
  .product-right .product-buttons .btn-outline {
    padding: 7px 25px; }

.product-right .product-description h6 span {
  float: right; }

.product-right .product-description .qty-box {
  display: flex;
  align-items: center;
  margin-top: 10px; }
  .product-right .product-description .qty-box .input-group {
    justify-content: unset;
    width: unset; }
    .product-right .product-description .qty-box .input-group .form-control {
      border-right: none; }

.product-right .size-box {
  margin-top: 10px;
  margin-bottom: 10px; }
  .product-right .size-box ul li {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #f7f7f7;
    text-align: center; }
    .product-right .size-box ul li a {
      color: #222222;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; }
    .product-right .size-box ul li.active {
      background-color: #f7f7f7; }

.product-right .product-icon {
  display: flex; }
  .product-right .product-icon .product-social {
    margin-top: 5px; }
    .product-right .product-icon .product-social li {
      padding-right: 30px; }
      .product-right .product-icon .product-social li a {
        color: #333333;
        transition: all 0.3s ease; }
        .product-right .product-icon .product-social li a i {
          font-size: 18px; }
        .product-right .product-icon .product-social li a:hover {
          color: #b5dccd; }
      .product-right .product-icon .product-social li:last-child {
        padding-right: 0; }
  .product-right .product-icon .wishlist-btn {
    background-color: transparent;
    border: none; }
    .product-right .product-icon .wishlist-btn i {
      border-left: 1px solid #dddddd;
      font-size: 18px;
      padding-left: 10px;
      margin-left: 5px;
      transition: all 0.5s ease; }
    .product-right .product-icon .wishlist-btn span {
      padding-left: 10px;
      font-size: 18px; }
    .product-right .product-icon .wishlist-btn:hover i {
      color: #b5dccd;
      transition: all 0.5s ease; }

.product-right .payment-card-bottom {
  margin-top: 10px; }
  .product-right .payment-card-bottom ul li {
    padding-right: 10px; }

.product-right .timer {
  margin-top: 10px;
  background-color: #f7f7f7; }
  .product-right .timer p {
    color: #222222; }

.product-right.product-form-box {
  text-align: center;
  border: 1px solid #dddddd;
  padding: 20px; }
  .product-right.product-form-box .size-text {
    text-align: left; }
  .product-right.product-form-box .product-description .qty-box {
    margin-bottom: 5px; }
    .product-right.product-form-box .product-description .qty-box .input-group {
      justify-content: center;
      width: 100%; }
  .product-right.product-form-box .product-buttons {
    margin-bottom: 0; }
  .product-right.product-form-box .timer {
    margin-bottom: 10px;
    text-align: left; }

.single-product-tables {
  display: flex;
  margin-top: 15px; }
  .single-product-tables table {
    width: 20%; }
    .single-product-tables table tr {
      height: 35px; }
      .single-product-tables table tr td:first-child {
        font-weight: 600; }
  .single-product-tables.detail-section {
    margin-top: 0; }
    .single-product-tables.detail-section table {
      width: 55%; }

.product-accordion .btn-link {
  color: #222222; }
  .product-accordion .btn-link:hover {
    text-decoration: none; }

.product-accordion .card {
  border: none; }

.product-accordion .card-body {
  width: 100%;
  height: 300px; }

.product-accordion .card-header {
  padding: 5px 8px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
  background-color: rgba(127, 127, 127, 0.03); }

.product-related h2 {
  color: #222222;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddada;
  margin-bottom: 20px;
  margin-top: -5px; }

.rating {
  margin-top: 0; }
  .rating i {
    padding-right: 5px; }
    .rating i:nth-child(-n + 4) {
      color: #ffa200; }
    .rating i:last-child {
      color: #dddddd; }
  .rating .three-star {
    padding-bottom: 5px; }
    .rating .three-star i {
      color: #acacac; }
      .rating .three-star i:nth-child(-n + 3) {
        color: #ffd200; }

.tab-border {
  border: 1px solid #dddddd;
  margin: 0;
  padding: 8px 0; }
  .tab-border .nav-border {
    border-bottom: 0;
    border-right: 1px solid #dddddd; }

.vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
  padding: 15px 20px;
  cursor: pointer; }

.vertical-tab.tab-product .nav-material.nav-tabs .nav-item:focus,
.vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item:focus {
  border: none;
  outline: none;
  box-shadow: none; }

.tab-product,
.product-full-tab {
  padding-top: 30px; }
  .tab-product .nav-material.nav-tabs,
  .product-full-tab .nav-material.nav-tabs {
    display: flex;
    align-items: center;
    flex-wrap: nowrap; }
    .tab-product .nav-material.nav-tabs .nav-item .nav-link,
    .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
      color: #212121;
      text-align: center;
      padding: 0 15px 20px 15px;
      text-transform: uppercase;
      border: 0;
      cursor: pointer; }
    .tab-product .nav-material.nav-tabs .nav-item .material-border,
    .product-full-tab .nav-material.nav-tabs .nav-item .material-border {
      border-bottom: 2px solid #b5dccd;
      opacity: 0; }
  .tab-product .theme-form input,
  .product-full-tab .theme-form input {
    border-color: #dddddd;
    font-size: 0.85rem;
    padding: 15px 25px;
    margin-bottom: 15px;
    height: inherit;
    text-align: left; }
  .tab-product .theme-form .btn-solid,
  .product-full-tab .theme-form .btn-solid {
    margin: 0 auto; }
  .tab-product .theme-form textarea,
  .product-full-tab .theme-form textarea {
    border-color: #dddddd;
    font-size: 1rem;
    padding: 17px 25px;
    margin-bottom: 15px;
    height: inherit; }
  .tab-product .tab-content.nav-material p,
  .product-full-tab .tab-content.nav-material p {
    margin-top: -8px;
    margin-bottom: -8px;
    line-height: 2;
    letter-spacing: 0.05em; }
  .tab-product .tab-content.nav-material .media,
  .product-full-tab .tab-content.nav-material .media {
    margin-top: 20px; }
  .tab-product .title,
  .product-full-tab .title {
    padding-right: 45px;
    color: #b5dccd;
    padding-bottom: 20px; }
  .tab-product .theme-slider .slick-arrow,
  .product-full-tab .theme-slider .slick-arrow {
    top: -45px;
    height: auto; }
    .tab-product .theme-slider .slick-arrow :before,
    .product-full-tab .theme-slider .slick-arrow :before {
      color: #000000;
      font-size: 18px; }
  .tab-product .product-box,
  .product-full-tab .product-box {
    position: relative;
    margin: 5px; }
    .tab-product .product-box:hover,
    .product-full-tab .product-box:hover {
      box-shadow: 0 0 12px 0 #dddddd; }
      .tab-product .product-box:hover .lbl-1,
      .product-full-tab .product-box:hover .lbl-1 {
        opacity: 1;
        transition: all 0.3s ease; }
      .tab-product .product-box:hover .lbl-2,
      .product-full-tab .product-box:hover .lbl-2 {
        opacity: 1;
        animation: flipInY 1000ms ease-in-out; }
      .tab-product .product-box:hover .color-variant li,
      .product-full-tab .product-box:hover .color-variant li {
        opacity: 1 !important;
        animation: fadeInUp 500ms ease-in-out; }
    .tab-product .product-box .img-block,
    .product-full-tab .product-box .img-block {
      min-height: unset; }
    .tab-product .product-box .cart-info,
    .product-full-tab .product-box .cart-info {
      position: absolute;
      padding: 10px 0;
      top: 25%;
      right: 15px;
      width: 40px;
      margin-right: 0; }
      .tab-product .product-box .cart-info i,
      .product-full-tab .product-box .cart-info i {
        padding-right: 0; }
      .tab-product .product-box .cart-info a,
      .tab-product .product-box .cart-info button,
      .product-full-tab .product-box .cart-info a,
      .product-full-tab .product-box .cart-info button {
        color: #333333;
        transition: all 0.3s ease;
        background-color: #ffffff;
        height: 35px;
        width: 35px;
        margin: 7px 0;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0;
        box-shadow: 0 0 12px 0 #dddddd; }
        .tab-product .product-box .cart-info a :hover,
        .tab-product .product-box .cart-info button :hover,
        .product-full-tab .product-box .cart-info a :hover,
        .product-full-tab .product-box .cart-info button :hover {
          transition: all 0.3s ease;
          color: #b5dccd; }
    .tab-product .product-box .lbl-1,
    .product-full-tab .product-box .lbl-1 {
      background-color: #b5dccd;
      padding: 2px 20px 2px 10px;
      display: inline-block;
      text-align: center;
      color: #ffffff;
      position: absolute;
      left: 0;
      top: 15px;
      font-size: 14px;
      line-height: 1.5;
      opacity: 0; }
      .tab-product .product-box .lbl-1:before,
      .product-full-tab .product-box .lbl-1:before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 0;
        height: 0;
        border-top: 12px solid #b5dccd;
        border-bottom: 13px solid #b5dccd;
        border-right: 7px solid #ffffff; }
    .tab-product .product-box .lbl-2,
    .product-full-tab .product-box .lbl-2 {
      font-size: 14px;
      top: 15px;
      position: absolute;
      right: 10px;
      color: #333333;
      font-weight: 600;
      text-transform: capitalize;
      opacity: 0; }
    .tab-product .product-box a,
    .product-full-tab .product-box a {
      color: #0072bb;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 1px; }
    .tab-product .product-box .color-variant,
    .product-full-tab .product-box .color-variant {
      position: absolute;
      top: -35px;
      width: 100%; }
    .tab-product .product-box .slick-slide img,
    .product-full-tab .product-box .slick-slide img {
      display: block; }
    .tab-product .product-box .product-details,
    .product-full-tab .product-box .product-details {
      position: relative; }
      .tab-product .product-box .product-details .color-variant,
      .product-full-tab .product-box .product-details .color-variant {
        position: absolute;
        top: -35px;
        width: 100%; }
        .tab-product .product-box .product-details .color-variant li,
        .product-full-tab .product-box .product-details .color-variant li {
          opacity: 0;
          display: inline-block;
          height: 15px;
          width: 15px;
          border-radius: 100%;
          margin: 0 3px;
          transition: all 0.3s ease;
          cursor: pointer; }
      .tab-product .product-box .product-details h6,
      .product-full-tab .product-box .product-details h6 {
        color: #333333;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: capitalize; }
      .tab-product .product-box .product-details .price,
      .product-full-tab .product-box .product-details .price {
        padding-bottom: 10px;
        font-size: 16px;
        color: #b5dccd; }

.product-full-tab {
  padding-top: 70px; }

.product-load-more .col-grid-box {
  display: none; }

.load-more-sec {
  margin-top: 40px;
  text-align: center; }
  .load-more-sec a {
    font-size: 18px;
    text-transform: uppercase;
    display: block;
    padding: 10px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    width: 100%; }

.quick-view {
  width: 100%;
  max-width: 1080px;
  max-height: 600px;
  position: relative; }

.checkout_class {
  text-transform: capitalize; }

.checkout-page .checkout-title {
  margin-bottom: 25px; }
  .checkout-page .checkout-title h3 {
    color: #444444;
    font-weight: 700; }

.checkout-page .checkout-form .checkout-details {
  position: relative;
  background-color: #f9f9f9;
  border: 1px solid #dddddd;
  padding: 40px; }

.checkout-page .checkout-form .check-out .form-group:last-child {
  margin-bottom: -5px; }
  .checkout-page .checkout-form .check-out .form-group:last-child label {
    margin-bottom: -5px; }

.checkout-page .checkout-form .form-group {
  position: relative;
  margin-bottom: 25px; }
  .checkout-page .checkout-form .form-group h3 {
    color: #444444;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px; }
  .checkout-page .checkout-form .form-group .field-label {
    line-height: 24px;
    text-transform: capitalize;
    color: #333333;
    margin-bottom: 10px;
    font-weight: 700; }
    .checkout-page .checkout-form .form-group .field-label span {
      font-size: 16px;
      color: #222222;
      font-weight: 600; }
  .checkout-page .checkout-form .form-group label {
    color: #777777; }

.checkout-page .checkout-form select {
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  -moz-appearance: none;
  background: url("/assets/images/dropdown.png") no-repeat 95%; }

.checkout-page .checkout-form input[type='text'], .checkout-page .checkout-form input[type='email'], .checkout-page .checkout-form input[type='password'], .checkout-page .checkout-form input[type='tel'], .checkout-page .checkout-form input[type='number'], .checkout-page .checkout-form input[type='url'] {
  width: 100%;
  padding: 0 22px;
  height: 45px;
  border: 1px solid #dddddd; }

.checkout-page .checkout-form select,
.checkout-page .checkout-form textarea {
  width: 100%;
  padding: 0 22px;
  height: 45px;
  border: 1px solid #dddddd; }

.checkout-page .check-box {
  line-height: 24px;
  font-size: 14px;
  font-weight: normal;
  padding-top: 5px; }
  .checkout-page .check-box label {
    position: relative;
    top: -1px;
    font-weight: normal;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    color: #333333; }

.checkout-page .lower-content {
  margin-top: 30px; }
  .checkout-page .lower-content .order-column {
    margin-bottom: 40px; }

.order-box {
  position: relative;
  margin-bottom: 50px; }
  .order-box .title-box {
    position: relative;
    padding-bottom: 25px;
    color: #444444;
    font-weight: 600;
    font-size: 22px;
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px; }
    .order-box .title-box span {
      position: relative;
      width: 35%;
      float: right;
      line-height: 1.2em; }
  .order-box .qty {
    position: relative;
    border-bottom: 1px solid #ededed;
    margin-bottom: 30px; }
    .order-box .qty li {
      position: relative;
      display: block;
      font-size: 15px;
      color: #444444;
      line-height: 20px;
      margin-bottom: 20px; }
      .order-box .qty li span {
        float: right;
        font-size: 18px;
        line-height: 20px;
        color: #232323;
        font-weight: 400;
        width: 35%; }
  .order-box .sub-total {
    position: relative;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px; }
    .order-box .sub-total li {
      position: relative;
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
      margin-bottom: 20px;
      width: 100%; }
      .order-box .sub-total li .count {
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: #b5dccd;
        font-weight: 400;
        width: 35%;
        float: right; }
    .order-box .sub-total .shopping-option label {
      position: relative;
      font-size: 16px;
      line-height: 32px;
      padding-left: 10px;
      color: #6f6f6f; }
    .order-box .sub-total .shipping {
      width: 35%;
      float: right; }
  .order-box .total {
    position: relative;
    margin-bottom: 40px; }
    .order-box .total li {
      position: relative;
      display: block;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-bottom: 10px;
      font-size: 18px; }
      .order-box .total li .count {
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: #b5dccd;
        font-weight: 400; }
      .order-box .total li span {
        float: right;
        font-size: 15px;
        line-height: 20px;
        color: #444444;
        font-weight: 400;
        width: 35%;
        display: block; }

.payment-box {
  position: relative; }
  .payment-box .upper-box {
    position: relative; }
  .payment-box .payment-options {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px; }
    .payment-box .payment-options li {
      display: flex;
      margin-bottom: 15px; }
      .payment-box .payment-options li .radio-option {
        position: relative; }
        .payment-box .payment-options li .radio-option label {
          position: relative;
          padding-left: 30px;
          text-transform: capitalize;
          color: #6f6f6f;
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0; }
        .payment-box .payment-options li .radio-option input[type='radio'] {
          position: absolute;
          left: 0;
          top: 5px; }
        .payment-box .payment-options li .radio-option label .small-text {
          position: relative;
          display: none;
          font-size: 15px;
          line-height: 25px;
          font-weight: 300;
          color: #666666;
          margin-top: 10px; }
        .payment-box .payment-options li .radio-option label img {
          position: relative;
          display: block;
          max-width: 100%;
          margin-left: -30px;
          margin-top: 5px; }

.stripe-section {
  text-transform: uppercase;
  background-color: #f9f9f9;
  padding: 15px;
  border: 1px solid #dddddd; }
  .stripe-section table tbody tr td:first-child {
    padding-right: 15px; }
  .stripe-section h5 {
    font-weight: 700; }
  .stripe-section .content h5 {
    color: #4f4f4f;
    font-weight: 400; }
  .stripe-section .content table {
    width: 100%; }
    .stripe-section .content table tr {
      border-bottom: 1px solid #f1f1f1; }
      .stripe-section .content table tr td {
        padding: 8px 0;
        color: #9a9a9a; }
        .stripe-section .content table tr td:last-child {
          text-align: right; }

.stripe-section .content table tr td:first-child {
  text-align: right; }

.dashboard-left .block-title h2 {
  font-size: 24px;
  padding-bottom: 20px; }

.dashboard-left .block-content {
  border: 1px solid #ddd;
  padding: 15px; }
  .dashboard-left .block-content ul li {
    display: flex;
    transition: all 0.5s ease; }
    .dashboard-left .block-content ul li.active {
      color: #b5dccd; }
      .dashboard-left .block-content ul li.active a {
        color: #b5dccd; }
    .dashboard-left .block-content ul li:before {
      content: '\f054';
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: 900;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      width: 30px;
      height: 30px;
      text-align: center;
      font-size: 18px;
      margin-top: 3px; }
    .dashboard-left .block-content ul li:hover {
      padding-left: 10px;
      color: #ff0000;
      transition: all 0.5s ease; }
      .dashboard-left .block-content ul li:hover a {
        color: #ff0000;
        transition: all 0.5s ease; }

.padding-cls {
  padding-left: 50px;
  padding-right: 50px; }

.dashboard-right p {
  color: #5f5f5f;
  line-height: 20px; }

.dashboard-right .dashboard {
  border: 1px solid #ddd;
  padding: 30px; }
  .dashboard-right .dashboard .page-title h2 {
    font-size: 22px;
    margin-bottom: 15px; }
  .dashboard-right .dashboard .welcome-msg p {
    margin-bottom: 0; }

.dashboard .box-head h2 {
  font-size: 22px;
  margin: 20px 0 0;
  text-transform: capitalize;
  color: #333; }

.dashboard .box a {
  color: #b5dccd; }

.dashboard .box h6 {
  margin-bottom: 0; }

.dashboard .box .box-title {
  border-bottom: 1px solid #ddd;
  display: inline-block;
  margin-bottom: 5px;
  padding: 12px 0;
  position: relative;
  width: 100%; }
  .dashboard .box .box-title h3 {
    font-size: 16px;
    margin: 0;
    text-transform: capitalize;
    color: #333; }
  .dashboard .box .box-title > a {
    position: absolute;
    right: 0;
    top: 12px;
    color: #b5dccd; }

.dashboard .box address {
  margin-bottom: 0; }

.box-content h6 {
  font-size: 14px;
  line-height: 25px !important;
  margin-bottom: 0; }

.box-content a {
  color: #b5dccd; }

.account-sidebar {
  display: none;
  cursor: pointer;
  background-color: #b5dccd;
  color: #ffffff;
  width: 30%;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
  padding: 5px; }

.success-text {
  text-align: center; }
  .success-text i {
    font-size: 50px;
    color: #4ead4e; }
  .success-text h2 {
    margin-top: 10px;
    margin-bottom: 15px; }
  .success-text p {
    font-size: 18px;
    text-transform: capitalize; }

.order-success-sec h4 {
  font-weight: 700;
  text-transform: capitalize; }

.order-success-sec .order-detail li {
  display: flex;
  text-transform: capitalize;
  line-height: 24px;
  font-size: 16px; }

.order-success-sec .payment-mode p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0; }

.delivery-sec {
  padding: 30px;
  text-align: center;
  margin-top: 20px;
  background-color: #f9f9f9; }
  .delivery-sec h2,
  .delivery-sec h3 {
    color: #000000;
    text-transform: capitalize; }

.product-order h3 {
  font-weight: 700;
  text-transform: uppercase;
  color: #000000; }

.product-order .product-order-detail {
  margin-top: 15px; }
  .product-order .product-order-detail img {
    height: 130px; }
  .product-order .product-order-detail .order_detail {
    display: flex;
    align-items: center; }
    .product-order .product-order-detail .order_detail h4 {
      margin-bottom: 5px;
      text-transform: capitalize;
      font-weight: 700; }
    .product-order .product-order-detail .order_detail h5 {
      text-transform: capitalize;
      margin-bottom: 0; }

.product-order .total-sec {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin: 15px 0;
  padding: 15px 0; }
  .product-order .total-sec ul li {
    font-size: 18px;
    display: block;
    text-transform: capitalize; }
    .product-order .total-sec ul li span {
      float: right; }

.product-order .final-total h3 {
  display: block;
  margin-bottom: 0; }
  .product-order .final-total h3 span {
    float: right; }

.compare-padding {
  padding-bottom: 50px; }
  .compare-padding h2 {
    margin-bottom: 20px; }

.compare-lable {
  position: absolute;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  top: 20px;
  left: 16px;
  letter-spacing: 0.1em;
  z-index: 1; }

.compare-page .table-wrapper {
  padding-bottom: 0; }
  .compare-page .table-wrapper .table {
    border: 1px solid #eeeeee;
    text-transform: capitalize;
    color: #222222; }
    .compare-page .table-wrapper .table .product-name {
      width: 15%; }
    .compare-page .table-wrapper .table .featured-image {
      width: 185px; }
    .compare-page .table-wrapper .table td {
      border-top: none; }
    .compare-page .table-wrapper .table thead .th-compare td {
      font-size: 14px;
      font-weight: 700;
      background: #f8f8f8;
      border-right: 1px solid #eee; }
    .compare-page .table-wrapper .table thead .th-compare th {
      text-align: left;
      border-bottom: 1px solid #eeeeee;
      border-right: 1px solid #eeeeee; }
      .compare-page .table-wrapper .table thead .th-compare th .remove-compare {
        float: left;
        border: none;
        background: transparent;
        padding: 0;
        font-size: 14px;
        font-weight: 500; }
    .compare-page .table-wrapper .table tbody tr th {
      background: #f8f8f8; }
    .compare-page .table-wrapper .table tbody tr td {
      border-right: 1px solid #eee;
      position: relative; }
    .compare-page .table-wrapper .table tbody tr th,
    .compare-page .table-wrapper .table tbody tr td {
      padding: 15px;
      vertical-align: top;
      border-bottom: 1px solid #eee;
      border-top: 1px solid #eee;
      border-right: 1px solid #eee; }
    .compare-page .table-wrapper .table tbody tr .grid-link__title {
      font-size: 15px; }
    .compare-page .table-wrapper .table tbody tr .product_price {
      margin: 15px 0; }
      .compare-page .table-wrapper .table tbody tr .product_price span.money {
        padding-left: 5px; }
    .compare-page .table-wrapper .table tbody tr p {
      color: #222222;
      font-size: 13px;
      line-height: 20px;
      margin: 0; }
    .compare-page .table-wrapper .table tbody tr .add-to-cart,
    .compare-page .table-wrapper .table tbody tr .select-option-cls {
      padding: 6px 10px; }

.compare-section .compare-part {
  position: relative; }
  .compare-section .compare-part .close-btn {
    background-color: transparent;
    border: none;
    font-size: 25px;
    position: absolute;
    top: 0;
    right: 0; }
  .compare-section .compare-part .img-secton {
    padding: 0 15px 15px; }
    .compare-section .compare-part .img-secton img {
      width: 50%;
      margin: 0 auto;
      padding-top: 25px; }
    .compare-section .compare-part .img-secton a h5 {
      margin-bottom: 0;
      text-transform: capitalize;
      margin-top: 10px; }
    .compare-section .compare-part .img-secton h5 {
      margin-bottom: 0;
      line-height: 1.2; }
  .compare-section .compare-part .detail-part .title-detail {
    background-color: #f7f8fa;
    text-align: center;
    padding: 8px 0;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb; }
    .compare-section .compare-part .detail-part .title-detail h5 {
      margin-bottom: 0;
      text-transform: uppercase; }
  .compare-section .compare-part .detail-part .inner-detail {
    padding: 15px; }
    .compare-section .compare-part .detail-part .inner-detail p {
      margin-bottom: 0;
      line-height: 1.2;
      letter-spacing: 0.05em; }
  .compare-section .compare-part .btn-part {
    text-align: center;
    padding: 15px;
    border-top: 1px solid #ddd; }
    .compare-section .compare-part .btn-part .btn-solid {
      padding: 5px 10px; }

.compare-section .slick-slide > div {
  border: 1px solid #ddd;
  border-left: none; }

.compare-section .slick-slide:first-child {
  border-left: 1px solid #ddd; }

/*=====================
    24.RTL CSS start
==========================*/
body.rtl {
  direction: rtl; }

.rtl h1,
.rtl h2,
.rtl h3,
.rtl h4,
.rtl h5,
.rtl h6,
.rtl p {
  text-align: right; }

.rtl .slick-slider {
  direction: ltr; }

.rtl .slick-slide {
  float: left; }

.rtl ul {
  -webkit-padding-start: 0; }

.rtl .mr-3 {
  margin-right: 0 !important;
  margin-left: 5px; }

.rtl .offset-md-3,
.rtl .offset-lg-2,
.rtl .offset-xl-3,
.rtl .offset-lg-3,
.rtl .offset-md-2 {
  margin: 0 auto; }

.rtl .title1 h1,
.rtl .title1 h2,
.rtl .title1 h3,
.rtl .title1 h4,
.rtl .title1 h5,
.rtl .title1 h6,
.rtl .title2 h1,
.rtl .title2 h2,
.rtl .title2 h3,
.rtl .title2 h4,
.rtl .title2 h5,
.rtl .title2 h6,
.rtl .title3 h1,
.rtl .title3 h2,
.rtl .title3 h3,
.rtl .title3 h4,
.rtl .title3 h5,
.rtl .title3 h6,
.rtl .title4 h1,
.rtl .title4 h2,
.rtl .title4 h3,
.rtl .title4 h4,
.rtl .title4 h5,
.rtl .title4 h6 {
  text-align: center; }

.rtl .setting-box {
  direction: ltr; }
  .rtl .setting-box h1,
  .rtl .setting-box h2,
  .rtl .setting-box h3,
  .rtl .setting-box h4,
  .rtl .setting-box h5,
  .rtl .setting-box h6,
  .rtl .setting-box p {
    text-align: left; }

.rtl .brand-logo img {
  transform: scaleX(-1); }

.rtl [dir='rtl'] .slick-slide {
  float: left; }

.rtl .effect-cls:before, .rtl .effect-cls:after {
  right: 0; }

.rtl .game-product .product-box:hover .cart-info a:nth-child(1) i {
  animation: fadeInLeft 300ms ease-in-out; }

.rtl .breadcrumb-section .breadcrumb {
  padding-left: 0; }

.rtl .cart-section .wishlist-buttons,
.rtl .wishlist-section .wishlist-buttons {
  text-align: left; }

.rtl .category-block .category-details h6 {
  text-align: center; }

.rtl .offer-slider .slick-slide > div {
  direction: rtl; }

.rtl .tools-parallax-product .tools-description .tools-form .search-button {
  text-align: right; }

.rtl .typo-content.typo-buttons {
  text-align: right; }

.rtl .typography_section .typography-box .headings {
  text-align: right; }

.rtl .typography_section .typography-box ul,
.rtl .typography_section .typography-box ol,
.rtl .typography_section .typography-box dl {
  text-align: right; }

.rtl .typography_section .typography-box ol {
  padding-inline-start: 0px; }

.rtl .typography_section .typography-box .typo-content.input_button {
  text-align: right; }

.rtl .absolute_banner .collection-banner .absolute-contain h3,
.rtl .absolute_banner .collection-banner .absolute-contain h4 {
  text-align: center; }

.rtl .absolute-product .product-box .product-detail .rating,
.rtl .absolute-product .product-box .product-detail h4,
.rtl .absolute-product .product-box .product-detail h6,
.rtl .absolute-product .product-box .product-detail .color-variant {
  text-align: center; }

.rtl .product-box .img-wrapper .front,
.rtl .product-wrap .img-wrapper .front {
  text-align: right;
  left: unset;
  right: 0; }

.rtl .product-box .img-wrapper .back,
.rtl .product-wrap .img-wrapper .back {
  transform: translateX(100px);
  text-align: right;
  left: unset;
  right: 0; }

.rtl .product-box:hover .img-wrapper .back,
.rtl .product-wrap:hover .img-wrapper .back {
  transform: translateX(0); }

.rtl .box-product .full-box .theme-card .offer-slider .product-box2 .media {
  padding-right: 0;
  padding-left: 15px; }
  .rtl .box-product .full-box .theme-card .offer-slider .product-box2 .media img {
    padding: 0 0 0 15px; }

.rtl .box-product .full-box .theme-card .offer-slider .product-box2 .cart-bottom {
  text-align: right; }

.rtl header.header-tools .main-menu .menu-left .navbar {
  padding: 25px 0 25px 45px; }

.rtl .form_search button {
  left: 5px;
  right: unset; }

.rtl .j-box .product-box .product-detail {
  padding-left: 0;
  padding-right: 15px; }

.rtl .gym-product .product-box .product-detail h6,
.rtl .gym-product .product-box .product-detail h4 {
  text-align: center; }

.rtl .search-overlay > div .closebtn {
  right: unset;
  left: 25px; }

.rtl .home-slider .text-center p,
.rtl .home-slider .text-center h1,
.rtl .home-slider .text-center h2,
.rtl .home-slider .text-center h3,
.rtl .home-slider .text-center h4,
.rtl .home-slider .text-center h5,
.rtl .home-slider .text-center h6,
.rtl .center-slider .text-center p,
.rtl .center-slider .text-center h1,
.rtl .center-slider .text-center h2,
.rtl .center-slider .text-center h3,
.rtl .center-slider .text-center h4,
.rtl .center-slider .text-center h5,
.rtl .center-slider .text-center h6 {
  text-align: center !important; }

.rtl .home-slider .text-left p,
.rtl .home-slider .text-left h1,
.rtl .home-slider .text-left h2,
.rtl .home-slider .text-left h3,
.rtl .home-slider .text-left h4,
.rtl .home-slider .text-left h5,
.rtl .home-slider .text-left h6,
.rtl .center-slider .text-left p,
.rtl .center-slider .text-left h1,
.rtl .center-slider .text-left h2,
.rtl .center-slider .text-left h3,
.rtl .center-slider .text-left h4,
.rtl .center-slider .text-left h5,
.rtl .center-slider .text-left h6 {
  text-align: left; }

.rtl .home-slider .text-right p,
.rtl .home-slider .text-right h1,
.rtl .home-slider .text-right h2,
.rtl .home-slider .text-right h3,
.rtl .home-slider .text-right h4,
.rtl .home-slider .text-right h5,
.rtl .home-slider .text-right h6,
.rtl .center-slider .text-right p,
.rtl .center-slider .text-right h1,
.rtl .center-slider .text-right h2,
.rtl .center-slider .text-right h3,
.rtl .center-slider .text-right h4,
.rtl .center-slider .text-right h5,
.rtl .center-slider .text-right h6 {
  text-align: right; }

.rtl .home-slider .p-left .slider-contain,
.rtl .center-slider .p-left .slider-contain {
  justify-content: flex-start; }

.rtl .home-slider .p-right .slider-contain,
.rtl .center-slider .p-right .slider-contain {
  justify-content: flex-end; }

.rtl .home-slider .slider-contain h1,
.rtl .home-slider .slider-contain h2,
.rtl .home-slider .slider-contain h3,
.rtl .home-slider .slider-contain h4,
.rtl .home-slider .slider-contain h5,
.rtl .home-slider .slider-contain h6,
.rtl .center-slider .slider-contain h1,
.rtl .center-slider .slider-contain h2,
.rtl .center-slider .slider-contain h3,
.rtl .center-slider .slider-contain h4,
.rtl .center-slider .slider-contain h5,
.rtl .center-slider .slider-contain h6 {
  text-align: left; }

.rtl .home-slider .slider-contain .btn-solid,
.rtl .home-slider .slider-contain .btn-outline,
.rtl .center-slider .slider-contain .btn-solid,
.rtl .center-slider .slider-contain .btn-outline {
  float: inherit; }

.rtl .home-slider .slick-next,
.rtl .center-slider .slick-next {
  right: unset;
  left: 1px; }
  .rtl .home-slider .slick-next:before,
  .rtl .center-slider .slick-next:before {
    transform: rotate(180deg); }

.rtl .home-slider .slick-prev,
.rtl .center-slider .slick-prev {
  left: unset;
  right: 1px; }
  .rtl .home-slider .slick-prev:before,
  .rtl .center-slider .slick-prev:before {
    transform: rotate(180deg); }

.rtl .home-slider:hover .slick-next,
.rtl .center-slider:hover .slick-next {
  right: unset;
  left: 100px; }

.rtl .home-slider:hover .slick-prev,
.rtl .center-slider:hover .slick-prev {
  left: unset;
  right: 100px; }

.rtl .pwd-page h2 {
  text-align: center; }

.rtl .product-box .color-variant,
.rtl .product-wrap .color-variant {
  -webkit-padding-start: 0; }

.rtl .product-box .product-info h4,
.rtl .product-box .product-info h6,
.rtl .product-wrap .product-info h4,
.rtl .product-wrap .product-info h6 {
  text-align: center; }

.rtl .product-box .product-detail .rating,
.rtl .product-wrap .product-detail .rating {
  text-align: right; }

.rtl .product-box .product-detail .color-variant,
.rtl .product-wrap .product-detail .color-variant {
  text-align: right;
  padding: 0;
  padding-top: 15px; }
  .rtl .product-box .product-detail .color-variant li:first-child,
  .rtl .product-wrap .product-detail .color-variant li:first-child {
    margin-right: 0; }

.rtl .product-box .img-block .lable-wrapper .lable1,
.rtl .product-wrap .img-block .lable-wrapper .lable1 {
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.rtl .product-box .img-block .lable-wrapper .lable2,
.rtl .product-wrap .img-block .lable-wrapper .lable2 {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.rtl .product-box .img-wrapper .lable-block .lable3,
.rtl .product-wrap .img-wrapper .lable-block .lable3 {
  left: unset;
  right: 7px; }

.rtl .product-box .img-wrapper .lable-block .lable4,
.rtl .product-wrap .img-wrapper .lable-block .lable4 {
  left: 7px;
  right: unset; }

.rtl .product-box .cart-detail,
.rtl .product-wrap .cart-detail {
  left: 20px;
  right: unset; }

.rtl .product-box .cart-info.cart-wrap,
.rtl .product-wrap .cart-info.cart-wrap {
  left: 10px;
  right: unset; }

.rtl .product-box:hover .cart-wrap button,
.rtl .product-wrap:hover .cart-wrap button {
  animation: fadeInLeft 300ms ease-in-out; }

.rtl .product-box:hover .cart-wrap a:nth-child(2) i,
.rtl .product-wrap:hover .cart-wrap a:nth-child(2) i {
  animation: fadeInLeft 500ms ease-in-out; }

.rtl .product-box:hover .cart-wrap a:nth-child(3) i,
.rtl .product-wrap:hover .cart-wrap a:nth-child(3) i {
  animation: fadeInLeft 700ms ease-in-out; }

.rtl .product-box:hover .cart-wrap a:nth-child(4) i,
.rtl .product-wrap:hover .cart-wrap a:nth-child(4) i {
  animation: fadeInLeft 1000ms ease-in-out; }

.rtl .product-box:hover .cart-detail button,
.rtl .product-wrap:hover .cart-detail button {
  animation: fadeInLeft 300ms ease-in-out; }

.rtl .product-box:hover .cart-detail a:nth-child(2) i,
.rtl .product-wrap:hover .cart-detail a:nth-child(2) i {
  animation: fadeInLeft 500ms ease-in-out; }

.rtl .product-box:hover .cart-detail a:nth-child(3) i,
.rtl .product-wrap:hover .cart-detail a:nth-child(3) i {
  animation: fadeInLeft 700ms ease-in-out; }

.rtl .product-box:hover .cart-detail a:nth-child(4) i,
.rtl .product-wrap:hover .cart-detail a:nth-child(4) i {
  animation: fadeInLeft 1000ms ease-in-out; }

.rtl .footer-light .subscribe {
  border-right: none;
  border-left: 1px solid #dddddd; }
  .rtl .footer-light .subscribe h4 {
    text-align: right; }

.rtl .subscribe h4 {
  text-align: center; }
  .rtl .subscribe h4 i {
    padding-left: 5px;
    padding-right: 0; }

.rtl .service-block svg {
  margin-left: 12px;
  margin-right: 0; }

.rtl .service-block + .service-block {
  border-right: 1px solid #dddddd;
  border-left: none; }

.rtl .service-block1 h4,
.rtl .service-block1 p {
  text-align: center; }

.rtl .blog-details h4,
.rtl .blog-details h6 {
  text-align: center; }

.rtl .blog-details a p {
  text-align: center; }

.rtl .instagram h2 {
  text-align: center; }

.rtl .category-block .category-details h5 {
  text-align: center; }

.rtl .category-bg .contain-block h6 {
  text-align: center; }

.rtl .about-text p {
  text-align: center; }

.rtl .beauty-about .text-center {
  text-align: left !important; }

.rtl .collection-banner.p-left .contain-banner {
  justify-content: flex-end; }

.rtl .collection-banner.p-right .contain-banner {
  justify-content: flex-start; }

.rtl .collection-banner.p-center .contain-banner {
  justify-content: center; }

.rtl .collection-banner.text-center .contain-banner h1,
.rtl .collection-banner.text-center .contain-banner h2,
.rtl .collection-banner.text-center .contain-banner h3,
.rtl .collection-banner.text-center .contain-banner h4,
.rtl .collection-banner.text-center .contain-banner h5,
.rtl .collection-banner.text-center .contain-banner h6 {
  text-align: center; }

.rtl .collection-banner.text-left .contain-banner h1,
.rtl .collection-banner.text-left .contain-banner h2,
.rtl .collection-banner.text-left .contain-banner h3,
.rtl .collection-banner.text-left .contain-banner h4,
.rtl .collection-banner.text-left .contain-banner h5,
.rtl .collection-banner.text-left .contain-banner h6 {
  text-align: left; }

.rtl .collection-banner.text-right .contain-banner h1,
.rtl .collection-banner.text-right .contain-banner h2,
.rtl .collection-banner.text-right .contain-banner h3,
.rtl .collection-banner.text-right .contain-banner h4,
.rtl .collection-banner.text-right .contain-banner h5,
.rtl .collection-banner.text-right .contain-banner h6 {
  text-align: right; }

.rtl .collection-banner .contain-banner {
  justify-content: flex-end; }
  .rtl .collection-banner .contain-banner h1,
  .rtl .collection-banner .contain-banner h2,
  .rtl .collection-banner .contain-banner h3,
  .rtl .collection-banner .contain-banner h4,
  .rtl .collection-banner .contain-banner h5,
  .rtl .collection-banner .contain-banner h6 {
    text-align: left; }

.rtl .full-banner.p-left .banner-contain {
  float: left; }

.rtl .full-banner.p-right .banner-contain {
  float: right; }

.rtl .full-banner.p-center .banner-contain {
  left: 0;
  right: 0;
  margin: 0 auto; }
  .rtl .full-banner.p-center .banner-contain h3,
  .rtl .full-banner.p-center .banner-contain h4 {
    text-align: center; }
  .rtl .full-banner.p-center .banner-contain .btn-solid,
  .rtl .full-banner.p-center .banner-contain .btn-outline {
    float: unset; }

.rtl .full-banner.text-center .banner-contain h2,
.rtl .full-banner.text-center .banner-contain h3,
.rtl .full-banner.text-center .banner-contain h4 {
  text-align: center; }

.rtl .full-banner.text-left .banner-contain h2,
.rtl .full-banner.text-left .banner-contain h3,
.rtl .full-banner.text-left .banner-contain h4 {
  text-align: left; }

.rtl .full-banner.text-right .banner-contain h2,
.rtl .full-banner.text-right .banner-contain h3,
.rtl .full-banner.text-right .banner-contain h4 {
  text-align: right; }

.rtl .theme-card .slick-prev {
  left: 0;
  right: unset; }

.rtl .theme-card .slick-next {
  right: unset;
  left: 25px; }

.rtl .theme-card .offer-slider img {
  padding: 15px 0 15px 15px; }

.rtl .theme-card .offer-slider .media .media-body a h6 {
  margin-left: 61px;
  margin-right: 0; }

.rtl .theme-card .offer-slider .media .media-body .rating {
  text-align: right; }
  .rtl .theme-card .offer-slider .media .media-body .rating i {
    padding-left: 5px; }

.rtl .theme-card.card-border h5 {
  padding-left: 0;
  padding-right: 50px; }

.rtl .theme-card.card-border .slick-prev {
  left: 30px; }

.rtl .theme-card.card-border .slick-next {
  left: 50px; }

.rtl .theme-card.card-border .offer-slider img {
  padding: 15px 30px 15px 15px; }

.rtl .theme-card.card-border .offer-slider .slick-slide > div {
  direction: rtl; }

.rtl .background .contain-bg h4 {
  text-align: center; }

.rtl .center-slider:hover .slick-next {
  left: 20px;
  right: unset; }

.rtl .center-slider:hover .slick-prev {
  left: unset;
  right: 20px; }

.rtl .theme-tab .tab-title {
  -webkit-padding-start: 0; }

.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
  padding: 15px; }

.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating {
  text-align: right; }

.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
  margin-left: 35px;
  margin-right: 0; }

.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant {
  text-align: right;
  -webkit-padding-start: 0; }

.rtl .beauty-about .about-text p {
  text-align: right; }

.rtl .beauty-about .service-block1 h5 {
  text-align: center; }

.rtl .timer {
  padding-left: 0;
  padding-right: 40px; }
  .rtl .timer span .padding-l {
    padding-left: 0;
    padding-right: 22px; }

.rtl .category-m .category-wrapper h4 {
  text-align: center; }

.rtl .category-m .category-wrapper .category-link {
  -webkit-padding-start: 0; }

.rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
  margin-right: 0;
  margin-left: 20px; }

.rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant {
  text-align: right;
  -webkit-padding-start: 0; }

.rtl .compare-page .table-wrapper .table tbody tr th,
.rtl .compare-page .table-wrapper .table tbody tr td {
  text-align: right; }

.rtl .compare-page .table-wrapper .table thead .th-compare td {
  text-align: right; }

.rtl .compare-page .table-wrapper .table thead .th-compare th .remove-compare {
  float: right; }

.rtl .product-wrap .product-detail .rating,
.rtl .product-wrap .product-detail h4,
.rtl .product-wrap .product-detail h6,
.rtl .product-wrap .product-detail .color-variant,
.rtl .product-wrap .product-info .rating,
.rtl .product-wrap .product-info h4,
.rtl .product-wrap .product-info h6,
.rtl .product-wrap .product-info .color-variant {
  text-align: center; }

.rtl .product-wrap .product-detail .color-variant li,
.rtl .product-wrap .product-info .color-variant li {
  margin: 0 2px; }

.rtl .breadcrumb-item + .breadcrumb-item {
  padding-right: 0.5rem;
  padding-left: 0; }
  .rtl .breadcrumb-item + .breadcrumb-item:before {
    padding-right: 0;
    padding-left: 0.5rem; }

.rtl .top-header .header-dropdown li:first-child {
  padding-right: 25px;
  padding-left: 0; }

.rtl .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media img.mr-3 {
  margin-right: unset !important;
  margin-left: 1rem !important; }

.rtl .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle {
  right: unset;
  left: 0; }

.rtl .main-menu .menu-left .navbar i {
  float: unset; }

.rtl .main-menu .menu-left .navbar .sidenav .sidebar-menu li a {
  text-align: right; }

.rtl .main-nav-center .toggle-nav {
  left: 175px;
  right: 0; }

.rtl .testimonial .testimonial-slider .slick-track .slick-slide .media h5,
.rtl .testimonial .testimonial-slider .slick-track .slick-slide .media h6 {
  text-align: center; }

.rtl .sitemap_page ul {
  text-align: right; }

.rtl .team .slick-next {
  right: unset;
  left: 20px; }

.rtl .team .slick-prev {
  right: unset;
  left: 0; }

.rtl .team h4,
.rtl .team h6 {
  text-align: center; }

.rtl .blog-page .blog-sidebar .theme-card .recent-blog {
  text-align: right; }
  .rtl .blog-page .blog-sidebar .theme-card .recent-blog li .media img {
    margin-right: 0;
    margin-left: 10px; }

.rtl .blog-page .blog-sidebar .theme-card .popular-blog li .blog-date {
  margin: 0 0 0 15px; }

.rtl .blog-page .blog-media .blog-right ul {
  text-align: right; }
  .rtl .blog-page .blog-media .blog-right ul li + li {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #dddddd;
    padding-left: 0;
    margin-left: 0;
    border-left: none; }

.rtl .blog-detail-page .blog-detail .post-social {
  text-align: right; }
  .rtl .blog-detail-page .blog-detail .post-social li + li {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #dddddd;
    padding-left: 0;
    margin-left: 0;
    border-left: none; }

.rtl .blog-detail-page .blog-advance ul {
  text-align: right; }

.rtl .blog-detail-page .comment-section li img {
  margin-right: 0;
  margin-left: 20px; }

.rtl .blog-detail-page .blog-contact .theme-form label,
.rtl .blog-detail-page .blog-contact .theme-form .btn-solid,
.rtl .blog-detail-page .blog-contact .theme-form .btn-outline {
  float: right; }

.rtl .cart-section tbody tr td h2,
.rtl .cart-section tbody tr td p,
.rtl .wishlist-section tbody tr td h2,
.rtl .wishlist-section tbody tr td p {
  text-align: center; }

.rtl .cart-section tfoot tr td:first-child,
.rtl .wishlist-section tfoot tr td:first-child {
  padding-right: 0;
  padding-left: 10px;
  text-align: left; }

.rtl .cart-section .cart-buttons > div:last-child,
.rtl .wishlist-section .cart-buttons > div:last-child {
  text-align: left;
  padding-left: 59px;
  padding-right: 0; }

.rtl .cart-section .cart-buttons > div:first-child .btn-solid,
.rtl .cart-section .cart-buttons > div:first-child .btn-outline,
.rtl .wishlist-section .cart-buttons > div:first-child .btn-solid,
.rtl .wishlist-section .cart-buttons > div:first-child .btn-outline {
  float: right; }

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li:first-child {
  margin-right: 0;
  margin-left: 14px; }

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
  border-right: none;
  border-left: 1px solid #dddddd;
  padding-right: 0;
  padding-left: 20px; }

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select {
  border-right: none;
  border-left: 1px solid #dddddd;
  padding: 20px;
  background: url("/assets/images/dropdown.png") no-repeat 5%; }

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
  border-right: none;
  border-left: 1px solid #dddddd; }

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
  padding: 20px;
  background: url("/assets/images/dropdown.png") no-repeat 5%; }

.rtl .collection-product-wrapper .product-top-filter .popup-filter .open-popup {
  left: unset;
  right: 0; }

.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .search-count {
  border-right: none;
  border-left: 1px solid #dddddd; }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view select,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup select,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .search-count select {
    border-right: none; }

.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul {
  float: right; }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li:first-child {
    margin-right: 0;
    margin-left: 14px; }

.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
  background: url("/assets/images/dropdown.png") no-repeat 5%; }

.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
  background: url("/assets/images/dropdown.png") no-repeat 5%; }

.rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
  text-align: right;
  background: url("/assets/images/dropdown.png") no-repeat 5%; }

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .collection-view,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
  text-align: right; }

.rtl .qty-box .input-group span button {
  transform: rotate(180deg); }

.rtl .product-pagination .product-search-count-bottom {
  padding-right: 0;
  padding-left: 15px; }

.rtl .product-pagination .pagination .page-item a i {
  transform: rotate(180deg); }

.rtl .custom-control-label:after {
  right: -1.5rem;
  left: unset; }

.rtl .contact-page .theme-form label {
  float: right; }

.rtl .contact-page .theme-form .btn-solid,
.rtl .contact-page .theme-form .btn-outline {
  float: right; }

.rtl .contact-page .contact-right ul li {
  padding-right: 150px;
  padding-left: 0; }
  .rtl .contact-page .contact-right ul li .contact-icon {
    left: unset;
    border-left: 1px solid #dddddd;
    border-right: 0;
    right: 0; }
    .rtl .contact-page .contact-right ul li .contact-icon h6 {
      text-align: center; }

.rtl .faq-section .accordion.theme-accordion .card .card-header button {
  float: right;
  text-align: right; }
  .rtl .faq-section .accordion.theme-accordion .card .card-header button:before {
    right: unset;
    left: 20px; }

.rtl .login-page .theme-card {
  text-align: right; }
  .rtl .login-page .theme-card .theme-form label {
    float: right; }

.rtl .register-page .theme-card .theme-form label {
  float: right; }

.rtl .image-swatch {
  text-align: right; }

.rtl .product-right .color-variant {
  text-align: center; }

.rtl .product-right .rating {
  text-align: right; }

.rtl .product-right.product-form-box h4,
.rtl .product-right.product-form-box h3,
.rtl .product-right.product-form-box h6 {
  text-align: center; }

.rtl .product-right .product-icon .product-social li {
  padding-right: 0;
  padding-left: 30px; }

.rtl .product-right .product-icon .wishlist-btn i {
  border-left: none;
  border-right: 1px solid #dddddd;
  padding-left: 0;
  margin-left: 0;
  padding-right: 10px;
  margin-right: 5px; }

.rtl .product-right .product-icon .wishlist-btn span {
  padding-left: 0;
  padding-right: 10px; }

.rtl .product-right .payment-card-bottom {
  text-align: right; }
  .rtl .product-right .payment-card-bottom ul li {
    padding-right: 0;
    padding-left: 10px; }

.rtl .single-product-tables {
  text-align: right; }
  .rtl .single-product-tables.detail-section {
    text-align: right; }

.rtl .tab-product .theme-form input,
.rtl .product-full-tab .theme-form input {
  text-align: right; }

.rtl .tab-product .tab-content .theme-form .media .ml-3,
.rtl .product-full-tab .tab-content .theme-form .media .ml-3 {
  margin-left: 0 !important;
  margin-right: 1rem !important; }

.rtl .theme-form {
  text-align: right; }

.rtl .collection .collection-block .collection-content h3,
.rtl .collection .collection-block .collection-content h4,
.rtl .collection .collection-block .collection-content p {
  text-align: center; }

.rtl .product-right-slick .slick-next,
.rtl .product-slick .slick-next {
  right: unset;
  left: 1px;
  transform: rotate(180deg); }

.rtl .product-right-slick .slick-prev,
.rtl .product-slick .slick-prev {
  left: unset;
  right: 1px;
  transform: rotate(180deg); }

.rtl .product-right-slick:hover .slick-next,
.rtl .product-slick:hover .slick-next {
  right: unset;
  left: 20px; }

.rtl .product-right-slick:hover .slick-prev,
.rtl .product-slick:hover .slick-prev {
  left: unset;
  right: 20px; }

.rtl .collection-wrapper .product-right .product-buttons {
  text-align: center; }

.rtl .collection-wrapper .product-right .size-box {
  text-align: center; }
  .rtl .collection-wrapper .product-right .size-box ul li {
    margin-right: 0;
    margin-left: 10px; }

.rtl .collection-wrapper .product-right .product-description h6 span {
  float: left; }

.rtl .collection-mobile-back span i {
  float: left; }

.rtl .modal-header .close {
  padding: unset;
  margin: unset; }

.rtl .collection-collapse-block .collapse-block-title:after {
  left: -3px;
  right: unset; }

.rtl .collection-filter-block .product-service .media .media-body {
  padding-right: 10px;
  padding-left: 0; }

.rtl .collection-sidebar-banner {
  text-align: right; }

.rtl .tab-border .nav-border {
  border-right: 0;
  border-left: 1px solid #dddddd; }

.rtl .product-pagination .pagination .page-item.active a {
  border-right: none; }

.rtl .custom-control {
  padding-left: 0;
  padding-right: 1.5rem; }

.rtl .collection-collapse-block .collection-collapse-block-content .color-selector ul {
  text-align: right; }

.rtl .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox {
  text-align: right; }

.rtl .theme-modal .modal-dialog .quick-view-modal button.close {
  left: 10px;
  right: 0; }

.rtl .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close {
  left: 17px;
  right: unset; }

.rtl .custom-control-label:before {
  right: -1.5rem;
  left: 0; }

.rtl .product-wrapper-grid.list-view .product-box .product-detail {
  padding-right: 15px;
  padding-left: 0; }

.rtl .top-header .header-contact {
  text-align: right; }
  .rtl .top-header .header-contact li {
    padding-right: 0;
    padding-left: 25px; }
    .rtl .top-header .header-contact li i {
      padding-right: 0;
      padding-left: 10px; }

.rtl .top-header .header-dropdown {
  text-align: left; }
  .rtl .top-header .header-dropdown .onhover-show-div li {
    padding-right: 0; }
  .rtl .top-header .header-dropdown li i {
    padding-right: 0;
    padding-left: 5px; }

.rtl .main-menu .menu-right {
  float: left; }

.rtl .search-overlay > div .overlay-content button {
  right: unset;
  left: 10px; }

.rtl .footer-theme .sub-title {
  text-align: right; }
  .rtl .footer-theme .sub-title li a:before {
    right: 0; }
  .rtl .footer-theme .sub-title .contact-list li {
    padding-left: 0;
    padding-right: 25px; }
  .rtl .footer-theme .sub-title .contact-list i {
    left: unset;
    right: 0; }

.rtl .footer-logo {
  text-align: right; }

.rtl .footer-social li,
.rtl .social-white li {
  padding-right: 0;
  padding-left: 45px; }

.rtl .sub-footer .payment-card-bottom li {
  padding-left: 0;
  padding-right: 15px; }

.rtl .footer-theme2 .footer-block h4,
.rtl .footer-theme2 .footer-block h2,
.rtl .footer-theme2 .footer-title h4,
.rtl .footer-theme2 .footer-title h2 {
  text-align: center; }

.rtl .footer-theme2 .footer-logo {
  text-align: center; }

.rtl .footer-theme2 .social-white li {
  padding-left: 22px;
  padding-right: 22px; }

.rtl .dark-layout h2 {
  text-align: center; }

.rtl .dark-layout .footer-contant p {
  text-align: center; }

.rtl footer.footer-black.footer-light .light-layout.upside .subscribe {
  border-left: 1px solid rgba(0, 0, 0, 0.8);
  border-right: none; }

.rtl .subscribe-form.classic-form .form-control {
  margin-left: 0;
  margin-right: 15px; }

.rtl .rtl-text .product-right .color-variant,
.rtl .rtl-text .product-right .size-box,
.rtl .rtl-text .product-right .product-buttons,
.rtl .rtl-text .product-right .border-product {
  text-align: right; }

.rtl .search-block .form-header .input-group i {
  padding-right: 0;
  padding-left: 10px; }

.rtl .payment-box .payment-options li .radio-option {
  text-align: right; }
  .rtl .payment-box .payment-options li .radio-option label {
    padding-right: 30px;
    padding-left: 0; }
  .rtl .payment-box .payment-options li .radio-option input[type='radio'] {
    right: 0;
    left: unset; }

.rtl .order-box .total li .count {
  text-align: right; }

.rtl .order-box .sub-total .shipping {
  text-align: right; }

.rtl .order-box .sub-total .shopping-option label {
  padding-left: 0;
  padding-right: 10px; }

.rtl .order-box .sub-total li .count {
  text-align: right; }

.rtl .order-box .qty li span {
  text-align: right; }

.rtl .order-box .title-box span {
  text-align: right; }

.rtl .error-section h1,
.rtl .error-section h2 {
  text-align: center; }

.rtl .dashboard .box .box-title > a {
  right: unset;
  left: 0; }

.rtl .dashboard .box address {
  text-align: right; }

.rtl .success-text h2,
.rtl .success-text p {
  text-align: center; }

.rtl .product-order .product-order-detail {
  text-align: right; }

.rtl .product-order .final-total h3 span {
  float: left; }

.rtl .delivery-sec h2,
.rtl .delivery-sec h3 {
  text-align: center; }

.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 {
  text-align: center; }

.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6,
.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h4 {
  text-align: center; }

.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .product-upsell h4 {
  text-align: center; }

.rtl .add_to_cart .cart-inner .cart_top .close-cart {
  margin-right: auto;
  margin-left: unset; }

.rtl .add_to_cart .cart-inner .cart_media .close-circle {
  left: 0;
  right: unset; }

.rtl .payment-box .payment-options li .radio-option label img {
  margin-right: -30px;
  margin-left: 0; }

.rtl .checkout-page .checkout-form select {
  background: url("/assets/images/dropdown.png") no-repeat 3%; }

.rtl .compare-section .compare-part .detail-part .title-detail h5 {
  text-align: center; }

.rtl .dashboard-left .block-content ul li:hover {
  padding-right: 10px;
  padding-left: 0; }

.rtl .layout7-product .product-box .details-product {
  right: 15px;
  left: unset; }

.rtl .game-product .product-box .product-detail .rating,
.rtl .game-product .product-wrap .product-detail .rating {
  text-align: center; }

.rtl .game-product .product-box .product-detail h4,
.rtl .game-product .product-box .product-detail h6,
.rtl .game-product .product-wrap .product-detail h4,
.rtl .game-product .product-wrap .product-detail h6 {
  text-align: center; }

.rtl .j-box .product-box .cart-info.cart-wrap,
.rtl .j-box .product-wrap .cart-info.cart-wrap {
  left: 0;
  right: 0; }

.rtl .j-box .product-box .cart-info a {
  border-right: 1px solid #dddddd;
  border-left: none; }

.rtl .product-m .slick-slide > div {
  direction: rtl; }

.rtl header.left-header .sidenav {
  right: 0; }
  .rtl header.left-header .sidenav .left-sidebar_center {
    padding: 40px 30px 40px 0; }
  .rtl header.left-header .sidenav .pixelstrap li a .sub-arrow {
    left: 7px;
    transform: rotate(180deg); }

.rtl header.left-header .top-header {
  padding-left: 0;
  padding-right: 300px; }

.rtl header.left-header .search-overlay {
  padding-right: 300px;
  padding-left: 0; }

.rtl .detail-cannabis .detail_section h4,
.rtl .detail-cannabis .detail_section p {
  text-align: center; }

.rtl .tools-grey .product-box.product-wrap .cart-info.cart-wrap {
  left: 0;
  right: 0; }

.rtl .tab-left .theme-tab .left-side .tab-title {
  margin-left: 100px;
  text-align: right;
  margin-right: 0; }

.rtl .review-page .comnt-sec {
  text-align: right; }
  .rtl .review-page .comnt-sec li {
    margin-right: 0;
    margin-left: 15px; }
    .rtl .review-page .comnt-sec li a i {
      margin-right: 0;
      margin-left: 5px; }

.rtl .template-password #container #login h2 {
  text-align: center; }

.rtl .template-password #container #login .theme-form {
  text-align: center; }
  .rtl .template-password #container #login .theme-form h3 {
    text-align: center; }

.rtl .template-password #container #powered p {
  text-align: center; }

.rtl .ribbon-1 {
  right: 14px;
  left: unset; }

.rtl .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects > div .demo-text h4 {
  text-align: center; }

/*=====================
    25.Dark CSS start
==========================*/
body.dark {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cfd4da; }

.dark h1 {
  color: #ffffff; }

.dark h2 {
  color: #f1f3f5; }

.dark h3 {
  color: #eaedef; }

.dark h4 {
  color: #e7eaec; }

.dark h5 {
  color: #dee2e6; }

.dark h6 {
  color: #cfd4da; }

.dark p {
  color: #cfd4da; }

.dark li {
  color: #cfd4da; }

.dark a {
  color: #cbcbcb; }

.dark .page-link {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040; }

.dark .breadcrumb-section {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .light-layout {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .form-control {
  color: #cfd4da;
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border: 1px solid #404040; }

.dark .btn-solid {
  color: #ffffff !important; }
  .dark .btn-solid:hover {
    color: #222222 !important; }

.dark select {
  color: #cfd4da; }

.dark option {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark header {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }
  .dark header.sticky.fixed {
    background-color: #2b2b2b; }
  .dark header.header-5 .pixelstrap a, .dark header.header-5 .pixelstrap:hover, .dark header.header-5 .pixelstrap:active {
    color: #222222; }
  .dark header.header-metro .metro {
    background-color: #232323;
    transition: all 0.3s ease; }
  .dark header.header-metro .top-header .header-contact li {
    color: #999999; }
  .dark header.header-metro .top-header .header-dropdown li {
    color: #999999; }
    .dark header.header-metro .top-header .header-dropdown li a i {
      color: #999999; }
  .dark header.header-tools {
    background-color: transparent;
    transition: all 0.3s ease; }
    .dark header.header-tools .logo-menu-part > .container {
      background-color: #2b2b2b;
      transition: all 0.3s ease;
      border-color: #404040; }
  .dark header.left-header .top-header {
    background-color: #2b2b2b;
    transition: all 0.3s ease; }
  .dark header.left-header .main-menu .menu-right .header-dropdown > li img {
    filter: invert(100); }
  .dark header.left-header .onhover-div > div img {
    filter: invert(100); }
  .dark header.left-header .sidenav nav {
    background-color: #232323;
    transition: all 0.3s ease; }
  .dark header.left-header .sidenav .left-sidebar_center {
    background-color: #232323;
    transition: all 0.3s ease; }
  .dark header.left-header .sidenav .pixelstrap > li > a {
    background-color: #232323;
    transition: all 0.3s ease; }
  .dark header.green-gradient {
    background-color: #2b2b2b;
    transition: all 0.3s ease; }
    .dark header.green-gradient .top-header {
      background-color: #232323;
      transition: all 0.3s ease;
      background: #232323;
      background-image: none; }

.dark .top-header {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .sub-footer {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark .border-section {
  border-color: #404040; }

.dark .main-navbar .nav-menu .back-btn .mobile-back {
  color: #f1f3f5; }

.dark .main-navbar .nav-menu > li .nav-submenu {
  background-color: #2b2b2b;
  box-shadow: none; }
  .dark .main-navbar .nav-menu > li .nav-submenu li .nav-sub {
    color: #f1f3f5; }
  .dark .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
    background-color: #2b2b2b;
    box-shadow: none; }
    .dark .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu li a {
      color: #f1f3f5; }

.dark .main-navbar .nav-menu > li .mega-menu-container {
  background-color: #2b2b2b;
  box-shadow: none; }
  .dark .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
    color: #f1f3f5; }

.dark .sm-vertical {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .sidenav nav {
  background-color: #232323;
  transition: all 0.3s ease; }
  .dark .sidenav nav .sidebar-menu {
    background-color: #232323; }
    .dark .sidenav nav .sidebar-menu li a {
      color: #f1f3f5; }
    .dark .sidenav nav .sidebar-menu li .mega-menu {
      background-color: #232323;
      box-shadow: none; }
      .dark .sidenav nav .sidebar-menu li .mega-menu .link-section ul {
        background-color: #232323; }
    .dark .sidenav nav .sidebar-menu li ul {
      background-color: #232323; }

.dark .sidenav .sidebar-back {
  color: #f1f3f5;
  border-color: #404040; }

.dark .pixelstrap ul {
  background: #232323;
  box-shadow: none; }
  .dark .pixelstrap ul a {
    color: #e7eaec; }
    .dark .pixelstrap ul a:hover, .dark .pixelstrap ul a:focus, .dark .pixelstrap ul a:active, .dark .pixelstrap ul a.highlighted {
      color: #e7eaec; }

.dark .pixelstrap .home-menu,
.dark .pixelstrap .feature-menu,
.dark .pixelstrap .category-menu,
.dark .pixelstrap .full-mega-menu,
.dark .pixelstrap .clothing-menu {
  box-shadow: 0 0 1px 0 #2b2b2b; }

.dark .onhover-div > div img {
  filter: brightness(100); }

.dark .onhover-div .show-div {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none; }

.dark .error-section h1 {
  color: #e6e6e6; }

.dark .breadcrumb-item.active {
  color: #e7eaec; }

.dark .breadcrumb-section .breadcrumb a {
  color: #f7f7f7; }

.dark .main-menu .menu-left .navbar i {
  color: #f7f7f7; }

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.setting h6 {
  color: #cfd4da; }

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li a {
  color: #cfd4da; }

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 {
  color: #e7eaec; }
  .dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media .media-body h4 span {
    color: #dee2e6; }

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle i:hover {
  color: #ffffff; }

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total {
  border-color: #404040; }
  .dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .total h5 {
    color: #dee2e6; }

.dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a {
  color: #cbcbcb; }
  .dark .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .buttons a:hover {
    color: #b5dccd; }

.dark .footer-theme .sub-title li {
  color: #cfd4da; }
  .dark .footer-theme .sub-title li a {
    color: #cfd4da; }

.dark .footer-theme .sub-title h4 {
  color: #e7eaec; }

.dark .footer-social i,
.dark .social-white i {
  color: #cfd4da; }

.dark .footer-light .subscribe {
  border-color: #404040; }

.dark .footer-theme2 .contact-details li {
  color: #cfd4da; }
  .dark .footer-theme2 .contact-details li a {
    color: #e7eaec; }

.dark .footer-theme2 .footer-link a,
.dark .footer-theme2 .footer-link-b a {
  color: #cbcbcb; }
  .dark .footer-theme2 .footer-link a:hover,
  .dark .footer-theme2 .footer-link-b a:hover {
    color: #ffffff; }

.dark .top-header .header-contact li {
  color: #cfd4da; }

.dark .top-header .header-dropdown li {
  color: #cfd4da; }
  .dark .top-header .header-dropdown li a i {
    color: #cfd4da; }

.dark .top-header .header-dropdown .onhover-dropdown .onhover-show-div li a {
  color: #cbcbcb; }

.dark .top-header.top-header-dark .header-dropdown li a {
  color: #cbcbcb; }

.dark .top-header.top-header-dark .header-contact li i {
  color: #cbcbcb; }

.dark .top-header.top-header-dark2 .header-dropdown li a {
  color: #cfd4da; }

.dark .top-header.top-header-dark2 .header-contact li i {
  color: #cfd4da; }

.dark .top-header.top-header-dark3 .header-dropdown li a {
  color: #cbcbcb; }

.dark .top-header.top-header-dark3 .header-contact li i {
  color: #cbcbcb; }

.dark .onhover-dropdown .onhover-show-div {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none; }

.dark .testimonial {
  background-color: #232323;
  transition: all 0.3s ease; }
  .dark .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
    border-color: #404040; }
  .dark .testimonial .testimonial-slider .media img {
    border-color: #404040; }

.dark .team h2 {
  border-color: #404040; }

.dark .service-block + .service-block {
  border-color: #404040; }

.dark .pixelstrap.light-font-menu li > a {
  color: #f1f3f5; }

.dark .theme-tab .tab-title a,
.dark .theme-tab .tab-title2 a {
  color: #cbcbcb; }

.dark .theme-tab .tab-title .current a,
.dark .theme-tab .tab-title2 .current a {
  color: #b5dccd; }

.dark .theme-tab .tab-title2:after {
  border-color: #404040; }

.dark .theme-tab .tab-title2 .current {
  background-color: #232323;
  transition: all 0.3s ease; }
  .dark .theme-tab .tab-title2 .current a {
    background-color: #232323;
    transition: all 0.3s ease; }

.dark .product-box .product-detail h4,
.dark .product-box .product-info h4,
.dark .product-wrap .product-detail h4,
.dark .product-wrap .product-info h4 {
  color: #e7eaec; }

.dark .theme-card .offer-slider .media .media-body h4 {
  color: #e7eaec; }

.dark .theme-card .slick-prev:before,
.dark .theme-card .slick-next:before {
  color: #ffffff; }

.dark .theme-card h5.title-border {
  border-color: #404040; }

.dark .theme-card.card-border {
  border-color: #404040; }

.dark .dark-layout {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark .sub-footer.darker-subfooter {
  background-color: #232323;
  transition: all 0.3s ease; }
  .dark .sub-footer.darker-subfooter p {
    color: #cfd4da; }

.dark .blog-page .blog-media .blog-right h4 {
  color: #e7eaec; }

.dark .blog-page .blog-media .blog-right ul {
  color: #cfd4da; }

.dark .blog-page .blog-sidebar .theme-card {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040; }

.dark .blog-page .blog-sidebar h4 {
  color: #e7eaec; }

.dark .blog-page .blog-sidebar h6 {
  color: #cfd4da; }

.dark .blog-page .blog-sidebar p {
  color: #cfd4da; }

.dark .blog-detail-page .blog-detail h3 {
  color: #eaedef; }

.dark .blog-detail-page .blog-detail p {
  color: #cfd4da; }

.dark .blog-detail-page .post-social {
  color: #cfd4da;
  border-color: #404040; }
  .dark .blog-detail-page .post-social li + li {
    border-color: #404040; }

.dark .blog-detail-page .comment-section {
  border-color: #404040; }
  .dark .blog-detail-page .comment-section li {
    border-color: #404040; }
    .dark .blog-detail-page .comment-section li h6 {
      color: #cfd4da; }
      .dark .blog-detail-page .comment-section li h6 span {
        color: #929292; }

.dark .blog-detail-page .blog-contact .theme-form label {
  color: #cfd4da; }

.dark .blog-detail-page .blog-contact .theme-form input,
.dark .blog-detail-page .blog-contact .theme-form textarea {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040; }

.dark .cart-section .cart-table thead th,
.dark .wishlist-section .cart-table thead th {
  color: #f1f3f5; }

.dark .cart-section tbody tr td,
.dark .wishlist-section tbody tr td {
  border-color: #404040; }
  .dark .cart-section tbody tr td a,
  .dark .wishlist-section tbody tr td a {
    color: #cbcbcb; }
  .dark .cart-section tbody tr td h2,
  .dark .wishlist-section tbody tr td h2 {
    color: #f1f3f5; }

.dark .table tbody + tbody {
  border-color: #404040; }

.dark .table thead th {
  border-color: #404040; }

.dark .top-banner-wrapper .top-banner-content h4 {
  color: #e7eaec; }

.dark .top-banner-wrapper .top-banner-content h5 {
  color: #dee2e6; }

.dark .top-banner-wrapper .top-banner-content p {
  color: #cfd4da; }

.dark .collection-product-wrapper .product-top-filter {
  border-color: #404040; }
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .search-count,
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup,
  .dark .collection-product-wrapper .product-top-filter .popup-filter .search-count,
  .dark .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    border-color: #404040; }
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
  .dark .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
    border-color: #404040; }
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
  .dark .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    border-color: #404040; }
  .dark .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup a {
    color: #cbcbcb; }

.dark .product-pagination {
  border-color: #404040; }
  .dark .product-pagination .pagination .page-item.active a {
    background-color: #232323;
    transition: all 0.3s ease;
    border-color: #404040; }
  .dark .product-pagination .product-search-count-bottom {
    border-color: #404040; }
    .dark .product-pagination .product-search-count-bottom h5 {
      color: #dee2e6; }

.dark .portfolio-section.metro-section .product-box .product-detail {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }
  .dark .portfolio-section.metro-section .product-box .product-detail h4 {
    color: #e7eaec; }
  .dark .portfolio-section.metro-section .product-box .product-detail h6 {
    color: #cfd4da; }

.dark .portfolio-section.metro-section .product-box .cart-wrap button {
  border-color: #404040;
  background-color: rgba(43, 43, 43, 0.8);
  transition: all 0.3s ease; }
  .dark .portfolio-section.metro-section .product-box .cart-wrap button i {
    color: #cbcbcb; }

.dark .portfolio-section.metro-section .product-box .cart-wrap a i {
  border-color: #404040;
  background-color: rgba(43, 43, 43, 0.8);
  transition: all 0.3s ease;
  color: #cbcbcb; }

.dark .collection-filter-block {
  border-color: #404040;
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark .collection-collapse-block .collapse-block-title {
  color: #eaedef; }
  .dark .collection-collapse-block .collapse-block-title:after {
    color: #eaedef; }

.dark .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
  color: #929292; }

.dark .load-more-sec a {
  border-color: #404040; }

.dark .checkout-page .checkout-title h3 {
  color: #eaedef; }

.dark .checkout-page .checkout-form .form-group .field-label {
  color: #cfd4da; }

.dark .checkout-page .checkout-form input[type='text'], .dark .checkout-page .checkout-form input[type='email'], .dark .checkout-page .checkout-form input[type='password'], .dark .checkout-page .checkout-form input[type='tel'], .dark .checkout-page .checkout-form input[type='number'], .dark .checkout-page .checkout-form input[type='url'] {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040; }

.dark .checkout-page .checkout-form select,
.dark .checkout-page .checkout-form textarea {
  border-color: #404040; }

.dark .checkout-page .checkout-form .checkout-details {
  background-color: #232323;
  transition: all 0.3s ease;
  border: 1px solid #404040; }

.dark .order-box .title-box {
  color: #eaedef;
  border-color: #404040; }

.dark .order-box .qty {
  border-color: #404040; }
  .dark .order-box .qty li {
    color: #cfd4da; }
    .dark .order-box .qty li span {
      color: #dee2e6; }

.dark .order-box .sub-total {
  border-color: #404040; }
  .dark .order-box .sub-total li {
    color: #cfd4da; }
  .dark .order-box .sub-total .shopping-option label {
    color: #929292; }

.dark .order-box .total li {
  color: #cfd4da; }

.dark .payment-box .payment-options li .radio-option label {
  color: #929292; }

.dark .collection .collection-block .collection-content h4 {
  color: #e7eaec; }

.dark .collection .collection-block .collection-content h3 {
  color: #eaedef; }

.dark .table th,
.dark .table td {
  border-color: #404040; }

.dark .compare-page .table-wrapper .table {
  color: #f1f3f5;
  border-color: #404040; }
  .dark .compare-page .table-wrapper .table thead .th-compare td {
    background: #232323;
    border-color: #404040; }
  .dark .compare-page .table-wrapper .table thead .th-compare th {
    border-color: #404040; }
    .dark .compare-page .table-wrapper .table thead .th-compare th .remove-compare {
      color: #cfd4da; }
  .dark .compare-page .table-wrapper .table tbody tr th {
    border-color: #404040;
    background: #232323; }
  .dark .compare-page .table-wrapper .table tbody tr td {
    border-color: #404040; }
  .dark .compare-page .table-wrapper .table tbody tr p {
    color: #cfd4da; }

.dark .compare-section .compare-part .detail-part .title-detail {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: #404040; }

.dark .compare-section .compare-part .btn-part {
  border-color: #404040; }

.dark .compare-section .compare-part .close-btn {
  color: #e7eaec; }

.dark .compare-section .slick-slide > div {
  border-color: #404040; }

.dark .compare-section .slick-slide:first-child {
  border-color: #404040; }

.dark .contact-page .theme-form label {
  color: #cfd4da; }

.dark .contact-page .theme-form input {
  border-color: #404040; }

.dark .contact-page .theme-form textarea {
  border-color: #404040; }

.dark .contact-page .contact-right ul li .contact-icon {
  border-color: #404040; }

.dark .dashboard .box-head h2 {
  color: #f1f3f5; }

.dark .dashboard .box .box-title {
  border-color: #404040; }
  .dark .dashboard .box .box-title h3 {
    color: #eaedef; }

.dark .dashboard-left .block-content {
  border-color: #404040; }
  .dark .dashboard-left .block-content ul li a {
    color: #cbcbcb; }

.dark .dashboard-right .dashboard {
  border-color: #404040; }

.dark .white-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark .border-bottom-grey {
  border-color: #404040; }

.dark .layout-8 .layout-8-bg {
  background-blend-mode: overlay;
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .card {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }
  .dark .card .card-header {
    background-color: #232323;
    transition: all 0.3s ease; }

.dark .faq-section .accordion.theme-accordion .card {
  border-color: #404040; }
  .dark .faq-section .accordion.theme-accordion .card .card-header {
    background-color: #232323;
    transition: all 0.3s ease; }
    .dark .faq-section .accordion.theme-accordion .card .card-header button {
      color: #dee2e6; }
      .dark .faq-section .accordion.theme-accordion .card .card-header button[aria-expanded='true']:before {
        border-bottom-color: #404040; }
      .dark .faq-section .accordion.theme-accordion .card .card-header button:before {
        border-top-color: #404040; }
  .dark .faq-section .accordion.theme-accordion .card .card-body p {
    color: #cfd4da; }

.dark.box-layout-body .box-layout-header {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark.box-layout-body .box-layout.bg-image {
  background-color: #232323;
  transition: all 0.3s ease;
  background-image: none;
  box-shadow: none; }

.dark.box-layout-body .light-layout {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark.box-layout-body .sub-footer {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .full-box .theme-card .offer-slider .product-box2 + .product-box2 {
  border-top: none; }

.dark .center-slider {
  border-color: #404040; }

.dark .bg-block {
  background-color: #232323;
  transition: all 0.3s ease;
  background-image: none; }

.dark .theme-tab .tab-content .product-tab .tab-box {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }
  .dark .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
    color: #e7eaec; }

.dark .flower-bg {
  background-color: #232323;
  transition: all 0.3s ease;
  background-image: none; }

.dark .pwd-page .theme-form input {
  border-color: #404040; }

.dark .full-scroll-menu {
  background-color: transparent;
  transition: all 0.3s ease; }

.dark .full-scroll-footer .sub-footer {
  background-color: transparent;
  transition: all 0.3s ease; }

.dark .portfolio-section .filter-button {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cfd4da; }
  .dark .portfolio-section .filter-button.active {
    background-color: #b5dccd;
    transition: all 0.3s ease;
    color: #ffffff; }

.dark .portfolio-section .isotopeSelector:hover .overlay-background {
  border-color: #dddddd; }
  .dark .portfolio-section .isotopeSelector:hover .overlay-background i {
    background-color: #2b2b2b;
    transition: all 0.3s ease; }

.dark .portfolio-section .isotopeSelector .overlay {
  border-color: #dddddd; }

.dark .bg-img-gym .dark-layout {
  background-color: transparent;
  transition: all 0.3s ease; }

.dark .bg-img-gym .sub-footer.darker-subfooter {
  background-color: #000000;
  transition: all 0.3s ease; }

.dark .category-block .category-image {
  border-color: #404040; }
  .dark .category-block .category-image.svg-image {
    background-color: #232323;
    transition: all 0.3s ease; }

.dark .j-box .product-box {
  border-color: #404040; }
  .dark .j-box .product-box .product-detail {
    background-color: #232323;
    transition: all 0.3s ease; }
  .dark .j-box .product-box .cart-info {
    background-color: rgba(35, 35, 35, 0.84);
    transition: all 0.3s ease;
    border-color: #404040; }
    .dark .j-box .product-box .cart-info a {
      border-color: #000000; }

.dark .white-layout {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark .footer-theme2.footer-border {
  border-color: #404040; }

.dark .footer-theme2.section-light .footer-block .subscribe-white {
  border-color: #404040; }

.dark .footer-theme2.section-light .footer-block h4 {
  color: #e7eaec; }

.dark .footer-theme2.section-light .footer-block .contact-details li a {
  color: #e7eaec; }

.dark .footer-theme2 .footer-link.link-white h4 {
  color: #e7eaec; }

.dark .jewel-footer .sub-footer.black-subfooter {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .jewel-footer .sub-footer p {
  color: #cfd4da; }

.dark .absolute-banner .absolute-bg {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none; }

.dark .login-page .theme-card {
  border-color: #404040; }
  .dark .login-page .theme-card .theme-form input {
    border-color: #404040; }
  .dark .login-page .theme-card .theme-form label {
    color: #929292; }

.dark .login-page .authentication-right h6,
.dark .login-page .authentication-right p {
  color: #cfd4da; }

.dark .lookbook .lookbook-block .lookbook-dot:before {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark .blog-left .blog-details h4 {
  color: #e7eaec; }

.dark .blog-left .blog-details h6 {
  color: #cfd4da; }

.dark footer.footer-black .below-section {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark footer.footer-black .sub-footer {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark footer.footer-black .upside .small-section {
  background-color: #383838;
  transition: all 0.3s ease; }

.dark footer.footer-black .subscribe h4 {
  color: #e7eaec; }

.dark footer.footer-black .subscribe p {
  color: #cfd4da; }

.dark footer.footer-black.footer-light .subscribe {
  border-color: #929292; }

.dark footer.pet-layout-footer .white-layout {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark footer.pet-layout-footer .sub-footer.black-subfooter {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark footer.footer-classic .sub-footer {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark footer.footer-5 {
  background-image: unset; }
  .dark footer.footer-5 .footer-theme2 .subscribe-block {
    border-color: #404040; }
  .dark footer.footer-5 .sub-footer > .container {
    border-color: #404040; }

.dark .logo-block img {
  filter: invert(100); }
  .dark .logo-block img:hover {
    filter: invert(100); }

.dark .bg-white {
  background-color: #2b2b2b !important;
  transition: all 0.3s ease; }

.dark .product-box .img-wrapper .cart-box,
.dark .product-wrap .img-wrapper .cart-box {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  box-shadow: none; }

.dark .tab-bg.tab-grey-bg {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .delivery-sec {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .product-order .total-sec {
  border-color: #404040; }

.dark .blog.blog_box .blog-details p {
  color: #cfd4da; }

.dark .blog.blog_box .blog-details a p {
  color: #cfd4da; }

.dark .blog.blog_box .blog-details .read-cls {
  color: #cbcbcb; }

.dark .product-right .product-title {
  color: #cfd4da; }

.dark .product-right .border-product {
  border-color: #404040; }

.dark .product-right .product-icon .product-social li a {
  color: #cbcbcb; }

.dark .product-right .product-icon .wishlist-btn {
  color: #cbcbcb; }
  .dark .product-right .product-icon .wishlist-btn i {
    border-color: #404040; }

.dark .product-right.product-form-box {
  border-color: #404040; }
  .dark .product-right.product-form-box .timer {
    background-color: #232323;
    transition: all 0.3s ease; }

.dark .product-right .timer {
  background-color: #232323;
  transition: all 0.3s ease; }
  .dark .product-right .timer p {
    color: #cfd4da; }

.dark .product-right .size-box ul li {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  border-color: #404040; }
  .dark .product-right .size-box ul li.active {
    background-color: #232323;
    transition: all 0.3s ease; }
  .dark .product-right .size-box ul li a {
    color: #cbcbcb; }

.dark .product-right h4 del {
  color: #929292; }

.dark .timer span .timer-cal {
  color: #929292; }

.dark .qty-box .input-group span button {
  background: #2b2b2b !important;
  border-color: #404040; }

.dark .qty-box .input-group button i {
  color: #929292; }

.dark .nav-tabs {
  border-color: #404040; }
  .dark .nav-tabs .nav-link.active {
    background-color: #2b2b2b;
    transition: all 0.3s ease; }

.dark .tab-product .nav-material.nav-tabs .nav-item .nav-link {
  color: #cbcbcb; }

.dark .tab-product .nav-material.nav-tabs .nav-link.active {
  color: #b5dccd; }

.dark .product-full-tab .nav-material.nav-tabs .nav-link.active {
  color: #b5dccd; }

.dark .tab-product .theme-form input,
.dark .tab-product .theme-form textarea,
.dark .product-full-tab .theme-form input,
.dark .product-full-tab .theme-form textarea {
  border-color: #404040; }

.dark .product-related h2 {
  border-color: #404040; }

.dark .product-accordion .btn-link {
  color: #e7eaec; }

.dark .product-accordion .card-header {
  border-color: rgba(64, 64, 64, 0.125); }

.dark .theme_checkbox label {
  color: #929292; }
  .dark .theme_checkbox label .checkmark {
    background-color: #232323;
    transition: all 0.3s ease;
    border-color: #404040; }
    .dark .theme_checkbox label .checkmark:after {
      border-color: #ffffff; }

.dark .bundle .bundle_detail .price_product {
  color: #ffffff; }

.dark .modal-content {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark button.close {
  color: #ffffff; }

.dark .modal-header {
  border-color: #404040; }

.dark .collection-filter-block .product-service .media {
  border-color: #404040; }

.dark .pro_sticky_info {
  border-color: #404040; }

.dark .tab-border {
  border-color: #404040; }

.dark .register-page .theme-card {
  border-color: #404040; }
  .dark .register-page .theme-card .theme-form input {
    border-color: #404040; }
  .dark .register-page .theme-card .theme-form label {
    color: #929292; }

.dark .category-border {
  background-color: #404040;
  transition: all 0.3s ease; }
  .dark .category-border div .category-banner .category-box h2 {
    background-color: #232323;
    transition: all 0.3s ease; }

.dark .background {
  background-color: #404040;
  transition: all 0.3s ease; }
  .dark .background .contain-bg {
    background-color: #2b2b2b;
    transition: all 0.3s ease; }
    .dark .background .contain-bg h4 {
      color: #e7eaec; }
    .dark .background .contain-bg:hover h4 {
      color: #b5dccd; }

.dark .blog-bg {
  background-color: #404040;
  transition: all 0.3s ease; }

.dark .sub-footer.black-subfooter {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark.tools-bg {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }
  .dark.tools-bg section {
    background-color: #2b2b2b;
    transition: all 0.3s ease; }

.dark .category-tools .category-m .category-wrapper {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .category-m .category-wrapper {
  border-color: #404040; }
  .dark .category-m .category-wrapper h4 {
    color: #e7eaec; }
  .dark .category-m .category-wrapper .category-link li a {
    color: #cbcbcb; }

.dark .tools-grey .product-box .product-info {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: rgba(64, 64, 64, 0.2); }

.dark .tools-grey .product-box .cart-info button {
  background-color: #232323;
  transition: all 0.3s ease;
  color: #cbcbcb; }

.dark .tools-grey .product-box .cart-info a {
  background-color: #232323;
  transition: all 0.3s ease; }
  .dark .tools-grey .product-box .cart-info a i {
    color: #cbcbcb; }

.dark .tools-grey .product-box .img-wrapper .front {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark .tools-brand .row {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none; }

.dark .typography_section .typography-box .headings {
  background-color: rgba(35, 35, 35, 0.5);
  transition: all 0.3s ease;
  border-color: #404040; }
  .dark .typography_section .typography-box .headings h3 {
    color: #eaedef; }
  .dark .typography_section .typography-box .headings span {
    color: #929292; }
    .dark .typography_section .typography-box .headings span code {
      color: #929292; }

.dark .typography_section .typography-box .typo-content .sub-title {
  color: #f1f3f5;
  border-color: rgba(64, 64, 64, 0.7); }

.dark .typography_section .typo-content.heading_content h1,
.dark .typography_section .typo-content.heading_content h2,
.dark .typography_section .typo-content.heading_content h3,
.dark .typography_section .typo-content.heading_content h4,
.dark .typography_section .typo-content.heading_content h5,
.dark .typography_section .typo-content.heading_content h6 {
  color: white; }

.dark .typography_section .typo-content.product-pagination .pagination .page-item {
  border-color: #404040; }

.dark .typography_section code {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark .absolute_banner .collection-banner .absolute-contain {
  background-color: #232323;
  transition: all 0.3s ease;
  box-shadow: none; }
  .dark .absolute_banner .collection-banner .absolute-contain h4 {
    color: #e7eaec; }

.dark .absolute-product .product-box {
  background-color: #232323;
  transition: all 0.3s ease; }
  .dark .absolute-product .product-box .product-detail .cart-bottom {
    border-color: #404040; }
    .dark .absolute-product .product-box .product-detail .cart-bottom i {
      color: #cbcbcb; }
      .dark .absolute-product .product-box .product-detail .cart-bottom i:hover {
        color: #ffffff; }

.dark .box-product .full-box .theme-card .offer-slider .product-box2 {
  box-shadow: none; }
  .dark .box-product .full-box .theme-card .offer-slider .product-box2 .media {
    background-color: #232323;
    transition: all 0.3s ease; }
  .dark .box-product .full-box .theme-card .offer-slider .product-box2 .cart-bottom {
    border-color: #404040; }

.dark .insta-title {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark .tab-bg {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .darken-layout {
  background-color: #232323;
  transition: all 0.3s ease; }
  .dark .darken-layout p {
    color: #cfd4da; }
  .dark .darken-layout .sub-title .contact-list li {
    color: #cfd4da; }
  .dark .darken-layout .sub-title .contact-list i {
    color: #cfd4da; }
  .dark .darken-layout .footer-social i {
    color: #cfd4da; }

.dark .sub-footer.dark-subfooter p {
  color: #cfd4da; }

.dark .cart-section tbody tr td a,
.dark .cart-section tbody tr td p,
.dark .wishlist-section tbody tr td a,
.dark .wishlist-section tbody tr td p {
  color: #cbcbcb; }

.dark .footer-title {
  border-color: #404040; }

.dark .category-bg {
  background-color: #232323;
  transition: all 0.3s ease; }
  .dark .category-bg .contain-block {
    background-color: #2b2b2b;
    transition: all 0.3s ease; }
    .dark .category-bg .contain-block h2 {
      color: #f1f3f5; }
    .dark .category-bg .contain-block h6 span {
      color: #929292; }

.dark .service_slide .service-home .service-block1 {
  background-color: rgba(35, 35, 35, 0.9);
  transition: all 0.3s ease; }
  .dark .service_slide .service-home .service-block1:nth-child(even) {
    background-color: rgba(35, 35, 35, 0.7);
    transition: all 0.3s ease; }
  .dark .service_slide .service-home .service-block1 svg path {
    fill: #ffffff; }

.dark .bg-grey {
  background-color: #232323;
  transition: all 0.3s ease; }

.dark .detail-cannabis .detail_section > div {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  box-shadow: none; }

.dark .detail-cannabis .detail_section svg {
  fill: #ffffff; }

.dark .border-box.tools-grey .product-box {
  border-color: #404040; }

.dark .theme-modal .modal-dialog .modal-content .modal-body {
  background-image: linear-gradient(135deg, #b5dccd 5.77%, #232323 5.77%, #232323 25%, #f1f3f5 25%, #f1f3f5 30.77%, #232323 30.77%, #232323 50%, #b5dccd 50%, #b5dccd 55.77%, #232323 55.77%, #232323 75%, #f1f3f5 75%, #f1f3f5 80.77%, #232323 80.77%, #232323 100%); }
  .dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
    background-color: #2b2b2b;
    transition: all 0.3s ease; }
    .dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
      color: #e7eaec; }
    .dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close span {
      color: #ffffff; }
    .dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
      color: #f1f3f5; }

.dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }
  .dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects > div .layout-container {
    box-shadow: none; }
  .dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects > div .demo-text h4 {
    color: #e7eaec; }
  .dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects > div .demo-text .demo-btn .btn {
    border-color: #404040;
    background-color: #2b2b2b;
    transition: all 0.3s ease;
    color: #cbcbcb; }
  .dark .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .title-text h3 {
    color: #eaedef; }

.dark .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 a {
  color: #cbcbcb; }

.dark .theme-modal.exit-modal .media .media-body h5 {
  color: #dee2e6; }

.dark .theme-modal.exit-modal .stop {
  filter: invert(100); }

.dark #quick-view .modal-dialog .modal-content .modal-body {
  background-color: #2b2b2b;
  transition: all 0.3s ease; }

.dark .addcart_btm_popup {
  background-color: #232323;
  transition: all 0.3s ease;
  border-color: #404040; }
  .dark .addcart_btm_popup .fixed_cart i {
    color: #cbcbcb; }

.dark .review-page .comnt-sec li a {
  color: #cbcbcb; }

.dark .sitemap_page ul li a {
  color: #cbcbcb; }
  .dark .sitemap_page ul li a:hover {
    color: #b5dccd; }

.dark .sitemap_page ul ul li a {
  color: #dee2e6; }

.dark .effect-cls:before, .dark .effect-cls:after {
  filter: invert(100); }

.dark .game-product .product-box .cart-info a i {
  background-color: #2b2b2b;
  transition: all 0.3s ease;
  color: #cbcbcb; }

.dark .demo-right a {
  background-color: #000000;
  transition: all 0.3s ease; }

.dark .rtl-btn,
.dark .dark-light {
  background-color: #000000;
  box-shadow: none; }

.dark .color-picker a.handle {
  background-color: #000000;
  transition: all 0.3s ease;
  color: #cbcbcb; }

.dark .setting-box .setting-title h4 {
  color: #333333; }

/*=====================
    28.Masonry
==========================*/
.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  width: 100%; }

.my-masonry-grid_column {
  padding-left: 10px;
  /* gutter size */
  padding-right: 10px;
  /* gutter size */
  background-clip: padding-box; }

/*=====================
    27.Menu css start
==========================*/
.sm, .sm ul, .sm li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left; }

.sm {
  box-sizing: border-box;
  position: relative;
  z-index: 1; }
  .sm ul {
    display: none; }
  .sm li {
    position: relative; }
  .sm > li > h1 {
    margin: 0;
    padding: 0; }
  .sm > li > h2 {
    margin: 0;
    padding: 0; }
  .sm > li > h3 {
    margin: 0;
    padding: 0; }
  .sm > li > h4 {
    margin: 0;
    padding: 0; }
  .sm > li > h5 {
    margin: 0;
    padding: 0; }
  .sm > li > h6 {
    margin: 0;
    padding: 0; }
  .sm * {
    box-sizing: inherit; }
  .sm *::before {
    box-sizing: inherit; }
  .sm *::after {
    box-sizing: inherit; }

.sm::after {
  content: '';
  display: block;
  height: 0;
  font: 0/0 serif;
  clear: both;
  overflow: hidden; }

header .main-navbar .nav-menu {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left; }
  header .main-navbar .nav-menu > li {
    position: relative;
    float: left;
    display: block; }
    header .main-navbar .nav-menu > li > a .sub-arrow {
      position: absolute;
      top: 50%;
      margin-top: -17px;
      left: auto;
      right: 15px;
      width: 34px;
      height: 34px;
      overflow: hidden;
      font: bold 16px/34px monospace !important;
      text-align: center;
      text-shadow: none;
      border-radius: 4px; }
      header .main-navbar .nav-menu > li > a .sub-arrow:before {
        content: '\f107';
        font-family: "Font Awesome 5 Free";
        position: absolute; }
    header .main-navbar .nav-menu > li .nav-submenu {
      position: absolute;
      left: 0;
      z-index: 4;
      box-shadow: 0 0 1px 0 #ebebeb;
      padding: 15px 0px 15px 0px;
      background: white; }
      header .main-navbar .nav-menu > li .nav-submenu li {
        border: 0;
        float: none;
        position: relative;
        display: block; }
        header .main-navbar .nav-menu > li .nav-submenu li .nav-sub {
          border: 0 !important;
          background: transparent;
          color: #222222;
          border-radius: 0 !important;
          font-size: 14px;
          font-weight: 400;
          text-transform: capitalize;
          padding: 5px 35px;
          display: block;
          letter-spacing: 0.07em;
          line-height: 1.9; }
          header .main-navbar .nav-menu > li .nav-submenu li .nav-sub:before {
            position: absolute;
            bottom: 0;
            content: '';
            height: 2px;
            width: 0;
            background-color: #ff4c3b;
            -webkit-transition: 0.5s ease;
            transition: 0.5s ease; }
          header .main-navbar .nav-menu > li .nav-submenu li .nav-sub .sub-arrow {
            position: absolute;
            top: 50%;
            margin-top: -17px;
            left: auto;
            right: 15px;
            width: 34px;
            height: 34px;
            overflow: hidden;
            font: bold 16px/34px monospace !important;
            text-align: center;
            text-shadow: none;
            border-radius: 4px; }
            header .main-navbar .nav-menu > li .nav-submenu li .nav-sub .sub-arrow:before {
              content: '\f105';
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              position: absolute; }
        header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
          position: absolute;
          width: 200px;
          right: -200px;
          top: 0;
          background: #fff;
          border: none;
          padding: 15px 0px 15px 0px;
          box-shadow: 0 0 1px 0 #ebebeb; }
    header .main-navbar .nav-menu > li .mega-menu-container {
      position: absolute;
      left: 0;
      z-index: 4;
      box-shadow: 0 0 1px 0 #ebebeb;
      padding: 30px;
      background: white;
      width: 100%; }
      header .main-navbar .nav-menu > li .mega-menu-container .mega-box {
        width: 20%; }
        header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 {
          font-weight: 700;
          text-transform: capitalize;
          font-size: 14px; }
        header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content {
          box-shadow: none;
          background-color: transparent;
          position: relative;
          display: block;
          padding: 0; }
          header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li {
            border: 0;
            float: none;
            position: relative;
            display: block; }
            header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
              border: 0 !important;
              background: transparent;
              color: #222222;
              border-radius: 0 !important;
              font-size: 14px;
              font-weight: 400;
              text-transform: capitalize;
              padding: 3px 0;
              display: block;
              letter-spacing: 0.07em;
              line-height: 1.9;
              position: relative; }
              header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:before {
                position: absolute;
                bottom: 0;
                content: '';
                height: 2px;
                width: 0;
                background-color: #ff4c3b;
                -webkit-transition: 0.5s ease;
                transition: 0.5s ease; }
    header .main-navbar .nav-menu > li:hover > a {
      color: #b5dccd; }
    header .main-navbar .nav-menu > li.mega-menu {
      position: unset; }

header .sidenav {
  height: 100vh;
  width: 300px;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: -300px;
  background-color: #ffffff;
  transition: all 0.5s ease; }
  header .sidenav .sidebar-back {
    padding: 20px;
    font-size: 18px;
    color: #222222;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #efefef;
    margin-bottom: 0px;
    cursor: pointer; }
  header .sidenav nav {
    background-color: #ffffff;
    z-index: 9;
    position: relative;
    height: 100vh;
    top: 0; }
  header .sidenav.open-side {
    left: 0;
    transition: all 0.5s ease; }
    header .sidenav.open-side .sidebar-overlay {
      opacity: 0.8;
      visibility: visible; }
  header .sidenav .sidebar-overlay {
    visibility: hidden;
    opacity: 0;
    background-color: #212331;
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-transition: all 1s;
    -webkit-transition: all 1s;
    transition: all 1s; }
  header .sidenav .closebtn {
    position: absolute;
    top: 0;
    font-size: 36px;
    margin-left: 50px;
    margin-top: 50px;
    color: #8c9391; }
  header .sidenav .sidebar-menu {
    background-color: #ffffff;
    display: block;
    margin: 0;
    padding: 0;
    direction: ltr;
    text-align: left; }
    header .sidenav .sidebar-menu li {
      position: relative;
      display: block;
      margin: 0;
      padding: 0;
      direction: ltr;
      text-align: left;
      float: none; }
      header .sidenav .sidebar-menu li a {
        padding: 10px 50px 20px 40px;
        position: relative;
        color: #222222;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.07em;
        display: block; }
      header .sidenav .sidebar-menu li ul {
        position: absolute;
        width: 200px;
        left: 100%;
        top: 0;
        padding: 15px 0px;
        display: none;
        background-color: #fff; }
        header .sidenav .sidebar-menu li ul li a {
          padding: 5px 35px; }
          header .sidenav .sidebar-menu li ul li a:active, header .sidenav .sidebar-menu li ul li a:focus, header .sidenav .sidebar-menu li ul li a.highlighted {
            padding: 5px 35px; }
      header .sidenav .sidebar-menu li .mega-menu {
        position: absolute;
        left: 100%;
        width: 815px !important;
        padding: 15px 30px 25px !important;
        background-color: #ffffff;
        box-shadow: 0 0 1px 0 #ebebeb; }
        header .sidenav .sidebar-menu li .mega-menu li ul {
          position: relative;
          width: 100%;
          left: inherit; }
  header .sidenav .pixelstrap li a {
    padding: 10px 50px 20px 40px; }
  header .sidenav .pixelstrap li ul li a {
    padding: 5px 35px; }
    header .sidenav .pixelstrap li ul li a:active, header .sidenav .pixelstrap li ul li a:focus, header .sidenav .pixelstrap li ul li a.highlighted {
      padding: 5px 35px; }
  header .sidenav .pixelstrap li .clothing-menu li a {
    padding: 5px 0 !important; }
    header .sidenav .pixelstrap li .clothing-menu li a:focus {
      font-size: 14px; }
    header .sidenav .pixelstrap li .clothing-menu li a:hover {
      font-size: 14px;
      padding: 5px 0; }
    header .sidenav .pixelstrap li .clothing-menu li a:focus, header .sidenav .pixelstrap li .clothing-menu li a:hover {
      padding: 5px 0 !important; }

header .layout3-menu .main-navbar .nav-menu {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left; }
  header .layout3-menu .main-navbar .nav-menu > li {
    position: relative;
    float: left;
    display: block; }
    header .layout3-menu .main-navbar .nav-menu > li.mega-menu {
      position: unset; }

.toggle-nav {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  cursor: pointer; }
  .toggle-nav i {
    font-size: 24px;
    color: #c6e4d9; }

.mega-menu-banner:hover:before {
  display: none; }

.new-tag {
  padding-left: 3px;
  color: #b5dccd;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700; }

.up-text {
  position: relative; }
  .up-text span {
    position: absolute;
    top: -5px;
    right: -10px;
    color: #b5dccd;
    font-size: 12px;
    font-weight: 700; }

.pixelstrap.sm-vertical a .sub-arrow {
  margin-top: -22px;
  right: 7px; }

.pixelstrap ul {
  border-radius: 0; }

.pixelstrap.light-font-menu li > a {
  color: #393230; }

.pixelstrap a, .pixelstrap a:hover, .pixelstrap a:active {
  padding-right: 45px;
  color: #222222;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  padding-top: 42px;
  padding-bottom: 42px;
  letter-spacing: 0.07em; }

.pixelstrap a:hover {
  color: #b5dccd; }

.pixelstrap a .sub-arrow {
  position: absolute;
  top: 50%;
  margin-top: -17px;
  left: auto;
  right: 15px;
  width: 34px;
  height: 34px;
  overflow: hidden;
  font: bold 16px/34px monospace !important;
  text-align: center;
  text-shadow: none;
  border-radius: 4px; }

.pixelstrap > li:last-child > a, .pixelstrap > li:last-child > *:not(ul) a, .pixelstrap > li:last-child > ul,
.pixelstrap > li:last-child > ul > li:last-child > a, .pixelstrap > li:last-child > ul > li:last-child > *:not(ul) a, .pixelstrap > li:last-child > ul > li:last-child > ul,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul {
  border-radius: 0 0 0 0; }

.pixelstrap > li:last-child > a.highlighted, .pixelstrap > li:last-child > *:not(ul) a.highlighted,
.pixelstrap > li:last-child > ul > li:last-child > a.highlighted, .pixelstrap > li:last-child > ul > li:last-child > *:not(ul) a.highlighted,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted,
.pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a.highlighted, .pixelstrap > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a.highlighted {
  border-radius: 0; }

.pixelstrap ul {
  background: #fff; }
  .pixelstrap ul ul {
    background: rgba(188, 223, 210, 0.04); }
  .pixelstrap ul a, .pixelstrap ul a:hover, .pixelstrap ul a:focus, .pixelstrap ul a:active {
    background: transparent;
    color: #a4d4c1;
    font-size: 14px;
    text-shadow: none; }
  .pixelstrap ul a.current {
    background: #b5dccd;
    color: #fff; }
  .pixelstrap ul ul a,
  .pixelstrap ul ul a:hover,
  .pixelstrap ul ul a:focus,
  .pixelstrap ul ul a:active {
    border-left: 16px solid transparent; }
  .pixelstrap ul ul ul a,
  .pixelstrap ul ul ul a:hover,
  .pixelstrap ul ul ul a:focus,
  .pixelstrap ul ul ul a:active {
    border-left: 24px solid transparent; }
  .pixelstrap ul ul ul ul a,
  .pixelstrap ul ul ul ul a:hover,
  .pixelstrap ul ul ul ul a:focus,
  .pixelstrap ul ul ul ul a:active {
    border-left: 32px solid transparent; }
  .pixelstrap ul ul ul ul ul a,
  .pixelstrap ul ul ul ul ul a:hover,
  .pixelstrap ul ul ul ul ul a:focus,
  .pixelstrap ul ul ul ul ul a:active {
    border-left: 40px solid transparent; }
  .pixelstrap ul li {
    border-top: 1px solid rgba(0, 0, 0, 0.05); }
    .pixelstrap ul li:first-child {
      border-top: 0; }

.pixelstrap .mega {
  position: unset !important; }
  .pixelstrap .mega .full-mega-menu.center-mega-menu {
    margin-left: 0 !important; }
  .pixelstrap .mega ul {
    margin-left: 0 !important;
    max-width: max-content; }

.pixelstrap .icon-trend {
  color: #b5dccd; }

.pixelstrap .full-mega-menu,
.pixelstrap .clothing-menu {
  max-width: inherit !important;
  padding: 30px 0 15px; }
  .pixelstrap .full-mega-menu a,
  .pixelstrap .clothing-menu a {
    padding: 0;
    position: relative; }
    .pixelstrap .full-mega-menu a h6,
    .pixelstrap .clothing-menu a h6 {
      font-size: 14px;
      text-align: center;
      transition: all 0.5s ease;
      font-weight: 400;
      text-transform: capitalize;
      color: #222222; }
    .pixelstrap .full-mega-menu a img,
    .pixelstrap .clothing-menu a img {
      display: flex;
      margin: 0 auto; }
    .pixelstrap .full-mega-menu a:hover,
    .pixelstrap .clothing-menu a:hover {
      padding: 0; }
      .pixelstrap .full-mega-menu a:hover:before,
      .pixelstrap .clothing-menu a:hover:before {
        width: 50px;
        height: 2px;
        transition: 0.5s ease; }
      .pixelstrap .full-mega-menu a:hover h6,
      .pixelstrap .clothing-menu a:hover h6 {
        transition: all 0.5s ease; }

.pixelstrap .link-section h5 {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 14px; }

.pixelstrap .link-section ul {
  position: relative;
  display: block;
  padding: 0; }
  .pixelstrap .link-section ul li a {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 14px;
    padding-left: 0; }

.pixelstrap .full-mega-menu {
  width: 100% !important;
  padding: 30px !important;
  border-radius: 0; }
  .pixelstrap .full-mega-menu .mega-box .link-section ul li a:hover, .pixelstrap .full-mega-menu .mega-box .link-section ul li a:focus {
    padding-top: 3px;
    padding-bottom: 3px; }
  .pixelstrap .full-mega-menu .img-section img {
    margin-top: 15px; }

.pixelstrap .clothing-menu {
  width: 815px !important;
  padding: 15px 30px 25px !important;
  background-color: #ffffff; }
  .pixelstrap .clothing-menu li img {
    padding: 20px 0 10px 0; }
  .pixelstrap .clothing-menu .link-section h5 {
    margin-top: 15px; }
  .pixelstrap .clothing-menu .link-section ul {
    background: none; }

.pixelstrap .mega-menu ul {
  box-shadow: none; }

.pixelstrap li a {
  position: relative; }

.pixelstrap li .lable-nav {
  position: absolute;
  background-color: #b5dccd;
  padding: 2px 7px;
  color: #ffffff;
  top: 20px;
  text-transform: uppercase;
  font-size: 9px;
  left: 0;
  line-height: 1.3; }
  .pixelstrap li .lable-nav:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #b5dccd;
    top: 15px;
    left: 5px; }
  .pixelstrap li .lable-nav.lable-red {
    background-color: #ff4c3b; }
    .pixelstrap li .lable-nav.lable-red:before {
      border-top: 5px solid #ff4c3b; }

.sm-vertical {
  background-color: #ffffff; }

.mega-box .link-section .demo ul li a {
  position: relative; }
  .mega-box .link-section .demo ul li a:hover, .mega-box .link-section .demo ul li a:focus {
    padding-top: 3px;
    padding-bottom: 3px; }

.mega-box .link-section .demo .new {
  position: absolute;
  top: -8px;
  right: 0px;
  color: white;
  background-color: #b5dccd;
  padding: 2px 7px;
  text-transform: uppercase;
  font-size: 9px;
  line-height: 1.3; }

.mega-box .link-section .demo .title {
  border-bottom: 2px solid red !important; }

.rtl .sidenav .pixelstrap li {
  text-align: right; }

.rtl .link-section {
  float: right; }

.rtl .pixelstrap li .lable-nav {
  left: unset; }

.rtl .mega-box .link-section .demo ul li a span img {
  right: unset;
  left: -120px; }

@media (min-width: 1200px) {
  .responsive-btn {
    display: none; }
  header .main-navbar .nav-menu > li .nav-submenu {
    width: 200px;
    display: none; }
    header .main-navbar .nav-menu > li .nav-submenu li .nav-sub:hover:before {
      width: 50px;
      height: 2px;
      -webkit-transition: 0.5s ease;
      transition: 0.5s ease; }
    header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
      display: none; }
    header .main-navbar .nav-menu > li .nav-submenu li:hover .nav-sub-childmenu {
      display: block; }
  header .main-navbar .nav-menu > li .mega-menu-container {
    display: none; }
    header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a:hover:before {
      width: 50px;
      height: 2px;
      -webkit-transition: 0.5s ease;
      transition: 0.5s ease; }
  header .main-navbar .nav-menu > li:hover .nav-submenu {
    display: block; }
  header .main-navbar .nav-menu > li:hover .mega-menu-container {
    display: block; }
  header .sidenav .sidebar-menu li a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    left: auto;
    right: 7px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px; }
    header .sidenav .sidebar-menu li a .sub-arrow:before {
      content: '\f105';
      font-family: FontAwesome;
      font-weight: 900;
      position: absolute; }
  header .sidenav .sidebar-menu li > ul {
    display: none; }
    header .sidenav .sidebar-menu li > ul li a {
      padding: 5px 35px;
      text-transform: capitalize; }
      header .sidenav .sidebar-menu li > ul li a:before {
        position: absolute;
        bottom: 0;
        content: '';
        height: 2px;
        width: 0;
        background-color: #ff4c3b;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease; }
      header .sidenav .sidebar-menu li > ul li a:hover:before {
        width: 50px;
        height: 2px;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease; }
      header .sidenav .sidebar-menu li > ul li a .sub-arrow {
        margin-top: -17px; }
  header .sidenav .sidebar-menu li:hover > ul {
    display: block; }
  header .sidenav .sidebar-menu li .mega-menu {
    position: absolute;
    left: 100%;
    width: 815px !important;
    padding: 15px 30px 25px !important;
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 #ebebeb; }
    header .sidenav .sidebar-menu li .mega-menu li .link-section h5 {
      font-weight: 700;
      text-transform: capitalize;
      font-size: 14px;
      margin-top: 15px; }
    header .sidenav .sidebar-menu li .mega-menu li .link-section ul {
      position: relative;
      width: 100%;
      left: inherit;
      display: block;
      padding: 0; }
      header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a {
        padding: 5px 0; }
        header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a:before {
          position: absolute;
          bottom: 0;
          content: '';
          height: 2px;
          width: 0;
          background-color: #ff4c3b;
          -webkit-transition: 0.5s ease;
          transition: 0.5s ease; }
        header .sidenav .sidebar-menu li .mega-menu li .link-section ul li a:hover:before {
          width: 50px;
          height: 2px;
          -webkit-transition: 0.5s ease;
          transition: 0.5s ease; }
    header .sidenav .sidebar-menu li .mega-menu li .mega-menu-banner {
      padding: 0; }
  header .sidenav .pixelstrap li a {
    padding: 10px 50px 20px 40px; }
  header .sidenav .pixelstrap li ul li a {
    padding: 5px 35px; }
    header .sidenav .pixelstrap li ul li a:active, header .sidenav .pixelstrap li ul li a:focus, header .sidenav .pixelstrap li ul li a.highlighted {
      padding: 5px 35px; }
  header .sidenav .pixelstrap li .clothing-menu li a {
    padding: 5px 0 !important; }
    header .sidenav .pixelstrap li .clothing-menu li a:focus {
      font-size: 14px; }
    header .sidenav .pixelstrap li .clothing-menu li a:hover {
      font-size: 14px;
      padding: 5px 0; }
    header .sidenav .pixelstrap li .clothing-menu li a:focus, header .sidenav .pixelstrap li .clothing-menu li a:hover {
      padding: 5px 0 !important; }
  .toggle-nav {
    display: none; }
  .sm-vertical li ul li a .sub-arrow {
    margin-top: -15px !important; }
  .mobile-back {
    display: none !important; }
  .pixelstrap ul {
    position: absolute;
    width: 16em !important; }
  .pixelstrap li {
    position: relative;
    float: left; }
  .pixelstrap.sm-rtl li {
    float: right; }
  .pixelstrap ul li,
  .pixelstrap.sm-rtl ul li,
  .pixelstrap.sm-vertical li {
    float: none; }
  .pixelstrap a {
    white-space: nowrap; }
  .pixelstrap ul a,
  .pixelstrap.sm-vertical a {
    white-space: normal; }
  .pixelstrap .sm-nowrap > li > a,
  .pixelstrap .sm-nowrap > li > :not(ul) a {
    white-space: nowrap; }
    .pixelstrap .sm-nowrap > li > a.highlighted,
    .pixelstrap .sm-nowrap > li > :not(ul) a.highlighted {
      white-space: nowrap; }
  .sm-horizontal .mega-menu li a {
    padding: 0; }
    .sm-horizontal .mega-menu li a:focus, .sm-horizontal .mega-menu li a:active {
      padding: 0; }
  .pixelstrap ul {
    -webkit-box-shadow: 0 0 1px 0 #ebebeb;
    -moz-box-shadow: 0 0 1px 0 #ebebeb;
    box-shadow: 0 0 1px 0 #ebebeb; }
  .pixelstrap .mega {
    position: unset !important; }
    .pixelstrap .mega ul {
      margin-left: 0 !important; }
      .pixelstrap .mega ul ul {
        box-shadow: none; }
  .pixelstrap .full-mega-menu,
  .pixelstrap .clothing-menu {
    -webkit-box-shadow: 0 0 1px 0 #ebebeb;
    -moz-box-shadow: 0 0 1px 0 #ebebeb;
    box-shadow: 0 0 1px 0 #ebebeb; }
    .pixelstrap .full-mega-menu a,
    .pixelstrap .clothing-menu a {
      padding: 0;
      position: relative; }
  .pixelstrap .full-mega-menu {
    position: absolute;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover; }
    .pixelstrap .full-mega-menu.center-mega-menu {
      left: 50% !important;
      transform: translateX(-50%); }
    .pixelstrap .full-mega-menu.layout-3 {
      margin-left: -100px !important;
      transform: unset !important; }
    .pixelstrap .full-mega-menu ul {
      background-color: transparent; }
    .pixelstrap .full-mega-menu .mega-box {
      max-width: 20%; }
  .pixelstrap .category-menu.center-menu {
    left: 50% !important;
    transform: translateX(-50%); }
  .pixelstrap a .sub-arrow::before {
    content: '\f107';
    font-family: "Font Awesome 5 Free";
    position: absolute; }
  .pixelstrap ul {
    border: none;
    padding: 15px 0px 15px 0px;
    background: white; }
    .pixelstrap ul ul {
      background: white; }
    .pixelstrap ul a {
      position: relative; }
      .pixelstrap ul a:before {
        position: absolute;
        bottom: 0;
        content: '';
        height: 2px;
        width: 0;
        background-color: #b5dccd;
        transition: 0.5s ease; }
      .pixelstrap ul a, .pixelstrap ul a:hover, .pixelstrap ul a:focus, .pixelstrap ul a:active, .pixelstrap ul a.highlighted {
        border: 0 !important;
        background: transparent;
        color: #222222;
        border-radius: 0 !important;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        padding: 5px 35px; }
        .pixelstrap ul a:hover:before, .pixelstrap ul a:hover:hover:before, .pixelstrap ul a:focus:hover:before, .pixelstrap ul a:active:hover:before, .pixelstrap ul a.highlighted:hover:before {
          width: 50px;
          height: 2px;
          transition: 0.5s ease; }
      .pixelstrap ul a .sub-arrow:before {
        content: '\f105';
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute; }
    .pixelstrap ul li {
      border: 0; }
  .pixelstrap .scroll-up,
  .pixelstrap .scroll-down {
    position: absolute;
    display: none;
    visibility: hidden;
    overflow: hidden;
    background: #fff;
    height: 20px; }
  .pixelstrap .scroll-up-arrow,
  .pixelstrap .scroll-down-arrow {
    position: absolute;
    top: -2px;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    overflow: hidden;
    border-width: 8px;
    border-style: dashed dashed solid dashed;
    border-color: transparent transparent #a4d4c1 transparent; }
  .pixelstrap .scroll-down-arrow {
    top: 6px;
    border-style: solid dashed dashed dashed;
    border-color: #a4d4c1 transparent transparent transparent; }
  .pixelstrap.sm-rtl.sm-vertical a .sub-arrow:before {
    content: '\f105';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute; }
  .pixelstrap.sm-rtl > li:first-child {
    border-left: 1px solid #a4d4c1; }
  .pixelstrap.sm-rtl > li:last-child {
    border-left: 0; }
  .pixelstrap.sm-rtl ul a .sub-arrow {
    right: auto;
    left: 10px; }
  .pixelstrap.sm-vertical a {
    padding: 9px 23px; }
    .pixelstrap.sm-vertical a .sub-arrow:before {
      content: '\f105';
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      position: absolute; }
  .pixelstrap.sm-vertical > li {
    border-left: 0 !important; }
  .main-nav-center > nav {
    text-align: center; }
  .main-nav-center .sm {
    display: inline-block;
    vertical-align: middle; }
    .main-nav-center .sm > li .lable-nav {
      top: 3px; }
    .main-nav-center .sm > li > a {
      padding-top: 20px;
      padding-bottom: 20px; }
  .layout3-menu .pixelstrap a {
    text-transform: capitalize;
    padding-right: 34px;
    letter-spacing: 0.04em; }
  .layout3-menu .pixelstrap .sub-arrow {
    right: 12px; }
  .rtl .pixelstrap .category-menu {
    width: 698px !important; }
    .rtl .pixelstrap .category-menu.center-menu {
      left: 0 !important;
      transform: unset; }
  .rtl .pixelstrap .full-mega-menu {
    right: auto !important; }
  .rtl .pixelstrap ul a:before {
    right: 0; }
  .rtl .pixelstrap ul a:hover, .rtl .pixelstrap ul a:focus, .rtl .pixelstrap ul a:active, .rtl .pixelstrap ul a.highlighted {
    padding-right: 0; }
  .rtl .mega .full-mega-menu {
    float: left !important;
    left: 50% !important;
    right: auto !important; }
    .rtl .mega .full-mega-menu.center-mega-menu {
      margin-right: 0 !important;
      margin-left: 0 !important;
      left: unset !important;
      right: 50% !important; }
  .rtl .layout3-menu .light-font-menu li > a, .rtl .layout3-menu .light-font-menu li > a:hover, .rtl .layout3-menu .light-font-menu li > a:active {
    padding-right: 0;
    padding-left: 28px; }
  .rtl .layout3-menu .light-font-menu .sub-arrow {
    left: -5px;
    right: auto; }
  .rtl .sm-horizontal .mega ul {
    float: right;
    left: 50% !important;
    right: 0; }
    .rtl .sm-horizontal .mega ul li {
      text-align: right; }
  .rtl .sm-horizontal ul li {
    text-align: right; }
    .rtl .sm-horizontal ul li a .sub-arrow {
      left: 15px;
      right: auto; }
  .rtl .pixelstrap .full-mega-menu.layout-3 {
    margin-right: -100px !important; }
  .rtl .light-font-menu .mega ul {
    float: right;
    left: unset !important;
    right: 0 !important; }
  .rtl .sidenav .pixelstrap li ul li a:hover, .rtl .sidenav .pixelstrap li ul li a:focus {
    padding: 5px 35px; }
  .rtl .sidenav .pixelstrap li ul li a:before {
    right: 35px; }
  .rtl .sidenav .pixelstrap li a .sub-arrow {
    right: 7px;
    left: unset; }
  .rtl .sidenav .pixelstrap .mega-menu li ul li a:before {
    right: 0; }
  .rtl .sidenav .sidebar-back i {
    float: left; }
  .rtl header.header-5 .top-header .header-dropdown > li:last-child {
    padding: 15px 25px;
    padding-left: 0; } }

@media (max-width: 1440px) {
  .pixelstrap .category-menu {
    width: 698px !important; }
  .pixelstrap .full-mega-menu.layout-3 {
    margin-left: 0 !important; }
  .rtl .pixelstrap .mega .full-mega-menu {
    margin-left: 0 !important;
    margin-right: 162px !important; }
    .rtl .pixelstrap .mega .full-mega-menu.layout-3 {
      margin-right: 0 !important; } }

@media (max-width: 1367px) {
  .pixelstrap .full-mega-menu.layout-3 {
    width: 1079px !important; }
  .pixelstrap a {
    font-size: 14px; }
    .pixelstrap a:hover, .pixelstrap a:active {
      font-size: 14px; } }

@media (max-width: 576px) {
  header .main-navbar .nav-menu {
    position: fixed;
    padding: 0 0.7rem 0 0.7rem;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #eee;
    top: 0;
    height: 100vh;
    right: -300px;
    z-index: 99;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow: scroll; }
    header .main-navbar .nav-menu.opennav {
      right: 0; }
    header .main-navbar .nav-menu .back-btn {
      display: block;
      width: 100%; }
      header .main-navbar .nav-menu .back-btn .mobile-back {
        padding: 20px;
        font-size: 18px;
        color: #222222;
        font-weight: 700;
        text-transform: uppercase;
        border-bottom: 1px solid #efefef;
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
    header .main-navbar .nav-menu > li {
      display: block;
      float: none;
      width: 100%; }
      header .main-navbar .nav-menu > li a {
        padding: 10px 15px;
        font-size: 16px;
        font-weight: 700;
        position: relative; }
        header .main-navbar .nav-menu > li a .sub-arrow {
          right: 0; }
          header .main-navbar .nav-menu > li a .sub-arrow:before {
            content: '+';
            font-family: inherit;
            position: relative; }
      header .main-navbar .nav-menu > li .nav-submenu {
        width: 100%;
        padding: 0;
        position: relative;
        display: none; }
        header .main-navbar .nav-menu > li .nav-submenu.opensubmenu {
          display: block; }
        header .main-navbar .nav-menu > li .nav-submenu li .nav-sub {
          text-transform: uppercase; }
          header .main-navbar .nav-menu > li .nav-submenu li .nav-sub .sub-arrow {
            right: 0; }
            header .main-navbar .nav-menu > li .nav-submenu li .nav-sub .sub-arrow:before {
              content: '+';
              font-family: inherit;
              position: relative; }
        header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu {
          display: none;
          position: relative;
          right: 0;
          width: 100%;
          padding: 0;
          background: rgba(255, 85, 69, 0.04); }
          header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu.opensubchild {
            display: block; }
          header .main-navbar .nav-menu > li .nav-submenu li .nav-sub-childmenu li a {
            padding: 5px 35px 5px 50px; }
  header .sidenav .sidebar-menu li a {
    padding: 10px 25px;
    font-weight: 700; }
    header .sidenav .sidebar-menu li a .sub-arrow {
      position: absolute;
      top: 50%;
      margin-top: -17px;
      left: auto;
      right: 15px;
      width: 34px;
      height: 34px;
      overflow: hidden;
      font: bold 16px/34px monospace !important;
      text-align: center;
      text-shadow: none;
      border-radius: 4px; }
      header .sidenav .sidebar-menu li a .sub-arrow:before {
        content: '+';
        font-family: inherit;
        position: relative; }
  header .sidenav .sidebar-menu li ul {
    position: relative;
    left: 0;
    width: 100%;
    display: none;
    padding: 5px 0; }
    header .sidenav .sidebar-menu li ul.opensub1 {
      display: block; }
    header .sidenav .sidebar-menu li ul li a {
      font-size: 12px;
      font-weight: 400; }
    header .sidenav .sidebar-menu li ul li ul {
      display: none;
      background: rgba(255, 85, 69, 0.04); }
      header .sidenav .sidebar-menu li ul li ul.opensub2 {
        display: block; }
      header .sidenav .sidebar-menu li ul li ul li a {
        padding: 5px 45px; }
      header .sidenav .sidebar-menu li ul li ul li ul {
        display: none;
        background: rgba(255, 85, 69, 0.04); }
        header .sidenav .sidebar-menu li ul li ul li ul.opensub3 {
          display: block; }
        header .sidenav .sidebar-menu li ul li ul li ul li ul {
          display: none; }
          header .sidenav .sidebar-menu li ul li ul li ul li ul.opensub4 {
            display: block; }
  header .sidenav .sidebar-menu li .mega-menu {
    width: 100% !important;
    padding: 0 30px !important;
    position: relative;
    display: none;
    left: 0; }
    header .sidenav .sidebar-menu li .mega-menu.opensidesubmenu {
      display: block;
      padding: 10px 30px 0 !important;
      box-shadow: none; }
    header .sidenav .sidebar-menu li .mega-menu .link-section h5 {
      font-weight: 700;
      font-size: 14px;
      text-transform: capitalize; }
    header .sidenav .sidebar-menu li .mega-menu .link-section ul {
      display: block;
      padding: 0;
      background: #fff; }
      header .sidenav .sidebar-menu li .mega-menu .link-section ul li a {
        padding: 5px 0px 5px 10px !important;
        font-size: 12px;
        font-weight: 400; }
    header .sidenav .sidebar-menu li .mega-menu .mega-menu-banner {
      padding: 5px 0; }
      header .sidenav .sidebar-menu li .mega-menu .mega-menu-banner img {
        padding: 20px 0 0; }
  .mega-box .link-section .demo ul li a span img {
    top: 30px;
    right: -60px; }
  .sm-horizontal {
    position: fixed;
    background-color: #fff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #eee;
    top: 0;
    height: 100vh;
    right: -300px;
    z-index: 99;
    transition: all 0.3s ease;
    overflow: scroll; }
    .sm-horizontal .mobile-back {
      padding: 20px;
      font-size: 18px;
      color: #222222;
      font-weight: 700;
      text-transform: uppercase;
      border-bottom: 1px solid #efefef;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
  .mega-box.col {
    flex-basis: unset; }
  .pixelstrap .feature-menu.product-menu {
    width: 100% !important; }
  .pixelstrap .mega {
    position: unset !important; }
    .pixelstrap .mega .full-mega-menu {
      margin-left: 0 !important; }
    .pixelstrap .mega ul {
      margin-left: 0 !important; }
  .pixelstrap .full-mega-menu,
  .pixelstrap .clothing-menu {
    width: 100% !important;
    padding: 0 30px !important; }
  .pixelstrap a.highlighted .sub-arrow:before {
    content: '-'; }
  .pixelstrap a .sub-arrow {
    right: 0; }
    .pixelstrap a .sub-arrow:before {
      content: '+'; }
  .pixelstrap > li > a {
    font-weight: 700; }
    .pixelstrap > li > a:hover, .pixelstrap > li > a:focus, .pixelstrap > li > a:active {
      font-weight: 700; }
  .pixelstrap .link-section .menu-title h5 {
    cursor: pointer; }
  .pixelstrap .link-section .menu-title.active .according-menu {
    font: bold 16px/34px monospace !important; }
    .pixelstrap .link-section .menu-title.active .according-menu:before {
      content: '-';
      position: absolute;
      right: -18px; }
  .pixelstrap .link-section .menu-title .according-menu {
    font: bold 16px/34px monospace !important; }
    .pixelstrap .link-section .menu-title .according-menu:before {
      content: '+';
      position: absolute;
      right: -18px; }
  .pixelstrap .link-section .menu-content a {
    text-transform: capitalize; }
  .pixelstrap .link-section ul {
    background-color: unset; }
  .pixelstrap .link-section h5 {
    font-weight: 700; }
  .pixelstrap .full-mega-menu .mega-box {
    border-right: none;
    margin-bottom: 0; }
  .pixelstrap .category-menu a img {
    width: 30% !important; }
  .pixelstrap .clothing-menu {
    background: none; }
  .pixelstrap li .lable-nav {
    left: 15px;
    top: -8px; }
  .pixelstrap li a {
    padding: 10px 15px;
    font-size: 16px; }
    .pixelstrap li a:hover {
      font-size: 16px;
      padding: 10px 15px; }
  .pixelstrap li ul li a {
    padding: 5px 35px;
    font-size: 14px;
    color: #222222;
    font-weight: 400; }
    .pixelstrap li ul li a:hover {
      color: #b5dccd;
      font-size: 14px;
      padding: 5px 35px; }
  .pixelstrap .category-menu a:hover img {
    transform: scale(1); }
  .pixelstrap.sm-vertical a .sub-arrow {
    margin-top: -18px; }
  .pixelstrap .mega ul {
    max-width: 100%; }
  .pixelstrap ul li {
    border-top: none; }
  .sidenav {
    overflow-y: auto; }
    .sidenav .pixelstrap li ul li a {
      padding: 5px 45px; }
      .sidenav .pixelstrap li ul li a:hover, .sidenav .pixelstrap li ul li a.highlighted {
        padding: 5px 45px; }
    .sidenav .pixelstrap li a {
      padding: 10px 25px; }
      .sidenav .pixelstrap li a:hover {
        padding: 10px 25px; }
  .main-menu.border-section {
    border: none; }
  .main-menu .menu-left .main-menu-right .toggle-nav {
    position: absolute;
    z-index: 2;
    right: 145px;
    top: 6px; }
    .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
      font-size: 18px;
      padding-left: 5px;
      text-transform: uppercase; }
  .main-nav-center .toggle-nav {
    position: absolute;
    z-index: 99;
    right: 175px;
    top: -105px; }
  .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
    right: unset;
    top: -1px; }
  .rtl .mega-box .link-section .demo ul li a span img {
    left: -70px; }
  .rtl .sm li {
    text-align: right; }
  .rtl .pixelstrap a .sub-arrow {
    right: unset;
    left: 0; }
  .rtl .pixelstrap li .lable-nav {
    left: unset;
    right: 15px; }
    .rtl .pixelstrap li .lable-nav:before {
      left: unset;
      right: 5px; } }

@media (max-width: 991px) {
  .top-header .header-contact {
    display: none; } }

@media (min-width: 578px) {
  .main-menu .menu-right .icon-nav .mobile-search i,
  .main-menu .menu-right .icon-nav .mobile-cart i,
  .main-menu .menu-right .icon-nav .mobile-setting i {
    display: none; }
  .main-menu .menu-right .icon-nav .mobile-search .close-circle i,
  .main-menu .menu-right .icon-nav .mobile-cart .close-circle i,
  .main-menu .menu-right .icon-nav .mobile-setting .close-circle i {
    display: block; }
  .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
    display: inline-block; } }

@media (max-width: 577px) {
  .top-header {
    height: 0; }
  .main-nav-center .toggle-nav {
    right: 15px;
    top: -85px; }
  .mobile-fix-option {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 60px;
    background-color: #333333;
    width: 100vw;
    z-index: 9;
    box-shadow: 0 2px 5px 0 #a8a8a8; }
  .header-5 .top-header .header-dropdown .mobile-wishlist img,
  .header-5 .top-header .header-dropdown .mobile-account img {
    filter: brightness(5); }
  .header-5 .main-menu .brand-logo {
    margin: 0;
    left: 0;
    transform: unset; }
  .top-header .header-dropdown .mobile-account,
  .top-header .header-dropdown .mobile-wishlist {
    position: fixed;
    bottom: 20px;
    right: 32%;
    font-size: 0;
    padding: 0;
    z-index: 9; }
    .top-header .header-dropdown .mobile-account i,
    .top-header .header-dropdown .mobile-wishlist i {
      font-size: 22px;
      font-family: themify;
      color: #999999; }
      .top-header .header-dropdown .mobile-account i:before,
      .top-header .header-dropdown .mobile-wishlist i:before {
        content: '\e602'; }
    .top-header .header-dropdown .mobile-account .onhover-show-div,
    .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
      top: unset;
      bottom: 42px;
      right: 0; }
    .top-header .header-dropdown .mobile-account:hover i,
    .top-header .header-dropdown .mobile-wishlist:hover i {
      color: #999999; }
  .top-header .header-dropdown .mobile-wishlist {
    right: 64%; }
    .top-header .header-dropdown .mobile-wishlist i {
      font-family: themify;
      color: #999999 !important; }
      .top-header .header-dropdown .mobile-wishlist i:before {
        content: '\e634'; }
  .main-menu .menu-left.category-nav-right .navbar {
    padding-left: 0 !important; }
  .main-menu .menu-left .navbar {
    padding: 30px 45px 30px 0 !important; }
  .main-menu .menu-right .icon-nav .mobile-setting,
  .main-menu .menu-right .icon-nav .mobile-cart,
  .main-menu .menu-right .icon-nav .mobile-search,
  .main-menu .menu-right .icon-nav .mobile-globe {
    position: fixed;
    bottom: 20px;
    font-size: 0;
    padding: 0; }
    .main-menu .menu-right .icon-nav .mobile-setting .show-div,
    .main-menu .menu-right .icon-nav .mobile-cart .show-div,
    .main-menu .menu-right .icon-nav .mobile-search .show-div,
    .main-menu .menu-right .icon-nav .mobile-globe .show-div {
      top: -58px;
      left: 0; }
      .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
      .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
      .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart,
      .main-menu .menu-right .icon-nav .mobile-globe .show-div.shopping-cart {
        top: unset;
        bottom: 42px;
        left: -118px !important; }
  .main-menu .menu-right .icon-nav .mobile-setting i {
    display: inline-block;
    font-size: 22px;
    color: #999999; }
  .main-menu .menu-right .icon-nav .mobile-setting .setting {
    top: unset;
    bottom: 42px;
    left: unset !important; }
  .main-menu .menu-right .icon-nav .mobile-cart {
    right: 48%; }
    .main-menu .menu-right .icon-nav .mobile-cart .close-circle i {
      font-size: 14px; }
    .main-menu .menu-right .icon-nav .mobile-cart i {
      display: inline-block;
      font-size: 22px;
      color: #999999; }
    .main-menu .menu-right .icon-nav .mobile-cart > div img {
      display: none; }
  .main-menu .menu-right .icon-nav .mobile-search {
    right: 80%;
    z-index: 1; }
    .main-menu .menu-right .icon-nav .mobile-search i {
      display: inline-block;
      font-size: 22px;
      color: #999999; }
    .main-menu .menu-right .icon-nav .mobile-search img {
      display: none; }
    .main-menu .menu-right .icon-nav .mobile-search .show-div .form-group {
      margin-bottom: 0; }
    .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
      font-size: 14px; }
  .brand-logo {
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translateX(-50%);
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .toggle-nav {
    padding-top: 30px;
    padding-bottom: 30px; }
  .rtl .main-nav-center .toggle-nav {
    right: unset;
    left: 15px; }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting,
  .rtl .main-menu .menu-right .icon-nav .mobile-cart,
  .rtl .main-menu .menu-right .icon-nav .mobile-search {
    right: unset;
    left: 16%; }
    .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div,
    .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div,
    .rtl .main-menu .menu-right .icon-nav .mobile-search .show-div {
      left: unset;
      right: 0; }
      .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
      .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
      .rtl .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart {
        left: unset !important;
        right: -118px !important; }
  .rtl .main-menu .menu-right .icon-nav .mobile-setting .setting {
    left: unset !important; }
  .rtl .main-menu .menu-right .icon-nav .mobile-cart {
    right: unset;
    left: 48%; }
  .rtl .main-menu .menu-right .icon-nav .mobile-search {
    right: unset;
    left: 80%; }
  .rtl .main-menu .menu-left .navbar {
    padding: 30px 0 30px 25px !important; }
  .rtl .top-header .header-dropdown .mobile-account,
  .rtl .top-header .header-dropdown .mobile-wishlist {
    right: unset;
    left: 32%; }
    .rtl .top-header .header-dropdown .mobile-account .onhover-show-div,
    .rtl .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
      right: 0; }
  .rtl .top-header .header-dropdown .mobile-wishlist {
    right: unset;
    left: 64%; } }

@media (max-width: 480px) {
  .brand-logo img {
    height: 25px; } }

/*=====================
    27.Responsive CSS start
==========================*/
@media (min-width: 1430px) {
  .container {
    max-width: 1400px; } }

@media (max-width: 1430px) {
  .banner-slider .height-banner {
    height: unset; }
  .pet-parallax .pet-decor {
    left: 60px;
    bottom: -125px; }
    .pet-parallax .pet-decor img {
      width: 240px; }
  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    width: 100%;
    border-left: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    text-align: center; }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    width: 20%;
    padding-right: 0 !important;
    border-left: 1px solid #dddddd; }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    padding: 20px !important;
    padding-left: 0 !important;
    width: 20%; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li:last-child {
      display: none; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    border-right: 1px solid #dddddd;
    width: 60%; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    width: 30%; }
  .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
    width: 10%; }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    width: 25%; }
  .cart-section .cart-buttons > div:last-child {
    padding-right: 38px; }
  .cart-section tbody tr td {
    min-width: 175px; }
    .cart-section tbody tr td .qty-box .input-group .form-control {
      width: 75px; }
  .cart-section tfoot tr td {
    padding-right: 41px; }
  .product-right .product-icon .product-social li {
    padding-right: 10px; }
  .product-form-box .timer {
    padding-left: 17px; }
    .product-form-box .timer span {
      width: 55px; }
  .rtl .cart-section .cart-buttons > div:last-child,
  .rtl .wishlist-section .cart-buttons > div:last-child {
    padding-left: 41px; }
  .rtl .collection-product-wrapper .product-filter-content .collection-view {
    border-left: none;
    border-right: 1px solid #dddddd; }
  .rtl .collection-product-wrapper .product-filter-content .product-page-filter {
    border-left: 1px solid #dddddd;
    border-right: none; }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    border-right: 1px solid #dddddd;
    padding-right: 20px; }
    .rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5 {
      text-align: center; }
  .rtl .product-right .product-icon .product-social li {
    padding-left: 10px; }
  .rtl header.left-header .sidenav {
    right: -300px;
    left: unset; }
    .rtl header.left-header .sidenav.open-side {
      right: 0;
      left: unset; }
  .rtl header.left-header .top-header {
    padding-right: 0; }
  .rtl .left-sidebar_space {
    padding-right: 0;
    padding-left: 0; }
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    border-color: #404040; }
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    border-color: #404040; } }

@media (max-width: 1430px) and (min-width: 1368px) {
  .banner-timer .timer {
    margin-top: 45px; } }

@media (max-width: 1430px) and (min-width: 1200px) {
  .layout3-menu {
    max-width: 100%; }
    .layout3-menu .main-menu .menu-left .navbar {
      padding: 40px 10px 40px 0; }
    .layout3-menu .pixelstrap > a {
      padding-right: 38px; }
  .portfolio-section.metro-section .product-box .cart-wrap a i {
    padding: 5px; }
  .portfolio-section.metro-section .product-box .cart-wrap i {
    font-size: 14px; }
  .portfolio-section.metro-section .product-box .cart-wrap button i {
    padding: 5px; }
  .rtl .layout3-menu .main-menu .menu-left .navbar {
    padding: 40px 0 40px 35px; } }

@media (max-width: 1367px) {
  h2 {
    font-size: 32px; }
  .title1 .title-inner1 {
    padding-bottom: 10px; }
    .title1 .title-inner1:after {
      height: 2px; }
  .title3 .line {
    height: 3px; }
    .title3 .line:after, .title3 .line:before {
      height: 3px; }
  .title3 .title-inner3 {
    margin-bottom: 10px; }
  .title4 .title-inner4 {
    padding-bottom: 15px; }
  hr.style1 {
    height: 1px;
    margin-top: 7px;
    margin-bottom: 7px; }
  .home-slider:hover .slick-prev,
  .home-slider:hover .slick-next {
    transform: scale(1.8); }
  .product-box .product-detail .rating i,
  .product-box .product-info .rating i,
  .product-wrap .product-detail .rating i,
  .product-wrap .product-info .rating i {
    padding-right: 3px; }
  .blog-details h4 {
    margin-top: 20px; }
  .blog-details p {
    font-size: 1.5rem; }
  .service-block h4 {
    font-size: 16px;
    margin-bottom: 5px; }
  .banner-timer {
    background-position: right; }
    .banner-timer .banner-text h2 {
      font-size: 28px; }
  .collection-product-wrapper .product-wrapper-grid .product-five {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; }
  .subscribe-form .form-control {
    width: 215px; }
  .category-bg .image-block.even:after {
    bottom: 91%; }
  .category-bg .contain-block h2 {
    font-size: 30px; }
  .category-bg .contain-block.even:after {
    top: 91%; }
  .category-bg .contain-block .category-btn {
    margin-bottom: 20px;
    margin-top: 20px; }
  .category-bg .contain-block:after {
    bottom: 91%; }
  .full-banner .banner-contain h2 {
    font-size: 90px; }
  .full-banner .banner-contain h3 {
    font-size: 55px; }
  .full-banner .banner-contain h4 {
    font-size: 24px;
    padding-top: 8px; }
  .full-banner .banner-contain .color {
    padding-top: 0; }
  .collection-banner .contain-banner.banner-3 {
    padding-left: 45px;
    padding-right: 45px; }
    .collection-banner .contain-banner.banner-3 h2 {
      font-size: 30px; }
  .collection-banner .contain-banner.banner-4 {
    padding-left: 45px;
    padding-right: 45px; }
    .collection-banner .contain-banner.banner-4 h2 {
      font-size: 28px; }
  .collection-banner .contain-banner h2 {
    font-size: 55px;
    letter-spacing: 0.01em; }
  .collection-banner .contain-banner h4 {
    letter-spacing: 0.01em; }
  .footer-theme2 .contact-details li {
    padding: 0 40px; }
  .footer-theme2 .footer-mobile-title {
    display: none !important; }
  .footer-theme .sub-title h4 {
    font-size: 16px; }
  .footer-theme .sub-title .contact-list li {
    line-height: 20px; }
  .footer-theme .sub-title .contact-list i {
    top: 17px; }
  .theme-card .offer-slider .media .media-body h4 {
    margin-top: 10px; }
  .theme-card .offer-slider .media .media-body a h6 {
    margin-right: 50px; }
  .full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
    margin-right: 0; }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 160px; }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
    margin-top: 15px; }
  .blog-page .blog-media .blog-right h4 {
    line-height: 1.2;
    margin-bottom: 10px; }
  .blog-page .blog-media .blog-right h6 {
    margin-bottom: 5px; }
  .blog-page .blog-media .blog-right ul {
    margin-bottom: 10px; }
  .blog-page .blog-media .blog-right p {
    line-height: 1.4; }
  .pro_sticky_info {
    padding: 10px; }
  .is_stuck {
    margin-top: 30px; } }

@media (max-width: 1199px) {
  .top-header .header-contact {
    display: none; }
  .pet-parallax .pet-decor {
    left: 45px;
    bottom: -105px; }
    .pet-parallax .pet-decor img {
      width: 200px; }
  header.header-6 .mobile-search {
    display: inline-block; }
  header.header-6.sticky.fixed .toggle-nav {
    padding-top: 40px;
    padding-bottom: 40px; }
  .sticky.fixed .pixelstrap > li > a,
  .sticky.fixed .pixelstrap > li > a:hover,
  .sticky.fixed .pixelstrap > li > a:active {
    padding: 10px 15px; }
  .sticky.fixed .sidenav .pixelstrap > li > a,
  .sticky.fixed .sidenav .pixelstrap > li > a:hover,
  .sticky.fixed .sidenav .pixelstrap > li > a:active {
    padding: 9px 23px; }
  .footer-light .subscribe p {
    line-height: 1.2; }
  .footer-social,
  .social-white {
    margin-top: 25px; }
  .timer span {
    width: 60px; }
  .collection-filter-block .product-service .media svg {
    height: 30px;
    width: 30px; }
  section,
  .section-t-space {
    padding-top: 1rem; }
  .section-b-space {
    padding-bottom: 60px; }
  .partition1 {
    margin-bottom: -25px; }
    .partition1 > div {
      margin-bottom: 25px; }
  .title1 h4 {
    padding-bottom: 8px; }
  .title1 .title-inner1 {
    padding-bottom: 12px;
    margin-bottom: 25px; }
  .title2 h4 {
    padding-bottom: 8px; }
  .title2 .title-inner2 {
    margin-bottom: 25px; }
  .title3 h4 {
    padding-bottom: 8px; }
  .title3 .title-inner3 {
    margin-bottom: 10px; }
  .title3 .line {
    margin-bottom: 25px; }
  .title4 .title-inner4 {
    padding-bottom: 15px; }
  .title4 .line {
    margin-bottom: 25px; }
  .title-borderless {
    margin-bottom: 25px; }
  .about-text p {
    margin-bottom: 25px; }
  .banner-timer .timer {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 30px;
    bottom: 8px;
    margin-top: 38px; }
  .footer-social li {
    padding-right: 38px; }
  .footer-theme2 p {
    line-height: 25px; }
  .footer-theme2 .subscribe-block {
    padding: 10px 70px; }
  .footer-theme2 .footer-mobile-title {
    display: none !important; }
  .footer-theme2 .contact-details li {
    line-height: 25px;
    padding: 0 10px; }
  .footer-theme2 .footer-link li {
    padding-right: 25px; }
  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 50px; }
  .social-white li {
    padding-left: 12px;
    padding-right: 12px; }
  .service-block svg,
  .service-block1 svg {
    width: 50px;
    height: 50px; }
  .service-block1 svg {
    margin-bottom: 15px; }
  .category-border div .category-banner .category-box h2 {
    padding: 13px 27px; }
  .category-bg .image-block.even:after {
    bottom: 87%; }
  .category-bg .contain-block.even:after {
    top: 87%; }
  .category-bg .contain-block:after {
    bottom: 87%; }
  .no-slider .product-box {
    flex: 0 0 33.33%;
    max-width: calc(33.33% - 30px);
    margin: 0 15px 30px; }
    .no-slider .product-box:nth-last-child(-n + 4) {
      margin: 0 15px 30px; }
    .no-slider .product-box:nth-last-child(-n + 3) {
      margin: 0 15px 0; }
  .theme-tab .tab-title2 {
    font-size: 22px; }
    .theme-tab .tab-title2:after {
      top: 17px; }
  .theme-tab .tab-content .product-tab .tab-box {
    flex: 0 0 50%;
    max-width: calc(50% - 10px); }
    .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n + 2) {
      margin: 0 5px 0; }
    .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n + 4) {
      margin: 0 5px 10px; }
    .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
      height: 280px; }
  .full-banner .banner-contain h2 {
    font-size: 85px; }
  .full-banner .banner-contain h3 {
    font-size: 50px; }
  .full-banner .banner-contain h4 {
    font-size: 22px;
    padding-top: 5px; }
  .collection-banner .contain-banner.banner-3 {
    padding-left: 35px;
    padding-right: 35px; }
    .collection-banner .contain-banner.banner-3 h2 {
      font-size: 25px; }
    .collection-banner .contain-banner.banner-3 h4 {
      font-size: 12px; }
  .collection-banner .contain-banner.banner-4 {
    padding-left: 35px;
    padding-right: 35px; }
    .collection-banner .contain-banner.banner-4 h2 {
      font-size: 24px; }
    .collection-banner .contain-banner.banner-4 h4 {
      font-size: 16px; }
  .collection-banner .contain-banner h2 {
    font-size: 40px; }
  .home-slider .home {
    height: 70vh; }
  .home-slider .slider-contain {
    height: 70vh; }
    .home-slider .slider-contain h1 {
      font-size: 50px; }
  .background .contain-bg {
    padding-top: 30px;
    padding-bottom: 30px; }
  .theme-card .offer-slider .media .media-body a h6 {
    margin-right: 20px; }
  .theme-card .offer-slider .media .media-body .rating i {
    padding-right: 3px; }
  .theme-card.card-border .offer-slider {
    padding-top: 0;
    padding-bottom: 0; }
  .full-box .theme-card .offer-slider img {
    padding: 15px 0 15px 0; }
  .full-box .theme-card .offer-slider .product-box2 .media img {
    height: 250px; }
  .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant {
    margin-top: 5px; }
    .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
      margin-top: 5px; }
  .home-slider.fullpage .home .slider-contain h1 {
    font-size: 35px; }
  .home-slider.fullpage .home .slider-contain p {
    max-width: 450px;
    font-size: 14px; }
  .home-slider.fullpage .home .slider-contain .btn-solid {
    padding: 10px 15px; }
  .about-section h2 {
    font-size: 22px; }
  .about-section .about-text p {
    line-height: 24px; }
  .about-section .service .service-block1 svg {
    margin-bottom: 5px; }
  .about-section .service .service-block1 h5 {
    line-height: 18px; }
  .about-section .small-section {
    padding-top: 20px; }
  .layout7-product .product-box .details-product {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px; }
  .padding-bottom-cls {
    padding-bottom: 30px; }
  .layout-8 {
    padding: 0 40px; }
  .blog-detail-page .comment-section li {
    padding-top: 45px;
    padding-bottom: 45px; }
  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    width: 100%;
    border-left: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd; }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
    width: 20%;
    padding-right: 0 !important;
    border-left: 1px solid #dddddd; }
  .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    padding: 20px !important;
    padding-left: 0 !important;
    width: 20%; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li:last-child {
      display: none; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    width: 30%; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    width: 30%; }
  .testimonial .testimonial-slider .media .media-body {
    padding: 30px; }
    .testimonial .testimonial-slider .media .media-body p {
      line-height: 1.5; }
  .search-product > div:nth-last-child(1), .search-product > div:nth-last-child(2), .search-product > div:nth-last-child(3) {
    margin-top: 30px; }
  .cart-section tbody tr td {
    min-width: 140px; }
    .cart-section tbody tr td .qty-box .input-group .form-control {
      width: 52px; }
  .cart-section tfoot tr td {
    padding-right: 25px; }
  .blog-page .blog-media {
    margin-bottom: 20px; }
    .blog-page .blog-media .blog-right {
      display: block;
      margin-top: 15px; }
      .blog-page .blog-media .blog-right p {
        line-height: 1.3; }
  .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:first-child {
    margin-top: 15px; }
  .collection-filter-block .product-service .media .media-body h4 {
    font-size: 16px; }
  .product-right h2 {
    font-size: 20px; }
  .tab-product .tab-content.nav-material .single-product-tables table {
    width: 50%; }
  .product-description-box .border-product {
    padding-top: 10px;
    padding-bottom: 15px; }
  .product-description-box .product-icon .product-social li {
    padding-right: 4px; }
  .product-description-box .product-icon .wishlist-btn span {
    padding-left: 4px; }
  .product-form-box .border-product {
    padding-top: 10px;
    padding-bottom: 15px; }
  .product-form-box .timer {
    padding-left: 17px; }
    .product-form-box .timer span {
      width: 55px; }
  .product-form-box .product-buttons .btn-solid,
  .product-form-box .product-buttons .btn-outline {
    padding: 7px 13px; }
  .product-accordion .single-product-tables table {
    width: 70%; }
  .tab-product .flex-column {
    flex-direction: unset !important;
    justify-content: center; }
  .tab-product .nav-material.nav-tabs {
    border-bottom: 1px solid #dddddd;
    border-right: 0; }
    .tab-product .nav-material.nav-tabs .nav-item .nav-link.active {
      background-color: unset; }
  .tab-border {
    padding-top: 0; }
  .rtl header .main-navbar .nav-menu > li .nav-sub {
    text-align: right; }
    .rtl header .main-navbar .nav-menu > li .nav-sub .sub-arrow {
      left: 0;
      right: auto; }
  .rtl header .main-navbar .nav-menu > li .nav-submenu li .nav-sub .sub-arrow {
    right: auto;
    left: 0; }
  .rtl header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-title h5 .sub-arrow:before {
    right: auto;
    left: 0; }
  .rtl header .sidenav .sidebar-menu li a {
    text-align: right; }
    .rtl header .sidenav .sidebar-menu li a .sub-arrow {
      left: 0;
      right: auto; }
  .rtl header.video-header #main-nav {
    right: unset;
    left: 0; }
  .rtl header.video-header .main-menu .menu-right .icon-nav {
    padding-left: 40px;
    padding-right: 0; }
  .rtl .cart-section .cart-buttons > div:last-child,
  .rtl .wishlist-section .cart-buttons > div:last-child {
    padding-left: 17px; }
  .rtl .tab-border .nav-border {
    border-left: none; }
  .rtl .collection-product-wrapper .product-filter-content .collection-view {
    padding-right: 20px !important; }
  .rtl .footer-social li {
    padding-right: 0;
    padding-left: 38px; }
  .rtl .footer-theme2 .social-white li {
    padding-left: 12px;
    padding-right: 12px; }
  .rtl .tab-left .theme-tab .left-side .tab-title {
    text-align: center;
    margin-left: 0; }
  .dark .tab-product .nav-material.nav-tabs {
    background-color: #2b2b2b; }
  .dark header {
    background-color: #2b2b2b; }
    .dark header .main-navbar {
      background-color: #2b2b2b;
      border-color: #404040; }
      .dark header .main-navbar .nav-menu {
        background-color: #2b2b2b; }
        .dark header .main-navbar .nav-menu > li ul {
          background-color: #2b2b2b;
          box-shadow: none; }
        .dark header .main-navbar .nav-menu > li .mega-menu-container {
          background-color: #2b2b2b;
          box-shadow: none; }
          .dark header .main-navbar .nav-menu > li .mega-menu-container .mega-box .link-section .menu-content ul li a {
            color: #e7eaec; }
        .dark header .main-navbar .nav-menu .back-btn .mobile-back {
          border-color: #404040;
          color: #e7eaec; }
    .dark header.left-header .pixelstrap ul {
      background-color: #232323; } }

@media (max-width: 991px) and (min-width: 767px) {
  .partition-f > div + div + div {
    margin-top: 30px; }
  .lookbook .lookbook-block .lookbook-dot .dot-showbox {
    width: 80px; }
    .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
      line-height: 15px; } }

@media (max-width: 991px) {
  .pet-parallax .pet-decor {
    left: 34px;
    bottom: -86px; }
    .pet-parallax .pet-decor img {
      width: 165px; }
  .pet-parallax .banner-contain p {
    max-width: 100%; }
  .m-sm-t-2 {
    margin-top: 2rem; }
  .service-block p {
    line-height: 1.2; }
  h2 {
    font-size: 28px; }
  section,
  .section-t-space {
    padding-top: 50px; }
  .section-b-space {
    padding-bottom: 50px; }
  .small-section {
    padding: 30px 0; }
  .product-full-tab {
    padding-top: 50px !important; }
  .product-slick .slick-slide img,
  .rtl-product-slick .slick-slide img,
  .product-right-slick .slick-slide img,
  .rtl-product-right-slick .slick-slide img {
    width: 100% !important; }
  hr.style1 {
    margin-top: 12px;
    margin-bottom: 12px; }
  h4 {
    font-size: 13px !important; }
  .blog-details h4 {
    margin-top: 17px;
    font-size: 13px; }
  .blog-details p {
    font-size: 1.5rem;
    line-height: 1.3;
    margin-top: 10px; }
  .service-block svg,
  .service-block1 svg {
    margin-bottom: 20px;
    height: auto;
    width: 46px; }
  .partition_3 > div + div {
    margin-top: 30px; }
  .partition4 > div + div + div {
    margin-top: 30px; }
  .banner-timer .banner-text h2 {
    font-size: 20px; }
  .banner-timer .timer span {
    width: 65px; }
  .banner-timer .timer p {
    font-size: 14px; }
  .about-text p {
    line-height: 24px; }
  .footer-light .subscribe {
    text-align: center;
    justify-content: center;
    border: none;
    margin-bottom: 5px; }
    .footer-light .subscribe p {
      display: none; }
  .footer-light .subscribe-form {
    justify-content: center;
    margin-bottom: 0; }
  .footer-theme .col {
    max-width: 50%;
    flex-basis: unset; }
  .footer-theme .footer-logo {
    margin-bottom: 30px; }
  .footer-theme .sub-title h4 {
    margin-bottom: 10px; }
  .footer-social {
    margin-top: 15px; }
  .subscribe-wrapper {
    order: 0;
    padding-top: 20px;
    padding-bottom: 20px; }
  .footer-theme2 .footer-mobile-title {
    display: none !important; }
  .footer-theme2 h4 {
    padding-bottom: 10px; }
  .footer-theme2 .col {
    flex-basis: unset;
    padding: 20px 0; }
  .footer-theme2.section-light .footer-block h4 {
    padding-bottom: 15px; }
  .footer-theme2.section-light .footer-block .subscribe-white {
    border: none; }
  .footer-theme2 .footer-logo {
    margin-bottom: 20px; }
  .footer-theme2 .subscribe-block {
    border: none; }
  .social-white li {
    padding-left: 10px;
    padding-right: 10px; }
  .category-border div .category-banner .category-box h2 {
    padding: 5px 15px;
    font-size: 30px; }
  .category-bg .image-block.even:after {
    bottom: 85%; }
  .category-bg .image-block:after {
    width: 35px; }
  .category-bg .image-block:hover:after {
    height: 70px; }
  .category-bg .contain-block h2 {
    font-size: 20px;
    margin-top: 8px; }
  .category-bg .contain-block:after {
    width: 35px; }
  .category-bg .contain-block.even:after {
    top: 85%; }
  .category-bg .contain-block .category-btn {
    margin-bottom: 15px;
    margin-top: 15px;
    letter-spacing: 0.2em;
    padding: 10px 15px; }
  .category-bg .contain-block:after {
    bottom: 85%; }
  .category-bg .contain-block:hover:after {
    height: 70px; }
  .no-slider .product-box {
    flex: 0 0 50%;
    max-width: calc(50% - 30px);
    margin: 0 15px 30px !important; }
    .no-slider .product-box:nth-last-child(-n + 2) {
      margin: 0 15px 0 !important; }
  .full-banner {
    padding-top: 180px;
    padding-bottom: 180px; }
    .full-banner.parallax-layout {
      padding-top: 180px;
      padding-bottom: 180px; }
    .full-banner .banner-contain h2 {
      font-size: 60px; }
    .full-banner .banner-contain h3 {
      font-size: 35px; }
    .full-banner .banner-contain h4 {
      font-size: 20px;
      padding-top: 5px; }
  .collection-banner .contain-banner {
    padding-left: 60px;
    padding-right: 60px; }
    .collection-banner .contain-banner.banner-3 {
      padding-left: 30px;
      padding-right: 30px; }
      .collection-banner .contain-banner.banner-3 h2 {
        font-size: 20px; }
    .collection-banner .contain-banner.banner-4 {
      padding-left: 40px;
      padding-right: 40px; }
      .collection-banner .contain-banner.banner-4 h2 {
        font-size: 18px; }
      .collection-banner .contain-banner.banner-4 h4 {
        font-size: 18px; }
    .collection-banner .contain-banner h2 {
      font-size: 25px; }
  .collection-collapse-block {
    border-bottom: 1px solid #dddddd !important; }
  .home-slider .home {
    height: 65vh; }
  .home-slider .slider-contain {
    height: 65vh; }
    .home-slider .slider-contain h1 {
      font-size: 36px; }
    .home-slider .slider-contain .btn-solid,
    .home-slider .slider-contain .btn-outline {
      margin-top: 17px; }
  .home-slider:hover .slick-next {
    right: 90px; }
  .home-slider:hover .slick-prev {
    left: 90px; }
  .background .contain-bg {
    padding-top: 25px;
    padding-bottom: 25px; }
    .background .contain-bg h4 {
      font-size: 16px; }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 210px; }
  .beauty-about .about-text p {
    text-align: center; }
  .theme-card .offer-slider img {
    padding: 10px 10px 10px 0; }
  .theme-card.card-border .offer-slider img {
    padding: 10px 10px 10px 30px; }
  .multiple-slider > div:nth-child(-n + 2) {
    margin-bottom: 30px; }
  .full-box .center-slider .offer-slider .product-box .product-info h4 {
    padding-bottom: 5px; }
  .full-box .center-slider .offer-slider .product-box .product-info .btn-outline {
    padding: 7px 20px; }
  .full-box .theme-card .offer-slider .product-box2 .media img {
    height: 150px; }
  .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
    margin-top: 0; }
  .layout-8 .layout-8-bg {
    padding: 0; }
  .alert {
    max-width: 45% !important; }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
    margin-bottom: 20px; }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
    margin-bottom: 18px; }
  .parallax-cls .banner-contain {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 35px; }
  .parallax-cls section .banner-contain {
    margin-top: 0; }
  .about-section {
    padding-top: 30px; }
    .about-section h2 {
      text-align: center; }
  .product-box .cart-info {
    bottom: 20px; }
  .absolute-banner {
    margin-top: -60px; }
  .product-right.product-form-box {
    margin-top: 0;
    margin-bottom: 30px; }
  .testimonial .testimonial-slider .media .media-body {
    padding: 0 30px;
    height: 100%;
    align-self: center; }
    .testimonial .testimonial-slider .media .media-body p {
      margin-bottom: 0; }
  .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
    border-left: none;
    padding-left: 0; }
  .blog-detail-page .blog-advance ul {
    margin-bottom: 20px; }
  .blog-detail-page .blog-advance p:last-child {
    margin-bottom: 0; }
  .collection-product-wrapper .product-top-filter {
    border-top: none; }
    .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
      border-top: 1px solid #dddddd; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
      display: none; }
    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
      display: none; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
      width: 50%; }
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view:before,
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before {
        right: 15px !important; }
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
      .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
        border-left: 1px solid #dddddd;
        padding: 21px 34px 21px 34px;
        border-top: none;
        border-bottom: none; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select {
      border-right: none !important; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
      border-top: 1px solid #dddddd; }
  .top-banner-content h4 {
    font-size: 20px; }
  .filter-main-btn {
    display: block; }
  .collection-filter {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -370px;
    background-color: white;
    z-index: 99;
    overflow-y: scroll;
    padding: 15px 0 15px 15px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 1px 0 5px #ddd; }
    .collection-filter .collection-sidebar-banner {
      text-align: center; }
    .collection-filter .theme-card {
      padding-left: 30px;
      padding-right: 30px; }
    .collection-filter .collection-sidebar-banner {
      padding: 0 30px; }
  .collection-filter-block {
    border: none; }
  .collection-mobile-back {
    display: block; }
  .collection .section-t-space {
    padding-top: 30px; }
  .collection .partition-collection > div:nth-last-child(1) {
    margin-top: 30px; }
  .collection .partition-collection > div:nth-last-child(2) {
    margin-top: 30px; }
  .right-login {
    margin-top: 30px; }
  .contact-page .map iframe {
    height: 350px; }
  .contact-page .contact-right {
    padding-bottom: 0; }
    .contact-page .contact-right ul li {
      padding-left: 0;
      border-bottom: 1px solid #dddddd;
      text-align: center;
      padding-bottom: 10px;
      margin-top: 15px; }
      .contact-page .contact-right ul li .contact-icon {
        position: relative;
        margin: 0 auto;
        border-right: 0; }
      .contact-page .contact-right ul li p {
        margin-bottom: 10px; }
  .cart-section .cart-table thead th:last-child {
    display: none; }
  .cart-section tbody tr td {
    min-width: 135px; }
    .cart-section tbody tr td:last-child {
      display: none; }
  .cart-section .cart-buttons > div:last-child {
    padding-right: 15px; }
  .cart-section tfoot tr td {
    padding-right: 0; }
  .wishlist-section tbody tr td {
    min-width: 138px; }
  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    width: 55%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 20px;
    background: none #b5dccd; }
    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .open-popup {
      text-align: left; }
    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup > a {
      color: #ffffff; }
    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
      height: 100vh;
      left: -350px;
      transition: all 0.5s ease; }
  .collection-product-wrapper .product-top-filter .popup-filter .search-count {
    width: 100%;
    padding: 10px 0;
    text-align: center; }
  .collection-product-wrapper .product-top-filter .popup-filter .collection-view,
  .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
    display: none; }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    width: 50%; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
      padding-top: 10px;
      padding-bottom: 10px; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
      top: 13px; }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    left: unset;
    right: 35px; }
  .tab-product {
    padding-top: 0; }
  .product-right h2 {
    margin-top: 15px; }
  .pro_sticky_info {
    border: none;
    padding: 10px 0; }
  .product-right {
    margin: 20px 0 10px 0; }
    .product-right .detail-section,
    .product-right .product-icon {
      justify-content: center; }
    .product-right .size-text {
      text-align: left; }
    .product-right .timer {
      text-align: left; }
    .product-right .product-icon .product-social li {
      padding-right: 20px; }
  .product-form-box {
    margin-bottom: 10px; }
  .tab-product .nav-material.nav-tabs {
    justify-content: center; }
  .dashboard-left {
    position: fixed;
    height: 100vh;
    top: 0;
    left: -350px;
    background-color: white;
    z-index: 99;
    padding: 0 35px 35px;
    max-width: 350px;
    transition: all 0.5s ease;
    box-shadow: 0 0 8px 0 #dddddd; }
    .dashboard-left .block-title h2 {
      display: none; }
    .dashboard-left .block-content {
      border: none;
      padding: 0;
      margin-top: 20px; }
      .dashboard-left .block-content ul li:hover {
        padding-left: 0; }
  .account-sidebar {
    display: block; }
  .rtl .tools-parallax-product .tools-description .tools-form .search-button {
    text-align: center; }
  .rtl .tools-service .service-block + .service-block {
    border-right: none; }
  .rtl .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
    border-right: none;
    padding-right: 0; }
  .rtl .contact-page .contact-right ul li {
    padding-right: 0; }
    .rtl .contact-page .contact-right ul li .contact-icon {
      border-left: none; }
    .rtl .contact-page .contact-right ul li p {
      text-align: center; }
  .rtl .rtl-text .product-right .color-variant {
    text-align: center; }
  .rtl .rtl-text .product-right .size-box {
    text-align: center; }
  .rtl .rtl-text .product-right .product-buttons {
    text-align: center; }
  .rtl .rtl-text .product-right .border-product {
    text-align: center; }
  .rtl .product-accordion .card-header h5 {
    text-align: center; }
  .rtl .image-swatch {
    text-align: center; }
  .rtl .product-right h4,
  .rtl .product-right h2,
  .rtl .product-right h3,
  .rtl .product-right p,
  .rtl .product-right .product-title {
    text-align: center; }
  .rtl .product-right .detail-section,
  .rtl .product-right .product-icon {
    justify-content: center; }
  .rtl .product-right .size-text {
    text-align: right; }
  .rtl .product-right .payment-card-bottom {
    text-align: center; }
  .rtl .product-right .rating {
    text-align: center; }
  .rtl .single-product-tables table tr td {
    text-align: center; }
  .rtl .tab-product {
    padding-top: 30px; }
  .rtl .collection-product-wrapper .product-filter-content .product-page-filter {
    border-left: none; }
  .rtl .collection-product-wrapper .product-filter-content .collection-view {
    padding-right: 20px !important; }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    padding: 21px 34px 21px 34px; }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-right: 1px solid #dddddd; }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-right: none; }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .search-count h5 {
    text-align: center; }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select,
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    padding: 10px 34px 10px 34px; }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    background: none #b5dccd; }
  .rtl .banner-timer .timer {
    padding-left: 10px; }
    .rtl .banner-timer .timer span {
      width: 55px; }
  .rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
    margin-left: 5px;
    margin-right: unset; }
  .rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .rating i {
    padding: 0; }
  .rtl .beauty-about .text-center {
    text-align: center !important; }
  .rtl .beauty-about .about-text p {
    text-align: center; }
  .rtl .footer-light .subscribe {
    border-left: none; }
  .dark .collection-filter {
    background-color: #2b2b2b; }
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    border-color: #404040; }
  .dark .collection-mobile-back {
    border-color: #404040; }
    .dark .collection-mobile-back span {
      color: #929292; }
  .dark .collection-collapse-block {
    border-color: #404040 !important; }
  .dark .collection-filter {
    box-shadow: none; }
  .dark .contact-page .contact-right ul li {
    border-color: #404040; }
  .dark .dashboard-left {
    background-color: #232323;
    box-shadow: none; }
  .dark header.header-tools .logo-menu-part {
    background-color: #2b2b2b; } }

@media (max-width: 767px) {
  .pet-parallax .pet-decor {
    display: none; }
  .portfolio-section.metro-section .product-box .cart-wrap a i {
    opacity: 1;
    font-size: 16px;
    padding: 5px; }
  .portfolio-section.metro-section .product-box .cart-wrap button i {
    font-size: 16px;
    padding: 5px; }
  .portfolio-section.metro-section .product-box .product-detail {
    opacity: 1;
    bottom: 15px;
    background-color: rgba(255, 255, 255, 0.5); }
    .portfolio-section.metro-section .product-box .product-detail h6 {
      color: #000000; }
  .portfolio-section.metro-section .product-box:hover .product-detail {
    opacity: 1; }
  .portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(2) i, .portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(3) i, .portfolio-section.metro-section .product-box:hover .cart-wrap a:nth-child(4) i {
    animation: none; }
  .pet-parallax .full-banner {
    padding-top: 120px;
    padding-bottom: 120px; }
  .pet-layout-footer .footer-theme2 .footer-link .social-white {
    margin-top: 30px;
    margin-bottom: 20px; }
    .pet-layout-footer .footer-theme2 .footer-link .social-white li {
      display: unset; }
  .j-box .product-box .cart-info {
    bottom: 0;
    position: relative;
    opacity: 1; }
    .j-box .product-box .cart-info a i {
      padding: 10px; }
  .pets-box .product-box .img-wrapper .cart-info {
    transform: none; }
    .pets-box .product-box .img-wrapper .cart-info i {
      font-size: 16px; }
  .addtocart_count .product-box:hover .cart-info a:nth-child(1) i, .addtocart_count .product-box:hover .cart-info a:nth-child(2) i, .addtocart_count .product-box:hover .cart-info a:nth-child(3) i {
    animation: none; }
  .breadcrumb-section .page-title,
  .breadcrumb-section .breadcrumb {
    justify-content: center;
    padding-bottom: 0;
    margin-bottom: -4px; }
  .layout3-menu {
    max-width: 100%; }
    .layout3-menu .main-menu .brand-logo img {
      height: 25px; }
  section,
  .section-t-space {
    padding-top: 40px; }
  .section-b-space {
    padding-bottom: 40px; }
  .product-full-tab {
    padding-top: 30px !important; }
  .small-section {
    padding: 20px 0; }
  .theme-tab .tab-title a,
  .theme-tab .tab-title2 a {
    text-transform: capitalize; }
  .about-cls .service.border-section {
    border-bottom: none; }
  .about-cls .service.small-section {
    padding-bottom: 0; }
  .service-block .media {
    display: block;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0; }
  .service-block svg {
    margin-right: 0; }
  .service-block + .service-block {
    border-left: 0;
    margin-top: 20px; }
  .service-block:last-child .media .media-body p {
    margin-bottom: 5px; }
  .partition2 {
    margin-bottom: -25px; }
    .partition2 > div {
      margin-bottom: 25px; }
  .partition3 > div + div {
    margin-top: 30px; }
  .partition4 > div + div {
    margin-top: 30px; }
  .center-slider {
    border-left: none;
    border-right: none; }
  .about-text p {
    line-height: 25px; }
  .banner-timer {
    padding-top: 5px;
    padding-bottom: 5px; }
    .banner-timer .banner-text {
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: rgba(255, 255, 255, 0.65); }
      .banner-timer .banner-text h2 {
        font-size: 15px; }
    .banner-timer .timer-box {
      text-align: center; }
    .banner-timer .timer {
      margin-top: 5px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 20px; }
      .banner-timer .timer p {
        font-size: 14px; }
      .banner-timer .timer span {
        width: 40px; }
        .banner-timer .timer span .padding-l {
          padding-left: 10px; }
  .darken-layout .footer-title,
  .dark-layout .footer-title {
    border-bottom: 1px solid #525252; }
  .darken-layout .subscribe-wrapper,
  .dark-layout .subscribe-wrapper {
    padding-bottom: 10px; }
  .footer-title {
    margin-top: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
    position: relative; }
    .footer-title.footer-mobile-title {
      margin-top: 0; }
    .footer-title h4 {
      text-transform: uppercase;
      font-weight: 700;
      cursor: pointer; }
    .footer-title.active .according-menu {
      font: normal normal normal 14px/1 FontAwesome; }
      .footer-title.active .according-menu:before {
        content: '\f106';
        position: absolute;
        right: 2px;
        top: 2px; }
    .footer-title .according-menu {
      font: normal normal normal 14px/1 FontAwesome; }
      .footer-title .according-menu:before {
        content: '\f107';
        position: absolute;
        right: 2px;
        top: 2px; }
  .banner-slider .home-banner > div img {
    margin-top: 30px; }
  .banner-slider .home-banner > div:last-child img {
    margin-top: 30px; }
  .lookbook .row > div:last-child .lookbook-block {
    margin-top: 30px; }
  .lookbook-section .row > div:first-child .lookbook-img > div:last-child {
    margin-bottom: 25px; }
  .lookbook-section .lookbook-img > div:last-child img {
    margin-top: 0; }
  .full-scroll-footer .sub-footer p {
    padding: 0;
    line-height: 25px; }
  .box-layout-body .box-layout {
    margin-top: -40px; }
  .layout-20 {
    padding: 15px; }
  .home-slider.fullpage .slick-dots {
    display: flex;
    bottom: 70px;
    top: unset;
    left: 50%;
    transform: translateX(-50%); }
    .home-slider.fullpage .slick-dots li button:before {
      color: #0a0100; }
  .footer-contant {
    text-align: left; }
    .footer-contant .footer-logo {
      margin-top: 20px;
      margin-bottom: 20px; }
    .footer-contant li {
      text-align: left; }
    .footer-contant .footer-social ul {
      margin: unset; }
      .footer-contant .footer-social ul li {
        padding-right: 25px;
        padding-left: 0; }
  .social-white li:first-child {
    padding-left: 0; }
  .footer-theme {
    text-align: center; }
    .footer-theme .footer-mobile-title {
      display: block !important; }
    .footer-theme .col {
      max-width: 100%;
      flex-basis: unset; }
    .footer-theme .footer-social {
      margin-bottom: 15px; }
    .footer-theme .sub-title li {
      padding-top: 7px; }
    .footer-theme .sub-title .contact-list i {
      position: relative;
      top: 0;
      margin-right: 10px; }
    .footer-theme .sub-title .contact-list li {
      padding-left: 0; }
  .footer-social ul {
    margin: 0 auto; }
  .footer-social li {
    padding: 0 15px; }
  .footer-end {
    text-align: center; }
  .sub-footer .payment-card-bottom {
    justify-content: center;
    padding-bottom: 25px; }
  .subscribe-wrapper {
    margin: 10px 0 0 0;
    order: 0; }
  .footer-theme2 p {
    margin-bottom: 10px; }
  .footer-theme2 .footer-mobile-title {
    display: block !important; }
  .footer-theme2 .p-set {
    padding: 0 15px !important; }
  .footer-theme2 .col {
    padding: 0; }
  .footer-theme2 .footer-link li {
    display: flex;
    padding-top: 10px; }
  .footer-theme2 .footer-link h4 {
    padding-bottom: 10px; }
  .footer-theme2 .contact-details li {
    padding-left: 0;
    padding-top: 10px; }
  .footer-theme2 .footer-link-b li {
    display: flex;
    padding-top: 10px; }
  .footer-theme2 .footer-link-b h4 {
    margin-top: 0;
    padding-bottom: 10px; }
  .footer-theme2.section-light .footer-block h4 {
    padding-bottom: 10px; }
  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 40px 65px; }
    .footer-theme2.section-light .footer-block .subscribe-white h2 {
      margin-bottom: 20px; }
    .footer-theme2.section-light .footer-block .subscribe-white .form-group {
      display: flex; }
      .footer-theme2.section-light .footer-block .subscribe-white .form-group .btn-solid,
      .footer-theme2.section-light .footer-block .subscribe-white .form-group .btn-outline {
        margin-top: 0; }
  .footer-theme2 .subscribe-block h2 {
    margin-bottom: 20px; }
  .footer-theme2 h4 {
    padding-bottom: 10px; }
  .darken-layout .footer-theme .footer-mobile-title h4 {
    color: #ffffff; }
  .dark-layout .small-section {
    padding-bottom: 40px; }
  .dark-layout section {
    padding-top: 0; }
  .dark-layout .section-b-space {
    border: none;
    padding-bottom: 0; }
  .dark-layout .subscribe-wrapper {
    order: -1; }
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block {
    padding: 10px 60px; }
    .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block h2 {
      display: none; }
    .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group {
      display: flex; }
      .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .btn-solid,
      .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .btn-outline {
        margin-top: 0; }
  .social-white {
    margin-bottom: 10px; }
  .category-border div .category-banner .category-box h2 {
    font-size: 25px; }
  .box-layout .small-section {
    padding-top: 0; }
  .white-layout .footer-theme2 > div:nth-child(2) {
    order: -1; }
  .white-layout .footer-theme2.footer-border {
    padding: 0;
    border: none; }
  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 540px;
    width: 100%; }
    .white-layout .footer-theme2 .footer-block .footer-container .footer-mobile-title {
      margin-top: 20px; }
  .white-layout .footer-theme2 .footer-block .subscribe-white h2 {
    display: none; }
  .category-bg {
    padding: 30px 40px; }
    .category-bg .image-block.even:after {
      bottom: 85%; }
    .category-bg .image-block:after {
      width: 25px;
      margin-top: -25px; }
    .category-bg .image-block:hover:after {
      height: 50px; }
    .category-bg .contain-block h2 {
      font-size: 18px;
      margin-top: 8px; }
    .category-bg .contain-block h6 {
      letter-spacing: 0.1em;
      font-size: 12px; }
    .category-bg .contain-block:after {
      width: 25px; }
    .category-bg .contain-block.even:after {
      top: 87%; }
    .category-bg .contain-block .category-btn {
      margin-bottom: 10px;
      margin-top: 10px;
      letter-spacing: 0.1em;
      padding: 5px 15px; }
    .category-bg .contain-block:after {
      bottom: 87%; }
    .category-bg .contain-block:hover:after {
      height: 50px; }
  .layout9-box .product-box .img-block {
    min-height: unset; }
  .product-box .cart-detail,
  .product-wrap .cart-detail {
    top: 5px;
    right: 10px; }
  .product-box .img-block {
    min-height: 350px; }
  .product-box .cart-info,
  .product-box .cart-detail {
    opacity: 1; }
  .product-box .img-wrapper .cart-box {
    opacity: 1; }
  .product-box:hover .img-wrapper .cart-box {
    animation: none; }
  .product-box:hover .cart-info button,
  .product-box:hover .cart-detail button {
    animation: none; }
  .product-box:hover .cart-info a i,
  .product-box:hover .cart-detail a i {
    animation: none; }
  .product-box:hover .cart-info a:nth-child(2) i,
  .product-box:hover .cart-detail a:nth-child(2) i {
    animation: none; }
  .product-box:hover .cart-info a:nth-child(3) i,
  .product-box:hover .cart-detail a:nth-child(3) i {
    animation: none; }
  .product-box:hover .cart-info a:nth-child(4) i,
  .product-box:hover .cart-detail a:nth-child(4) i {
    animation: none; }
  .theme-tab .tab-title2 {
    font-size: 20px; }
    .theme-tab .tab-title2 li {
      padding-left: 15px;
      padding-right: 15px; }
    .theme-tab .tab-title2:after {
      top: 15px; }
  .multiple-slider > div:nth-child(-n + 3) {
    margin-bottom: 30px; }
  .full-banner {
    padding-top: 120px;
    padding-bottom: 120px; }
    .full-banner.parallax-layout {
      padding-top: 120px;
      padding-bottom: 120px; }
    .full-banner .banner-contain h2 {
      font-size: 75px; }
  .collection-banner .contain-banner {
    padding-left: 60px;
    padding-right: 60px; }
    .collection-banner .contain-banner.banner-3 {
      padding-left: 40px;
      padding-right: 40px; }
      .collection-banner .contain-banner.banner-3 h2 {
        font-size: 20px; }
      .collection-banner .contain-banner.banner-3 h4 {
        font-size: 18px; }
    .collection-banner .contain-banner.banner-4 {
      padding-left: 45px;
      padding-right: 45px; }
      .collection-banner .contain-banner.banner-4 h2 {
        font-size: 18px; }
    .collection-banner .contain-banner h2 {
      font-size: 35px; }
  .home-slider .home {
    height: 60vh; }
  .home-slider .slider-contain {
    height: 60vh; }
    .home-slider .slider-contain h1 {
      font-size: 35px;
      margin-top: 5px; }
    .home-slider .slider-contain .btn-solid,
    .home-slider .slider-contain .btn-outline {
      margin-top: 10px;
      padding: 10px 25px; }
  .home-slider:hover .slick-next {
    right: 80px; }
  .home-slider:hover .slick-prev {
    left: 80px; }
  .background .col {
    flex-basis: unset; }
  .background .contain-bg {
    margin: 10px 0; }
  .full-box .center-slider .offer-slider {
    text-align: center; }
    .full-box .center-slider .offer-slider .product-box {
      border: none;
      display: inline-block; }
      .full-box .center-slider .offer-slider .product-box .img-wrapper {
        text-align: center; }
        .full-box .center-slider .offer-slider .product-box .img-wrapper img {
          height: 200px; }
      .full-box .center-slider .offer-slider .product-box:hover {
        box-shadow: none; }
  .full-box .theme-card .offer-slider .sec-1 {
    display: flex; }
    .full-box .theme-card .offer-slider .sec-1 .product-box2 .media .media-body .color-variant li {
      height: 18px;
      width: 18px;
      padding-right: 3px; }
    .full-box .theme-card .offer-slider .sec-1 .product-box2 + .product-box2 {
      border-top: none; }
  .theme-tab .tab-title li,
  .theme-tab .tab-title2 li {
    padding-left: 5px;
    padding-right: 5px; }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 150px;
    padding: 5px; }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h2 {
    margin-bottom: 20px;
    padding-bottom: 20px; }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
    margin-bottom: 20px;
    font-size: 15px; }
  .filter-main-btn span {
    font-size: 16px; }
  .tap-top {
    bottom: 70px;
    right: 20px; }
  .footer-theme2 .footer-link li,
  .footer-theme2 .footer-link-b li {
    padding-left: 0; }
  .blog-detail-page .comment-section li {
    padding-top: 40px;
    padding-bottom: 40px; }
  .product-pagination .pagination {
    justify-content: center;
    border-left: 1px solid #dddddd; }
  .testimonial {
    background-color: #f7f7f7; }
    .testimonial .testimonial-slider .media .media-body {
      padding: 30px 30px 30px 30px; }
  .about-page {
    text-align: center; }
    .about-page p {
      line-height: 1.5; }
  .team h2 {
    font-size: 25px; }
  .layout2-logo {
    padding-right: 70px; }
  .collection .partition-collection > div:nth-last-child(1), .collection .partition-collection > div:nth-last-child(2), .collection .partition-collection > div:nth-last-child(3) {
    margin-top: 30px; }
  .collection-product-wrapper .product-pagination .pagination .page-item a {
    padding: 15px 18px; }
  .collection-product-wrapper .product-pagination .product-search-count-bottom {
    justify-content: center;
    padding-right: 0; }
    .collection-product-wrapper .product-pagination .product-search-count-bottom h5 {
      padding: 10px 0; }
  .collection-product-wrapper .product-pagination .theme-paggination-block nav {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
  .faq-section .theme-accordion .card .card-body p {
    line-height: 1.5 !important; }
  .faq-section .theme-accordion .card .card-header button {
    font-size: 14px !important;
    white-space: normal;
    width: 100%;
    padding: 16px 30px 16px 18px !important; }
  .register-page .theme-card {
    padding: 25px; }
    .register-page .theme-card .theme-form input {
      margin-bottom: 25px;
      padding: 13px 18px; }
  .search-product > div:nth-last-child(4) {
    margin-top: 30px; }
  .cart-section tbody tr td a,
  .wishlist-section tbody tr td a {
    justify-content: center;
    display: flex;
    align-items: center; }
  .cart-section .cart-table thead th:nth-last-child(-n + 4) {
    display: none; }
  .cart-section tbody tr td:nth-last-child(-n + 4) {
    display: none; }
  .cart-section tbody tr td .mobile-cart-content {
    display: flex; }
  .wishlist-section .cart-table thead th:nth-last-child(-n + 3) {
    display: none; }
  .wishlist-section tbody tr td:nth-last-child(-n + 3) {
    display: none; }
  .wishlist-section tbody tr td .mobile-cart-content {
    display: flex; }
  .blog-page .order-sec {
    order: -1; }
  .blog-page .blog-media:last-child {
    margin-bottom: 30px; }
  .blog-page .blog-sidebar ul li {
    display: block; }
  .tab-pane iframe {
    width: 100%; }
  .product-order .product-order-detail img {
    height: auto; }
  .rtl .service-block svg {
    margin-right: 0; }
  .rtl .service-block + .service-block {
    border-right: 0; }
  .rtl .service-block .media .media-body h4,
  .rtl .service-block .media .media-body p {
    text-align: center; }
  .rtl .collection-product-wrapper .product-pagination .theme-paggination-block nav {
    border-right: none;
    border-left: 1px solid #dddddd; }
  .rtl .collection-product-wrapper .product-pagination .product-search-count-bottom {
    padding-left: 5px;
    padding-right: 5px; }
  .rtl .footer-title.active .according-menu:before {
    right: unset;
    left: 2px; }
  .rtl .footer-title .according-menu:before {
    right: unset;
    left: 2px; }
  .rtl .footer-end p {
    text-align: center; }
  .rtl .sub-footer .payment-card-bottom li {
    padding-left: 7px;
    padding-right: 7px; }
  .rtl .footer-theme2.section-light .footer-block h4 {
    text-align: right; }
  .rtl .footer-theme2.section-light .footer-block .footer-logo {
    text-align: right; }
  .rtl .footer-theme2 .footer-title h4 {
    text-align: right; }
  .rtl .footer-contant li {
    text-align: right; }
  .rtl .footer-contant .footer-social ul li {
    padding-right: 0;
    padding-left: 25px; }
  .rtl .dark-layout .footer-contant .footer-logo {
    text-align: right; }
  .rtl .dark-layout .footer-contant p {
    text-align: right; }
  .rtl .footer-theme .sub-title .contact-list i {
    margin-right: 0;
    margin-left: 10px; }
  .rtl .footer-theme .sub-title .contact-list li {
    padding-right: 0; }
  .rtl .layout2-logo {
    padding-left: 70px;
    padding-right: 0; }
  .rtl .full-box .theme-card .offer-slider .sec-1 .product-box2 + .product-box2 {
    border-left: none;
    border-right: none; }
  .rtl header.left-header .main-menu .menu-right .icon-nav li {
    padding-right: 15px;
    padding-left: 0; }
  .rtl .tools-grey .product-box .cart-info .mobile-quick-view {
    margin-left: 0;
    margin-right: 5px; }
  .dark .collection-product-wrapper .product-pagination .theme-paggination-block nav {
    border-color: #404040; }
  .dark .product-pagination .pagination {
    border-color: #404040; } }

@media (max-width: 577px) {
  h3 {
    font-size: 17px; }
  h5 {
    font-size: 1rem !important;
    font-weight: 400;
    color: #555555; }
  .progress-bar {
    font-size: 0.7rem; }
  .title1 .title-inner1:after {
    width: 20rem !important; }
  .portfolio-section .isotopeSelector {
    margin-bottom: 15px; }
  .portfolio-section .filter-button {
    padding: 0 15px;
    font-size: 16px;
    line-height: 30px; }
  .service_slide .home-slider .slider-contain {
    height: 58vh; }
  .portfolio-section.metro-section .product-box .cart-wrap a i {
    font-size: 18px;
    padding: 7px; }
  .portfolio-section.metro-section .product-box .cart-wrap button i {
    font-size: 18px;
    padding: 7px; }
  .about-section .small-section {
    padding-top: 0; }
  .mobile-cart img {
    display: none; }
  .pet-parallax .full-banner {
    padding-top: 50px;
    padding-bottom: 50px; }
  .setting-sidebar {
    width: 35px;
    height: 35px; }
    .setting-sidebar i {
      font-size: 19px; }
  .dark-light {
    width: 35px;
    height: 35px; }
  #fb-root {
    display: none; }
  .section-b-space {
    padding-bottom: 30px; }
  section,
  .section-t-space {
    padding-top: 30px; }
  header.header-6 {
    height: 60px; }
    header.header-6 .main-menu .brand-logo {
      padding: 0 !important;
      top: 16px;
      left: 0;
      transform: translateX(0); }
    header.header-6 .main-nav-center .toggle-nav {
      top: -5px;
      padding: 0; }
    header.header-6.sticky.fixed .toggle-nav {
      padding-top: 0;
      padding-bottom: 0; }
  h2 {
    font-size: 24px; }
  .dashboard-right .dashboard {
    padding: 20px; }
    .dashboard-right .dashboard .page-title h2 {
      font-size: 18px; }
  .dashboard .box-head h2 {
    font-size: 18px; }
  .top-header .header-dropdown li i {
    padding-right: 0; }
  .layout3-menu .main-menu .menu-left {
    width: calc(100vw - 32px); }
    .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
      right: 15px; }
      .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
        display: none; }
  .layout3-menu .main-menu > div:nth-child(2) {
    position: absolute;
    left: 54%;
    right: 50%;
    transform: translate(-50%, 0);
    top: 0; }
  .layout3-menu .main-menu > div:nth-child(3) {
    height: 0; }
  .layout3-menu .brand-logo {
    left: 35%; }
  .search-overlay > div .closebtn {
    top: 15px;
    right: 30px; }
  .btn-solid {
    padding: 7px 15px; }
  .product-box .product-detail .color-variant,
  .product-box .product-info .color-variant,
  .product-wrap .product-detail .color-variant,
  .product-wrap .product-info .color-variant {
    padding-top: 10px; }
  footer {
    margin-bottom: 60px; }
    footer .btn-solid {
      padding: 13px 29px; }
    footer p {
      line-height: 20px; }
  .video-section .video-img .play-btn span {
    height: 40px;
    width: 40px; }
  .video-section .video-img .play-btn i {
    font-size: 20px; }
  .partition1 {
    margin-bottom: -15px; }
    .partition1 > div {
      margin-bottom: 15px; }
  .theme-modal .modal-dialog .quick-view-modal .product-right {
    margin: 15px 0 10px 0; }
    .theme-modal .modal-dialog .quick-view-modal .product-right h2 {
      font-size: 18px;
      margin-bottom: 10px; }
    .theme-modal .modal-dialog .quick-view-modal .product-right h3 {
      font-size: 22px;
      margin-bottom: 10px; }
    .theme-modal .modal-dialog .quick-view-modal .product-right .color-variant li {
      height: 25px;
      width: 25px; }
    .theme-modal .modal-dialog .quick-view-modal .product-right .border-product {
      padding-top: 10px;
      padding-bottom: 15px; }
    .theme-modal .modal-dialog .quick-view-modal .product-right .size-box {
      margin-top: 5px;
      margin-bottom: 5px; }
    .theme-modal .modal-dialog .quick-view-modal .product-right .product-description .qty-box {
      margin-top: 5px; }
  .dark-layout .footer-theme2 .col {
    padding: 0 15px; }
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block {
    padding: 10px 20px 20px 10px; }
  .white-layout .small-section .footer-theme2 .col {
    padding: 0 15px; }
  .footer-theme2 .col {
    padding: 0 15px; }
  .footer-theme2 .form-p {
    padding: 0; }
  .footer-theme2.footer-border {
    padding: 15px 0; }
  .footer-theme2 .subscribe-block {
    padding: 0 75px; }
  .footer-theme2 .footer-link h4 {
    padding-bottom: 10px; }
  .footer-theme2 .footer-link li {
    padding-right: 20px; }
  .footer-theme2 .footer-link-b h4 {
    padding-bottom: 15px; }
  .footer-theme2 .footer-link-b li {
    padding-right: 20px; }
  .social-white {
    margin-top: 25px; }
  .category-border div .category-banner .category-box h2 {
    font-size: 30px;
    padding: 10px 20px; }
  .filter-main-btn span {
    font-size: 14px; }
  .category-bg .image-block:after {
    display: none; }
  .category-bg .contain-block:after {
    display: none; }
  .category-bg .order-section div {
    order: 1; }
    .category-bg .order-section div:nth-child(2) {
      order: 4;
      margin-bottom: 30px; }
    .category-bg .order-section div:nth-child(3) {
      order: 3; }
    .category-bg .order-section div:nth-child(4) {
      order: 2;
      margin-bottom: 30px; }
    .category-bg .order-section div:nth-child(5) {
      order: 5; }
    .category-bg .order-section div:nth-child(6) {
      order: 6; }
  .category-bg .contain-block {
    padding-top: 30px;
    padding-bottom: 30px; }
  .theme-tab .tab-title {
    margin-bottom: 25px; }
  .full-banner {
    padding-top: 50px;
    padding-bottom: 50px; }
    .full-banner.parallax-layout {
      padding-top: 50px;
      padding-bottom: 50px; }
    .full-banner .banner-contain {
      background-color: rgba(255, 255, 255, 0.2);
      padding: 25px; }
      .full-banner .banner-contain .btn-solid,
      .full-banner .banner-contain .btn-outline {
        padding: 10px 20px; }
    .full-banner.p-left .banner-contain, .full-banner.p-right .banner-contain {
      float: none; }
    .full-banner .banner-contain h2 {
      font-size: 65px; }
    .full-banner .banner-contain h3 {
      font-size: 40px; }
    .full-banner .banner-contain h4 {
      font-size: 18px;
      padding-top: 0;
      letter-spacing: 0.3em; }
  .home-slider .home {
    height: 58vh; }
  .home-slider .slider-contain {
    height: 58vh; }
    .home-slider .slider-contain h1 {
      font-size: 30px; }
  .home-slider:hover .slick-next {
    right: 70px; }
  .home-slider:hover .slick-prev {
    left: 70px; }
  .home-slider:hover .slick-prev,
  .home-slider:hover .slick-next {
    transform: scale(1.5); }
  .home-slider.fullpage .slick-dots {
    display: flex;
    bottom: 60px;
    top: unset;
    left: 50%;
    transform: translateX(-50%); }
  .home-slider.fullpage .home .slider-contain {
    text-align: center;
    justify-content: center;
    padding-left: 0; }
    .home-slider.fullpage .home .slider-contain > div {
      background-color: rgba(0, 0, 0, 0.15);
      padding: 30px 50px; }
    .home-slider.fullpage .home .slider-contain h1,
    .home-slider.fullpage .home .slider-contain p {
      color: #ffffff; }
    .home-slider.fullpage .home .slider-contain h1 {
      font-size: 22px; }
    .home-slider.fullpage .home .slider-contain p {
      max-width: 330px;
      font-size: 12px; }
    .home-slider.fullpage .home .slider-contain .btn-solid {
      padding: 10px 15px; }
  .background .contain-bg {
    background-color: unset;
    padding-top: 0;
    padding-bottom: 0;
    margin: 10px 0; }
    .background .contain-bg:hover {
      background-color: unset; }
  .theme-tab .tab-content .product-tab .tab-box {
    flex: 0 0 100%;
    max-width: calc(100% - 10px); }
    .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n + 2) {
      margin: 0 5px 10px; }
    .theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n + 1) {
      margin: 0 5px 0; }
    .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
      height: 150px; }
    .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
      margin-right: 10px; }
    .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
      margin-top: 7px;
      height: 18px;
      width: 18px; }
  .alert {
    right: 0 !important;
    left: 0;
    max-width: 90% !important; }
  .tap-top {
    width: 35px;
    height: 35px;
    padding: 2px;
    font-size: 19px; }
  .layout2-logo {
    padding-right: 0; }
  .absolute-banner {
    margin-top: 0; }
    .absolute-banner .absolute-bg {
      box-shadow: none;
      padding-bottom: 0; }
    .absolute-banner.banner-padding {
      padding-top: 0; }
  .box-layout-body .box-layout {
    margin-top: 0; }
  .full-scroll-menu .container-fluid {
    padding-left: 15px;
    padding-right: 15px; }
  .layout-8 {
    padding: 0 15px; }
  .layout-20 {
    padding: 0; }
  .addcart_btm_popup {
    width: 30px;
    height: 32px; }
    .addcart_btm_popup .fixed_cart {
      font-size: 14px; }
  .color-picker a.handle {
    width: 30px;
    height: 35px; }
    .color-picker a.handle i {
      margin-left: 7px;
      font-size: 17px; }
  .full-scroll-footer {
    display: none; }
  .error-section {
    padding: 100px 0; }
    .error-section h1 {
      font-size: 100px; }
    .error-section h2 {
      margin: 20px 0;
      font-size: 18px; }
  .about-page .about-head {
    display: inline-block;
    width: 100%;
    text-align: center; }
    .about-page .about-head .theme-breadcrumb .breadcrumb {
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 0; }
  .breadcrumb-section .page-title {
    text-align: center;
    margin: 0 auto;
    vertical-align: middle;
    display: unset; }
  .breadcrumb-section .breadcrumb {
    justify-content: center;
    padding: 0;
    line-height: 30px; }
  .blog-detail-page .blog-detail .post-social li {
    display: block; }
    .blog-detail-page .blog-detail .post-social li + li {
      padding-left: 0;
      margin-left: 0;
      border-left: none; }
  .collection .collection-block .collection-content p {
    margin-bottom: 10px; }
  .collection .collection-block .collection-content h4,
  .collection .collection-block .collection-content h3 {
    margin-bottom: 5px; }
  .collection-product-wrapper .product-wrapper-grid .product-box {
    margin-top: 30px; }
  .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    padding: 10px 20px 10px 20px; }
    .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5 {
      font-size: 14px;
      line-height: 20px; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    width: 100%; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
      padding: 10px 20px 10px 20px; }
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view:before,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before {
      top: 11px; }
  .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    border-right: 1px solid #dddddd !important;
    border-bottom: 1px solid #dddddd; }
  .collection-product-wrapper .product-top-filter .popup-filter .search-count {
    padding-top: 10px;
    padding-bottom: 10px; }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-bottom: 1px solid #dddddd;
    width: 100%; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
      border-right: none;
      padding-top: 10px;
      padding-bottom: 10px; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before {
      top: 14px; }
  .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    width: 100%;
    border-top: none; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
      padding-top: 10px;
      padding-bottom: 10px; }
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
      left: unset;
      right: 35px !important;
      top: 14px; }
  .search-product > div:nth-last-child(5) {
    margin-top: 30px; }
  .cart-section .cart-buttons > div:last-child {
    padding-right: 15px; }
  .cart-section .cart-buttons .btn-solid {
    padding: 7px 8px; }
  .wishlist-section .btn-solid {
    padding: 7px 6px; }
  .product-box .img-wrapper .lable-block .lable3 {
    font-size: 12px;
    padding: 13px 8px; }
  .product-box .img-block .lable-wrapper .lable1,
  .product-box .img-block .lable-wrapper .lable2 {
    padding: 6px 9px 8px 15px;
    font-size: 12px; }
  .tab-product .tab-content.nav-material p,
  .product-full-tab .tab-content.nav-material p {
    padding: 15px 0; }
  .tab-product .tab-content.nav-material p {
    line-height: 1.6; }
  .slider-right-nav .slick-slide:first-child > div {
    margin-top: 15px; }
  .collection-wrapper .order-up {
    order: -1; }
  .lookbook .lookbook-block .lookbook-dot .dot-showbox {
    width: 90px;
    top: -70px; }
    .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
      line-height: 16px; }
  .layout7-product .product-box .details-product {
    padding: 5px; }
  .layout7-product .product-box:hover .details-product {
    animation: none; }
  .checkout-page .checkout-form .checkout-details {
    padding: 15px; }
  .padding-cls {
    padding-left: 15px;
    padding-right: 15px; }
  .success-text i {
    font-size: 40px; }
  .success-text p {
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 1.2; }
  .success-text h2 {
    margin-bottom: 10px; }
  .order-success-sec > div {
    margin-top: 15px; }
  .delivery-sec {
    padding: 15px;
    margin-top: 15px; }
    .delivery-sec h3,
    .delivery-sec h2 {
      font-size: 20px; }
  .product-order h3 {
    font-size: 20px; }
  .product-order .product-order-detail .order_detail h4,
  .product-order .product-order-detail .order_detail h5 {
    font-size: 14px;
    line-height: 1.2; }
  .product-order .total-sec ul li {
    font-size: 16px; }
  .product-order .final-total h3 {
    font-size: 16px; }
  .beauty-about .service .service-block1 {
    margin-bottom: 20px; }
    .beauty-about .service .service-block1:last-child {
      margin-bottom: 0; }
  .main-menu .menu-left .navbar i {
    font-size: 24px; }
  .rtl .breadcrumb-section .page-title h2 {
    text-align: center; }
  .rtl .blog-detail-page .blog-detail .post-social li + li {
    padding-right: 0;
    margin-right: 0;
    border-right: none; }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
    border-right: 1px solid #dddddd; }
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
  .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    padding: 10px 34px 10px 34px; }
  .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
    border-left: none; }
    .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before {
      left: 25px; }
  .rtl .home-slider .slick-next,
  .rtl .center-slider .slick-next {
    right: unset;
    left: 1px; }
  .rtl .home-slider .slick-prev,
  .rtl .center-slider .slick-prev {
    left: unset;
    right: 1px; }
  .rtl .home-slider:hover .slick-next,
  .rtl .center-slider:hover .slick-next {
    right: unset;
    left: 30px; }
  .rtl .home-slider:hover .slick-prev,
  .rtl .center-slider:hover .slick-prev {
    left: unset;
    right: 30px; }
  .rtl .layout2-logo {
    padding-left: 0; }
  .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart {
    left: unset !important;
    right: -118px !important; }
  .rtl .main-menu .menu-right .icon-nav .onhover-div .show-div.setting {
    right: unset;
    left: 0 !important; }
  .rtl .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
    right: unset;
    left: 15px; }
  .rtl .layout3-menu .brand-logo {
    transform: translateX(-15%); }
  .rtl .top-header .header-dropdown li i {
    padding-left: 0; }
  .rtl .top-header .header-dropdown .mobile-account .onhover-show-div {
    right: unset;
    left: 0; }
  .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
    border-color: #404040 !important; }
  .dark .tools-service.absolute-banner .absolute-bg {
    box-shadow: none; }
  .dark .full-banner .banner-contain {
    background-color: rgba(0, 0, 0, 0.2); } }

@media (min-width: 577px) {
  .title1 .title-inner1 {
    margin-bottom: 20px;
    line-height: 1.3; }
  .rtl .onhover-dropdown:before {
    right: unset;
    left: 3px; }
  .rtl .onhover-dropdown .onhover-show-div {
    right: unset;
    left: 0; }
  .rtl .main-menu .menu-left .navbar {
    padding: 40px 0 40px 45px; }
  .rtl .main-menu .category-nav-right .navbar {
    padding: 40px 45px 40px 0; }
  .rtl .main-menu .menu-right .icon-nav li {
    padding-left: 0;
    padding-right: 20px; }
  .rtl .main-menu .menu-right .onhover-div .show-div.shopping-cart, .rtl .main-menu .menu-right .onhover-div .show-div.setting {
    left: 0;
    right: unset; }
  .rtl .main-menu .menu-right .onhover-div .show-div.shopping-cart li .close-circle {
    right: unset;
    left: 0; }
  .rtl .main-menu .menu-right .onhover-div .show-div.shopping-cart li .total h5 span {
    float: left; } }

@media (max-width: 480px) {
  .Toastify__toast-container {
    width: 90vw;
    margin: 0 auto; }
  .pet-parallax .full-banner {
    padding-top: 40px;
    padding-bottom: 40px; }
  .quick-view-modal .product-buttons .btn {
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px; }
  .title1 .title-inner1 {
    margin-bottom: 20px; }
  .title2 .title-inner2 {
    margin-bottom: 20px; }
  .title3 .line {
    margin-bottom: 20px; }
  .title4 .line {
    margin-bottom: 20px; }
  .title-borderless {
    margin-bottom: 20px; }
  .subscribe-form.classic-form .form-control {
    margin-left: 0; }
  .product-box .img-block {
    min-height: 290px; }
  .theme-tab .tab-title li {
    padding-left: 5px;
    padding-right: 5px; }
  .theme-tab .tab-title2 {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400; }
    .theme-tab .tab-title2 li {
      padding-left: 15px;
      padding-right: 15px; }
    .theme-tab .tab-title2:after {
      display: none; }
  .blog-details {
    padding-left: 0;
    padding-right: 0; }
  .full-banner {
    padding-top: 40px;
    padding-bottom: 40px; }
    .full-banner.parallax-layout {
      padding-top: 4px;
      padding-bottom: 40px; }
    .full-banner .banner-contain h2 {
      font-size: 60px; }
    .full-banner .banner-contain h4 {
      font-size: 18px; }
  .collection-banner .contain-banner {
    padding-left: 30px;
    padding-right: 30px; }
    .collection-banner .contain-banner h2 {
      font-size: 24px; }
  .home-slider .home {
    height: 55vh; }
  .home-slider .slider-contain {
    height: 55vh; }
    .home-slider .slider-contain h1 {
      font-size: 28px; }
    .home-slider .slider-contain h4 {
      letter-spacing: 0.1em;
      line-height: 1.4 !important; }
    .home-slider .slider-contain .btn-solid,
    .home-slider .slider-contain .btn-outline {
      margin-top: 5px; }
  .home-slider:hover .slick-prev,
  .home-slider:hover .slick-next {
    transform: scale(1.2); }
  .home-slider:hover .slick-next {
    right: 60px; }
  .home-slider:hover .slick-prev {
    left: 60px; }
  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 448px; }
  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 30px 30px; }
    .footer-theme2.section-light .footer-block .subscribe-white .form-control {
      letter-spacing: 3px; }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 35px; }
    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
      margin-bottom: 10px; }
    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
      margin-bottom: 15px; }
    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .form-control {
      height: 40px;
      padding: 0; }
    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .btn-solid,
    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .btn-outline {
      padding: 8px 15px; }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
    font-size: 15px; }
  .tab-bg,
  .bg-block {
    padding: 35px 25px 30px 25px; }
  .about-page p {
    line-height: 1.5; }
  .testimonial .testimonial-slider .media {
    display: inline-block; }
    .testimonial .testimonial-slider .media img {
      margin: 0 auto;
      height: 100px;
      width: 100px; }
    .testimonial .testimonial-slider .media .media-body {
      padding: 0;
      text-align: center; }
  .blog-detail-page .blog-contact h2 {
    font-size: 30px;
    margin-bottom: 25px; }
  .blog-detail-page .blog-contact .theme-form input,
  .blog-detail-page .blog-contact .theme-form textarea {
    padding: 12px 20px;
    margin-bottom: 20px; }
  .blog-detail-page .blog-detail h3 {
    font-size: 14px;
    margin-bottom: 15px; }
  .blog-detail-page .blog-detail img {
    margin-bottom: 25px; }
  .blog-detail-page .blog-advance ul {
    line-height: 1.5;
    font-size: 14px; }
  .blog-detail-page .blog-advance img {
    margin-bottom: 20px;
    margin-top: 10px; }
  .blog-detail-page .blog-advance p {
    line-height: 1.5; }
  .blog-detail-page .comment-section li {
    padding-top: 30px;
    padding-bottom: 30px; }
    .blog-detail-page .comment-section li p {
      line-height: 1.5; }
    .blog-detail-page .comment-section li h6 {
      margin-top: 10px;
      margin-bottom: 10px; }
      .blog-detail-page .comment-section li h6 span {
        display: inherit;
        margin-left: 0; }
  .blog-detail-page .comment-section .media {
    display: inline-block;
    text-align: center; }
  .faq-section .theme-accordion .card .card-body p {
    line-height: 1.5 !important; }
  .faq-section .theme-accordion .card .card-header button {
    line-height: 1.3;
    padding: 12px 35px 12px 12px !important; }
  .faq-section .accordion.theme-accordion .card .card-header button:before {
    top: 20px; }
  .pwd-page {
    padding: 70px 0; }
    .pwd-page h2 {
      font-size: 25px;
      margin-bottom: 15px; }
  .login-page .authentication-right h6 {
    margin-bottom: 10px; }
  .login-page .theme-card {
    padding: 25px; }
  .register-page .theme-card {
    padding: 18px; }
    .register-page .theme-card .theme-form input {
      margin-bottom: 20px;
      padding: 12px 15px; }
  .search-block .btn-solid {
    padding: 10px 15px; }
  .contact-page .map iframe {
    height: 275px; }
  .contact-page .theme-form input {
    padding: 12px 15px;
    margin-bottom: 18px; }
  .blog-page .blog-media .blog-right h6 {
    margin-bottom: 0; }
  .blog-page .blog-media .blog-right p {
    line-height: 1.3; }
  .blog-page .blog-media .blog-right h4 {
    margin-bottom: 5px; }
  .blog-page .blog-media .blog-right ul {
    margin-bottom: 5px; }
    .blog-page .blog-media .blog-right ul li {
      display: block; }
      .blog-page .blog-media .blog-right ul li + li {
        padding-left: 0;
        margin-left: 0;
        border-left: none; }
  .account-sidebar {
    width: 45%; }
  .success-text h2 {
    font-size: 20px; }
  .success-text p {
    font-size: 14px; }
  .delivery-sec h3,
  .delivery-sec h2 {
    font-size: 18px; }
  .rtl .theme-tab .tab-title2 {
    -webkit-padding-start: 0; }
  .rtl .blog-page .blog-media .blog-right ul li + li {
    padding-right: 0;
    margin-right: 0;
    border-right: 0; }
  .rtl .blog-detail-page .comment-section li p,
  .rtl .blog-detail-page .comment-section li h6 {
    text-align: center; }
  .rtl .product-right .product-icon .product-social li {
    padding-left: 16px; }
  .rtl .tab-product .nav-material.nav-tabs .nav-item .nav-link {
    text-align: right; }
  .rtl .tab-product .nav-material.nav-tabs .nav-item .nav-link,
  .rtl .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
    padding: 15px; }
  .rtl .testimonial .testimonial-slider .media .media-body p {
    text-align: center; } }

@media (max-width: 420px) {
  h2 {
    font-size: 22px !important;
    line-height: 1.4 !important; }
  .subscribe h4 {
    display: block; }
  footer .btn-solid {
    padding: 7px 8px; }
  footer p {
    line-height: 25px; }
  .blog-details p {
    font-size: 1.5rem;
    line-height: 1.3; }
  .product-box .img-wrapper .cart-box i,
  .product-wrap .img-wrapper .cart-box i {
    font-size: 16px;
    padding-left: 4px;
    padding-right: 4px; }
  .addtocart_count .full-box .center-slider .offer-slider .product-box .cart-info {
    display: none; }
  .addtocart_count .full-box .center-slider .offer-slider .product-box .addtocart_btn {
    display: none; }
  .addtocart_count .full-box .center-slider .offer-slider .product-box .product-detail {
    display: flex;
    align-items: center; }
  .timer span .padding-l {
    padding-left: 5px; }
  .about-text p {
    line-height: 22px; }
  .footer-theme .sub-title h4 {
    margin-bottom: 5px; }
  .footer-theme .sub-title .contact-list li {
    line-height: 25px; }
  .footer-theme2 p {
    line-height: 25px; }
  .breadcrumb-section {
    padding: 15px 0; }
  .footer-light .subscribe-form .form-control {
    width: 280px; }
  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 388px; }
  .white-layout .footer-theme2 .footer-block .subscribe-white .btn-solid,
  .white-layout .footer-theme2 .footer-block .subscribe-white .btn-outline {
    padding: 13px 8px; }
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .btn-solid,
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .btn-outline {
    padding: 13px 8px; }
  .footer-theme2 .subscribe-block .form-control {
    letter-spacing: 3px; }
  .subscribe-form {
    display: inline-block; }
    .subscribe-form .form-group {
      margin-bottom: 10px; }
  .category-border div .category-banner .category-box h2 {
    font-size: 25px; }
  .no-slider .product-box {
    flex: 0 0 100%;
    max-width: calc(100% - 30px);
    margin: 0 15px 30px !important; }
    .no-slider .product-box:nth-last-child(1) {
      margin: 0 15px 0 !important; }
    .no-slider .product-box:nth-last-child(2) {
      margin: 0 15px 30px !important; }
  .full-banner .banner-contain h2 {
    font-size: 55px; }
  .full-banner .banner-contain h3 {
    font-size: 36px; }
  .home-slider .slider-contain h1 {
    font-size: 25px;
    margin-top: 10px; }
  .home-slider .slider-contain h4 {
    letter-spacing: 0.01em;
    line-height: 1.5; }
  .home-slider .slider-contain .btn-solid,
  .home-slider .slider-contain .btn-outline {
    margin-top: 10px;
    padding: 5px 18px; }
  .home-slider:hover .slick-prev,
  .home-slider:hover .slick-next {
    transform: scale(1.5); }
  .home-slider:hover .slick-next {
    right: 50px; }
  .home-slider:hover .slick-prev {
    left: 50px; }
  .theme-card.center-align {
    display: unset;
    height: unset;
    align-items: unset; }
  .theme-card.card-border .offer-slider img {
    padding: 0 15px 0 15px; }
  .full-box .center-slider .offer-slider .product-box {
    display: flex;
    border-bottom: 1px solid #f1f5f4;
    border-top: 1px solid #f1f5f4; }
    .full-box .center-slider .offer-slider .product-box .img-wrapper img {
      height: 120px; }
    .full-box .center-slider .offer-slider .product-box .product-info {
      text-align: left; }
      .full-box .center-slider .offer-slider .product-box .product-info h6 {
        padding-top: 5px; }
      .full-box .center-slider .offer-slider .product-box .product-info .color-variant {
        padding-top: 2px; }
        .full-box .center-slider .offer-slider .product-box .product-info .color-variant li {
          height: 15px;
          width: 15px;
          padding-right: 3px; }
    .full-box .center-slider .offer-slider .product-box .product-detail {
      padding-left: 0; }
      .full-box .center-slider .offer-slider .product-box .product-detail h4 {
        font-size: 20px; }
      .full-box .center-slider .offer-slider .product-box .product-detail .color-variant {
        padding-top: 0; }
    .full-box .center-slider .offer-slider .product-box:hover .product-info .btn-outline {
      display: none; }
    .full-box .center-slider .offer-slider .product-box:hover .product-info .color-variant {
      opacity: 1; }
  .full-box .theme-card .offer-slider .sec-1 {
    display: inline; }
    .full-box .theme-card .offer-slider .sec-1 .product-box2 {
      border-bottom: 1px solid #f1f5f4; }
      .full-box .theme-card .offer-slider .sec-1 .product-box2:last-child {
        border-bottom: none; }
      .full-box .theme-card .offer-slider .sec-1 .product-box2 + .product-box2 {
        border-left: none; }
  .center-slider .offer-slider .product-box .product-detail {
    text-align: left !important; }
    .center-slider .offer-slider .product-box .product-detail .rating i {
      padding-right: 3px; }
    .center-slider .offer-slider .product-box .product-detail h4 {
      padding-bottom: 0.5rem; }
    .center-slider .offer-slider .product-box .product-detail .color-variant li {
      height: 18px;
      width: 18px;
      padding-right: 3px; }
  .center-slider .offer-slider .product-box .img-wrapper .cart-box {
    display: none; }
  .product-box .img-wrapper .lable-block .lable3 {
    font-size: 13px;
    padding: 15px 10px; }
  .product-box .img-block .lable-wrapper .lable1,
  .product-box .img-block .lable-wrapper .lable2 {
    padding: 10px 14px 10px 20px;
    font-size: 12px; }
  .team h6 {
    line-height: 15px; }
  .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
    padding: 30px; }
    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
      font-size: 30px; }
  .login-page .authentication-right p {
    line-height: 1.5; }
  .login-page .theme-card .theme-form input {
    padding: 15px 20px;
    margin-bottom: 25px; }
  .tab-product .nav-material.nav-tabs {
    display: block; }
    .tab-product .nav-material.nav-tabs .nav-item {
      width: 100%; }
      .tab-product .nav-material.nav-tabs .nav-item .nav-link {
        padding: 10px 20px; }
  .vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
  .vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
    padding: 10px 20px; }
  .product-right .product-buttons .btn-solid,
  .product-right .product-buttons .btn-outline {
    padding: 7px 13px; }
  .product-right.product-form-box .timer {
    padding-left: 29px; }
    .product-right.product-form-box .timer span {
      width: 45px; }
  .product-right .timer {
    padding-left: 35px; }
    .product-right .timer span {
      width: 45px; }
  .product-right .product-icon .product-social {
    margin-top: 0; }
    .product-right .product-icon .product-social li {
      padding-right: 5px; }
      .product-right .product-icon .product-social li a i {
        font-size: 14px; }
  .product-right .product-icon .wishlist-btn i {
    font-size: 14px;
    padding-left: 10px;
    margin-left: 5px; }
  .product-right .product-icon .wishlist-btn span {
    font-size: 14px; }
  .tab-product .theme-form input,
  .tab-product .theme-form textarea {
    font-size: 12px;
    padding: 8px 15px; }
  .order-box .sub-total .shipping {
    width: unset;
    float: unset;
    display: flex; }
    .order-box .sub-total .shipping .shopping-option:last-child {
      padding-left: 20px; }
  .checkout-page .checkout-form .form-group {
    margin-bottom: 15px; }
  .checkout-page .checkout-form input[type='text'], .checkout-page .checkout-form input[type='email'], .checkout-page .checkout-form input[type='password'], .checkout-page .checkout-form input[type='tel'], .checkout-page .checkout-form input[type='number'], .checkout-page .checkout-form input[type='url'] {
    height: 40px; }
  .checkout-page .checkout-form select,
  .checkout-page .checkout-form textarea {
    height: 40px; }
  .rtl .full-box .center-slider .offer-slider .product-box .product-detail .rating,
  .rtl .full-box .center-slider .offer-slider .product-box .product-detail h4,
  .rtl .full-box .center-slider .offer-slider .product-box .product-detail h6,
  .rtl .full-box .center-slider .offer-slider .product-box .product-detail .color-variant {
    text-align: right; }
  .rtl .order-box .sub-total .shopping-option label {
    padding-right: 0; } }

@media (max-width: 360px) {
  .btn-solid,
  .btn-outline {
    padding: 10px 15px; }
  .pet-parallax .full-banner {
    padding-top: 30px;
    padding-bottom: 30px; }
  h2 {
    font-size: 18px; }
  .category-button .col {
    flex-basis: unset; }
  .footer-theme2 .subscribe-block {
    padding: 0 30px; }
  .white-layout .small-section .footer-theme2 .col {
    padding: 0 15px; }
  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 330px; }
  .footer-theme2.section-light .footer-block .subscribe-white {
    padding: 20px 20px; }
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .form-control {
    border: none; }
  .footer-end p {
    font-size: 13px; }
  .category-border div .category-banner .category-box h2 {
    padding: 7px 15px; }
  .full-banner {
    padding-top: 30px;
    padding-bottom: 30px; }
    .full-banner.parallax-layout {
      padding-top: 30px;
      padding-bottom: 30px; }
    .full-banner .banner-contain h2 {
      font-size: 55px; }
    .full-banner .banner-contain h3 {
      font-size: 30px; }
  .home-slider .home {
    height: 50vh; }
  .home-slider .slider-contain {
    height: 50vh; }
    .home-slider .slider-contain h1 {
      font-size: 25px; }
    .home-slider .slider-contain .btn-solid,
    .home-slider .slider-contain .btn-outline {
      margin-top: 8px;
      padding: 5px 15px; }
  .home-slider:hover .slick-prev,
  .home-slider:hover .slick-next {
    transform: scale(1.1); }
  .home-slider:hover .slick-next {
    right: 40px; }
  .home-slider:hover .slick-prev {
    left: 40px; }
  .layout-7 .home .slider-contain {
    height: 50vh; }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
    font-size: 13px; }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
    height: 12px;
    width: 12px; }
  .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 115px; }
  .blog-detail-page .blog-detail p {
    line-height: 1.5; }
  .blog-detail-page .blog-contact h2 {
    font-size: 20px;
    margin-bottom: 20px; }
  .about-page p {
    line-height: 1.2; }
  .theme-modal .modal-dialog .modal-content .modal-body {
    padding: 10px; }
    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
      padding: 20px; }
      .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close {
        right: 10px; }
      .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control {
        padding-top: 10px;
        padding-bottom: 10px; }
      .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
        font-size: 20px;
        margin-bottom: 10px; }
  .collection-product-wrapper .product-pagination .pagination .page-item a {
    padding: 15px 13px; }
  .collection-product-wrapper .product-pagination .product-search-count-bottom {
    padding-left: 10px; }
    .collection-product-wrapper .product-pagination .product-search-count-bottom h5 {
      line-height: 15px; }
  .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    width: 80%; }
  .login-page .theme-card {
    padding: 18px; }
  .search-block .btn-solid {
    padding: 10px 5px; }
  .search-block .form-header .input-group input {
    padding: 10px 12px; }
  .contact-page .map iframe {
    height: 200px; }
  .cart-section tbody tr td,
  .wishlist-section tbody tr td {
    min-width: 115px; }
    .cart-section tbody tr td .mobile-cart-content .col-xs-3,
    .wishlist-section tbody tr td .mobile-cart-content .col-xs-3 {
      margin-left: 5px;
      margin-right: 5px; }
    .cart-section tbody tr td a img,
    .wishlist-section tbody tr td a img {
      height: 80px; }
  .cart-section tfoot tr td,
  .wishlist-section tfoot tr td {
    padding-right: 27px; }
    .cart-section tfoot tr td h2,
    .wishlist-section tfoot tr td h2 {
      font-size: 21px; }
  .wishlist-section .wishlist-buttons:last-child a {
    margin-left: 3px; }
  .product-buttons .btn-solid,
  .product-buttons .btn-outline {
    padding: 7px 16px; }
  .product-description .qty-box .input-group {
    width: 100%; }
  .single-product-tables.detail-section table {
    width: 70%; }
  .product-right .timer {
    padding-left: 25px; }
    .product-right .timer span .padding-l {
      padding-left: 10px; }
  .product-form-box .timer span {
    width: 47px; }
    .product-form-box .timer span .padding-l {
      padding-left: 9px; }
  .product-form-box .product-buttons .btn-solid,
  .product-form-box .product-buttons .btn-outline {
    padding: 5px 5px; }
  .product-form-box .timer span,
  .border-product .timer span {
    width: 45px; }
    .product-form-box .timer span .padding-l,
    .border-product .timer span .padding-l {
      padding-right: 5px; }
  .rtl .product-right .product-icon .product-social li {
    padding-left: 7px; }
  .rtl .banner-timer .timer {
    padding-left: 0;
    padding-right: 20px; }
    .rtl .banner-timer .timer span {
      width: 50px; } }

@media (max-width: 320px) {
  .footer-theme .footer-logo {
    margin-bottom: 20px; }
  .footer-theme2 .col {
    padding: 10px 5px; }
  .white-layout .footer-theme2 .col {
    padding: 0 15px; }
  .white-layout .footer-theme2 .footer-block .footer-container {
    max-width: 290px; }
  .dark-layout .footer-theme2 .subscribe-wrapper .subscribe-block .form-group .form-control {
    letter-spacing: 1px; }
  .footer-theme2.section-light .footer-block .subscribe-white .form-control {
    letter-spacing: 1px; }
  .collection-banner .contain-banner.banner-3 {
    padding-left: 25px;
    padding-right: 25px; }
    .collection-banner .contain-banner.banner-3 h2 {
      font-size: 15px; }
  .collection-banner .contain-banner.banner-4 {
    padding-left: 35px;
    padding-right: 35px; }
    .collection-banner .contain-banner.banner-4 h2 {
      font-size: 15px; } }

.landing-page-multikart {
  /*=====================
    1.General CSS start
==========================*/
  /*=====================
    2.Header CSS start
==========================*/
  /*=====================
    3.body CSS start
==========================*/
  /*=====================
    4.Responsive CSS start
==========================*/ }
  .landing-page-multikart body {
    position: relative;
    font-size: 14px;
    font-family: 'Lora', serif;
    line-height: 1.5; }
  .landing-page-multikart h1 {
    font-size: 56px;
    text-transform: uppercase;
    color: #fff;
    line-height: 2;
    font-weight: 800;
    letter-spacing: 0.04em; }
    .landing-page-multikart h1 span {
      font-weight: 700;
      font-size: 56px; }
  .landing-page-multikart h2 {
    font-weight: 100;
    font-size: 36px;
    text-transform: capitalize;
    line-height: 1.5; }
  .landing-page-multikart h3 {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1.5; }
  .landing-page-multikart h4 {
    font-size: 24px;
    font-weight: 400; }
  .landing-page-multikart h5 {
    font-size: 16px;
    font-weight: 400;
    color: #555555; }
  .landing-page-multikart ul {
    color: #fff;
    padding-left: 0;
    margin-bottom: 0; }
  .landing-page-multikart li {
    font-size: 20px;
    line-height: 1; }
  .landing-page-multikart p {
    font-size: 16px;
    color: black; }
  .landing-page-multikart a {
    text-decoration: none;
    color: black; }
    .landing-page-multikart a:hover {
      text-decoration: none;
      color: black; }
  .landing-page-multikart .blur-up {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 400ms, -webkit-filter 400ms; }
    .landing-page-multikart .blur-up.lazyloaded {
      -webkit-filter: blur(0);
      filter: blur(0); }
  .landing-page-multikart .btn:focus {
    box-shadow: none; }
  .landing-page-multikart section,
  .landing-page-multikart .section-t-space {
    padding-top: 70px; }
  .landing-page-multikart .section-b-space {
    padding-bottom: 70px; }
  .landing-page-multikart .section-space {
    padding-bottom: 20px; }
  .landing-page-multikart .section-lr {
    padding-left: 80px;
    padding-right: 80px; }
  .landing-page-multikart .title-landing {
    font-size: 20px;
    color: #000000;
    margin-bottom: 15px;
    text-transform: capitalize;
    font-weight: 700; }
  .landing-page-multikart .btn {
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    font-weight: 500;
    color: white;
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 25px;
    width: 200px;
    display: flex;
    justify-content: center; }
    .landing-page-multikart .btn:hover {
      border: 1px solid #b5dccd;
      color: white;
      background-color: #b5dccd; }
  .landing-page-multikart .btn-primary {
    padding: 12px 40px;
    background-color: #b5dccd;
    font-size: 16px;
    border: 1px solid #b5dccd;
    color: white;
    margin: 0 auto; }
    .landing-page-multikart .btn-primary:active, .landing-page-multikart .btn-primary:focus {
      background-color: transparent !important;
      border: 1px solid #b5dccd !important;
      color: #b5dccd !important;
      box-shadow: none !important; }
  .landing-page-multikart .red {
    background-color: #ff4a42; }
  .landing-page-multikart .orange {
    background-color: #ffbe26; }
  .landing-page-multikart .green {
    background-color: #11c52c; }
  .landing-page-multikart header {
    box-shadow: 0px 0px 5px 0px rgba(153, 153, 153, 0.62);
    background-color: #b5dccd;
    z-index: 999; }
    .landing-page-multikart header.sticky {
      position: fixed;
      margin-top: 3vh;
      top: 0;
      width: 100%;
      transition: all 0.5s ease;
      animation: smoothScroll 1s forwards; }
  .landing-page-multikart .navbar-light .navbar-nav .nav-link {
    color: black; }
    .landing-page-multikart .navbar-light .navbar-nav .nav-link:hover {
      color: #b5dccd;
      transition: all 0.5s ease; }
  .landing-page-multikart .navbar-light .navbar-nav .nav-item a {
    text-transform: capitalize; }
    .landing-page-multikart .navbar-light .navbar-nav .nav-item a.active {
      color: #b5dccd;
      transition: all 0.5s ease; }
  .landing-page-multikart .navbar-light .navbar-toggler:focus {
    outline: none; }
  .landing-page-multikart .top-header {
    display: flex;
    height: 100%;
    align-items: center; }
    .landing-page-multikart .top-header .logo img {
      display: flex;
      height: 100%;
      align-items: center; }
    .landing-page-multikart .top-header .nav-link {
      font-weight: 700; }
  .landing-page-multikart .navbar {
    padding: 0; }
    .landing-page-multikart .navbar li.nav-item {
      font-size: 16px;
      padding: 0 10px; }
  .landing-page-multikart .purchase-btn {
    letter-spacing: 0.3em;
    color: white;
    background: #b5dccd;
    width: 160px;
    height: 65px;
    font-weight: 500;
    border-radius: unset;
    font-size: 15px; }

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px); }
  100% {
    transform: translateY(0px); } }
  .landing-page-multikart .main-img {
    position: relative;
    background-image: url("/assets/images/landing-Page/home.jpg");
    height: 93vh;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden; }
    .landing-page-multikart .main-img .main-contain {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 93vh;
      width: 100%; }
      .landing-page-multikart .main-img .main-contain > div {
        background-color: rgba(248, 248, 248, 0.5);
        z-index: 9;
        background-blend-mode: overlay;
        padding: 0 0 22px 0; }
      .landing-page-multikart .main-img .main-contain h1 {
        color: #b5dccd;
        font-weight: 600;
        padding-bottom: 0; }
        .landing-page-multikart .main-img .main-contain h1 span {
          color: black; }
      .landing-page-multikart .main-img .main-contain h3 {
        color: black;
        padding-bottom: 25px; }
        .landing-page-multikart .main-img .main-contain h3 span {
          color: #b5dccd;
          font-weight: 700; }
      .landing-page-multikart .main-img .main-contain h4 {
        color: black; }
        .landing-page-multikart .main-img .main-contain h4 span {
          color: #b5dccd; }
  .landing-page-multikart .home-decor .decor {
    position: absolute; }
    .landing-page-multikart .home-decor .decor img {
      box-shadow: 0px 38px 62px -40px rgba(0, 0, 0, 0.2); }
    .landing-page-multikart .home-decor .decor.decor-1 {
      top: 11%;
      left: 8%; }
      .landing-page-multikart .home-decor .decor.decor-1 img {
        animation: movebounce 3.2s linear infinite; }
    .landing-page-multikart .home-decor .decor.decor-2 {
      top: 13%;
      left: 22%; }
      .landing-page-multikart .home-decor .decor.decor-2 img {
        animation: movebounce 2s linear infinite; }
    .landing-page-multikart .home-decor .decor.decor-3 {
      top: 29%;
      left: 2%; }
      .landing-page-multikart .home-decor .decor.decor-3 img {
        animation: movebounce 2.6s linear infinite; }
    .landing-page-multikart .home-decor .decor.decor-4 {
      top: 44%;
      left: 23%; }
      .landing-page-multikart .home-decor .decor.decor-4 img {
        animation: movebounce 2.2s linear infinite; }
    .landing-page-multikart .home-decor .decor.decor-5 {
      left: -1%;
      top: 58%; }
      .landing-page-multikart .home-decor .decor.decor-5 img {
        animation: movebounce 3.2s linear infinite;
        box-shadow: none; }
    .landing-page-multikart .home-decor .decor.decor-6 {
      top: 72%;
      left: 25%; }
      .landing-page-multikart .home-decor .decor.decor-6 img {
        animation: movebounce 3.02s linear infinite; }
    .landing-page-multikart .home-decor .decor.decor-7 {
      top: 9%;
      right: 0; }
      .landing-page-multikart .home-decor .decor.decor-7 img {
        animation: movebounce 2.3s linear infinite; }
    .landing-page-multikart .home-decor .decor.decor-8 {
      top: 17%;
      right: 22%; }
      .landing-page-multikart .home-decor .decor.decor-8 img {
        animation: movebounce 2.9s linear infinite; }
    .landing-page-multikart .home-decor .decor.decor-9 {
      top: 45%;
      right: 15%; }
      .landing-page-multikart .home-decor .decor.decor-9 img {
        animation: movebounce 2s linear infinite; }
    .landing-page-multikart .home-decor .decor.decor-10 {
      top: 45%;
      right: -1%; }
      .landing-page-multikart .home-decor .decor.decor-10 img {
        animation: movebounce 3s linear infinite;
        box-shadow: none; }
    .landing-page-multikart .home-decor .decor.decor-11 {
      top: 66%;
      right: 22%; }
      .landing-page-multikart .home-decor .decor.decor-11 img {
        animation: movebounce 2.6s linear infinite; }
  .landing-page-multikart .main-demo-section .feature-image img {
    border-radius: 25px;
    box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.04); }
  .landing-page-multikart .font-style {
    font-family: 'Pacifico', cursive;
    color: #656565;
    font-weight: 400;
    font-size: 22px;
    position: relative;
    text-transform: capitalize; }
  .landing-page-multikart .color-varient {
    display: flex;
    padding-inline-start: 0px;
    padding-top: 5px;
    margin-bottom: 0; }
    .landing-page-multikart .color-varient li {
      list-style: none;
      height: 10px;
      width: 10px;
      border-radius: 100%;
      margin-right: 5px;
      cursor: pointer; }

@keyframes movebounce {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(5px); }
  100% {
    transform: translateY(0px); } }
  .landing-page-multikart .feature p {
    max-width: 40%;
    margin: 0 auto 20px; }
  .landing-page-multikart .feature_row .feature-box {
    padding: 0 15px; }
    .landing-page-multikart .feature_row .feature-box p {
      margin-bottom: 0;
      line-height: 1.5; }
    .landing-page-multikart .feature_row .feature-box .bottom {
      display: flex;
      margin-top: 10px; }
      .landing-page-multikart .feature_row .feature-box .bottom h5 {
        margin-bottom: 0;
        color: #000000;
        font-weight: 600;
        text-transform: capitalize; }
        .landing-page-multikart .feature_row .feature-box .bottom h5 i {
          margin-right: 10px; }
        .landing-page-multikart .feature_row .feature-box .bottom h5 + h5 {
          margin-left: 15px; }
    .landing-page-multikart .feature_row .feature-box .icon {
      margin-top: 10px; }
      .landing-page-multikart .feature_row .feature-box .icon img + img {
        margin-left: 5px; }
    .landing-page-multikart .feature_row .feature-box:hover .feature-image {
      transform: scale(1.01); }
  .landing-page-multikart .feature_row .feature-image {
    border-radius: 25px;
    box-shadow: 0 0 40px 0 rgba(51, 51, 51, 0.06);
    transition: all 0.5s ease; }
    .landing-page-multikart .feature_row .feature-image img {
      border-radius: 25px;
      box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.04); }
  .landing-page-multikart .feature_row .feature-content {
    display: flex;
    padding-top: 25px; }
    .landing-page-multikart .feature_row .feature-content h6 {
      padding-left: 18px;
      font-weight: 600;
      text-transform: capitalize; }
  .landing-page-multikart .feature_row > div:nth-child(-n + 4) {
    margin-bottom: 30px; }
  .landing-page-multikart .admin-title h4 {
    font-weight: 700;
    color: black;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    text-transform: capitalize;
    letter-spacing: 0.2em; }
  .landing-page-multikart .demo-slider {
    position: relative;
    background: url("/assets/images/landing-page/f-bg.jpg");
    height: 50vh;
    background-repeat: no-repeat;
    background-size: contain; }
    .landing-page-multikart .demo-slider .demo-contain {
      position: absolute;
      justify-content: center; }
      .landing-page-multikart .demo-slider .demo-contain h2 {
        color: #f6f2f6;
        font-weight: bold;
        font-size: 175px;
        top: 30%;
        left: 5%; }
    .landing-page-multikart .demo-slider .demo {
      position: relative;
      display: flex;
      z-index: 9;
      width: 100%;
      justify-content: center;
      text-align: center; }
      .landing-page-multikart .demo-slider .demo .right-part {
        display: flex;
        align-items: center;
        margin-top: 100px; }
      .landing-page-multikart .demo-slider .demo h2 {
        font-weight: 700;
        color: black;
        font-size: 60px; }
      .landing-page-multikart .demo-slider .demo h4 {
        font-weight: 700;
        color: black;
        font-size: 22px;
        letter-spacing: 0.2em; }
  .landing-page-multikart .res-img {
    display: none; }
  .landing-page-multikart .main-demo {
    position: relative;
    margin-top: -35vh;
    z-index: 9; }
  .landing-page-multikart .sticker {
    margin-top: 100px;
    margin-left: 45px; }
  .landing-page-multikart .email-img {
    box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.1); }
  .landing-page-multikart ul.tabs {
    list-style: none;
    text-align: center;
    padding: 0 5px; }
    .landing-page-multikart ul.tabs li {
      color: #000000;
      display: inline-block;
      padding: 10px 15px;
      cursor: pointer;
      border-radius: 25px;
      margin: 0 6px;
      transition: all 0.5s ease;
      font-size: 18px; }
      .landing-page-multikart ul.tabs li.current {
        background: #b5dccd;
        color: white;
        box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.21);
        transition: all 0.5s ease; }
  .landing-page-multikart .tab-content {
    display: none; }
    .landing-page-multikart .tab-content[style='display: block;'] .demo-box img {
      animation: zoomIn 300ms ease-in-out; }
    .landing-page-multikart .tab-content.current {
      display: inherit; }
      .landing-page-multikart .tab-content.current .demo-box img {
        animation: zoomIn 300ms ease-in-out; }
  .landing-page-multikart .demo-box {
    margin-top: 35px;
    position: relative; }
    .landing-page-multikart .demo-box img {
      box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.1);
      transition: all 0.5s ease; }
    .landing-page-multikart .demo-box h3 {
      line-height: 28px;
      font-size: 22px;
      font-weight: 500;
      margin-top: 16px;
      color: black;
      text-align: center;
      text-transform: capitalize; }
    .landing-page-multikart .demo-box h6 {
      font-size: 14px;
      letter-spacing: 0.1em;
      color: #586082; }
    .landing-page-multikart .demo-box:hover img {
      transform: translateY(-3px);
      transition: all 0.5s ease; }
    .landing-page-multikart .demo-box:hover .ribbon-1 {
      transform: translateY(-3px);
      transition: all 0.5s ease; }
  .landing-page-multikart .badge {
    font-size: 11px;
    margin-left: 10px;
    vertical-align: 10px;
    line-height: 1.4;
    padding: 1px 6px;
    font-weight: 700;
    position: relative;
    padding-right: 15px; }
    .landing-page-multikart .badge:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-right: 7px solid #ff4c3b;
      border-bottom: 5px solid transparent;
      left: -5px;
      top: 2.1px; }
    .landing-page-multikart .badge:after {
      content: '';
      position: absolute;
      height: 7px;
      width: 7px;
      background-color: #ffffff;
      border-radius: 5px;
      top: 3.5px;
      right: 4px; }
    .landing-page-multikart .badge.badge-danger {
      background-color: #b5dccd; }
  .landing-page-multikart .special-feature {
    background-color: #f5f4f4; }
    .landing-page-multikart .special-feature .feature_row .feature-content {
      padding-top: 0;
      padding-bottom: 20px; }
    .landing-page-multikart .special-feature .feature-sec > div {
      margin-bottom: 40px; }
      .landing-page-multikart .special-feature .feature-sec > div:nth-last-child(-n + 3) {
        margin-bottom: 0; }
  .landing-page-multikart .email-section {
    background: url("/assets/images/landing-page/email-bg.png"); }
  .landing-page-multikart .cart {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .landing-page-multikart .gallery {
    background: #f9f8f8;
    overflow: hidden; }
  .landing-page-multikart .grey-bg {
    background: #f9f8f8; }
  .landing-page-multikart .main-feature .key-feature > div {
    margin-top: 70px; }
    .landing-page-multikart .main-feature .key-feature > div:nth-child(-n + 6) {
      margin-top: 0; }
  .landing-page-multikart .main-feature .key-feature .theme-collection .image-contain {
    background-color: rgba(249, 249, 249, 0.7);
    padding: 50px;
    transition: all 0.5s ease; }
    .landing-page-multikart .main-feature .key-feature .theme-collection .image-contain img {
      filter: grayscale(100%);
      transition: all 0.5s ease; }
    .landing-page-multikart .main-feature .key-feature .theme-collection .image-contain .set-image {
      text-align: center; }
  .landing-page-multikart .main-feature .key-feature .theme-collection h5 {
    margin-top: 20px;
    text-align: center;
    color: #555;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    padding-bottom: 0;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 700; }
  .landing-page-multikart .main-feature .key-feature .theme-collection:hover .image-contain {
    box-shadow: 0px 0px 26px 3px #e2e2e2;
    transition: all 0.5s ease; }
  .landing-page-multikart .main-feature .key-feature .theme-collection:hover h5 {
    color: #b5dccd;
    transition: all 0.3s ease; }
  .landing-page-multikart .main-feature .key-feature .theme-collection:hover img {
    filter: grayscale(0);
    transition: all 0.5s ease; }
  .landing-page-multikart .support-section {
    position: relative; }
    .landing-page-multikart .support-section .support-content {
      text-align: center;
      display: flex; }
      .landing-page-multikart .support-section .support-content h4 {
        margin-top: 25px;
        font-size: 18px;
        text-transform: capitalize;
        margin-bottom: 0;
        font-weight: 400; }
      .landing-page-multikart .support-section .support-content p {
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 16px;
        color: black; }
    .landing-page-multikart .support-section .support-text {
      position: absolute;
      left: 0;
      top: 0; }
    .landing-page-multikart .support-section .support-image {
      margin-top: 35px; }
  .landing-page-multikart .footer-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center; }
    .landing-page-multikart .footer-section .rate-section li {
      display: inline-block;
      padding: 0 3px; }
      .landing-page-multikart .footer-section .rate-section li i {
        color: #ffd203;
        font-size: 36px; }
    .landing-page-multikart .footer-section h2 {
      letter-spacing: 0.1em;
      margin: 20px 0 30px 0;
      line-height: 1.2; }
  .landing-page-multikart .footer {
    background: url("/assets/images/landing-page/footer.jpg"); }
  .landing-page-multikart .effect-cls {
    position: relative; }
    .landing-page-multikart .effect-cls:after {
      content: '';
      position: absolute;
      background: url("/assets/images/game/top.png");
      top: -1px;
      width: 100%;
      height: 85px;
      z-index: 1;
      background-repeat: no-repeat; }
    .landing-page-multikart .effect-cls:before {
      content: '';
      position: absolute;
      background: url("/assets/images/game/back.png");
      bottom: -12px;
      width: 100%;
      height: 110px;
      z-index: 1; }
    .landing-page-multikart .effect-cls.footer-effect:before {
      display: none; }
  .landing-page-multikart .tap-top {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 999;
    display: none;
    color: #fff;
    text-align: center;
    background: #b5dccd;
    border: none;
    font-size: 22px;
    padding: 7px;
    cursor: pointer; }
    .landing-page-multikart .tap-top.top-cls {
      bottom: 130px; }
  @media (max-width: 1800px) {
    .landing-page-multikart .home-decor .decor img {
      width: 55%; } }
  @media (max-width: 1430px) {
    .landing-page-multikart .feature p {
      max-width: 70%; }
    .landing-page-multikart .feature_row > div {
      margin-bottom: 30px; }
      .landing-page-multikart .feature_row > div:nth-last-child(-n + 2) {
        margin-bottom: 0; }
    .landing-page-multikart .main-demo-section .feature_row > div {
      margin-bottom: 0; }
    .landing-page-multikart .main-demo-section .feature_row .feature-box {
      padding: 0; }
    .landing-page-multikart .feature_row .feature-box {
      padding: 0; }
      .landing-page-multikart .feature_row .feature-box p {
        font-size: 14px; } }
  @media (max-width: 1199px) {
    .landing-page-multikart section,
    .landing-page-multikart .section-t-space {
      padding-top: 60px; }
    .landing-page-multikart .section-b-space {
      padding-bottom: 60px; }
    .landing-page-multikart .main-feature .key-feature > div {
      margin-top: 30px; }
      .landing-page-multikart .main-feature .key-feature > div:nth-child(-n + 6) {
        margin-top: 30px; }
      .landing-page-multikart .main-feature .key-feature > div:nth-child(-n + 4) {
        margin-top: 0; }
    .landing-page-multikart .main-demo {
      margin-top: -40vh; }
    .landing-page-multikart .main-img {
      height: 70vh; }
      .landing-page-multikart .main-img .main-contain {
        height: 70vh; }
    .landing-page-multikart .main-demo-section .feature_row .feature-box p {
      font-size: 14px; }
    .landing-page-multikart .main-demo-section .feature_row .feature-box .bottom h5 {
      font-size: 14px; } }
  @media (max-width: 991px) {
    .landing-page-multikart section,
    .landing-page-multikart .section-t-space {
      padding-top: 50px; }
    .landing-page-multikart .section-b-space {
      padding-bottom: 50px; }
    .landing-page-multikart .navbar-collapse {
      position: fixed;
      width: 100%;
      top: 55px;
      z-index: 99;
      left: 0px;
      background-color: white;
      text-align: center;
      padding-bottom: 10px; }
    .landing-page-multikart .main-img {
      height: 65vh; }
      .landing-page-multikart .main-img .main-contain {
        height: 65vh; }
        .landing-page-multikart .main-img .main-contain > div {
          background-color: transparent;
          padding: 0; }
    .landing-page-multikart .section-lr {
      padding-left: 40px;
      padding-right: 40px; }
    .landing-page-multikart .home-decor {
      display: none; }
    .landing-page-multikart .top-header .btn {
      width: 145px;
      height: 55px; }
    .landing-page-multikart .main-img .main-contain h1 {
      font-size: 45px; }
      .landing-page-multikart .main-img .main-contain h1 span {
        font-size: 45px; }
    .landing-page-multikart .main-img .main-contain h3 {
      font-size: 25px; }
    .landing-page-multikart .main-img .main-contain h4 {
      font-size: 23px; }
    .landing-page-multikart .feature p {
      max-width: unset;
      margin: 0 auto; }
    .landing-page-multikart .mr-auto,
    .landing-page-multikart .mx-auto {
      margin-right: 15px !important; }
    .landing-page-multikart ul.tabs li {
      padding: 8px 12px; }
    .landing-page-multikart .demo-box h3 {
      font-size: 20px; }
    .landing-page-multikart .gif {
      width: 90%; }
    .landing-page-multikart .key-feature > div {
      margin-top: 70px; }
      .landing-page-multikart .key-feature > div:nth-child(-n + 6) {
        margin-top: 0; }
    .landing-page-multikart .responsive-img {
      background: url("/assets/images/landing-Page/respnsive-img.jpg");
      background-blend-mode: overlay;
      background-color: rgba(241, 241, 241, 0.88); }
    .landing-page-multikart .main-demo {
      margin-top: -43vh; }
    .landing-page-multikart .demo-slider .demo .right-part {
      margin-top: 60px; }
    .landing-page-multikart .special-feature .feature-sec > div:nth-last-child(-n + 3) {
      margin-bottom: 40px; }
    .landing-page-multikart .special-feature .feature-sec > div:nth-last-child(-n + 1) {
      margin-bottom: 0; }
    .landing-page-multikart .footer-section h2 {
      font-size: 20px;
      margin: 5px 0 10px 0; }
    .landing-page-multikart .footer-section .rate-section li {
      padding: 0; }
      .landing-page-multikart .footer-section .rate-section li i {
        font-size: 20px; }
    .landing-page-multikart .btn-primary {
      padding: 8px 10px; }
    .landing-page-multikart .btn {
      width: 170px; } }
  @media (max-width: 767px) {
    .landing-page-multikart section,
    .landing-page-multikart .section-t-space {
      padding-top: 40px; }
    .landing-page-multikart .section-b-space {
      padding-bottom: 40px; }
    .landing-page-multikart .main-img {
      height: 60vh; }
      .landing-page-multikart .main-img .main-contain {
        height: 60vh; }
        .landing-page-multikart .main-img .main-contain h1 {
          font-size: 36px; }
          .landing-page-multikart .main-img .main-contain h1 span {
            font-size: 36px; }
        .landing-page-multikart .main-img .main-contain h3 {
          padding-bottom: 14px; }
    .landing-page-multikart .feature_row > div:nth-last-child(-n + 2) {
      margin-bottom: 30px; }
    .landing-page-multikart .feature_row > div:nth-last-child(-n + 1) {
      margin-bottom: 0; }
    .landing-page-multikart .main-feature .key-feature > div:nth-child(-n + 4) {
      margin-top: 30px; }
    .landing-page-multikart .main-feature .key-feature > div:nth-child(-n + 2) {
      margin-top: 0; }
    .landing-page-multikart h1 {
      font-size: 40px; }
    .landing-page-multikart .main-img .main-contain h3 {
      font-size: 20px; }
    .landing-page-multikart .main-img .main-contain h4 {
      font-size: 20px; }
    .landing-page-multikart .feature-image {
      width: 100%;
      margin: 0 auto;
      text-align: center; }
    .landing-page-multikart .feature-content {
      justify-content: center; }
    .landing-page-multikart .para {
      text-align: center; }
    .landing-page-multikart .main-demo {
      margin-top: -13vh; }
    .landing-page-multikart .demo-slider {
      height: 21vh;
      background-size: cover; }
      .landing-page-multikart .demo-slider .demo h2 {
        font-size: 35px; }
      .landing-page-multikart .demo-slider .demo h5 {
        font-size: 15px; }
      .landing-page-multikart .demo-slider .demo h6 {
        font-size: 12px; }
    .landing-page-multikart ul.tabs li {
      padding: 5px 9px;
      font-size: 16px; }
    .landing-page-multikart .demo-box h3 {
      font-size: 18px; }
    .landing-page-multikart .gallery2 {
      padding-top: 30px; }
    .landing-page-multikart .key-feature > div:nth-last-child(-n + 21) {
      margin-top: 35px; }
    .landing-page-multikart .support-section .support-content {
      justify-content: center; }
    .landing-page-multikart .tap-top {
      right: 20px; }
    .landing-page-multikart .email-section .center-part {
      order: -1; }
    .landing-page-multikart .email-section .left-part,
    .landing-page-multikart .email-section .right-part {
      display: none; }
    .landing-page-multikart .full-img {
      display: none; }
    .landing-page-multikart .res-img {
      display: block; }
    .landing-page-multikart .purchase-btn {
      letter-spacing: 0.09em;
      font-size: 14px; }
    .landing-page-multikart .top-header .btn {
      width: auto;
      height: 45px; }
    .landing-page-multikart .btn {
      padding: 12px 10px; }
    .landing-page-multikart .main-demo-section .feature_row > div:nth-child(-n + 2) {
      margin-bottom: 30px; }
    .landing-page-multikart .main-demo-section .feature_row .feature-box .bottom {
      justify-content: center; }
    .landing-page-multikart .main-demo-section .feature_row .feature-box .icon {
      text-align: center; } }
  @media (max-width: 640px) and (min-width: 599px) {
    .landing-page-multikart .main-demo {
      margin-top: 0vh; } }
  @media (max-width: 600px) {
    .landing-page-multikart .main-demo {
      margin-top: -13vh; } }
  @media (max-width: 576px) {
    .landing-page-multikart .section-b-space {
      padding-bottom: 30px; }
    .landing-page-multikart section,
    .landing-page-multikart .section-t-space {
      padding-top: 30px; }
    .landing-page-multikart p {
      font-size: 14px; }
    .landing-page-multikart .navbar-toggler {
      padding: 6px; }
    .landing-page-multikart .navbar-toggler-icon {
      width: 20px;
      height: 20px; }
    .landing-page-multikart .main-img {
      height: 58vh; }
      .landing-page-multikart .main-img .main-contain {
        height: 58vh; }
    .landing-page-multikart .sticker {
      display: none; }
    .landing-page-multikart .main-img .main-contain img {
      width: 53%; }
    .landing-page-multikart .section-lr {
      padding-left: 30px;
      padding-right: 30px; }
    .landing-page-multikart h5 {
      font-size: 18px; }
    .landing-page-multikart h6 {
      font-size: 16px; }
    .landing-page-multikart .demo-slider {
      height: 25vh;
      background-size: cover;
      background-blend-mode: overlay;
      background-color: rgba(252, 252, 252, 0.67); }
      .landing-page-multikart .demo-slider .demo h2 {
        font-size: 25px; }
      .landing-page-multikart .demo-slider .demo h5 {
        font-size: 14px; }
      .landing-page-multikart .demo-slider .demo h4 {
        font-size: 18px; }
    .landing-page-multikart .demo-box {
      margin-top: 30px; }
      .landing-page-multikart .demo-box h3 {
        font-size: 16px;
        margin-bottom: 0;
        margin-top: 10px; }
    .landing-page-multikart .theme-collection h5 {
      font-size: 16px; }
    .landing-page-multikart .support-section .support-content {
      justify-content: center; }
      .landing-page-multikart .support-section .support-content h4 {
        font-size: 17px; }
    .landing-page-multikart .tap-top {
      width: 35px;
      height: 35px;
      padding: 2px;
      font-size: 19px; } }
  @media (max-width: 480px) {
    .landing-page-multikart section,
    .landing-page-multikart .section-t-space {
      padding: 35px 0; }
    .landing-page-multikart .section-lr {
      padding-left: 0;
      padding-right: 0; }
    .landing-page-multikart .main-img .main-contain h1 {
      font-size: 30px;
      padding-bottom: 0; }
      .landing-page-multikart .main-img .main-contain h1 span {
        font-size: 30px; }
    .landing-page-multikart .main-img .main-contain h3 {
      font-size: 16px;
      padding-bottom: 15px; }
    .landing-page-multikart .main-img .main-contain h4 {
      font-size: 14px; }
    .landing-page-multikart h5 {
      font-size: 16px; }
    .landing-page-multikart h6 {
      font-size: 14px; }
    .landing-page-multikart .main-img .main-contain h3 {
      font-size: 16px; }
    .landing-page-multikart .main-img .main-contain h4 {
      font-size: 16px; }
    .landing-page-multikart .demo-slider .demo h2 {
      font-size: 20px; }
    .landing-page-multikart .demo-slider .demo h5 {
      font-size: 12px; }
    .landing-page-multikart .demo-box h3 {
      font-size: 14px; }
    .landing-page-multikart .theme-collection h5 {
      font-size: 16px; }
    .landing-page-multikart .support-section .support-content {
      justify-content: center; }
      .landing-page-multikart .support-section .support-content h4 {
        font-size: 16px; }
    .landing-page-multikart .main-img {
      height: 55vh; }
      .landing-page-multikart .main-img .main-contain {
        height: 55vh; } }
  @media (max-width: 360px) {
    .landing-page-multikart section,
    .landing-page-multikart .section-t-space {
      padding: 30px 0; }
    .landing-page-multikart h1 {
      font-size: 25px; }
    .landing-page-multikart .main-img .main-contain h1 {
      font-size: 25px;
      padding-bottom: 10px; }
      .landing-page-multikart .main-img .main-contain h1 span {
        font-size: 25px; }
    .landing-page-multikart .main-img .main-contain h3 {
      font-size: 14px;
      padding-bottom: 10px; }
    .landing-page-multikart .main-img .main-contain h4 {
      font-size: 14px; }
    .landing-page-multikart .demo-slider .demo h2 {
      font-size: 15px; }
    .landing-page-multikart .demo-slider .demo h5 {
      font-size: 10px; }
    .landing-page-multikart .main-feature .key-feature .theme-collection .image-contain {
      padding: 25px; }
    .landing-page-multikart .support-section .support-content h4 {
      font-size: 14px; }
    .landing-page-multikart .support-section .support-content p {
      font-size: 14px; }
    .landing-page-multikart .main-img {
      height: 50vh; }
      .landing-page-multikart .main-img .main-contain {
        height: 50vh; } }
  @media (max-width: 320px) {
    .landing-page-multikart h1 {
      font-size: 20px; }
    .landing-page-multikart .demo-slider .demo h2 {
      font-size: 15px; }
    .landing-page-multikart .demo-slider .demo h5 {
      font-size: 10px; }
    .landing-page-multikart .rate-section h2 {
      font-size: 15px; }
    .landing-page-multikart .rate-section ul li i {
      font-size: 15px; } }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0.3rem;
  -moz-border-radius: 0.3rem;
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: 0.25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

/**=====================
    Admin CSS Start
==========================**/
.card-left {
  z-index: 1; }

.checkbox-space {
  padding: 0; }

.translation-list .ReactTable .rt-thead .rt-tr {
  text-align: left; }

.react-datepicker__input-container input {
  border-radius: 0.25rem;
  padding: 0.48rem 0.75rem;
  border: 1px solid #ced4da; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range {
  background-color: #ff8084 !important; }

.coupon-list-delete .ReactTable .rt-table .rt-tr .rt-th:last-child .rt-resizable-header-content {
  padding: 10px !important; }

.rt-th,
.rt-resizable-header {
  width: 0; }

.rt-td {
  width: 0; }
  .rt-td div {
    padding: 5px; }

.form-label-center .form-group label {
  margin-top: 8px; }

.form-label-center .form-group .form-group {
  margin-bottom: 0; }

.tab-coupon li {
  color: #ff8084; }

.styles_modal__gNwvD {
  padding: 0; }
  .styles_modal__gNwvD button svg {
    width: 18px; }

.product-page-details h2 {
  margin-bottom: 5px !important; }

.product-page-details label {
  margin-bottom: 0 !important; }

.product-page-details .product-price h3 {
  color: #222222; }

.product-slider {
  margin-bottom: 20px; }

.small-slick .slick-slide.slick-current div .item {
  border: 1px solid #ff8084; }

.ReactTable .rt-tr {
  align-items: center; }

.rt-resizable-header-content {
  padding: 18px;
  background: #f8f8f9; }

.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin: 0 20px 0 0; }
  .checkbox_animated:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: rotate(-45deg) scale(0, 0);
    content: '';
    position: absolute;
    left: 0.25rem;
    top: 0.225rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.375rem;
    border: 2px solid #ff8084;
    border-top-style: none;
    border-right-style: none; }
  .checkbox_animated:after {
    content: '';
    position: absolute;
    top: -0.125rem;
    left: 0;
    width: 1.3rem;
    height: 1.3rem;
    background: #ffffff;
    border: 2px solid #e8ebf2;
    cursor: pointer; }
  .checkbox_animated:checked:before {
    transform: rotate(-45deg) scale(1, 1); }

.radio_animated {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer; }
  .radio_animated:before {
    transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
    transform: scale(0, 0);
    content: '';
    position: absolute;
    top: 0;
    left: 0.125rem;
    z-index: 1;
    width: 0.75rem;
    height: 0.75rem;
    background: #ff8084;
    border-radius: 50%; }
  .radio_animated:after {
    content: '';
    position: absolute;
    top: -0.25rem;
    left: -0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    background: #ffffff;
    border: 2px solid #e8ebf2;
    border-radius: 50%; }
  .radio_animated:checked:before {
    transform: scale(1, 1); }

a:hover {
  color: #ff8084; }

.btn-popup {
  margin-bottom: 30px; }

.add-product .bigImg img {
  width: 500px; }

.add-product ul li {
  display: flex;
  margin-bottom: 15px; }
  .add-product ul li .box-input-file {
    width: 50px;
    height: 50px;
    background-color: #dddddd;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle; }
    .add-product ul li .box-input-file .upload {
      position: absolute;
      width: 70px;
      left: 0;
      right: 0;
      opacity: 0; }

.file-upload-product .uploadImg {
  width: 50px !important;
  height: 50px !important; }

.dropzone {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding: 50px;
  border: 2px dashed #ff8084;
  border-radius: 15px;
  border-image: none;
  background: transparent;
  box-sizing: border-box;
  min-height: 150px;
  position: relative; }
  .dropzone i {
    font-size: 50px;
    color: #ff8084; }
  .dropzone .dzu-input {
    border: 1px solid #ddd;
    padding: 6px;
    margin-left: 15px; }
  .dropzone .dzu-previewStatusContainer,
  .dropzone .dzu-submitButtonContainer {
    display: none; }
  .dropzone .dzu-previewImage {
    width: 75px; }

.digital-add .col-form-label {
  font-family: Nunito; }

.digital-add .form-control {
  font-size: 14px; }

.digital-add .form-group .radio_animated {
  margin-right: 16px; }

.digital-add textarea {
  width: 100%; }

.add-product-form .form-group {
  align-items: center; }
  .add-product-form .form-group label {
    font-size: 16px;
    font-weight: 600; }
  .add-product-form .form-group:last-child {
    align-items: end; }
  .add-product-form .form-group .form-control {
    font-size: 14px; }

.add-product-form fieldset {
  padding-left: 15px;
  border: none; }

.add-product-form .qty-box {
  width: 15%; }
  .add-product-form .qty-box .touchspin {
    text-align: center; }
  .add-product-form .qty-box .bootstrap-touchspin-prefix {
    display: none; }
  .add-product-form .qty-box .bootstrap-touchspin-postfix {
    display: none; }
  .add-product-form .qty-box .input-group {
    justify-content: flex-start; }
    .add-product-form .qty-box .input-group button {
      padding: 12px; }
      .add-product-form .qty-box .input-group button i {
        font-weight: 900;
        color: #222222; }
    .add-product-form .qty-box .input-group .btn-primary {
      background-color: transparent !important;
      border: 1px solid #ddd !important; }
      .add-product-form .qty-box .input-group .btn-primary.bootstrap-touchspin-down {
        border-right: none !important; }
    .add-product-form .qty-box .input-group .input-group-append {
      margin-left: 0; }
      .add-product-form .qty-box .input-group .input-group-append .btn {
        border-left: none !important; }

.chart-block canvas {
  width: 100%; }

.sales-carousel .value-graph h3 {
  color: #313131;
  font-weight: 600; }

.sales-carousel .media .small-box {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center; }

.small-chartjs canvas {
  width: 100% !important;
  height: 60px !important; }

.order-graph h6 {
  color: #313131;
  font-family: Nunito;
  font-weight: 700;
  margin-bottom: 20px; }

.order-graph .order-graph-bottom {
  margin-top: 30px; }
  .order-graph .order-graph-bottom h6 {
    color: #999;
    margin-left: 15px;
    margin-right: 30px; }
    .order-graph .order-graph-bottom h6 span {
      color: #313131; }
  .order-graph .order-graph-bottom .media {
    margin-bottom: 20px; }
    .order-graph .order-graph-bottom .media:last-child {
      margin-bottom: 0; }
    .order-graph .order-graph-bottom .media .order-shape-primary {
      background-color: #ff8084;
      width: 18px;
      height: 8px;
      margin-top: 7px;
      border-radius: 4px; }
    .order-graph .order-graph-bottom .media .order-shape-secondary {
      background-color: #13c9ca;
      width: 18px;
      height: 8px;
      margin-top: 7px;
      border-radius: 4px; }
    .order-graph .order-graph-bottom .media .order-shape-success {
      background-color: #81ba00;
      width: 18px;
      height: 8px;
      margin-top: 7px;
      border-radius: 4px; }
    .order-graph .order-graph-bottom .media .order-shape-danger {
      background-color: #a5a5a5;
      width: 18px;
      height: 8px;
      margin-top: 7px;
      border-radius: 4px; }
    .order-graph .order-graph-bottom .media .order-shape-info {
      background-color: #00a8ff;
      width: 18px;
      height: 8px;
      margin-top: 7px;
      border-radius: 4px; }
    .order-graph .order-graph-bottom .media .order-shape-light {
      background-color: #f8f8f9;
      width: 18px;
      height: 8px;
      margin-top: 7px;
      border-radius: 4px; }
    .order-graph .order-graph-bottom .media .order-shape-dark {
      background-color: #2a3142;
      width: 18px;
      height: 8px;
      margin-top: 7px;
      border-radius: 4px; }
    .order-graph .order-graph-bottom .media .order-shape-warning {
      background-color: #ffbc58;
      width: 18px;
      height: 8px;
      margin-top: 7px;
      border-radius: 4px; }
  .order-graph .order-graph-bottom .order-color-primary {
    background-color: #ff8084;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px; }
  .order-graph .order-graph-bottom .order-color-secondary {
    background-color: #13c9ca;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px; }
  .order-graph .order-graph-bottom .order-color-success {
    background-color: #81ba00;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px; }
  .order-graph .order-graph-bottom .order-color-danger {
    background-color: #a5a5a5;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px; }
  .order-graph .order-graph-bottom .order-color-info {
    background-color: #00a8ff;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px; }
  .order-graph .order-graph-bottom .order-color-light {
    background-color: #f8f8f9;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px; }
  .order-graph .order-graph-bottom .order-color-dark {
    background-color: #2a3142;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px; }
  .order-graph .order-graph-bottom .order-color-warning {
    background-color: #ffbc58;
    width: 8px;
    height: 8px;
    margin-top: 7px;
    border-radius: 2px; }

.datepickers-container {
  top: -86px; }

.datepicker--nav {
  color: #ff8084; }

.datepicker--nav-action {
  color: #ff8084;
  background-color: #ff8084; }

.datepicker--nav-action:hover {
  color: #ff8084; }

.datepicker--nav-title i {
  color: #ff8084; }

.table thead th {
  border-bottom: 2px solid #e8ebf2; }

.authentication-box {
  min-width: 100vw;
  min-height: 100vh;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center; }
  .authentication-box .slick-slider {
    margin-bottom: 30px; }
  .authentication-box .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ff8084; }
  .authentication-box .back-btn {
    float: right;
    margin: 0;
    font-size: 14px;
    position: relative;
    padding-left: 60px !important; }
    .authentication-box .back-btn svg {
      position: absolute;
      left: 30px;
      height: 18px; }
  .authentication-box .row {
    align-items: center; }
  .authentication-box .tab2-card .nav-tabs .nav-link {
    font-size: 18px; }
    .authentication-box .tab2-card .nav-tabs .nav-link svg {
      width: 20px;
      vertical-align: sub;
      margin-right: 5px; }
  .authentication-box .btn-primary {
    border-radius: 25px;
    margin-top: 12px;
    font-weight: 400;
    padding: 11px 45px; }
  .authentication-box .form-footer {
    position: relative;
    padding-top: 25px;
    border-top: 1px solid #f3f3f3;
    margin-top: 25px; }
    .authentication-box .form-footer > span {
      position: absolute;
      top: -11px;
      padding-right: 20px;
      background-color: #fff;
      color: #909090; }
    .authentication-box .form-footer .social {
      margin-bottom: 0;
      border-bottom: 0; }
      .authentication-box .form-footer .social li {
        padding: 10px;
        border-radius: 100%;
        border: 1px solid #ff8084;
        height: 40px;
        width: 40px;
        margin-right: 10px;
        text-align: center;
        display: inline-block; }
  .authentication-box .slick-dots li.slick-active button:before {
    color: #ffffff; }
  .authentication-box .slick-dots li button:before {
    font-size: 12px;
    color: #ffffff; }
  .authentication-box .container {
    max-width: 900px; }
    .authentication-box .container .bg-primary {
      padding: 50px;
      background-image: url("https://artbanana.s3.ap-northeast-2.amazonaws.com/website/street-with-pencil.jpg");
      background-position: center;
      box-shadow: 1px 5px 24px 0 rgba(255, 128, 132, 0.8); }
    .authentication-box .container .form-group {
      margin-bottom: 1.5rem; }
    .authentication-box .container .svg-icon {
      padding: 24px;
      margin: 0 auto;
      border: 2px dashed #ffffff;
      border-radius: 100%;
      height: 130px;
      width: 130px;
      margin-bottom: 40px; }
      .authentication-box .container .svg-icon img {
        width: 100%; }
    .authentication-box .container p {
      color: rgba(255, 255, 255, 0.9);
      font-size: 15px;
      line-height: 2;
      text-align: center; }
    .authentication-box .container h3 {
      color: #ffffff;
      font-weight: 600;
      text-align: center; }

.auth-form .form-control {
  border-radius: 25px;
  padding: 9px 25px;
  border: 1px solid #eaeaea; }

.switch-sm .switch {
  width: 25px;
  height: 16px;
  margin-top: 10px;
  margin-bottom: 0px; }

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li {
  width: 100%; }

.page-wrapper .page-body-wrapper footer p {
  line-height: 1.7; }

.page-wrapper .page-body-wrapper .page-header .row h3 {
  color: #000; }

.widget-cards {
  border-radius: 25px !important; }

.static-top-widget div.align-self-center svg {
  width: 25px;
  height: 25px;
  vertical-align: middle; }

.static-top-widget .media-body {
  align-self: center !important; }
  .static-top-widget .media-body h3 {
    font-family: work-Sans, sans-serif;
    color: #ffffff; }
    .static-top-widget .media-body h3 small {
      font-size: 11px; }

.static-top-widget .icons-widgets .text-center {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center; }

.flot-chart-container {
  height: 400px; }

.user-status table tbody tr td {
  vertical-align: middle; }
  .user-status table tbody tr td .d-inline-block {
    margin-top: 11px; }
  .user-status table tbody tr td .image-sm-size img {
    width: 41px; }

.user-status table tbody tr:last-child td {
  padding-bottom: 0; }

.user-status table thead tr th {
  border-top: 0;
  font-size: 16px;
  color: #2a3142;
  font-weight: 600;
  padding-top: 0; }

.card-block .table-responsive .table caption {
  padding-left: 10px; }

.card-block .table-responsive .table-bordered td {
  vertical-align: middle; }

.card-block .table-border-radius {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.products-admin .product-box {
  padding: 20px; }
  .products-admin .product-box .img-wrapper {
    position: relative; }
  .products-admin .product-box .lable-block .lable3 {
    border-radius: 100%;
    background-color: #ff8084;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    padding: 12px 6px;
    text-transform: uppercase;
    color: #ffffff;
    top: 7px;
    left: 7px;
    z-index: 1; }
  .products-admin .product-box .lable-block .lable4 {
    position: absolute;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    top: 7px;
    right: 7px;
    letter-spacing: 0.1em;
    z-index: 1; }
  .products-admin .product-box .product-detail .rating {
    margin-top: 15px !important; }
    .products-admin .product-box .product-detail .rating i {
      padding-right: 5px; }
      .products-admin .product-box .product-detail .rating i:nth-child(-n + 4) {
        color: #ffa200; }
      .products-admin .product-box .product-detail .rating i:last-child {
        color: #dddddd; }
  .products-admin .product-box .product-detail h6 {
    line-height: 1;
    margin-bottom: 0;
    padding-top: 2px;
    padding-bottom: 5px;
    transition: all 0.5s ease;
    font-size: 16px;
    color: #777777; }
  .products-admin .product-box .product-detail h4 {
    font-size: 18px;
    color: #222222;
    font-weight: 700;
    margin-bottom: 0;
    transition: all 0.5s ease;
    color: #222222 !important; }
  .products-admin .product-box .product-detail del {
    font-size: 14px;
    color: #aaaaaa;
    font-weight: 400; }
  .products-admin .product-box .product-detail .color-variant {
    padding-top: 15px; }
    .products-admin .product-box .product-detail .color-variant li {
      display: inline-block;
      height: 20px;
      width: 20px;
      border-radius: 100%;
      margin-right: 5px;
      transition: all 0.1s ease;
      cursor: pointer; }
  .products-admin .product-box .front {
    position: relative; }
    .products-admin .product-box .front .product-hover {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.7);
      opacity: 0;
      border-radius: 100%;
      transform: scale(0);
      transition: all 0.3s ease; }
      .products-admin .product-box .front .product-hover ul li {
        display: inline-block;
        box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
        padding: 9px 14px;
        background-color: #ffffff;
        font-size: 18px;
        border-radius: 100%;
        line-height: 1.6;
        height: 45px;
        width: 45px;
        margin: 0 3px; }
        .products-admin .product-box .front .product-hover ul li .btn {
          padding: 0;
          background-color: transparent; }
          .products-admin .product-box .front .product-hover ul li .btn .editBtn {
            width: 18px; }
          .products-admin .product-box .front .product-hover ul li .btn svg {
            stroke-width: 1; }
          .products-admin .product-box .front .product-hover ul li .btn .deleteBtn {
            width: 19px;
            margin-top: 0;
            margin-left: -2px; }
    .products-admin .product-box .front:hover .product-hover {
      opacity: 1;
      border-radius: 0%;
      transform: scale(1);
      transition: all 0.3s ease; }

.product-right .timer {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 40px;
  display: inline-block;
  margin-top: 10px;
  background-color: #f7f7f7; }
  .product-right .timer p {
    color: #222222;
    font-size: 18px; }
  .product-right .timer span {
    width: 70px;
    display: inline-block; }
    .product-right .timer span .padding-l {
      padding-left: 22px;
      display: inline; }
  .product-right .timer .timer-cal {
    font-size: 12px;
    color: #777777; }

.product-right .size-box {
  margin-top: 10px;
  margin-bottom: 10px; }
  .product-right .size-box ul li {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #f7f7f7;
    text-align: center;
    display: inline-block; }
    .product-right .size-box ul li.active {
      background-color: #f7f7f7 !important; }
    .product-right .size-box ul li a {
      color: #222222 !important;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%; }

.product-right .color-variant {
  margin-bottom: 10px !important; }
  .product-right .color-variant li {
    height: 30px;
    width: 30px;
    cursor: pointer;
    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
    transition: all 0.1s ease;
    vertical-align: middle; }

.product-right .product-title {
  color: #222222;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 0; }
  .product-right .product-title a {
    color: #ff8084 !important; }
  .product-right .product-title p {
    margin-bottom: 0;
    line-height: 1.5em; }
  .product-right .product-title h3 {
    font-size: 26px;
    color: #222222;
    margin-bottom: 15px; }

.product-right h2 {
  text-transform: uppercase;
  margin-bottom: 15px;
  font-size: 25px;
  line-height: 1.2em; }

.bg-light0 {
  background-color: #d0edff; }

.bg-light1 {
  background-color: #f1e7e6; }

.bg-light2 {
  background-color: #bfbfbf; }

.react-tabs .nav-tabs .nav-link {
  color: #333333;
  transition: 0.3s;
  cursor: pointer; }
  .react-tabs .nav-tabs .nav-link.react-tabs__tab--selected, .react-tabs .nav-tabs .nav-link:hover, .react-tabs .nav-tabs .nav-link:focus {
    border-color: transparent transparent transparent !important;
    border-bottom: 2px solid #ff8084 !important;
    color: #ff8084; }

.deactivate-account {
  margin-top: 30px; }
  .deactivate-account .btn {
    margin-top: 20px; }

.account-setting h5 {
  margin-bottom: 20px; }

.profile-card ul {
  margin-bottom: 30px;
  border-bottom: 1px solid #dee2e6; }
  .profile-card ul li svg {
    width: 18px;
    vertical-align: middle; }

.profile-details img {
  margin-bottom: 20px; }

.profile-details .social {
  margin-top: 15px; }
  .profile-details .social .btn-showcase {
    margin-bottom: -10px; }
    .profile-details .social .btn-showcase .btn {
      padding: 8px 13px;
      margin: 0 5px 20px;
      border-radius: 100%;
      width: 40px;
      height: 40px; }
    .profile-details .social .btn-showcase .btn-fb {
      background-color: #50598e;
      color: #ffffff; }
    .profile-details .social .btn-showcase .btn-google {
      background-color: #c64e40;
      color: #ffffff; }
    .profile-details .social .btn-showcase .btn-twitter {
      background-color: #6fa2d8;
      color: #ffffff; }

.project-status {
  margin-top: 20px; }
  .project-status .media {
    margin-top: 20px; }

.sm-progress-bar {
  height: 6px; }

.profile-table table tbody tr td:first-child {
  min-width: 250px; }

.profile-table table th,
.profile-table table td {
  border-top: none; }

.report-employee .card-header {
  border-bottom: none !important; }

.report-employee .flot-chart-container {
  height: 323px; }

.sales-chart {
  height: 307px; }

.expense-chart #area-chart1 {
  height: 307px; }

.profile-card .media {
  align-items: center; }
  .profile-card .media img {
    margin-right: 20px; }

.dropzone .dz-preview {
  box-shadow: 0px 0px 3px #ff8084; }
  .dropzone .dz-preview .dz-error-message {
    color: #ff8084 !important;
    background: transparent !important;
    border: 1px solid #ff8084 !important; }
    .dropzone .dz-preview .dz-error-message:after {
      border-bottom: 6px solid #ff8084 !important; }

.tab-coupon {
  margin-bottom: 30px; }

.needs-validation .permission-block .attribute-blocks {
  padding-left: 15px; }
  .needs-validation .permission-block .attribute-blocks .row {
    padding-left: 20px; }
  .needs-validation .permission-block .attribute-blocks + .attribute-blocks h5 {
    margin-top: 30px; }
  .needs-validation .permission-block .attribute-blocks h6 {
    border-bottom: 1px solid #eff0f1;
    margin-bottom: 15px;
    padding-bottom: 5px; }

.needs-validation h4 {
  font-weight: 600;
  margin-bottom: 20px; }

.needs-validation input {
  font-size: 14px; }

.needs-validation .form-group {
  align-items: center; }
  .needs-validation .form-group label {
    margin-bottom: 0; }
    .needs-validation .form-group label span {
      color: red; }
  .needs-validation .form-group .checkbox {
    padding-left: 0; }

.needs-validation .editor-label {
  align-items: end; }

.needs-validation .editor-space {
  padding: 0; }

.needs-validation .checkbox input {
  opacity: 0; }

.needs-validation .checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px; }
  .needs-validation .checkbox label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0;
    margin-left: -16px;
    border: 1px solid #e8ebf2;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }

.needs-validation .checkbox input[type='checkbox']:checked + label::before {
  font-family: IcoFont;
  content: '\efab';
  text-align: center;
  line-height: 1;
  font-size: 18px; }

.needs-validation .checkbox-primary input[type='checkbox']:checked + label::before {
  border-color: #ff8084;
  color: #ff8084; }

.needs-validation .radio-animated label {
  margin-right: 20px; }

.needs-validation textarea {
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.badge-warning {
  color: #ffffff; }

.user-list img {
  border-radius: 100%; }

/**=====================
    Admin CSS Ends
  ==========================**/
/**=====================
        Button CSS start
  ==========================**/
.btn {
  font-size: 14px;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 700; }

.btn-lg {
  font-size: 18px; }

.btn-sm {
  font-size: 12px; }

.btn-xs {
  padding: 0.05rem 0.4rem;
  font-size: 11px; }

.large-btn {
  margin-bottom: -20px; }
  .large-btn .btn {
    margin-right: 5px;
    margin-bottom: 15px; }

.input-air-primary {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important; }
  .input-air-primary:focus {
    border-color: #ff8084; }

.input-air-secondary {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important; }
  .input-air-secondary:focus {
    border-color: #13c9ca; }

.input-air-success {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important; }
  .input-air-success:focus {
    border-color: #81ba00; }

.input-air-danger {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important; }
  .input-air-danger:focus {
    border-color: #a5a5a5; }

.input-air-info {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important; }
  .input-air-info:focus {
    border-color: #00a8ff; }

.input-air-light {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important; }
  .input-air-light:focus {
    border-color: #f8f8f9; }

.input-air-dark {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important; }
  .input-air-dark:focus {
    border-color: #2a3142; }

.input-air-warning {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important; }
  .input-air-warning:focus {
    border-color: #ffbc58; }

.form-bg-primary {
  background: #ff8084;
  border-color: #ff8084;
  color: #ffffff; }
  .form-bg-primary:focus {
    border-color: #ff8084;
    background: #ff8084;
    color: #ffffff; }
    .form-bg-primary:focus:active {
      border-color: #ff8084;
      background: #ff8084;
      color: #ffffff; }

.form-bg-secondary {
  background: #13c9ca;
  border-color: #13c9ca;
  color: #ffffff; }
  .form-bg-secondary:focus {
    border-color: #13c9ca;
    background: #13c9ca;
    color: #ffffff; }
    .form-bg-secondary:focus:active {
      border-color: #13c9ca;
      background: #13c9ca;
      color: #ffffff; }

.form-bg-success {
  background: #81ba00;
  border-color: #81ba00;
  color: #ffffff; }
  .form-bg-success:focus {
    border-color: #81ba00;
    background: #81ba00;
    color: #ffffff; }
    .form-bg-success:focus:active {
      border-color: #81ba00;
      background: #81ba00;
      color: #ffffff; }

.form-bg-danger {
  background: #a5a5a5;
  border-color: #a5a5a5;
  color: #ffffff; }
  .form-bg-danger:focus {
    border-color: #a5a5a5;
    background: #a5a5a5;
    color: #ffffff; }
    .form-bg-danger:focus:active {
      border-color: #a5a5a5;
      background: #a5a5a5;
      color: #ffffff; }

.form-bg-info {
  background: #00a8ff;
  border-color: #00a8ff;
  color: #ffffff; }
  .form-bg-info:focus {
    border-color: #00a8ff;
    background: #00a8ff;
    color: #ffffff; }
    .form-bg-info:focus:active {
      border-color: #00a8ff;
      background: #00a8ff;
      color: #ffffff; }

.form-bg-light {
  background: #f8f8f9;
  border-color: #f8f8f9;
  color: #2a3142; }
  .form-bg-light:focus {
    border-color: #f8f8f9;
    background: #f8f8f9;
    color: #2a3142; }
    .form-bg-light:focus:active {
      border-color: #f8f8f9;
      background: #f8f8f9;
      color: #2a3142; }

.form-bg-dark {
  background: #2a3142;
  border-color: #2a3142;
  color: #ffffff; }
  .form-bg-dark:focus {
    border-color: #2a3142;
    background: #2a3142;
    color: #ffffff; }
    .form-bg-dark:focus:active {
      border-color: #2a3142;
      background: #2a3142;
      color: #ffffff; }

.form-bg-warning {
  background: #ffbc58;
  border-color: #ffbc58;
  color: #ffffff; }
  .form-bg-warning:focus {
    border-color: #ffbc58;
    background: #ffbc58;
    color: #ffffff; }
    .form-bg-warning:focus:active {
      border-color: #ffbc58;
      background: #ffbc58;
      color: #ffffff; }

.btn-warning {
  color: #ffffff; }
  .btn-warning:hover, .btn-warning.disabled {
    color: #ffffff; }

.btn-primary-gradien {
  background-image: linear-gradient(to right, #ffa9ac 0%, #ff575c 51%, #ffa9ac 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease; }
  .btn-primary-gradien:focus, .btn-primary-gradien:active, .btn-primary-gradien.active {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #ffa9ac, 0%, #ff575c, 100%, #ffffff) !important; }

.btn-secondary-gradien {
  background-image: linear-gradient(to right, #1ce9ea 0%, #0fa4a5 51%, #1ce9ea 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease; }
  .btn-secondary-gradien:hover, .btn-secondary-gradien:focus, .btn-secondary-gradien:active, .btn-secondary-gradien.active, .btn-secondary-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #1ce9ea, 0%, #0fa4a5, 100%, #ffffff) !important; }

.btn-success-gradien {
  background-image: linear-gradient(to right, #9de300 0%, #659100 51%, #9de300 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease; }
  .btn-success-gradien:hover, .btn-success-gradien:focus, .btn-success-gradien:active, .btn-success-gradien.active, .btn-success-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #9de300, 0%, #659100, 100%, #ffffff) !important; }

.btn-danger-gradien {
  background-image: linear-gradient(to right, #b9b9b9 0%, #919191 51%, #b9b9b9 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease; }
  .btn-danger-gradien:hover, .btn-danger-gradien:focus, .btn-danger-gradien:active, .btn-danger-gradien.active, .btn-danger-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #b9b9b9, 0%, #919191, 100%, #ffffff) !important; }

.btn-warning-gradien {
  background-image: linear-gradient(to right, #ffcc81 0%, #ffac2f 51%, #ffcc81 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease; }
  .btn-warning-gradien:hover, .btn-warning-gradien:focus, .btn-warning-gradien:active, .btn-warning-gradien.active, .btn-warning-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #ffcc81, 0%, #ffac2f, 100%, #ffffff) !important; }

.btn-info-gradien {
  background-image: linear-gradient(to right, #29b6ff 0%, #008dd6 51%, #29b6ff 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease; }
  .btn-info-gradien:hover, .btn-info-gradien:focus, .btn-info-gradien:active, .btn-info-gradien.active, .btn-info-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #29b6ff, 0%, #008dd6, 100%, #ffffff) !important; }

.btn-light {
  margin-left: 7px; }

.btn-light-gradien {
  background-image: linear-gradient(to right, white 0%, #e2e2e6 51%, white 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease; }
  .btn-light-gradien:hover, .btn-light-gradien:focus, .btn-light-gradien:active, .btn-light-gradien.active, .btn-light-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, white, 0%, #e2e2e6, 100%, #ffffff) !important; }

.btn-dark-gradien {
  background-image: linear-gradient(to right, #3a445b 0%, #1a1e29 51%, #3a445b 100%);
  border: none;
  color: #ffffff;
  background-size: auto 200%;
  transition: all 0.3s ease; }
  .btn-dark-gradien:hover, .btn-dark-gradien:focus, .btn-dark-gradien:active, .btn-dark-gradien.active, .btn-dark-gradien.hover {
    transition: 1.5s;
    background-position: right center;
    background-image: linear-gradient(to right, #3a445b, 0%, #1a1e29, 100%, #ffffff) !important; }

[class*='-gradien']:hover {
  background-size: 50% 100%;
  transition: all 0.3s ease;
  color: #ffffff; }

/**=====================
   Button CSS end
  ==========================**/
/**=====================
        Card CSS Start
  ==========================**/
.card {
  margin-bottom: 30px;
  border: 0px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05); }
  .card .card-header {
    background-color: #ffffff;
    border-bottom: none;
    padding: 30px;
    border-bottom: 1px solid #f8f8f9;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
    .card .card-header h5 {
      font-size: 18px;
      margin-bottom: 0;
      text-transform: capitalize;
      font-weight: 600;
      line-height: 24px; }
    .card .card-header > span {
      font-size: 12px;
      color: #777777;
      margin-top: 5px;
      display: block;
      letter-spacing: 1px; }
  .card .card-body {
    padding: 30px;
    background-color: transparent; }
    .card .card-body p:last-child {
      margin-bottom: 0; }
  .card .sub-title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 5px;
    margin-bottom: 8px;
    font-size: 18px; }
  .card .card-footer {
    background-color: #ffffff;
    border-top: 1px solid #f8f8f9;
    padding: 30px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; }

.card-header .nav-material {
  margin-bottom: -13px; }

/**=====================
       Card CSS End
  ==========================**/
/**=====================
        Footer CSS Start
  ==========================**/
footer {
  background-color: #ffffff;
  border-top: 1px solid #f8f8f9;
  padding: 15px;
  bottom: 0;
  left: 0; }
  footer a {
    font-weight: 600; }

.page-wrapper .page-body-wrapper footer {
  margin-left: 255px;
  transition: all 0.3s ease;
  bottom: 0;
  z-index: 8; }
  .page-wrapper .page-body-wrapper footer p {
    color: #a5a5a5; }
    .page-wrapper .page-body-wrapper footer p i {
      color: #a5a5a5;
      margin-left: 5px; }

/**=====================
        Footer CSS Ends
  ==========================**/
/**=====================
        General CSS Start
  ==========================**/
h1 {
  font-size: 60px;
  color: #222222;
  font-weight: 700;
  text-transform: uppercase; }
  h1 span {
    font-size: 107px;
    font-weight: 700;
    color: #ff8084; }

h2 {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 700;
  color: #000000;
  line-height: 1;
  letter-spacing: 0.02em; }

h3 {
  font-size: 24px;
  font-weight: 400;
  color: #777777;
  letter-spacing: 0.03em; }

h4 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 1; }

del {
  font-size: 14px;
  color: #aaaaaa;
  font-weight: 400; }

h5 {
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  line-height: 24px;
  letter-spacing: 0.05em; }

h6 {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
  line-height: 24px; }

body {
  background-color: rgba(246, 246, 246, 0.6);
  font-size: 14px;
  overflow-x: hidden;
  font-family: work-Sans, sans-serif;
  color: #313131;
  position: relative; }

ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
  padding-right: 0px; }

li {
  display: inline-block; }

* a {
  color: #ff8084; }

*.btn:focus {
  box-shadow: none !important; }

p {
  color: #777777;
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px; }

code {
  color: #ff8084 !important;
  background-color: #f8f8f9;
  padding: 3px;
  margin: 0 3px;
  border-radius: 2px; }

a {
  transition: 0.5s ease; }
  a:hover {
    text-decoration: none;
    transition: 0.5s ease; }
  a:focus {
    outline: none; }

button:focus {
  outline: none; }

:focus {
  outline-color: #ff8084; }

.disabled {
  cursor: not-allowed;
  opacity: 0.5; }

svg {
  vertical-align: baseline; }

input:focus {
  outline-color: transparent; }

.txt-dark {
  color: #1b252a !important; }

.txt-success {
  color: #81ba00 !important; }

.txt-danger {
  color: #a5a5a5 !important; }

/**=====================
        General CSS Ends
  ==========================**/
/**=====================
      Header CSS Start
  ==========================**/
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li:hover > a, .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li.active > a {
  color: #313131;
  border-left-color: #2a3142;
  transition: 0.3s; }

.onhover-show-div {
  box-shadow: 0 0 2px 2px #f8f8f9;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0; }

.nav-menus .onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible; }
  .nav-menus .onhover-dropdown:hover .onhover-show-div:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ffffff;
    content: '';
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 2; }
  .nav-menus .onhover-dropdown:hover .onhover-show-div:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #898989;
    content: '';
    top: -7px;
    position: absolute;
    left: 10px;
    z-index: 1; }

/*======= Page Header css Start ======= */
.page-wrapper .page-main-header {
  background-color: #ffffff;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  width: calc(100% - 255px);
  display: flex;
  align-items: center;
  margin-left: 255px;
  transition: 0.3s; }
  .page-wrapper .page-main-header.open {
    margin-left: 0;
    width: 100%;
    transition: 0.3s; }

.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 80px);
  margin-top: 80px;
  padding: 0 15px;
  position: relative;
  background-color: #f8f8f9; }

.page-wrapper .page-body-wrapper .page-header {
  padding-top: 30px;
  padding-bottom: 30px; }
  .page-wrapper .page-body-wrapper .page-header .row {
    align-items: center; }
    .page-wrapper .page-body-wrapper .page-header .row .page-header-left {
      display: flex;
      align-items: center; }
    .page-wrapper .page-body-wrapper .page-header .row h3 {
      font-size: 24px;
      margin-bottom: 0;
      font-weight: 800;
      text-transform: uppercase;
      font-family: Nunito; }
      .page-wrapper .page-body-wrapper .page-header .row h3 small {
        display: block;
        font-size: 12px;
        margin-top: 7px;
        letter-spacing: 1px;
        text-transform: capitalize;
        color: #777777; }
  .page-wrapper .page-body-wrapper .page-header .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0; }
    .page-wrapper .page-body-wrapper .page-header .breadcrumb .breadcrumb-item {
      font-family: Nunito; }
      .page-wrapper .page-body-wrapper .page-header .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
        content: "/"; }
      .page-wrapper .page-body-wrapper .page-header .breadcrumb .breadcrumb-item a svg {
        width: 14px;
        height: 14px;
        vertical-align: text-top; }

/*======= Page Header css ends  ======= */
/**======Main Header css Start ======**/
.page-main-header {
  max-width: 100vw; }
  .page-main-header .main-header-right {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 40px;
    margin: 0; }
    .page-main-header .main-header-right svg {
      color: #ff8084; }
      .page-main-header .main-header-right svg line,
      .page-main-header .main-header-right svg polyline {
        color: #ff8084; }
    .page-main-header .main-header-right .nav-left i {
      margin-right: 20px; }
    .page-main-header .main-header-right .nav-left input:focus {
      outline: 0 !important; }
    .page-main-header .main-header-right .nav-right {
      text-align: right;
      padding-left: 0px; }
      .page-main-header .main-header-right .nav-right ul li .media .dotted-animation {
        position: relative;
        right: -3px;
        top: -7px; }
        .page-main-header .main-header-right .nav-right ul li .media .dotted-animation .animate-circle {
          position: absolute;
          top: -20px;
          right: -4px;
          height: 25px;
          width: 25px;
          z-index: 10;
          border: 5px solid #ff8084;
          border-radius: 70px;
          animation: heartbit 1s ease-out;
          animation-iteration-count: infinite; }
        .page-main-header .main-header-right .nav-right ul li .media .dotted-animation .main-circle {
          width: 6px;
          height: 6px;
          border-radius: 30px;
          background-color: #ff8084;
          position: absolute;
          right: 6px;
          top: -10px; }
      .page-main-header .main-header-right .nav-right ul li svg {
        margin-top: 10px;
        width: 18px;
        height: 18px; }
        .page-main-header .main-header-right .nav-right ul li svg path {
          color: #ff8084; }
      .page-main-header .main-header-right .nav-right ul li .dot {
        width: 3px;
        height: 3px;
        border-radius: 30px;
        background-color: #ff8084;
        position: absolute;
        right: 17px;
        bottom: 6px;
        animation: blink 1.5s infinite; }
      .page-main-header .main-header-right .nav-right ul li .dot-chat {
        right: 4px;
        bottom: -11px; }
      .page-main-header .main-header-right .nav-right .notification {
        position: absolute;
        top: 21px;
        right: -1px;
        font-size: 9px;
        animation: blink 1.5s infinite; }

@keyframes blink {
  0% {
    opacity: 1; }
  70% {
    opacity: 1; }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }
      .page-main-header .main-header-right .nav-right .icon-user {
        font-size: 16px; }

@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0; }
  25% {
    transform: scale(0.1);
    opacity: 0.1; }
  50% {
    transform: scale(0.5);
    opacity: 0.3; }
  75% {
    transform: scale(0.8);
    opacity: 0.5; }
  100% {
    transform: scale(1);
    opacity: 0; } }
      .page-main-header .main-header-right .nav-right > ul {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
        .page-main-header .main-header-right .nav-right > ul > li {
          position: relative;
          border-left: 1px solid #eff0f1;
          padding: 0 20px; }
          .page-main-header .main-header-right .nav-right > ul > li:first-child {
            width: 25%;
            border-left: none; }
            .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group {
              width: 100%;
              position: relative; }
              .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:focus {
                outline-color: transparent; }
              .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group input:focus {
                outline-color: transparent; }
              .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:before {
                position: absolute;
                content: '';
                width: 1px;
                height: 25px;
                background: #e8ebf2;
                left: 51px;
                top: 9px; }
              .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:after {
                position: absolute;
                content: '\f002';
                font-family: FontAwesome;
                top: 11px;
                left: 22px;
                color: #8e8e8e; }
          .page-main-header .main-header-right .nav-right > ul > li:last-child {
            border-left: none;
            padding-right: 0; }
          .page-main-header .main-header-right .nav-right > ul > li:nth-child(5) {
            border-left: none;
            border-right: 1px solid #eff0f1;
            padding-left: 0; }
          .page-main-header .main-header-right .nav-right > ul > li h6 {
            margin-top: 4px;
            margin-bottom: 4px;
            color: #ff8084; }
            .page-main-header .main-header-right .nav-right > ul > li h6 ul {
              left: inherit;
              right: -10px;
              width: 130px; }
              .page-main-header .main-header-right .nav-right > ul > li h6 ul:before, .page-main-header .main-header-right .nav-right > ul > li h6 ul:after {
                left: inherit;
                right: 10px; }
              .page-main-header .main-header-right .nav-right > ul > li h6 ul li {
                display: block; }
                .page-main-header .main-header-right .nav-right > ul > li h6 ul li a {
                  font-size: 14px;
                  color: #2a3142; }
                  .page-main-header .main-header-right .nav-right > ul > li h6 ul li a i {
                    margin-left: 10px;
                    font-size: 13px;
                    color: #2a3142; }
        .page-main-header .main-header-right .nav-right > ul .flag-icon {
          font-size: 16px; }
      .page-main-header .main-header-right .nav-right .notification-dropdown {
        top: 57px; }
      .page-main-header .main-header-right .nav-right .language-dropdown {
        width: 160px;
        text-align: left;
        top: 57px; }
        .page-main-header .main-header-right .nav-right .language-dropdown li {
          padding-top: 10px; }
          .page-main-header .main-header-right .nav-right .language-dropdown li a {
            color: #2a3142; }
            .page-main-header .main-header-right .nav-right .language-dropdown li a i {
              margin-right: 10px; }
          .page-main-header .main-header-right .nav-right .language-dropdown li:first-child {
            padding-top: 0; }
      .page-main-header .main-header-right .nav-right .profile-dropdown {
        right: -10px;
        left: inherit;
        width: 150px;
        top: 63px; }
        .page-main-header .main-header-right .nav-right .profile-dropdown:before, .page-main-header .main-header-right .nav-right .profile-dropdown:after {
          left: inherit;
          right: 10px; }
        .page-main-header .main-header-right .nav-right .profile-dropdown li {
          display: block;
          text-align: left;
          padding-top: 10px; }
          .page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(3) {
            padding-bottom: 10px; }
          .page-main-header .main-header-right .nav-right .profile-dropdown li:nth-child(4) {
            border-top: 1px solid #eff0f1; }
          .page-main-header .main-header-right .nav-right .profile-dropdown li:first-child {
            padding-top: 0; }
          .page-main-header .main-header-right .nav-right .profile-dropdown li:hover a {
            color: #ff8084;
            transition: 0.3s; }
            .page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg {
              color: #ff8084 !important; }
              .page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg path,
              .page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg line,
              .page-main-header .main-header-right .nav-right .profile-dropdown li:hover a svg polyline {
                color: #ff8084 !important; }
          .page-main-header .main-header-right .nav-right .profile-dropdown li a {
            color: #313131;
            transition: 0.3s;
            display: flex;
            align-items: center; }
            .page-main-header .main-header-right .nav-right .profile-dropdown li a svg {
              margin-right: 10px;
              color: #313131; }
              .page-main-header .main-header-right .nav-right .profile-dropdown li a svg polyline {
                color: #313131; }
    .page-main-header .main-header-right li {
      display: inline-block;
      position: relative; }

.nav-menus .notification-badge {
  position: absolute;
  right: 10px;
  top: 1px;
  padding: 4px 7px; }

.nav-menus .onhover-dropdown {
  cursor: pointer;
  position: relative; }
  .nav-menus .onhover-dropdown:before {
    display: none; }

.nav-menus .search-form input {
  border: 1px solid #eff0f1;
  padding: 10px 10px 10px 70px;
  border-radius: 50px;
  background-color: #f8f8f9; }

ul.notification-dropdown.onhover-show-div {
  width: 330px;
  right: -18px;
  left: initial; }
  ul.notification-dropdown.onhover-show-div:before, ul.notification-dropdown.onhover-show-div:after {
    left: inherit !important;
    right: 35px !important; }
  ul.notification-dropdown.onhover-show-div li {
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid #f8f8f9;
    text-align: left; }
    ul.notification-dropdown.onhover-show-div li h6 small {
      padding-top: 5px;
      color: #898989;
      font-size: 12px; }
    ul.notification-dropdown.onhover-show-div li span svg {
      margin-top: 0 !important;
      margin-right: 10px;
      vertical-align: text-top; }
    ul.notification-dropdown.onhover-show-div li span .shopping-color path,
    ul.notification-dropdown.onhover-show-div li span .shopping-color line {
      color: #ff8084; }
    ul.notification-dropdown.onhover-show-div li span .download-color path,
    ul.notification-dropdown.onhover-show-div li span .download-color line {
      color: #81ba00; }
    ul.notification-dropdown.onhover-show-div li span .alert-color path,
    ul.notification-dropdown.onhover-show-div li span .alert-color line {
      color: #a5a5a5; }
    ul.notification-dropdown.onhover-show-div li p {
      margin-left: 30px; }
    ul.notification-dropdown.onhover-show-div li + li:hover {
      background-color: #f8f8f9; }

.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: #ffffff;
  transition: all linear 0.3s; }
  .onhover-show-div li a svg {
    margin-top: 0 !important; }
    .onhover-show-div li a svg path,
    .onhover-show-div li a svg line {
      color: #313131 !important; }

/**======Main Header css Ends ======**/
/**=====================
      Sidebar CSS Start
  ==========================**/
.page-wrapper .page-body-wrapper .sidebar {
  height: calc(100vh - 80px);
  overflow: auto;
  box-shadow: 0 0 11px rgba(143, 164, 232, 0.08); }

.page-wrapper .page-body-wrapper .page-sidebar {
  width: 255px;
  position: fixed;
  background: #ffffff;
  top: 0;
  height: calc(100vh);
  z-index: 9;
  transition: 0.3s; }
  .page-wrapper .page-body-wrapper .page-sidebar .main-header-left {
    display: inline-flex;
    width: 100%;
    height: 80px;
    padding: 12px;
    align-items: center;
    background-color: transparent;
    z-index: 10;
    box-shadow: -3px 1px 3px 1px rgba(68, 102, 242, 0.1); }
    .page-wrapper .page-body-wrapper .page-sidebar .main-header-left .logo-wrapper {
      padding-left: 10px;
      height: 100%;
      display: flex;
      align-items: center; }
  .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user {
    padding: 25px 10px;
    box-shadow: 3px 2px 7px -1px rgba(127, 151, 249, 0.13);
    position: relative; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user h6 {
      color: #ff8084;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1.5px;
      margin-bottom: 3px; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user p {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 10px;
      margin-bottom: 0px; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user img {
      box-shadow: 0 0 15px rgba(68, 102, 242, 0.3); }
  .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {
    list-style: none;
    margin: 0;
    padding: 20px; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
      font-size: 14px;
      letter-spacing: 0.5px;
      padding-bottom: 12px;
      padding-top: 12px;
      text-transform: capitalize;
      font-weight: 600;
      color: #313131; }
      .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header svg {
        width: 14px;
        height: 14px;
        margin-right: 14px;
        stroke-width: 3px;
        vertical-align: text-bottom; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu.menu-open li.active .fa-angle-down:before {
      content: '\f104';
      transition: 0.3s; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a {
      display: block;
      transition: 0.3s; }
      .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a.active {
        color: #ff8084;
        transition: 0.3s; }
      .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a:hover {
        color: #ff8084;
        padding-left: 3px;
        transition: 0.3s; }
      .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a i {
        margin-right: 7px;
        text-align: right;
        margin-top: 3px;
        font-size: 15px; }
        .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a i:before {
          content: ""; }
        .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a i ~ i {
          margin-right: 0; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li .label {
      margin-top: 3px;
      margin-right: 5px; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li .badge {
      margin-left: 50px;
      text-transform: capitalize; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.sidebar-header {
      margin-bottom: 0;
      padding: 15px;
      color: #313131;
      font-weight: 600;
      transition: 0.3s; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li > a > .fa-angle-down {
      width: auto;
      height: auto;
      padding: 0;
      margin-right: 10px;
      margin-top: 10px;
      transform: rotate(-90deg);
      transition: 0.3s; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active > a > .fa-angle-right:before {
      content: "";
      font-family: FontAwesome;
      transition: 0.3s; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active > .sidebar-submenu {
      display: block;
      transform: rotateX(0deg);
      transform-origin: 10% 10%;
      transition: transform 0.3s, opacity 0.3s;
      transform-style: preserve-3d;
      margin-left: 25px; }
      .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active > .sidebar-submenu a i.pull-right {
        margin-top: 10px; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu a {
      text-decoration: none; }
    .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu {
      display: none;
      transform-style: preserve-3d;
      transform: rotateX(-90deg);
      list-style: none; }
      .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu .sidebar-submenu {
        padding-top: 0 !important; }
      .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a {
        padding-bottom: 7px;
        padding-top: 7px;
        font-size: 14px;
        color: #898989;
        transition: 0.3s;
        text-transform: capitalize;
        position: relative;
        padding-left: 10px;
        line-height: 2.5;
        letter-spacing: 0.7px; }
        .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > .fa-circle {
          width: 12px;
          font-size: 4px;
          position: absolute;
          left: 0;
          top: 13px; }
        .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > i {
          width: auto; }
        .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > .fa-angle-down {
          width: auto; }
        .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a:hover {
          color: #ff8084;
          transition: 0.3s; }
        .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a.active {
          color: #ff8084; }
      .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li.active > a {
        color: #313131; }
        .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li.active > a.active {
          color: #ff8084; }
  .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
    margin-left: 255px;
    transition: 0.3s; }
  .page-wrapper .page-body-wrapper .page-sidebar.open {
    display: block;
    margin-left: calc(-260px); }
    .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body {
      margin-left: 0;
      transition: 0.3s; }
    .page-wrapper .page-body-wrapper .page-sidebar.open ~ footer {
      margin-left: 0;
      padding-right: 15px; }
    .page-wrapper .page-body-wrapper .page-sidebar.open ~ .footer-fix {
      width: calc(100% - 0px); }

.page-wrapper .page-body-wrapper .sidebar-close .page-sidebar {
  transition: 0.3s;
  transform: translate(-255px); }

.page-wrapper .page-body-wrapper .sidebar-close .page-body {
  transition: 0.3s;
  margin-left: 0 !important; }

.right-sidebar {
  top: 81px;
  right: -285px;
  height: 100%;
  position: fixed;
  width: 285px;
  z-index: 9;
  background-color: #fff;
  transition: 0.5s;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36); }
  .right-sidebar.show {
    right: 0;
    transition: 0.3s; }
  .right-sidebar .modal-header .modal-title {
    padding-top: 2px; }
  .right-sidebar .friend-list-search {
    position: relative;
    background-color: #f8f8f9;
    padding: 20px; }
    .right-sidebar .friend-list-search input {
      color: #898989;
      width: 100%;
      background-color: #ffffff;
      border: 1px solid #f8f8f9;
      padding: 10px 15px;
      border-radius: 25px;
      letter-spacing: 1px; }
    .right-sidebar .friend-list-search .fa {
      position: absolute;
      right: 35px;
      top: 34px;
      font-size: 14px;
      color: #e8ebf2; }
  .right-sidebar .chat-box .people-list ul {
    padding-top: 20px; }
    .right-sidebar .chat-box .people-list ul li {
      position: relative; }
  .right-sidebar svg {
    width: 16px;
    height: 16px;
    margin-top: 5px; }

/**=====================
      Sidebar CSS Ends
  ==========================**/
/**=====================
        Generic CSS Start
  ==========================**/
/*====== Padding css starts ======*/
.p-0 {
  padding: 0px; }

.p-5 {
  padding: 5px; }

.p-10 {
  padding: 10px; }

.p-15 {
  padding: 15px; }

.p-20 {
  padding: 20px; }

.p-25 {
  padding: 25px; }

.p-30 {
  padding: 30px; }

.p-35 {
  padding: 35px; }

.p-40 {
  padding: 40px; }

.p-45 {
  padding: 45px; }

.p-50 {
  padding: 50px; }

/*====== Padding css ends ======*/
/*====== Padding-left css starts ======*/
.p-l-0 {
  padding-left: 0px; }

.p-l-5 {
  padding-left: 5px; }

.p-l-10 {
  padding-left: 10px; }

.p-l-15 {
  padding-left: 15px; }

.p-l-20 {
  padding-left: 20px; }

.p-l-25 {
  padding-left: 25px; }

.p-l-30 {
  padding-left: 30px; }

.p-l-35 {
  padding-left: 35px; }

.p-l-40 {
  padding-left: 40px; }

.p-l-45 {
  padding-left: 45px; }

.p-l-50 {
  padding-left: 50px; }

/*====== Padding-left css ends ======*/
/*====== Padding-top css starts ======*/
.p-t-0 {
  padding-top: 0px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.p-t-50 {
  padding-top: 50px !important; }

/*====== Padding-top css ends ======*/
/*====== Padding-bottom css starts ======*/
.p-b-0 {
  padding-bottom: 0px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

/*====== Padding-bottom css ends ======*/
/*====== Padding-right css starts ======*/
.p-r-0 {
  padding-right: 0px; }

.p-r-5 {
  padding-right: 5px; }

.p-r-10 {
  padding-right: 10px; }

.p-r-15 {
  padding-right: 15px; }

.p-r-20 {
  padding-right: 20px; }

.p-r-25 {
  padding-right: 25px; }

.p-r-30 {
  padding-right: 30px; }

.p-r-35 {
  padding-right: 35px; }

.p-r-40 {
  padding-right: 40px; }

.p-r-45 {
  padding-right: 45px; }

.p-r-50 {
  padding-right: 50px; }

/*====== Padding-right css ends ======*/
/*====== Margin css starts ======*/
.m-0 {
  margin: 0px !important; }

.m-5 {
  margin: 5px !important; }

.m-10 {
  margin: 10px !important; }

.m-15 {
  margin: 15px !important; }

.m-20 {
  margin: 20px !important; }

.m-25 {
  margin: 25px !important; }

.m-30 {
  margin: 30px !important; }

.m-35 {
  margin: 35px !important; }

.m-40 {
  margin: 40px !important; }

.m-45 {
  margin: 45px !important; }

.m-50 {
  margin: 50px !important; }

/*====== Margin css ends ======*/
/*====== Margin-top css starts ======*/
.m-t-0 {
  margin-top: 0px !important; }

.m-t-5 {
  margin-top: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.m-t-35 {
  margin-top: 35px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.m-t-45 {
  margin-top: 45px !important; }

.m-t-50 {
  margin-top: 50px !important; }

/*====== Margin-top css ends ======*/
/*====== Margin-Bottom css starts ======*/
.m-b-0 {
  margin-bottom: 0px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.m-b-45 {
  margin-bottom: 45px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

/*====== Margin-Bottom css ends ======*/
/*====== Margin-left css starts ======*/
.m-l-0 {
  margin-left: 0px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.m-l-35 {
  margin-left: 35px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.m-l-45 {
  margin-left: 45px !important; }

.m-l-50 {
  margin-left: 50px !important; }

/*====== Margin-left css ends ======*/
/*====== Margin-right css starts ======*/
.m-r-0 {
  margin-right: 0px; }

.m-r-5 {
  margin-right: 5px; }

.m-r-10 {
  margin-right: 10px; }

.m-r-15 {
  margin-right: 15px; }

.m-r-20 {
  margin-right: 20px; }

.m-r-25 {
  margin-right: 25px; }

.m-r-30 {
  margin-right: 30px; }

.m-r-35 {
  margin-right: 35px; }

.m-r-40 {
  margin-right: 40px; }

.m-r-45 {
  margin-right: 45px; }

.m-r-50 {
  margin-right: 50px; }

/*====== Margin-right css ends ======*/
/*====== Border-radius css starts ======*/
.b-r-0 {
  border-radius: 0px !important; }

.b-r-1 {
  border-radius: 1px !important; }

.b-r-2 {
  border-radius: 2px !important; }

.b-r-3 {
  border-radius: 3px !important; }

.b-r-4 {
  border-radius: 4px !important; }

.b-r-5 {
  border-radius: 5px !important; }

.b-r-6 {
  border-radius: 6px !important; }

.b-r-7 {
  border-radius: 7px !important; }

.b-r-8 {
  border-radius: 8px !important; }

.b-r-9 {
  border-radius: 9px !important; }

.b-r-10 {
  border-radius: 10px !important; }

/*====== Border-radius css ends ======*/
/*====== Font-size css starts ======*/
.f-12 {
  font-size: 12px; }

.f-14 {
  font-size: 14px; }

.f-16 {
  font-size: 16px; }

.f-18 {
  font-size: 18px; }

.f-20 {
  font-size: 20px; }

.f-22 {
  font-size: 22px; }

.f-24 {
  font-size: 24px; }

.f-26 {
  font-size: 26px; }

.f-28 {
  font-size: 28px; }

.f-30 {
  font-size: 30px; }

.f-32 {
  font-size: 32px; }

.f-34 {
  font-size: 34px; }

.f-36 {
  font-size: 36px; }

.f-38 {
  font-size: 38px; }

.f-40 {
  font-size: 40px; }

.f-42 {
  font-size: 42px; }

.f-44 {
  font-size: 44px; }

.f-46 {
  font-size: 46px; }

.f-48 {
  font-size: 48px; }

.f-50 {
  font-size: 50px; }

.f-52 {
  font-size: 52px; }

.f-54 {
  font-size: 54px; }

.f-56 {
  font-size: 56px; }

.f-58 {
  font-size: 58px; }

.f-60 {
  font-size: 60px; }

.f-62 {
  font-size: 62px; }

.f-64 {
  font-size: 64px; }

.f-66 {
  font-size: 66px; }

.f-68 {
  font-size: 68px; }

.f-70 {
  font-size: 70px; }

.f-72 {
  font-size: 72px; }

.f-74 {
  font-size: 74px; }

.f-76 {
  font-size: 76px; }

.f-78 {
  font-size: 78px; }

.f-80 {
  font-size: 80px; }

.f-82 {
  font-size: 82px; }

.f-84 {
  font-size: 84px; }

.f-86 {
  font-size: 86px; }

.f-88 {
  font-size: 88px; }

.f-90 {
  font-size: 90px; }

.f-92 {
  font-size: 92px; }

.f-94 {
  font-size: 94px; }

.f-96 {
  font-size: 96px; }

.f-98 {
  font-size: 98px; }

.f-100 {
  font-size: 100px; }

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100; }

.f-w-300 {
  font-weight: 300; }

.f-w-400 {
  font-weight: 400; }

.f-w-600 {
  font-weight: 600; }

.f-w-700 {
  font-weight: 700; }

.f-w-900 {
  font-weight: 900; }

/*====== Font-weight css ends ======*/
/*====== Font-style css starts ======*/
.f-s-normal {
  font-style: normal; }

.f-s-italic {
  font-style: italic; }

.f-s-oblique {
  font-style: oblique; }

.f-s-initial {
  font-style: initial; }

.f-s-inherit {
  font-style: inherit; }

/*====== Font-style css ends ======*/
/*====== Text-Decoration css starts ======*/
.text-overline {
  text-decoration: overline; }

.text-line-through {
  text-decoration: line-through; }

.text-underline {
  text-decoration: underline; }

.text-dashed {
  text-decoration: dashed; }

.text-blink {
  text-decoration: blink; }

.text-dotted {
  text-decoration: dotted; }

.text-initial {
  text-decoration: initial; }

.text-none {
  text-decoration: none; }

.text-solid {
  text-decoration: solid; }

.text-wavy {
  text-decoration: wavy; }

.text-inherit {
  text-decoration: inherit; }

.text-double {
  text-decoration: double; }

/*====== Text-Decoration css ends ======*/
/*====== Vertical-Align css starts ======*/
.baseline {
  vertical-align: baseline; }

.sub {
  vertical-align: sub; }

.super {
  vertical-align: super; }

.top {
  vertical-align: top; }

.text-top {
  vertical-align: text-top; }

.middle {
  vertical-align: middle; }

.bottom {
  vertical-align: bottom; }

.text-bottom {
  vertical-align: text-bottom; }

.initial {
  vertical-align: initial; }

.inherit {
  vertical-align: inherit; }

/*====== Vertical-Align css ends ======*/
/*====== Position css starts ======*/
.p-static {
  position: static; }

.p-absolute {
  position: absolute; }

.p-fixed {
  position: fixed; }

.p-relative {
  position: relative; }

.p-initial {
  position: initial; }

.p-inherit {
  position: inherit; }

/*====== Position css ends ======*/
/*====== Float css starts ======*/
.f-left {
  float: left; }

.f-right {
  float: right; }

.f-none {
  float: none; }

/*====== Float css ends ======*/
/*====== Overflow css starts ======*/
.o-hidden {
  overflow: hidden; }

.o-visible {
  overflow: visible; }

.o-auto {
  overflow: auto; }

/*====== Overflow css ends ======*/
/*====== Image-sizes css starts ======*/
.img-10 {
  width: 10px !important; }

.img-20 {
  width: 20px !important; }

.img-30 {
  width: 30px !important; }

.img-40 {
  width: 40px !important; }

.img-50 {
  width: 50px !important; }

.img-60 {
  width: 60px !important; }

.img-70 {
  width: 70px !important; }

.img-80 {
  width: 80px !important; }

.img-90 {
  width: 90px !important; }

.img-100 {
  width: 100px !important; }

/*====== Image-sizes css ends ======*/
/*======= Text css starts ===========*/
.font-primary {
  color: #ff8084 !important; }

.font-secondary {
  color: #13c9ca !important; }

.font-success {
  color: #81ba00 !important; }

.font-danger {
  color: #a5a5a5 !important; }

.font-info {
  color: #00a8ff !important; }

.font-light {
  color: #f8f8f9 !important; }

.font-dark {
  color: #2a3142 !important; }

.font-warning {
  color: #ffbc58 !important; }

/*======= Text css ends ===========*/
/*======= Label-color css starts  ======= */
.label {
  border-radius: 2px;
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize; }

/*======= Label-color css ends  ======= */
/*======= Badge-color css starts  ======= */
.badge-primary {
  background-color: #ff8084; }

.badge-secondary {
  background-color: #13c9ca; }

.badge-success {
  background-color: #81ba00; }

.badge-danger {
  background-color: #a5a5a5; }

.badge-info {
  background-color: #00a8ff; }

.badge-light {
  background-color: #f8f8f9; }

.badge-dark {
  background-color: #2a3142; }

.badge-warning {
  background-color: #ffbc58; }

/*======= Badge-color css end  ======= */
/*======= Background-color css starts  ======= */
.bg-primary {
  background-color: #ff8084 !important;
  color: #ffffff; }

.bg-secondary {
  background-color: #13c9ca !important;
  color: #ffffff; }

.bg-success {
  background-color: #81ba00 !important;
  color: #ffffff; }

.bg-danger {
  background-color: #a5a5a5 !important;
  color: #ffffff; }

.bg-info {
  background-color: #00a8ff !important;
  color: #ffffff; }

.bg-light {
  background-color: #f8f8f9 !important;
  color: #ffffff; }

.bg-dark {
  background-color: #2a3142 !important;
  color: #ffffff; }

.bg-warning {
  background-color: #ffbc58 !important;
  color: #ffffff; }

/*======= Background-color css end  ======= */
/*======= Font-color css starts  ======= */
/*======= Font-color css end  ======= */
/*======= Button-color css starts  ======= */
.btn-primary {
  background-color: #ff8084 !important;
  border-color: #ff8084 !important; }
  .btn-primary.disabled {
    background-color: #ff8084 !important;
    border-color: #ff8084 !important; }
  .btn-primary:disabled {
    background-color: #ff8084 !important;
    border-color: #ff8084 !important; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
    background-color: #ff4d53 !important;
    border-color: #ff4d53 !important; }
  .btn-primary:focus {
    box-shadow: 0 0 0 0.2rem white; }

.btn-secondary {
  background-color: #13c9ca !important;
  border-color: #13c9ca !important; }
  .btn-secondary.disabled {
    background-color: #13c9ca !important;
    border-color: #13c9ca !important; }
  .btn-secondary:disabled {
    background-color: #13c9ca !important;
    border-color: #13c9ca !important; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active {
    background-color: #0f9b9b !important;
    border-color: #0f9b9b !important; }
  .btn-secondary:focus {
    box-shadow: 0 0 0 0.2rem #83f3f3; }

.btn-success {
  background-color: #81ba00 !important;
  border-color: #81ba00 !important; }
  .btn-success.disabled {
    background-color: #81ba00 !important;
    border-color: #81ba00 !important; }
  .btn-success:disabled {
    background-color: #81ba00 !important;
    border-color: #81ba00 !important; }
  .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active {
    background-color: #5e8700 !important;
    border-color: #5e8700 !important; }
  .btn-success:focus {
    box-shadow: 0 0 0 0.2rem #cbff54; }

.btn-danger {
  background-color: #a5a5a5 !important;
  border-color: #a5a5a5 !important; }
  .btn-danger.disabled {
    background-color: #a5a5a5 !important;
    border-color: #a5a5a5 !important; }
  .btn-danger:disabled {
    background-color: #a5a5a5 !important;
    border-color: #a5a5a5 !important; }
  .btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active {
    background-color: #8c8c8c !important;
    border-color: #8c8c8c !important; }
  .btn-danger:focus {
    box-shadow: 0 0 0 0.2rem #f2f2f2; }

.btn-info {
  background-color: #00a8ff !important;
  border-color: #00a8ff !important; }
  .btn-info.disabled {
    background-color: #00a8ff !important;
    border-color: #00a8ff !important; }
  .btn-info:disabled {
    background-color: #00a8ff !important;
    border-color: #00a8ff !important; }
  .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active {
    background-color: #0086cc !important;
    border-color: #0086cc !important; }
  .btn-info:focus {
    box-shadow: 0 0 0 0.2rem #99dcff; }

.btn-light {
  background-color: #f8f8f9 !important;
  border-color: #f8f8f9 !important; }
  .btn-light.disabled {
    background-color: #f8f8f9 !important;
    border-color: #f8f8f9 !important; }
  .btn-light:disabled {
    background-color: #f8f8f9 !important;
    border-color: #f8f8f9 !important; }
  .btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active {
    background-color: #dddde1 !important;
    border-color: #dddde1 !important; }
  .btn-light:focus {
    box-shadow: 0 0 0 0.2rem white; }

.btn-dark {
  background-color: #2a3142 !important;
  border-color: #2a3142 !important; }
  .btn-dark.disabled {
    background-color: #2a3142 !important;
    border-color: #2a3142 !important; }
  .btn-dark:disabled {
    background-color: #2a3142 !important;
    border-color: #2a3142 !important; }
  .btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active {
    background-color: #161a23 !important;
    border-color: #161a23 !important; }
  .btn-dark:focus {
    box-shadow: 0 0 0 0.2rem #67779e; }

.btn-warning {
  background-color: #ffbc58 !important;
  border-color: #ffbc58 !important; }
  .btn-warning.disabled {
    background-color: #ffbc58 !important;
    border-color: #ffbc58 !important; }
  .btn-warning:disabled {
    background-color: #ffbc58 !important;
    border-color: #ffbc58 !important; }
  .btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active {
    background-color: #ffa825 !important;
    border-color: #ffa825 !important; }
  .btn-warning:focus {
    box-shadow: 0 0 0 0.2rem #fff9f1; }

/*======= Button-color css ends  ======= */
.btn-primary:not([disabled]):not(.disabled).active {
  background-color: #ff4d53;
  border-color: #ff4d53;
  box-shadow: none !important; }
  .btn-primary:not([disabled]):not(.disabled).active:hover, .btn-primary:not([disabled]):not(.disabled).active:focus, .btn-primary:not([disabled]):not(.disabled).active:active, .btn-primary:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #ff4d53;
    border-color: #ff4d53; }

.btn-secondary:not([disabled]):not(.disabled).active {
  background-color: #0f9b9b;
  border-color: #0f9b9b;
  box-shadow: none !important; }
  .btn-secondary:not([disabled]):not(.disabled).active:hover, .btn-secondary:not([disabled]):not(.disabled).active:focus, .btn-secondary:not([disabled]):not(.disabled).active:active, .btn-secondary:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #0f9b9b;
    border-color: #0f9b9b; }

.btn-success:not([disabled]):not(.disabled).active {
  background-color: #5e8700;
  border-color: #5e8700;
  box-shadow: none !important; }
  .btn-success:not([disabled]):not(.disabled).active:hover, .btn-success:not([disabled]):not(.disabled).active:focus, .btn-success:not([disabled]):not(.disabled).active:active, .btn-success:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #5e8700;
    border-color: #5e8700; }

.btn-danger:not([disabled]):not(.disabled).active {
  background-color: #8c8c8c;
  border-color: #8c8c8c;
  box-shadow: none !important; }
  .btn-danger:not([disabled]):not(.disabled).active:hover, .btn-danger:not([disabled]):not(.disabled).active:focus, .btn-danger:not([disabled]):not(.disabled).active:active, .btn-danger:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #8c8c8c;
    border-color: #8c8c8c; }

.btn-info:not([disabled]):not(.disabled).active {
  background-color: #0086cc;
  border-color: #0086cc;
  box-shadow: none !important; }
  .btn-info:not([disabled]):not(.disabled).active:hover, .btn-info:not([disabled]):not(.disabled).active:focus, .btn-info:not([disabled]):not(.disabled).active:active, .btn-info:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #0086cc;
    border-color: #0086cc; }

.btn-light:not([disabled]):not(.disabled).active {
  background-color: #dddde1;
  border-color: #dddde1;
  box-shadow: none !important; }
  .btn-light:not([disabled]):not(.disabled).active:hover, .btn-light:not([disabled]):not(.disabled).active:focus, .btn-light:not([disabled]):not(.disabled).active:active, .btn-light:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #dddde1;
    border-color: #dddde1; }

.btn-dark:not([disabled]):not(.disabled).active {
  background-color: #161a23;
  border-color: #161a23;
  box-shadow: none !important; }
  .btn-dark:not([disabled]):not(.disabled).active:hover, .btn-dark:not([disabled]):not(.disabled).active:focus, .btn-dark:not([disabled]):not(.disabled).active:active, .btn-dark:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #161a23;
    border-color: #161a23; }

.btn-warning:not([disabled]):not(.disabled).active {
  background-color: #ffa825;
  border-color: #ffa825;
  box-shadow: none !important; }
  .btn-warning:not([disabled]):not(.disabled).active:hover, .btn-warning:not([disabled]):not(.disabled).active:focus, .btn-warning:not([disabled]):not(.disabled).active:active, .btn-warning:not([disabled]):not(.disabled).active.active {
    color: white;
    background-color: #ffa825;
    border-color: #ffa825; }

.btn-outline-primary-2x:not([disabled]):not(.disabled).active {
  background-color: #ff8084;
  border-color: #ff8084;
  box-shadow: none !important;
  color: white; }
  .btn-outline-primary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-primary-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #ff4d53 !important;
    border-color: #ff4d53 !important; }

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active {
  background-color: #13c9ca;
  border-color: #13c9ca;
  box-shadow: none !important;
  color: white; }
  .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #0f9b9b !important;
    border-color: #0f9b9b !important; }

.btn-outline-success-2x:not([disabled]):not(.disabled).active {
  background-color: #81ba00;
  border-color: #81ba00;
  box-shadow: none !important;
  color: white; }
  .btn-outline-success-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-success-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-success-2x:not([disabled]):not(.disabled).active:active, .btn-outline-success-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #5e8700 !important;
    border-color: #5e8700 !important; }

.btn-outline-danger-2x:not([disabled]):not(.disabled).active {
  background-color: #a5a5a5;
  border-color: #a5a5a5;
  box-shadow: none !important;
  color: white; }
  .btn-outline-danger-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:active, .btn-outline-danger-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #8c8c8c !important;
    border-color: #8c8c8c !important; }

.btn-outline-info-2x:not([disabled]):not(.disabled).active {
  background-color: #00a8ff;
  border-color: #00a8ff;
  box-shadow: none !important;
  color: white; }
  .btn-outline-info-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-info-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-info-2x:not([disabled]):not(.disabled).active:active, .btn-outline-info-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #0086cc !important;
    border-color: #0086cc !important; }

.btn-outline-light-2x:not([disabled]):not(.disabled).active {
  background-color: #f8f8f9;
  border-color: #f8f8f9;
  box-shadow: none !important;
  color: white; }
  .btn-outline-light-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-light-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-light-2x:not([disabled]):not(.disabled).active:active, .btn-outline-light-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #dddde1 !important;
    border-color: #dddde1 !important; }

.btn-outline-dark-2x:not([disabled]):not(.disabled).active {
  background-color: #2a3142;
  border-color: #2a3142;
  box-shadow: none !important;
  color: white; }
  .btn-outline-dark-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:active, .btn-outline-dark-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #161a23 !important;
    border-color: #161a23 !important; }

.btn-outline-warning-2x:not([disabled]):not(.disabled).active {
  background-color: #ffbc58;
  border-color: #ffbc58;
  box-shadow: none !important;
  color: white; }
  .btn-outline-warning-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:active, .btn-outline-warning-2x:not([disabled]):not(.disabled).active.active {
    color: white;
    box-shadow: none !important;
    background-color: #ffa825 !important;
    border-color: #ffa825 !important; }

/*======= Table-Border-Bottom-color css starts  ======= */
table thead .border-bottom-primary th,
table tbody .border-bottom-primary th,
table tbody .border-bottom-primary td {
  border-bottom: 1px solid #ff8084; }

table thead .border-bottom-secondary th,
table tbody .border-bottom-secondary th,
table tbody .border-bottom-secondary td {
  border-bottom: 1px solid #13c9ca; }

table thead .border-bottom-success th,
table tbody .border-bottom-success th,
table tbody .border-bottom-success td {
  border-bottom: 1px solid #81ba00; }

table thead .border-bottom-danger th,
table tbody .border-bottom-danger th,
table tbody .border-bottom-danger td {
  border-bottom: 1px solid #a5a5a5; }

table thead .border-bottom-info th,
table tbody .border-bottom-info th,
table tbody .border-bottom-info td {
  border-bottom: 1px solid #00a8ff; }

table thead .border-bottom-light th,
table tbody .border-bottom-light th,
table tbody .border-bottom-light td {
  border-bottom: 1px solid #f8f8f9; }

table thead .border-bottom-dark th,
table tbody .border-bottom-dark th,
table tbody .border-bottom-dark td {
  border-bottom: 1px solid #2a3142; }

table thead .border-bottom-warning th,
table tbody .border-bottom-warning th,
table tbody .border-bottom-warning td {
  border-bottom: 1px solid #ffbc58; }

/*======= Table-Border-Bottom-color css ends  ======= */
/*======= Table styling css starts  ======= */
.table-styling .table-primary,
.table-styling.table-primary {
  background-color: #ff8084;
  color: #ffffff;
  border: 3px solid #ff8084; }
  .table-styling .table-primary thead,
  .table-styling.table-primary thead {
    background-color: #ff4d53;
    border: 3px solid #ff4d53; }

.table-styling .table-secondary,
.table-styling.table-secondary {
  background-color: #13c9ca;
  color: #ffffff;
  border: 3px solid #13c9ca; }
  .table-styling .table-secondary thead,
  .table-styling.table-secondary thead {
    background-color: #0f9b9b;
    border: 3px solid #0f9b9b; }

.table-styling .table-success,
.table-styling.table-success {
  background-color: #81ba00;
  color: #ffffff;
  border: 3px solid #81ba00; }
  .table-styling .table-success thead,
  .table-styling.table-success thead {
    background-color: #5e8700;
    border: 3px solid #5e8700; }

.table-styling .table-danger,
.table-styling.table-danger {
  background-color: #a5a5a5;
  color: #ffffff;
  border: 3px solid #a5a5a5; }
  .table-styling .table-danger thead,
  .table-styling.table-danger thead {
    background-color: #8c8c8c;
    border: 3px solid #8c8c8c; }

.table-styling .table-info,
.table-styling.table-info {
  background-color: #00a8ff;
  color: #ffffff;
  border: 3px solid #00a8ff; }
  .table-styling .table-info thead,
  .table-styling.table-info thead {
    background-color: #0086cc;
    border: 3px solid #0086cc; }

.table-styling .table-light,
.table-styling.table-light {
  background-color: #f8f8f9;
  color: #ffffff;
  border: 3px solid #f8f8f9; }
  .table-styling .table-light thead,
  .table-styling.table-light thead {
    background-color: #dddde1;
    border: 3px solid #dddde1; }

.table-styling .table-dark,
.table-styling.table-dark {
  background-color: #2a3142;
  color: #ffffff;
  border: 3px solid #2a3142; }
  .table-styling .table-dark thead,
  .table-styling.table-dark thead {
    background-color: #161a23;
    border: 3px solid #161a23; }

.table-styling .table-warning,
.table-styling.table-warning {
  background-color: #ffbc58;
  color: #ffffff;
  border: 3px solid #ffbc58; }
  .table-styling .table-warning thead,
  .table-styling.table-warning thead {
    background-color: #ffa825;
    border: 3px solid #ffa825; }

/*======= Table styling css ends  ======= */
/*======= All-Borders-color css starts  ======= */
.b-primary {
  border: 1px solid #ff8084 !important; }

.b-t-primary {
  border-top: 1px solid #ff8084 !important; }

.b-b-primary {
  border-bottom: 1px solid #ff8084 !important; }

.b-l-primary {
  border-left: 1px solid #ff8084 !important; }

.b-r-primary {
  border-right: 1px solid #ff8084 !important; }

.b-secondary {
  border: 1px solid #13c9ca !important; }

.b-t-secondary {
  border-top: 1px solid #13c9ca !important; }

.b-b-secondary {
  border-bottom: 1px solid #13c9ca !important; }

.b-l-secondary {
  border-left: 1px solid #13c9ca !important; }

.b-r-secondary {
  border-right: 1px solid #13c9ca !important; }

.b-success {
  border: 1px solid #81ba00 !important; }

.b-t-success {
  border-top: 1px solid #81ba00 !important; }

.b-b-success {
  border-bottom: 1px solid #81ba00 !important; }

.b-l-success {
  border-left: 1px solid #81ba00 !important; }

.b-r-success {
  border-right: 1px solid #81ba00 !important; }

.b-danger {
  border: 1px solid #a5a5a5 !important; }

.b-t-danger {
  border-top: 1px solid #a5a5a5 !important; }

.b-b-danger {
  border-bottom: 1px solid #a5a5a5 !important; }

.b-l-danger {
  border-left: 1px solid #a5a5a5 !important; }

.b-r-danger {
  border-right: 1px solid #a5a5a5 !important; }

.b-info {
  border: 1px solid #00a8ff !important; }

.b-t-info {
  border-top: 1px solid #00a8ff !important; }

.b-b-info {
  border-bottom: 1px solid #00a8ff !important; }

.b-l-info {
  border-left: 1px solid #00a8ff !important; }

.b-r-info {
  border-right: 1px solid #00a8ff !important; }

.b-light {
  border: 1px solid #f8f8f9 !important; }

.b-t-light {
  border-top: 1px solid #f8f8f9 !important; }

.b-b-light {
  border-bottom: 1px solid #f8f8f9 !important; }

.b-l-light {
  border-left: 1px solid #f8f8f9 !important; }

.b-r-light {
  border-right: 1px solid #f8f8f9 !important; }

.b-dark {
  border: 1px solid #2a3142 !important; }

.b-t-dark {
  border-top: 1px solid #2a3142 !important; }

.b-b-dark {
  border-bottom: 1px solid #2a3142 !important; }

.b-l-dark {
  border-left: 1px solid #2a3142 !important; }

.b-r-dark {
  border-right: 1px solid #2a3142 !important; }

.b-warning {
  border: 1px solid #ffbc58 !important; }

.b-t-warning {
  border-top: 1px solid #ffbc58 !important; }

.b-b-warning {
  border-bottom: 1px solid #ffbc58 !important; }

.b-l-warning {
  border-left: 1px solid #ffbc58 !important; }

.b-r-warning {
  border-right: 1px solid #ffbc58 !important; }

/*======= All-Borders-color css ends  ======= */
/*====== Border width css starts ======*/
.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.border-6 {
  border-width: 6px !important; }

.border-7 {
  border-width: 7px !important; }

.border-8 {
  border-width: 8px !important; }

.border-9 {
  border-width: 9px !important; }

.border-10 {
  border-width: 10px !important; }

/*====== Border width css ends ======*/
.opacity-0 {
  opacity: 0; }

.shadow-0 {
  box-shadow: none; }

/**====== custom scrollbar css start ======**/
.digits {
  font-family: work-Sans, sans-serif; }

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e8ebf2; }

.custom-scrollbar::-webkit-scrollbar {
  width: 6px; }

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(255, 128, 132, 0.17); }

/**====== Custom scrollbar css end ======**/
/**====== Animation css Start ======**/
.line pre {
  font-size: 100%; }

/**====== Animation css end ======**/
/**=====================
        Generic CSS Ends
  ==========================**/
/**=====================
       Chat CSS Start
  ==========================**/
.chat-box .toogle-bar {
  display: none; }

.chat-box .people-list .search {
  position: relative; }
  .chat-box .people-list .search .form-control {
    background-color: #f1f4fb;
    border: 1px solid #f8f8f9; }
    .chat-box .people-list .search .form-control::placeholder {
      color: #aaaaaa; }
  .chat-box .people-list .search i {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    color: #e8ebf2; }

.chat-box .people-list ul {
  padding: 0; }
  .chat-box .people-list ul li {
    padding-bottom: 20px; }
    .chat-box .people-list ul li:last-child {
      padding-bottom: 0; }

.chat-box .user-image {
  float: left;
  width: 52px;
  height: 52px;
  margin-right: 5px; }

.chat-box .about {
  float: left;
  margin-top: 5px;
  padding-left: 10px; }
  .chat-box .about .name {
    color: #2a3142;
    letter-spacing: 1px;
    font-weight: 600; }

.chat-box .status {
  color: #aaaaaa;
  letter-spacing: 1px;
  font-size: 12px;
  margin-top: 5px; }
  .chat-box .status .chat-status {
    font-weight: 600;
    color: #313131; }
  .chat-box .status p {
    font-size: 14px; }

.status-circle {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 40px;
  left: 40px;
  border-radius: 50%;
  border: 2px solid #ffffff; }

.away {
  background-color: #ffbc58; }

.online {
  background-color: #81ba00; }

.offline {
  background-color: #a5a5a5; }

/**=====================
        Chat CSS Ends
  ==========================**/
.market-chart {
  height: 303px; }

.needs-validation .custom-select {
  background: white; }

.needs-validation .form-control {
  border-radius: 0.25rem;
  padding: 0.48rem 0.75rem; }

.license-key[disabled] {
  cursor: not-allowed; }

.checkbox label {
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px; }

.checkbox .form-check-input {
  margin-top: 13px;
  margin-bottom: 10px; }

.form-row .form-control {
  font-size: 14px;
  letter-spacing: 1px; }

.form-row .custom-select {
  font-size: 14px;
  letter-spacing: 1px; }

/*=====================
      Responsive CSS start
  ==========================*/
@media only screen and (max-width: 1660px) {
  .products-table table tr td:first-child {
    min-width: 268px; } }

@media screen and (max-width: 1440px) and (min-width: 1366px) {
  .product-physical .ReactTable .rt-table .rt-tr .rt-th:nth-child(2) {
    width: 200px !important; }
  .product-physical .ReactTable .rt-table .rt-tr-group .rt-td:nth-child(2) {
    width: 200px !important; }
  .flot-chart-container {
    height: 350px; }
  .product-adding .col-xl-5 {
    flex: 0 0 36%;
    max-width: 36%; }
  .product-adding .col-xl-7 {
    flex: 0 0 64%;
    max-width: 64%; } }

@media only screen and (max-width: 1366px) {
  .report-employee h2 {
    font-size: 32px; }
  .add-product-form .qty-box {
    width: 24%; }
  .slick-slide img {
    margin: 0 auto; }
  .page-main-header .main-header-right .nav-right > ul > li:first-child {
    width: 32%; }
  .product-adding .add-product-form {
    margin-top: 30px; }
  .user-list table tr td:nth-child(5),
  .user-list table tr th:nth-child(5) {
    width: 195px !important; } }

@media only screen and (max-width: 1199px) {
  .product-page-details {
    margin-top: 30px; }
  .user-list table tr th,
  .user-list table tr td {
    width: 200px !important; } }

@media screen and (max-device-width: 1366px) and (min-device-width: 1200px) {
  .xl-100 {
    max-width: 100%;
    flex: 0 0 100%; }
  .xl-50 {
    max-width: 50%;
    flex: 0 0 50%; }
  .product-adding .col-xl-5,
  .product-adding .col-xl-7 {
    flex: 0 0 100%;
    max-width: 100%; }
  .product-adding .add-product-form {
    margin-top: 30px; }
  .user-list table tr td:first-child,
  .user-list table tr th:first-child {
    width: 130px !important; }
  .profile-table table tr th,
  .profile-table table tr td {
    width: 200px !important; } }

@media only screen and (max-width: 991px) {
  .authentication-box {
    padding: 30px; }
    .authentication-box .container .bg-primary {
      padding: 40px; }
  .btn-popup {
    margin-bottom: 20px; }
  .order-graph .order-graph-bottom {
    margin-top: 20px; }
  .card .card-body,
  .card .card-header {
    padding: 20px; }
  .page-wrapper .page-main-header.open {
    z-index: 11; }
  .right-sidebar {
    top: 127px; }
  .page-wrapper .page-body-wrapper .page-header .row h3 {
    font-size: 22px; }
  .page-wrapper .page-body-wrapper .page-header .row .pull-right {
    float: none;
    margin-top: 5px; }
  .page-wrapper .page-body-wrapper .page-sidebar {
    top: 60px !important;
    height: calc(100vh - 60px) !important; }
    .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
      margin-left: 0;
      margin-top: 60px; }
      .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body .page-header .row .page-header-left {
        display: inherit; }
  .page-wrapper .page-body-wrapper .sidebar {
    height: calc(100vh - 60px); }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body {
    margin-top: 60px; }
  .page-wrapper .page-body-wrapper footer {
    margin-left: 0; }
  .page-wrapper .page-main-header {
    height: 60px;
    margin-left: 0;
    width: 100%;
    z-index: 11; }
    .page-wrapper .page-main-header .main-header-left .logo-wrapper img {
      height: 23px;
      margin-top: 0;
      margin-right: 15px; }
    .page-wrapper .page-main-header .main-header-right .nav-right {
      position: unset;
      padding: 0; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul {
        top: 60px;
        position: absolute;
        z-index: -1;
        background-color: #fff;
        -webkit-transition: all linear 0.3s;
        transition: all linear 0.3s;
        -webkit-box-shadow: 0 2px 2px 2px #efefef;
        box-shadow: 0 2px 2px 2px #efefef;
        width: 100%;
        left: 0;
        padding: 0 40px;
        -webkit-transform: translateY(-35px) scaleY(0);
        transform: translateY(-35px) scaleY(0);
        opacity: 0;
        visibility: hidden; }
        .page-wrapper .page-main-header .main-header-right .nav-right > ul > li {
          margin: 10px 0; }
      .page-wrapper .page-main-header .main-header-right .nav-right > .mobile-toggle {
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .page-wrapper .page-main-header .main-header-right .nav-right > .mobile-toggle svg circle {
          color: #ff8084; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul.open {
        z-index: 1;
        opacity: 1;
        -webkit-transform: translateY(0px) scaleY(1);
        transform: translateY(0px) scaleY(1);
        visibility: visible; }
  .page-header {
    padding-top: 25px !important;
    padding-bottom: 25px !important; }
    .page-header .row .page-header-left .breadcrumb {
      margin-top: 5px;
      margin-left: 0; }
  .product-page-details {
    text-align: left !important; }
  .add-product .bigImg img {
    width: 460px; }
  .product-adding .add-product-form {
    margin-top: 20px; }
  .tab-coupon {
    margin-bottom: 20px; }
  .deactivate-account {
    margin-top: 20px; }
  .sell-graph canvas {
    height: 312px !important; }
  .needs-validation h4 {
    font-size: 16px; } }

@media only screen and (max-width: 767px) {
  .checkbox-space {
    padding: 0 15px; }
  .react-datepicker__input-container {
    margin-left: 15px; }
  .authentication-box .form-footer,
  .authentication-box .form-button {
    text-align: center; }
    .authentication-box .form-footer > span,
    .authentication-box .form-button > span {
      padding: 0;
      left: 0;
      right: 0; }
  .ReactTable .rt-thead .rt-resizable-header-content .btn {
    padding: 6px 12px; }
  .page-main-header .main-header-right .nav-right > ul > li:first-child {
    width: 40%; }
  .products-table table tr td:first-child,
  .products-table table tr th:first-child {
    min-width: 280px; }
  .products-table table tr td:nth-child(3),
  .products-table table tr th:nth-child(3) {
    min-width: 110px; }
  .page-wrapper .page-body-wrapper .page-header .row .col {
    flex-basis: unset; }
    .page-wrapper .page-body-wrapper .page-header .row .col .pull-right {
      float: none;
      margin-top: 20px; }
  .page-wrapper .page-body-wrapper footer .row div {
    text-align: center; }
    .page-wrapper .page-body-wrapper footer .row div .pull-right {
      float: none; }
  .footer-fix .pull-right {
    float: none !important;
    text-align: center !important; }
  .add-product-form .form-group .form-control,
  .add-product-form .form-group select {
    width: 100% !important;
    margin: 0 !important; }
  .add-product-form .input-group .touchspin {
    width: 30% !important; }
  .add-product-form .radio-animated label {
    margin-bottom: 0; }
  .add-product-form .editor-space {
    padding: 0 15px; }
  .add-product-form textarea {
    margin: 0 15px; }
  .add-product-form .qty-box {
    width: 29%; }
  .add-product .bigImg img {
    width: 400px; }
  .digital-add textarea {
    margin: 0 !important; }
  .digital-add .form-group select,
  .digital-add .form-group .form-control {
    width: 100% !important; }
  .digital-add .form-group label {
    margin-bottom: 0 !important; }
  .needs-validation .form-group.row {
    margin-left: 0;
    margin-right: 0; }
  .needs-validation .form-group .form-control,
  .needs-validation .form-group select {
    width: 95%;
    margin: 0 auto; }
  .needs-validation .form-group .checkbox {
    padding-left: 15px; }
  .needs-validation .form-group label {
    margin-bottom: 4px; }
  .needs-validation .radio-animated label {
    margin-bottom: 0; }
  .needs-validation .editor-space {
    padding: 0 15px; }
  .needs-validation textarea {
    margin: 0 15px; } }

@media only screen and (max-width: 577px) {
  footer {
    margin-bottom: 0 !important; } }

@media only screen and (max-width: 575px) {
  .authentication-box .btn-primary {
    margin-top: 10px; }
  .sales-chart {
    height: 200px; }
  .report-last-sm canvas {
    height: 200px !important; }
  .dropzone .dzu-input {
    width: 90%; }
  .product-right .timer span {
    width: 55px; }
  .market-chart {
    height: 205px; }
  .ct-4.flot-chart-container {
    height: 240px; }
  .sell-graph canvas {
    height: 250px !important; }
  .btn-popup {
    margin-bottom: 15px; }
  .static-top-widget div.align-self-center svg {
    width: 20px;
    height: 20px; }
  .latest-order-table table tr td {
    min-width: 100px; }
    .latest-order-table table tr td + td {
      min-width: 150px; }
    .latest-order-table table tr td:nth-child(3) {
      min-width: 200px; }
  .card {
    margin-bottom: 15px; }
    .card .card-header {
      padding: 15px; }
      .card .card-header h5 {
        font-size: 17px; }
    .card .card-body {
      padding: 15px; }
  .sm-order-space,
  .xl-space {
    margin-top: 15px; }
  .order-graph .order-graph-bottom {
    margin-top: 15px; }
    .order-graph .order-graph-bottom h6 {
      margin-right: 0; }
    .order-graph .order-graph-bottom .media {
      margin-bottom: 15px; }
  .form-inline .form-control-plaintext {
    display: inline-block; }
  .d-sm-none:after {
    display: none; }
  ul.notification-dropdown.onhover-show-div {
    width: 284px;
    right: -112px; }
    ul.notification-dropdown.onhover-show-div:before, ul.notification-dropdown.onhover-show-div:after {
      right: 135px !important; }
  .page-wrapper .page-main-header .main-header-right {
    padding: 0 15px; }
    .page-wrapper .page-main-header .main-header-right .nav-right > ul {
      padding: 0 0;
      justify-content: flex-start; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul > li:first-child {
        width: auto;
        margin: 0; }
        .page-wrapper .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:before {
          display: none; }
        .page-wrapper .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:after {
          display: none; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-group {
        margin-bottom: 0; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-control-plaintext.open {
        -webkit-transform: translateY(0px) scaleY(1);
        transform: translateY(0px) scaleY(1);
        opacity: 1;
        visibility: visible;
        -webkit-transition: all linear 0.3s;
        transition: all linear 0.3s; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .form-control-plaintext {
        top: 60px;
        position: absolute;
        -webkit-transition: all linear 0.3s;
        transition: all linear 0.3s;
        left: 0;
        background-color: #fff;
        -webkit-transform: translateY(-35px) scaleY(0);
        transform: translateY(-35px) scaleY(0);
        opacity: 0;
        visibility: hidden;
        width: 180px;
        padding: 10px 10px 10px 15px; }
      .page-wrapper .page-main-header .main-header-right .nav-right > ul .search-form .mobile-search svg {
        color: #ff8084; }
  .page-wrapper .search-form .form-group {
    margin-right: 0; }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .activity .media .gradient-round.gradient-line-1:after {
    bottom: -41px; }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .activity .media .gradient-round.small-line:after {
    bottom: -19px;
    height: 12px; }
  .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body .activity .media .gradient-round.medium-line:after {
    bottom: -34px; }
  .page-wrapper .page-body-wrapper .page-body {
    padding: 0; }
  .page-wrapper .page-body-wrapper .page-header {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .profile-details img {
    margin-bottom: 15px; }
  .project-status {
    margin-top: 15px; }
    .project-status .media {
      margin-top: 15px; }
  .profile-card ul {
    display: inherit;
    margin-bottom: 15px; }
    .profile-card ul li {
      display: block;
      text-align: center; }
  .profile-table table tbody tr td:first-child {
    min-width: 150px; }
  .account-setting h5 {
    margin-bottom: 15px; }
  .deactivate-account {
    margin-top: 15px; }
    .deactivate-account .btn {
      margin-top: 15px; }
  .sm-label-radio {
    margin-bottom: 4px !important; }
  .permission-block .attribute-blocks .form-group {
    padding-bottom: 15px; }
  .needs-validation textarea {
    margin: 0 10px; }
  .needs-validation h4 {
    margin-bottom: 15px; }
  .needs-validation .permission-block .attribute-blocks .row {
    padding-left: 15px; }
  .needs-validation .permission-block .attribute-blocks + .attribute-blocks h5 {
    margin-top: 20px; }
  .needs-validation .radio_animated {
    margin: 0 6px 0 0; }
  .needs-validation .radio-animated label {
    margin-right: 15px; }
  .tab-coupon {
    display: grid;
    text-align: center; }
  .add-product-form .form-group .form-control {
    width: 93%;
    margin: 0 auto; }
  .add-product-form .form-group .col-xl-8 {
    padding: 0; }
  .add-product-form .form-group fieldset {
    padding-left: 0; }
  .add-product-form .form-group label {
    padding: 0; }
  .add-product-form .qty-box {
    width: 162px; }
    .add-product-form .qty-box .input-group .form-control {
      width: 80px; }
  .add-product-form .description-sm {
    padding: 0 !important; }
  .add-product ul li .box-input-file {
    width: 15px;
    height: 15px; }
    .add-product ul li .box-input-file img {
      width: 40px !important;
      height: 40px !important; }
  .file-upload-product {
    margin-top: 11px; }
    .file-upload-product li {
      margin-bottom: 34px !important; } }

@media only screen and (max-width: 360px) {
  .authentication-box {
    padding: 30px 15px; }
    .authentication-box .forgot-pass {
      float: none;
      padding-top: 10px; }
    .authentication-box .container .form-group {
      margin-bottom: 1rem; }
    .authentication-box .container h3 {
      font-size: 20px; }
    .authentication-box .container p {
      line-height: 1.6; }
    .authentication-box .container .bg-primary {
      padding: 30px 15px; }
    .authentication-box .container .svg-icon {
      padding: 17px;
      height: 90px;
      width: 90px;
      margin-bottom: 25px; }
      .authentication-box .container .svg-icon svg {
        height: 50px; }
  .nav-menus .notification-badge {
    right: 0; }
  .page-wrapper .page-main-header .main-header-right .nav-right > ul > li {
    padding: 0 13px; }
    .page-wrapper .page-main-header .main-header-right .nav-right > ul > li .dot {
      right: 13px; }
  .footer {
    padding-left: 0;
    padding-right: 0 !important; }
    .footer p {
      font-size: 12px; }
  .add-product .bigImg img {
    width: 300px; }
  .product-page-main .btn {
    padding: 5px 12px; } }

@media only screen and (max-height: 800px) {
  .right-sidebar .chat-box .friend-list {
    max-height: calc(100vh - 150px);
    overflow: scroll; } }

/*=====================
    Responsive CSS End
  ==========================*/
.custom-theme {
  position: fixed;
  right: 0;
  width: 40px;
  height: 38px;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-decoration: none;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f7f7f7;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
  top: calc(50% + 34px);
  cursor: pointer; }

body.rtl {
  direction: rtl; }
  body.rtl .m-r-15 {
    margin-left: 15px;
    margin-right: unset; }
  body.rtl .mr-2,
  body.rtl .mx-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important; }
  body.rtl .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem; }
  body.rtl .pl-0,
  body.rtl .px-0 {
    padding-right: 0 !important;
    padding-left: unset !important; }
  body.rtl .m-r-10 {
    margin-left: 10px;
    margin-right: unset; }
  body.rtl .radio_animated,
  body.rtl .checkbox_animated {
    margin: 0 0 0 1rem; }
  body.rtl .digital-add .form-group .radio_animated {
    margin-left: 8px;
    margin-right: unset; }
  body.rtl .needs-validation .form-group {
    text-align: right; }
    body.rtl .needs-validation .form-group .checkbox {
      padding-right: 0;
      padding-left: unset; }
  body.rtl .needs-validation .permission-block .attribute-blocks {
    padding-right: 15px;
    padding-left: unset;
    text-align: right; }
    body.rtl .needs-validation .permission-block .attribute-blocks .row {
      padding-right: 20px;
      padding-left: unset; }
  body.rtl .needs-validation .checkbox {
    right: 0;
    margin-left: -16px; }
    body.rtl .needs-validation .checkbox label {
      padding-right: 16px;
      padding-left: unset; }
      body.rtl .needs-validation .checkbox label::before {
        right: 0;
        left: unset;
        margin-left: unset;
        margin-right: -16px; }
  body.rtl .needs-validation .radio-animated label {
    margin-left: 20px;
    margin-right: unset; }
  body.rtl .modal-footer > :not(:last-child) {
    margin-left: 0.25rem;
    margin-right: unset; }
  body.rtl .jsgrid-button + .jsgrid-button {
    margin-right: 5px;
    margin-left: unset; }
  body.rtl .custom-theme {
    left: 0;
    right: unset;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }
  body.rtl .text-right {
    text-align: left !important; }
  body.rtl .br-wrapper {
    text-align: right; }
  body.rtl .card .card-header .card-header-right {
    left: 20px;
    right: unset; }
    body.rtl .card .card-header .card-header-right .card-option {
      text-align: left; }
      body.rtl .card .card-header .card-header-right .card-option.list-unstyled {
        padding-right: 0;
        padding-left: unset; }
  body.rtl code[class*='language-'],
  body.rtl pre[class*='language-'] {
    text-align: right; }
  body.rtl .code-box-copy__btn {
    right: unset;
    left: 11px; }
  body.rtl table thead tr th,
  body.rtl table thead tr td,
  body.rtl table tbody tr th,
  body.rtl table tbody tr td {
    text-align: right; }
  body.rtl .pull-right {
    float: left; }
  body.rtl .status-circle {
    right: 40px;
    left: unset; }
  body.rtl .right-sidebar.show {
    left: 0;
    right: unset; }
  body.rtl .right-sidebar .friend-list-search .fa {
    left: 35px;
    right: unset; }
  body.rtl .right-sidebar .chat-box .about {
    float: right;
    padding-right: 10px;
    padding-left: unset;
    text-align: right; }
  body.rtl .right-sidebar .chat-box .friend-list {
    text-align: right; }
  body.rtl .right-sidebar .chat-box .user-image {
    float: right;
    margin-left: 5px;
    margin-right: 0; }
  body.rtl .nav-menus .search-form input {
    padding: 10px 70px 10px 10px; }
  body.rtl ul.notification-dropdown.onhover-show-div li {
    text-align: right; }
  body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user h6,
  body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user p {
    text-align: center; }
  body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li {
    text-align: right; }
    body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active > .sidebar-submenu {
      margin-right: 15px;
      margin-left: unset; }
  body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a {
    padding-right: 10px;
    padding-left: unset; }
    body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > .fa-circle {
      right: 0;
      left: unset; }
  body.rtl .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header svg {
    margin-right: unset;
    margin-left: 14px; }
  body.rtl .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
    margin-right: 255px;
    margin-left: unset; }
  body.rtl .page-wrapper .page-body-wrapper .page-sidebar.open {
    margin-right: calc(-260px);
    margin-left: unset; }
    body.rtl .page-wrapper .page-body-wrapper .page-sidebar.open ~ .page-body,
    body.rtl .page-wrapper .page-body-wrapper .page-sidebar.open ~ footer {
      margin-right: 0; }
  body.rtl .page-wrapper .page-body-wrapper .page-header .row h3 {
    text-align: right; }
  body.rtl .page-wrapper .page-body-wrapper footer {
    margin-right: 255px;
    margin-left: unset; }
    body.rtl .page-wrapper .page-body-wrapper footer p i {
      margin-right: 5px;
      margin-left: unset; }
  body.rtl .page-main-header {
    margin-right: 255px;
    margin-left: unset; }
    body.rtl .page-main-header .main-header-right .nav-right {
      text-align: left; }
      body.rtl .page-main-header .main-header-right .nav-right .profile-dropdown li svg {
        margin-right: unset;
        margin-left: 10px; }
      body.rtl .page-main-header .main-header-right .nav-right > ul > li {
        text-align: right; }
        body.rtl .page-main-header .main-header-right .nav-right > ul > li:before {
          right: 0;
          left: unset; }
        body.rtl .page-main-header .main-header-right .nav-right > ul > li .dot {
          left: 17px;
          right: unset; }
        body.rtl .page-main-header .main-header-right .nav-right > ul > li .media .dotted-animation {
          left: -3px;
          right: unset; }
          body.rtl .page-main-header .main-header-right .nav-right > ul > li .media .dotted-animation .main-circle {
            left: 6px;
            right: unset; }
          body.rtl .page-main-header .main-header-right .nav-right > ul > li .media .dotted-animation .animate-circle {
            left: -4px;
            right: unset; }
        body.rtl .page-main-header .main-header-right .nav-right > ul > li.onhover-dropdown:hover .onhover-show-div:before {
          right: 10px;
          left: unset; }
        body.rtl .page-main-header .main-header-right .nav-right > ul > li.onhover-dropdown:hover .onhover-show-div:after {
          right: 10px;
          left: unset; }
        body.rtl .page-main-header .main-header-right .nav-right > ul > li.onhover-dropdown:last-child {
          padding-left: 0;
          padding-right: 20px;
          border-right: none; }
        body.rtl .page-main-header .main-header-right .nav-right > ul > li:first-child {
          border-right: none; }
          body.rtl .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:after {
            right: 22px;
            left: unset; }
          body.rtl .page-main-header .main-header-right .nav-right > ul > li:first-child .search-form .form-group:before {
            right: 51px;
            left: unset; }
        body.rtl .page-main-header .main-header-right .nav-right > ul > li:last-child {
          padding-right: 20px;
          padding-left: 0; }
        body.rtl .page-main-header .main-header-right .nav-right > ul > li i.ml-2 {
          margin-right: 0.5rem !important;
          margin-left: unset !important; }
      body.rtl .page-main-header .main-header-right .nav-right .language-dropdown {
        text-align: right; }
        body.rtl .page-main-header .main-header-right .nav-right .language-dropdown li a i {
          margin-left: 10px; }
      body.rtl .page-main-header .main-header-right .nav-right .profile-dropdown li a svg {
        margin-right: unset;
        margin-left: 10px; }
    body.rtl .page-main-header .main-header-right .mobile-sidebar {
      padding-right: 20px; }
    body.rtl .page-main-header.open {
      margin-right: 0; }
  body.rtl ul.notification-dropdown.onhover-show-div {
    right: initial;
    left: -30px;
    padding: 0; }
    body.rtl ul.notification-dropdown.onhover-show-div:before {
      right: inherit !important;
      left: 35px !important; }
    body.rtl ul.notification-dropdown.onhover-show-div:after {
      right: inherit !important;
      left: 35px !important; }
    body.rtl ul.notification-dropdown.onhover-show-div li {
      margin-right: 0 !important; }
      body.rtl ul.notification-dropdown.onhover-show-div li span.badge {
        margin-left: unset !important; }
      body.rtl ul.notification-dropdown.onhover-show-div li span svg {
        margin-right: unset;
        margin-left: 10px; }
      body.rtl ul.notification-dropdown.onhover-show-div li .notification-icon {
        margin-left: 20px;
        margin-right: unset; }
  body.rtl .owl-carousel {
    direction: ltr; }
  body.rtl .media .media-body {
    text-align: right; }
  body.rtl .order-graph .order-graph-bottom h6 {
    margin-right: 15px !important;
    margin-left: 30px; }
  body.rtl .latest-order-table .btn {
    float: right; }
  body.rtl .product-physical table tr th:nth-child(2),
  body.rtl .product-physical table tr td:nth-child(2) {
    text-align: right; }
  body.rtl .product-page-details .color-variant,
  body.rtl .product-page-details .size-box,
  body.rtl .product-right .color-variant,
  body.rtl .product-right .size-box {
    text-align: right; }
  body.rtl .add-product-form .qty-box .input-group .input-group-append .btn {
    border-right: none !important;
    border-left: 1px solid #dddddd !important; }
  body.rtl .add-product-form .qty-box .input-group .btn-primary.bootstrap-touchspin-down {
    border-left: none !important;
    border-right: 1px solid #dddddd !important; }
  body.rtl .add-product-form .offset-sm-4 {
    text-align: right; }
  body.rtl .timer {
    float: right; }
    body.rtl .timer ~ .m-t-15 {
      clear: both;
      text-align: right;
      padding-top: 15px; }
    body.rtl .timer span .padding-l {
      right: 35px;
      left: unset;
      padding-right: 0; }
  body.rtl .product-list table tr td,
  body.rtl .product-list table tr th,
  body.rtl .report-table table tr td,
  body.rtl .report-table table tr th {
    text-align: right !important; }
    body.rtl .product-list table tr td:last-child,
    body.rtl .product-list table tr th:last-child,
    body.rtl .report-table table tr td:last-child,
    body.rtl .report-table table tr th:last-child {
      text-align: center !important; }
  body.rtl .dropzone .dz-preview .dz-error-mark,
  body.rtl .dropzone .dz-preview .dz-success-mark {
    left: unset;
    right: 48%;
    margin-right: -27px;
    margin-left: unset; }
  body.rtl .dropzone.dz-clickable .dz-message * {
    text-align: center; }
  body.rtl .digital-add .col-form-label {
    display: block;
    text-align: right; }
  body.rtl .dataTables_wrapper .dataTables_filter input[type='search'] {
    margin-right: 10px;
    margin-left: unset; }
  body.rtl .dataTables_wrapper table.dataTable .vendor-list img {
    margin-left: 20px;
    margin-right: unset; }
  body.rtl .category-table table tr td:nth-child(3),
  body.rtl .category-table table tr th:nth-child(3) {
    text-align: right; }
  body.rtl .media-table table tr td:last-child,
  body.rtl .media-table table tr th:last-child {
    text-align: right !important; }
  body.rtl .profile-details h5 {
    text-align: center; }
  body.rtl .account-setting {
    text-align: right; }
  @media only screen and (max-width: 991px) {
    body.rtl .page-wrapper .page-main-header {
      margin-right: 0; }
    body.rtl .page-wrapper .page-body-wrapper .page-sidebar ~ .page-body,
    body.rtl .page-wrapper .page-body-wrapper .page-sidebar ~ footer {
      margin-right: 0; } }
  @media only screen and (max-width: 767px) {
    body.rtl .needs-validation .form-group .checkbox {
      padding-right: 15px;
      padding-left: unset; }
    body.rtl .page-wrapper .page-body-wrapper footer p {
      text-align: center; } }
  @media (min-width: 1200px) {
    body.rtl .offset-xl-3 {
      margin-right: 25%;
      margin-left: unset; } }
