@import url(~slick-carousel/slick/slick.css);
@import url(~slick-carousel/slick/slick-theme.css);
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  /* Makes all the box-sizing get inherited from parents */
  box-sizing: inherit; }

html {
  font-size: 0.87rem;
  -ms-overflow-style: scrollbar;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: scroll;
  overflow-x: hidden; }

::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */ }

/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

textarea {
  overflow: auto;
  resize: vertical; }

body {
  /* For power of inheritance, we specify in the body */
  /* Lato and if its not available, sans-serif */
  font-family: 'Nanum Gothic', sans-serif;
  font-weight: 400;
  /* Line-height is 1.7times bigger than the pre-defined line height.*/
  line-height: 1.7;
  color: #212121;
  /* change the box model so that the borders and the paddings are not added to the height and width of the box. */
  box-sizing: border-box; }

a {
  text-decoration: none !important; }
  a:hover {
    color: #212121; }

input:focus,
input :hover,
input :active {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #212121;
  text-decoration: none; }

.small-text {
  font-size: 0.8rem;
  color: #757575; }

.make-link-black {
  color: #212121; }

.landing-titles {
  margin-top: 3vh;
  margin-bottom: 3vh;
  font-weight: 600; }

.catcnap-logo-text {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 1.3rem;
  color: #212121; }

.bg-login-image {
  background: url("../assets/photos/login.jpg");
  background-position: center;
  background-size: cover; }

.bg-signup-image {
  background: url("../assets/photos/signup.jpg");
  background-position: center;
  background-size: cover; }

.radio-item {
  display: block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0; }

.radio-item input[type='radio'] {
  display: none; }

.radio-item label {
  color: #666;
  font-weight: 600; }

.radio-item label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #b5dccd;
  background-color: transparent; }

.radio-item input[type='radio']:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: ' ';
  display: block;
  background: #b5dccd; }

.btn-file {
  position: relative;
  overflow: hidden; }

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block; }

.border-radius-zero {
  border-radius: 0; }

.btn-yellow {
  background-color: 'yellow';
  color: 'black';
  border-radius: 0;
  border: none; }
  .btn-yellow:focus,
  .btn-yellow :hover,
  .btn-yellow :active {
    outline: none;
    box-shadow: none;
    border: none;
    text-decoration: none; }

.btn-clear {
  background: transparent;
  border: none; }
  .btn-clear:focus,
  .btn-clear :hover,
  .btn-clear :active {
    outline: none;
    box-shadow: none;
    border: none;
    text-decoration: none; }

.nav-link:focus,
.nav-link :hover,
.nav-link :active {
  background: transparent !important; }

.btn-naver {
  background-color: #00c73c; }

.btn-google {
  background-color: #de5246; }

.form-control-user::placeholder {
  color: #757575;
  font-size: 0.8rem;
  opacity: 1;
  /* Firefox */ }

.form-control-user:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #757575; }

.form-control-user::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #757575; }

#exampleInputEmail {
  border-radius: 0 !important;
  border-color: #bdbdbd; }

#exampleInputPassword {
  border-radius: 0 !important;
  border-color: #bdbdbd; }

.form_search {
  height: 45px;
  -webkit-box-shadow: 0 0 0 1px #e2e2e2;
  box-shadow: 0 0 0 1px #e2e2e2;
  overflow: hidden;
  position: relative;
  width: 525px;
  background-color: transparent !important;
  border-radius: none !important; }

.form_search input {
  width: 100%;
  height: 45px;
  font-size: 16px;
  color: white;
  border: none;
  outline: none;
  background: none;
  padding: 0 10px; }
  .form_search input::placeholder {
    color: #bdbdbd;
    opacity: 1;
    /* Firefox */ }

.form_search button {
  position: absolute;
  color: white;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 35px;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: none; }

@media (max-width: 576px) {
  .form_search input {
    color: black; } }

.modal-footer {
  justify-content: center !important; }

.modal-content {
  border-radius: 0; }

.modal-body {
  font-weight: 500; }

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li a {
  font-weight: 600 !important;
  font-size: 13px !important; }

.main-menu .menu-right .icon-nav .onhover-div .show-div.setting ul li {
  padding-left: 1px !important; }

@media (max-width: 576px) {
  header.masthead {
    background-image: url("../assets/photos/login.jpg");
    color: black; }
    header.masthead .intro-text-bg {
      font-size: 1.9rem;
      color: black; }
    header.masthead .intro-text-sm {
      font-size: 1.2rem;
      text-align: left;
      letter-spacing: 0.1rem;
      color: black; } }

@media (max-width: 576px) {
  header.masthead2 {
    background-image: url("../assets/photos/login.jpg");
    color: black; }
    header.masthead2 .intro-text-bg {
      font-size: 1.3rem;
      color: black; }
    header.masthead2 .intro-text-sm {
      font-size: 1rem;
      text-align: left;
      letter-spacing: 0.1rem;
      color: black; } }

header.masthead-2 {
  text-align: center;
  background-image: url("https://artbanana.s3.ap-northeast-2.amazonaws.com/website/street-with-pencil.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 40rem; }
  header.masthead-2 .intro-text-bg {
    font-size: 2.5rem;
    color: black; }
  header.masthead-2 .intro-text-sm {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    color: black; }

@media (max-width: 576px) {
  header.masthead-2 {
    background-image: url("https://artbanana.s3.ap-northeast-2.amazonaws.com/website/street-portrait.jpg");
    color: black; }
    header.masthead-2 .intro-text-bg {
      font-size: 1.2rem;
      color: black; }
    header.masthead-2 .intro-text-sm {
      font-size: 1.2rem;
      text-align: left;
      letter-spacing: 0.1rem;
      color: black; } }

header.masthead-3 {
  text-align: center;
  background-image: url("../assets/photos/green-landscape.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 40rem; }
  header.masthead-3 .intro-text-bg {
    font-size: 3rem;
    color: black; }
  header.masthead-3 .intro-text-sm {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    color: black; }

@media (max-width: 576px) {
  header.masthead-3 {
    background-image: url("https://artbanana.s3.ap-northeast-2.amazonaws.com/website/green-portrait.jpg");
    color: black; }
    header.masthead-3 .intro-text-bg {
      font-size: 1.9rem;
      color: black; }
    header.masthead-3 .intro-text-sm {
      font-size: 1.2rem;
      text-align: left;
      letter-spacing: 0.1rem;
      color: black; } }

header.masthead-4 {
  background-image: url("https://artbanana.s3.ap-northeast-2.amazonaws.com/website/catalogue1.jpg");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  width: 100vw;
  height: 60rem; }
  header.masthead-4 .intro-text-bg {
    font-size: 1.5rem;
    font-weight: 600;
    color: black; }
  header.masthead-4 .intro-text-sm {
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    color: black; }

@media (max-width: 576px) {
  header.masthead-4 {
    background-image: url("https://artbanana.s3.ap-northeast-2.amazonaws.com/website/catalogue-mobile.jpg");
    color: black;
    height: 30rem; }
    header.masthead-4 .intro-text-bg {
      font-size: 1.9rem;
      color: black; }
    header.masthead-4 .intro-text-sm {
      font-size: 1.2rem;
      text-align: left;
      letter-spacing: 0.1rem;
      color: black; } }

ul li a.search-link {
  color: black !important; }
  @media (max-width: 577px) {
    ul li a.search-link {
      color: white !important; } }

@media (max-width: 577px) {
  .main-menu .menu-right .icon-nav .mobile-setting {
    bottom: 20px;
    right: 29% !important;
    font-size: 1rem !important;
    color: white; } }

@media (max-width: 577px) {
  .main-menu .menu-right .icon-nav .mobile-search {
    bottom: 20px;
    right: 75% !important;
    font-size: 1rem !important;
    color: white !important; } }

@media (max-width: 577px) {
  .main-menu .menu-right .icon-nav .mobile-globe {
    bottom: 20px;
    right: 47% !important;
    font-size: 1rem !important;
    color: white !important; } }

@media (max-width: 577px) {
  .main-menu .menu-right .icon-nav .mobile-person {
    right: 20% !important; } }

@media (max-width: 577px) {
  .main-menu .menu-right .icon-nav .mobile-basket {
    right: 65% !important;
    color: white !important; }
    .main-menu .menu-right .icon-nav .mobile-basket > div > a {
      color: white !important; } }

.small-slider .home-slider .home .slider-contain .photo-section {
  margin: 0 30% !important; }
  .small-slider .home-slider .home .slider-contain .photo-section p {
    font-size: 1rem; }

@media (max-width: 577px) {
  .small-slider .home-slider .home .slider-contain .photo-section {
    margin: 0 !important; }
    .small-slider .home-slider .home .slider-contain .photo-section p {
      font-size: 0.8rem; } }

.testimonial .testimonial-brand {
  fill: currentColor;
  margin-bottom: 1.35rem; }
  .testimonial .testimonial-brand svg {
    height: 3rem; }

.testimonial .testimonial-quote {
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 1.35; }

.testimonial .testimonial-name {
  font-weight: bold; }

.testimonial .testimonial-position {
  font-weight: lighter; }

@media (min-width: 992px) {
  .divider-right {
    border-right: 0.0625rem solid #dee2e6; } }

.testimonial .testimonial-brand {
  fill: currentColor;
  margin-bottom: 1.35rem; }

.testimonial .testimonial-brand svg {
  height: 3rem; }

.testimonial .testimonial-quote {
  font-size: 1.7rem;
  font-weight: 700; }

.testimonial .testimonial-name {
  font-weight: bold; }

.testimonial .testimonial-position {
  font-weight: lighter; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: flex;
  height: 3rem;
  overflow: hidden;
  font-size: 1.1rem;
  background-color: #e3e6ec;
  border-radius: 0.35rem;
  margin-bottom: 2rem; }

.progress-bar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: left;
  white-space: nowrap;
  background-color: #0061f2;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  padding-left: 0.7rem; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none; } }

i-feather {
  display: inline-flex !important; }
  i-feather svg {
    height: 100%;
    width: 100%;
    vertical-align: top; }

.feather {
  height: 1rem;
  width: 1rem;
  vertical-align: top; }

.icon-stack {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: 2rem;
  width: 2rem;
  font-size: 1rem;
  background-color: #f8f9fa; }
  .icon-stack svg {
    height: 1rem;
    width: 1rem; }

.icon-stack-lg {
  height: 4rem;
  width: 4rem;
  font-size: 1.5rem; }
  .icon-stack-lg svg {
    height: 1.5rem;
    width: 1.5rem; }

.icon-stack-xl {
  height: 5rem;
  width: 5rem;
  font-size: 1.75rem; }
  .icon-stack-xl svg {
    height: 1.75rem;
    width: 1.75rem; }

.icon-list-social {
  display: flex; }
  .icon-list-social .icon-list-social-link {
    font-size: 1.25rem;
    margin-right: 0.5rem; }

section {
  position: relative; }

.svg-border-angled {
  padding-top: 5rem; }
  .svg-border-angled svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5rem;
    width: 100%; }

.svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%; }

.svg-border-waves {
  padding-top: 2rem; }
  .svg-border-waves svg {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%; }

.badge-transparent-light {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.1); }

.badge-transparent-dark {
  color: rgba(33, 37, 41, 0.5);
  background-color: rgba(33, 37, 41, 0.8); }

.badge-marketing {
  padding: 0.5em 1em; }

.badge-sm {
  font-size: 0.75rem; }

.badge-md {
  font-size: 1rem; }

.badge-lg {
  font-size: 1.25rem; }

.border-lg {
  border-width: 0.25rem !important; }

.border-top-lg {
  border-top-width: 0.25rem !important; }

.border-right-lg {
  border-right-width: 0.25rem !important; }

.border-bottom-lg {
  border-bottom-width: 0.25rem !important; }

.border-left-lg {
  border-left-width: 0.25rem !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.rounded-xl {
  border-radius: 1rem !important; }

.border-gray-100 {
  border-color: #f8f9fa !important; }

.border-gray-200 {
  border-color: #e9ecef !important; }

.border-gray-300 {
  border-color: #dee2e6 !important; }

.border-gray-400 {
  border-color: #ced4da !important; }

.border-gray-500 {
  border-color: #adb5bd !important; }

.border-gray-600 {
  border-color: #6c757d !important; }

.border-gray-700 {
  border-color: #495057 !important; }

.border-gray-800 {
  border-color: #343a40 !important; }

.border-gray-900 {
  border-color: #212529 !important; }

.lift {
  box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 37, 41, 0.15);
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .lift:hover {
    transform: translateY(-0.33333rem);
    box-shadow: 0 0.5rem 2rem 0 rgba(33, 37, 41, 0.25); }
  .lift:active {
    transform: none;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(33, 37, 41, 0.15); }

.lift-sm {
  box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 37, 41, 0.2); }
  .lift-sm:hover {
    transform: translateY(-0.16667rem);
    box-shadow: 0 0.25rem 1rem 0 rgba(33, 37, 41, 0.25); }
  .lift-sm:active {
    transform: none;
    box-shadow: 0 0.125rem 0.25rem 0 rgba(33, 37, 41, 0.2); }

.card.lift {
  text-decoration: none;
  color: inherit; }
